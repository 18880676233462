import {useTranslation} from "react-i18next";
import {useAppDispatch} from "../../../../hooks/hooks";
import {useUtil} from "../../../../util/hooks/useUtil";
import {useCallback} from "react";
import {callAsync} from "../../../../util/sliceUtil";
import {deleteCounselingMemoAsync} from "../../../../store/counselorSlice";

interface Args {
    onRefresh: () => void;
}
export function useCounselorMemoDeleter({ onRefresh }: Args) {
    const { t } = useTranslation("memo");
    const dispatch = useAppDispatch();
    const { defaultErrorMessage } = useUtil();

    const deleteMemo = useCallback(
        (memoIdx: number) => {
            const wantToDelete = window.confirm(t("delete.confirm"));

            if (wantToDelete) {
                callAsync(
                    dispatch(deleteCounselingMemoAsync(memoIdx)).unwrap(),
                    () => {
                        onRefresh();
                        alert(t("delete.result"));
                    },
                    () => alert(defaultErrorMessage)
                ).then();
            }
        },
        [dispatch, onRefresh, t, defaultErrorMessage]
    );

    return {
        deleteMemo,
    };
}