import style from "../../style/test_result/guide/learningStrategy.module.scss";
import {BrainType} from "../../../types/testResult";
import {Trans, useTranslation} from "react-i18next";
import React from "react";

interface Args {
    brainType: BrainType
}

/* eslint-disable max-len */
export function LeftLearningStrategyGuide({brainType}: Args) {

    /**
     * 두뇌유형별 학습법 중 특징
     */

    const {t} = useTranslation("guideLearningStrategy");
    const brainValue = brainType === BrainType.BALANCED ? `${t('summary.left.title')} - ` : '';
    const subject = 'strategy.left.recommendation';

    //todo 리팩토링 필요
    return (
        <>
            {/*  좌뇌우세형 특징  */}
            {brainValue && (
                <article className={style.article}>
                    <h2 className={style.title}>{t('strategy.left.characteristics.title')}</h2>
                    <p className={style.description}>
                        <Trans t={t} i18nKey="strategy.left.characteristics.description"
                               values={{blank: '\u00A0'}} components={{br: <br/>,}}/>
                    </p>
                </article>
            )}

            {/*  좌뇌우세형 - 효율적인 학습법  */}
            <article className={style.article}>
                <h2 className={style.title}>
                    <Trans t={t} i18nKey="strategy.left.learningStrategy.title"
                           values={{name: brainValue, blank: '\u00A0'}} components={{br: <br/>,}}/>
                </h2>
                <img src="https://images.feel-good.io/guide/left/left_01.png" alt={'1'}/>

                <p className={style.description}>{t('strategy.left.learningStrategy.description')}</p>

                <article className={style.subArticle}>
                    <section>
                        <h3 className={style.title}>
                            <span className={style.number}>1</span>
                            <span>{t('strategy.left.learningStrategy.subTitle1')}</span>
                        </h3>
                        <p className={style.description}>
                            <Trans t={t} i18nKey="strategy.left.learningStrategy.subDesc1"
                                   values={{blank: '\u00A0'}}/>
                        </p>
                    </section>
                    <section>
                        <h3 className={style.title}>
                            <span className={style.number}>2</span>
                            <span>{t('strategy.left.learningStrategy.subTitle2')}</span>
                        </h3>
                        <p className={style.description}>
                            <Trans t={t} i18nKey="strategy.left.learningStrategy.subDesc2"
                                   values={{blank: '\u00A0'}}/>
                        </p>
                    </section>
                    <section>
                        <h3 className={style.title}>
                            <span className={style.number}>3</span>
                            <span>{t('strategy.left.learningStrategy.subTitle3')}</span>
                        </h3>
                        <p className={style.description}>
                            <Trans t={t} i18nKey="strategy.left.learningStrategy.subDesc3"
                                   values={{blank: '\u00A0'}}/>
                        </p>
                    </section>
                    <section>
                        <h3 className={style.title}>
                            <span className={style.number}>4</span>
                            <span>{t('strategy.left.learningStrategy.subTitle4')}</span>
                        </h3>
                        <p className={style.description}>
                            <Trans t={t} i18nKey="strategy.left.learningStrategy.subDesc4"
                                   values={{blank: '\u00A0'}}/>
                        </p>
                    </section>
                </article>
            </article>

            {/*  좌뇌우세형 -  부족한 영역 보충 학습법  */}
            <article className={style.article}>
                <h2 className={style.title}>
                    <Trans t={t} i18nKey="strategy.left.weakness.title"
                           values={{name: brainValue, blank: '\u00A0'}} components={{br: <br/>,}}/>
                </h2>
                <img src="https://images.feel-good.io/guide/left/left_02.png" alt={'2'}/>

                <article className={style.subArticle}>
                    <section>
                        <h3 className={style.title}>
                            <span className={style.number}>1</span>
                            <span>{t('strategy.left.weakness.subTitle1')}</span>
                        </h3>
                        <p className={style.description}>
                            <Trans t={t} i18nKey="strategy.left.weakness.subDesc1"
                                   values={{blank: '\u00A0'}}/>
                        </p>
                    </section>
                    <section>
                        <h3 className={style.title}>
                            <span className={style.number}>2</span>
                            <span>{t('strategy.left.weakness.subTitle2')}</span>
                        </h3>
                        <p className={style.description}>
                            <Trans t={t} i18nKey="strategy.left.weakness.subDesc1" values={{blank: '\u00A0'}}/>
                        </p>
                    </section>
                    <section>
                        <h3 className={style.title}>
                            <span className={style.number}>3</span>
                            <span>{t('strategy.left.weakness.subTitle3')}</span>
                        </h3>
                        <p className={style.description}>
                            <Trans t={t} i18nKey="strategy.left.weakness.subDesc2" values={{blank: '\u00A0'}}/>
                        </p>
                    </section>
                    <section>
                        <h3 className={style.title}>
                            <span className={style.number}>4</span>
                            <span>{t('strategy.left.weakness.subTitle4')}</span>
                        </h3>
                        <p className={style.description}>
                            <Trans t={t} i18nKey="strategy.left.weakness.subDesc4" values={{blank: '\u00A0'}}/>
                        </p>
                    </section>
                    <section>
                        <h3 className={style.title}>
                            <span className={style.number}>5</span>
                            <span>{t('strategy.left.weakness.subTitle5')}</span>
                        </h3>
                        <p className={style.description}>
                            <Trans t={t} i18nKey="strategy.left.weakness.subDesc5" values={{blank: '\u00A0'}}/>
                        </p>
                    </section>
                </article>
            </article>

            {/*  좌뇌우세형 - 과목별 추천 학습법  */}
            <article className={style.article}>
                <h2 className={style.title}>
                    <Trans t={t} i18nKey="strategy.left.recommendation.title"
                           values={{name: brainValue, blank: '\u00A0'}} components={{br: <br/>,}}/>
                </h2>
                <img src="https://images.feel-good.io/guide/left/left_03.png" alt={'3'}/>

                <p className={style.description}>
                    <Trans t={t} i18nKey="strategy.left.recommendation.description"
                           values={{blank: '\u00A0'}}
                           components={{
                               br: <br/>,
                               bold: <span className={style.bold}/>,
                               underlineText: <span className={style.underlineText}/>,
                           }}/>
                </p>
                <article className={style.subArticle}>
                    <section>
                        <h3 className={style.title}>
                            {/*  국어, 독서  */}
                            <span className={style.number}>1</span>
                            <span>{t(subject + '.subject1.title')}</span>
                        </h3>
                        <div className={style.description}>
                            <dl>{t(subject + '.subject1.group1')}</dl>
                            <dt>{t(subject + '.subject1.group1Title1')}</dt>
                            <dd>{t(subject + '.subject1.group1Desc1')}</dd>
                            <dt>{t(subject + '.subject1.group1Title2')}</dt>
                            <dd>{t(subject + '.subject1.group1Desc2')}</dd>
                            <dt>{t(subject + '.subject1.group1Title3')}</dt>
                            <dd>{t(subject + '.subject1.group1Desc3')}</dd>
                            <dt>{t(subject + '.subject1.group1Title4')}</dt>
                            <dd>{t(subject + '.subject1.group1Desc4')}</dd>
                            <dt>{t(subject + '.subject1.group1Title5')}</dt>
                            <dd>{t(subject + '.subject1.group1Desc5')}</dd>
                            <dt>{t(subject + '.subject1.group1Title6')}</dt>
                            <dd>{t(subject + '.subject1.group1Desc6')}</dd>
                        </div>

                        <div className={style.description}>
                            <span className={style.subTitle}><dl>{t(subject + '.subject1.group2')}</dl></span>
                            <ol>
                                <li>
                                    <h4>{t(subject + '.subject1.group2Title1')}</h4>
                                    <span>{t(subject + '.subject1.group2Desc1')}</span>
                                </li>
                                <li>
                                    <h4>{t(subject + '.subject1.group2Title2')}</h4>
                                    <span>{t(subject + '.subject1.group2Desc2')}</span>
                                </li>
                                <li>
                                    <h4>{t(subject + '.subject1.group2Title3')}</h4>
                                    <span>{t(subject + '.subject1.group2Desc3')}</span>
                                </li>
                                <li>
                                    <h4>{t(subject + '.subject1.group2Title4')}</h4>
                                    <span>{t(subject + '.subject1.group2Desc4')}</span>
                                </li>
                            </ol>
                        </div>
                    </section>
                </article>
                <article className={style.subArticle}>
                    <section>
                        <h3 className={style.title}>
                            <span className={style.number}>2</span>
                            <span>{t(subject + '.subject2.title')}</span>
                        </h3>
                        <ul className={style.description}>
                            <li>
                                <h4 className={style.title}>{t(subject + '.subject2.group1Title1')}</h4>
                                <Trans t={t} i18nKey={`${subject}.subject2.group1Desc1`} values={{blank: '\u00A0'}} />
                            </li>
                            <li>
                                <h4 className={style.title}>{t(subject + '.subject2.group1Title2')}</h4>
                                <Trans t={t} i18nKey={`${subject}.subject2.group1Desc2`} values={{blank: '\u00A0'}} />
                            </li>
                            <li>
                                <h4 className={style.title}>{t(subject + '.subject2.group1Title3')}</h4>
                                <Trans t={t} i18nKey={`${subject}.subject2.group1Desc3`} values={{blank: '\u00A0'}} />
                            </li>
                            <li>
                                <h4 className={style.title}>{t(subject + '.subject2.group1Title4')}</h4>
                                <Trans t={t} i18nKey={`${subject}.subject2.group1Desc4`} values={{blank: '\u00A0'}} />
                            </li>
                            <li>
                                <h4 className={style.title}>{t(subject + '.subject2.group1Title5')}</h4>
                                <Trans t={t} i18nKey={`${subject}.subject2.group1Desc5`} values={{blank: '\u00A0'}} />
                            </li>
                        </ul>
                    </section>
                </article>
                <article className={style.subArticle}>
                    <section>
                        <h3 className={style.title}>
                            <span className={style.number}>3</span>
                            <span>{t(subject + '.subject3.title')}</span>

                        </h3>
                        <ul className={style.description}>
                            <li>
                                <h4 className={style.title}>{t(subject + '.subject3.group1Title1')}</h4>
                                <Trans t={t} i18nKey={`${subject}.subject3.group1Desc1`} values={{blank: '\u00A0'}} />
                            </li>
                            <li>
                                <h4 className={style.title}>{t(subject + '.subject3.group1Title2')}</h4>
                                <Trans t={t} i18nKey={`${subject}.subject3.group1Desc2`} values={{blank: '\u00A0'}} />
                            </li>
                            <li>
                                <h4 className={style.title}>{t(subject + '.subject3.group1Title3')}</h4>
                                <Trans t={t} i18nKey={`${subject}.subject3.group1Desc3`} values={{blank: '\u00A0'}} />
                            </li>
                            <li>
                                <h4 className={style.title}>{t(subject + '.subject3.group1Title4')}</h4>
                                <Trans t={t} i18nKey={`${subject}.subject3.group1Desc4`} values={{blank: '\u00A0'}} />
                            </li>
                            <li>
                                <h4 className={style.title}>{t(subject + '.subject3.group1Title5')}</h4>
                                <Trans t={t} i18nKey={`${subject}.subject3.group1Desc5`} values={{blank: '\u00A0'}} />
                            </li>
                            <li>
                                <h4 className={style.title}>{t(subject + '.subject3.group1Title6')}</h4>
                                <Trans t={t} i18nKey={`${subject}.subject3.group1Desc6`} values={{blank: '\u00A0'}} />
                            </li>
                        </ul>
                    </section>
                </article>
                <article className={style.subArticle}>
                    <section>
                        <h3 className={style.title}>
                            <span className={style.number}>4</span>
                            <span>{t(subject + '.subject4.title')}</span>

                        </h3>
                        <ul className={style.description}>
                            <li>
                                <h4 className={style.title}>{t(subject + '.subject4.group1Title1')}</h4>
                                <Trans t={t} i18nKey={`${subject}.subject4.group1Desc1`} values={{blank: '\u00A0'}} />
                            </li>
                            <li>
                                <h4 className={style.title}>{t(subject + '.subject4.group1Title2')}</h4>
                                <Trans t={t} i18nKey={`${subject}.subject4.group1Desc2`} values={{blank: '\u00A0'}} />
                            </li>
                            <li>
                                <h4 className={style.title}>{t(subject + '.subject4.group1Title3')}</h4>
                                <Trans t={t} i18nKey={`${subject}.subject4.group1Desc3`} values={{blank: '\u00A0'}} />
                            </li>
                            <li>
                                <h4 className={style.title}>{t(subject + '.subject4.group1Title4')}</h4>
                                <Trans t={t} i18nKey={`${subject}.subject4.group1Desc4`} values={{blank: '\u00A0'}} />
                            </li>
                            <li>
                                <h4 className={style.title}>{t(subject + '.subject4.group1Title5')}</h4>
                                <Trans t={t} i18nKey={`${subject}.subject4.group1Desc5`} values={{blank: '\u00A0'}} />
                            </li>
                        </ul>
                    </section>
                </article>
                <article className={style.subArticle}>
                    <section>
                        <h3 className={style.title}>
                            <span className={style.number}>5</span>
                            <span>{t(subject + '.subject5.title')}</span>

                        </h3>
                        <ul className={style.description}>
                            <li>
                                <h4 className={style.title}>{t(subject + '.subject5.group1Title1')}</h4>
                                <Trans t={t} i18nKey={`${subject}.subject5.group1Desc1`} values={{blank: '\u00A0'}} />
                            </li>
                            <li>
                                <h4 className={style.title}>{t(subject + '.subject5.group1Title2')}</h4>
                                <Trans t={t} i18nKey={`${subject}.subject5.group1Desc2`} values={{blank: '\u00A0'}} />
                            </li>
                            <li>
                                <h4 className={style.title}>{t(subject + '.subject5.group1Title3')}</h4>
                                <Trans t={t} i18nKey={`${subject}.subject5.group1Desc3`} values={{blank: '\u00A0'}} />
                            </li>
                            <li>
                                <h4 className={style.title}>{t(subject + '.subject5.group1Title4')}</h4>
                                <Trans t={t} i18nKey={`${subject}.subject5.group1Desc4`} values={{blank: '\u00A0'}} />
                            </li>
                            <li>
                                <h4 className={style.title}>{t(subject + '.subject5.group1Title5')}</h4>
                                <Trans t={t} i18nKey={`${subject}.subject5.group1Desc5`} values={{blank: '\u00A0'}} />
                            </li>
                            <li>
                                <h4 className={style.title}>{t(subject + '.subject5.group1Title6')}</h4>
                                <Trans t={t} i18nKey={`${subject}.subject5.group1Desc1`} values={{blank: '\u00A0'}} />
                            </li>
                        </ul>
                    </section>
                </article>
            </article>

            {/*  좌뇌우세형 - 두뇌 훈련을 위한 놀이법  */}
            <article className={style.article}>
                <h2 className={style.title}>
                    <Trans t={t} i18nKey="strategy.left.brainTraining.title"
                           values={{name: brainValue, blank: '\u00A0'}} components={{br: <br/>,}}/>
                </h2>
                <img src="https://images.feel-good.io/guide/left/left_04.png" alt={'4'}/>

                <p className={style.description}>
                    <Trans t={t} i18nKey="strategy.left.brainTraining.description" />
                </p>
                <article className={style.subArticle}>
                    <section>
                        <h3 className={style.title}>
                            <span className={style.number}>1</span>
                            <span>{t('strategy.left.brainTraining.subTitle1')}</span>
                        </h3>
                        <p className={style.description}>
                            <Trans t={t} i18nKey="strategy.left.brainTraining.subDesc1" values={{blank: '\u00A0'}}/>
                        </p>
                    </section>
                    <section>
                        <h3 className={style.title}>
                            <span className={style.number}>2</span>
                            <span>{t('strategy.left.brainTraining.subTitle2')}</span>
                        </h3>
                        <p className={style.description}>
                            <Trans t={t} i18nKey="strategy.left.brainTraining.subDesc2" values={{blank: '\u00A0'}}/>
                        </p>
                    </section>
                    <section>
                        <h3 className={style.title}>
                            <span className={style.number}>3</span>
                            <span>{t('strategy.left.brainTraining.subTitle3')}</span>
                        </h3>
                        <p className={style.description}>
                            <Trans t={t} i18nKey="strategy.left.brainTraining.subDesc3" values={{blank: '\u00A0'}}/>
                        </p>
                    </section>
                    <section>
                        <h3 className={style.title}>
                            <span className={style.number}>4</span>
                            <span>{t('strategy.left.brainTraining.subTitle4')}</span>
                        </h3>
                        <p className={style.description}>
                            <Trans t={t} i18nKey="strategy.left.brainTraining.subDesc4" values={{blank: '\u00A0'}}/>
                        </p>
                    </section>
                    <section>
                        <h3 className={style.title}>
                            <span className={style.number}>5</span>
                            <span>{t('strategy.left.brainTraining.subTitle5')}</span>
                        </h3>
                        <p className={style.description}>
                            <Trans t={t} i18nKey="strategy.left.brainTraining.subDesc5" values={{blank: '\u00A0'}}/>
                        </p>
                    </section>
                    <section>
                        <h3 className={style.title}>
                            <span className={style.number}>6</span>
                            <span>{t('strategy.left.brainTraining.subTitle6')}</span>
                        </h3>
                        <p className={style.description}>
                            <Trans t={t} i18nKey="strategy.left.brainTraining.subDesc6" values={{blank: '\u00A0'}}/>
                        </p>
                    </section>
                </article>
                <p className={style.description}>
                    <Trans t={t} i18nKey="strategy.left.brainTraining.subDesc7"
                           values={{blank: '\u00A0'}}
                           components={{bold:<span className={style.bold} />}}/>
                </p>
            </article>

            <article className={style.article}>
                <h2 className={style.title}>
                    <Trans t={t} i18nKey="strategy.left.supplement.title"
                           values={{name: brainValue, blank: '\u00A0'}} components={{br: <br/>,}}/>
                </h2>
                <img src="https://images.feel-good.io/guide/left/left_05.png" alt={'5'}/>

                <article className={style.subArticle}>
                    <section>
                        <h3 className={style.title}>
                            <span className={style.number}>1</span>
                            <span>{t('strategy.left.supplement.subTitle1')}</span>
                        </h3>
                        <p className={style.description}>
                            <Trans t={t} i18nKey="strategy.left.supplement.subDesc1" values={{blank: '\u00A0'}}/>
                        </p>
                    </section>
                    <section>
                        <h3 className={style.title}>
                            <span className={style.number}>2</span>
                            <span>{t('strategy.left.supplement.subTitle2')}</span>
                        </h3>
                        <p className={style.description}>
                            <Trans t={t} i18nKey="strategy.left.supplement.subDesc2" values={{blank: '\u00A0'}}/>
                        </p>
                    </section>
                    <section>
                        <h3 className={style.title}>
                            <span className={style.number}>3</span>
                            <span>{t('strategy.left.supplement.subTitle3')}</span>
                        </h3>
                        <p className={style.description}>
                            <Trans t={t} i18nKey="strategy.left.supplement.subDesc3" values={{blank: '\u00A0'}}/>
                        </p>
                    </section>
                    <section>
                        <h3 className={style.title}>
                            <span className={style.number}>4</span>
                            <span>{t('strategy.left.supplement.subTitle4')}</span>
                        </h3>
                        <p className={style.description}>
                            <Trans t={t} i18nKey="strategy.left.supplement.subDesc4" values={{blank: '\u00A0'}}/>
                        </p>
                    </section>
                    <section>
                        <h3 className={style.title}>
                            <span className={style.number}>5</span>
                            <span>{t('strategy.left.supplement.subTitle5')}</span>
                        </h3>
                        <p className={style.description}>
                            <Trans t={t} i18nKey="strategy.left.supplement.subDesc5" values={{blank: '\u00A0'}}/>
                        </p>

                        <div className={style.description}>
                            <h4 className={style.title}>
                                추천 게임
                            </h4>
                            <ul className={style.tagWrap}>
                                <Trans t={t} i18nKey="strategy.left.supplement.recommendationGame"
                                       values={{blank: '\u00A0'}}
                                       components={{
                                           li:<li className={style.tag}/>,
                                           span:<span className={style.eng} />
                                       }}
                                />

                            </ul>
                        </div>
                    </section>
                </article>
            </article>
        </>
    );
}