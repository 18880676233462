import { AdminMenuCode } from "./adminRole";
import { ReactNode } from "react";

enum Status {
  IDLE = "idle",
  LOADING = "loading",
  FAILED = "failed",
}

enum ModalType {
  NONE = "NONE",
  TERMS = "TERMS",
}

interface SliceState {
  status: Status;
}

interface Menu {
  label: string;
  link: string;
  subMenu?: Menu[];
  isDisabled: boolean;
  isSpecial: boolean;
  menuCode?: AdminMenuCode;
}

interface ListObject {
  idx: number;
}

interface TableColumnData<T extends ListObject> {
  accessor: keyof T;
  header: string | ReactNode;
  getNode?: (a: T, idx: number) => ReactNode;
  sortable: boolean;
  className?: string;
  isLeftAlign?: boolean;
  isRightAlign?: boolean;
  menuCode?: AdminMenuCode;
  width?: number;
  hideThisColumn?: boolean;
  padding?: string;
}

enum Gender {
  MALE = "MALE",
  FEMALE = "FEMALE",
}

enum CustomerType {
  USER = "USER",
  ORGAN_MANAGER = "ORGAN_MANAGER",
}

export enum WebsiteType {
  KPASS = "KPASS",
  DCAS = "DCAS",
}

export enum Languages{
  KO = "ko",
  EN = "en",
  ZH = "zh",
  RU = "ru",
}

export interface SearchByKeyword {
  query?: string;
}

export type { SliceState, Menu, ListObject, TableColumnData };
export { Status, ModalType, Gender, CustomerType };

export enum TestResultFormType{
  /* 각각 정식, 약식, 추가 결과지 */
  FORMAL = "FORMAL",
  SUMMARY = "SUMMARY",
  ADDITIONAL = "ADDITIONAL",
}

export enum AdditionalResultType{
  /* 각각 정식, 약식, 추가 결과지 */
  SPECIALIZED_HIGH_SCHOOL = "SPECIALIZED_HIGH_SCHOOL",
  CORPORATE_INTERVIEW = "CORPORATE_INTERVIEW",
  NONE = "NONE",
}

export enum TestType{
  KPASS = "KPASS",
  DCAS = "DCAS",
}

export enum ValidStatus{
  /* 각각 사용가능, 공백 예외, 상세정보 공백 예외, 중복, 미선택, 타입 불일치, 값 불일치, 길이 */
  VALID = "VALID",
  UNWRITTEN = "UNWRITTEN",
  DETAIL_UNWRITTEN = "DETAIL_UNWRITTEN",
  DUPLICATED = "DUPLICATED",
  UNCHECKED = "UNCHECKED",
  WRONG_TYPE = "WRONG_TYPE",
  INPUT_LENGTH = "INPUT_LENGTH",
  MISMATCH = "MISMATCH",
  NONE = "NONE"
}