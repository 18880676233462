import {CounselorVoucherStatus} from "../../../../../types/counselor";
import styled from "styled-components";

interface Args{
    status:CounselorVoucherStatus
}

const Available = styled.div`
    color:#2E75CC;
`

const Unavailable = styled.div`
    color:#E61B00;
`

const Text = styled.div`
    color: #757575;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 22.4px */
    letter-spacing: -0.42px;
`

export function UserCounselorStatusConverter({status}: Args) {
    const getStatusValue = () =>{
        switch (status) {
            case CounselorVoucherStatus.USABLE:
                return <Available>사용가능</Available>
            case CounselorVoucherStatus.EXPIRED:
                return <Unavailable>기한만료</Unavailable>
            case CounselorVoucherStatus.COLLECTED:
                return <Unavailable>회수됨</Unavailable>
            case CounselorVoucherStatus.USED:
                return <span>사용완료</span>

        }
    }

    return(
        <Text>
            {getStatusValue()}
        </Text>
    )
}