import moment from "moment-timezone";

export class TimeConverter {
  private static instance: TimeConverter;
  private timezone: string;

  private constructor(timezone: string, locale: string) {
    this.timezone = timezone;
    this.setLocale(locale);
  }

  public static getInstance(): TimeConverter {
    if (!TimeConverter.instance) {
      moment.updateLocale("ko", {
        longDateFormat: {
          LT: "A h:mm",
          LTS: "A h:mm:ss",
          L: "YYYY-MM-DD",
          LL: "YYYY년 MMMM D일",
          LLL: "YYYY년 MMMM D일 A h:mm",
          LLLL: "YYYY년 MMMM D일 dddd A h:mm",
          l: "YYYY.MM.DD.",
          ll: "YYYY년 MMMM D일",
          lll: "YYYY년 MMMM D일 A h:mm",
          llll: "YYYY년 MMMM D일 dddd A h:mm",
        },
      });

      TimeConverter.instance = new TimeConverter("Asia/Seoul", "ko");
    }

    return TimeConverter.instance;
  }

  public convertToLocalTime(utcTime: string | undefined | null): string {
    utcTime?.trim();
    if (utcTime === undefined || utcTime == null || utcTime.length === 0) {
      return "";
    }

    // return moment.utc(utcTime).tz(this.timezone).format("L HH:mm:ss");
    return moment.utc(utcTime).tz(this.timezone).format("L");
  }

  public convertToLocalDate(utcTime: string | undefined | null): string {
    utcTime?.trim();
    if (utcTime === undefined || utcTime == null || utcTime.length === 0) {
      return "";
    }

    return moment.utc(utcTime).tz(this.timezone).format("L");
  }

  public convertToUTC(localTime: string | undefined | null): string {
    if (localTime === undefined || localTime == null || localTime.length === 0) {
      return "";
    }

    return moment.tz(localTime, this.timezone).utc().toISOString();
  }

  public convertStartOfDayToUTC(localDate: string | undefined | null): string {
    if (localDate === undefined || localDate == null || localDate.length === 0) {
      return "";
    }

    return moment.tz(`${localDate} 00:00:00`, this.timezone).utc().toISOString();
  }

  public convertEndOfDayToUTC(localDate: string | undefined | null): string {
    if (localDate === undefined || localDate == null || localDate.length === 0) {
      return "";
    }

    return moment.tz(`${localDate} 23:59:59`, this.timezone).utc().toISOString();
  }

  public convertToUTCFromDate(localTime: Date): string {
    return localTime.toISOString();
  }

  public convertToFilterDate(date: string): Date | undefined {
    if (!date || date.length === 0) {
      return undefined;
    }
    return moment(date).toDate();
  }

  public convertToFilterStartOfDay(date: string): Date | undefined {
    return this.convertToFilterDate(`${date} 00:00:00.000`);
  }

  public convertToFilterEndOfDay(date: string): Date | undefined {
    return this.convertToFilterDate(`${date} 23:59:59.999`);
  }

  public getStartOfMonth(year: number, month: number, timezone?: string): Date {
    return moment
      .tz([year, month - 1], timezone ? timezone : this.timezone)
      .startOf("month")
      .startOf("day")
      .toDate();
  }

  public getEndOfMonth(year: number, month: number, timezone?: string): Date {
    return moment
      .tz([year, month - 1], timezone ? timezone : this.timezone)
      .endOf("month")
      .endOf("day")
      .toDate();
  }

  public convertToFilterDateString(date: Date | undefined | null): string {
    if (!date) return "";

    return moment(date).format("YYYY-MM-DD");
  }

  public setTimezone(timezone: string): void {
    this.timezone = timezone;
  }

  public getTimezone(): string {
    return this.timezone;
  }

  public setLocale(locale: string): void {
    moment.locale(locale);
  }

  public getTimezoneFormat(timezone: string): string {
    return moment.tz(timezone).format("Z z");
  }

  public isBeforeFromNow(utcDatetime: string) {
    return moment.utc(utcDatetime).tz(this.timezone).isBefore();
  }

  // yyyy년 mm월 dd일 <- 형태로 변환하는 함수
  public convertDateWithWords(dateString: string): string {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');

    return `${year}년 ${month}월 ${day}일`;
  }
}
