import React, {useCallback} from "react";
import {ResultType} from "../../../types/testResult";
import {BannerContent} from "./BannerContent";

interface Args {
    testerName: string;
    bannerType: ResultType;
}

/* eslint-disable max-len */
export function TestResultGuideBanner({testerName, bannerType}: Args) {
    /**
     * 학습진로가이드 배너
     */

    const BannerFragment = useCallback(() => {
        switch (bannerType) {
            case ResultType.TEST_RESULT:
                return (
                    <BannerContent
                        testerName={testerName}
                        type={bannerType}
                        description="guide"
                        bannerName='educationMap'
                        buttonText="check"
                        iconSrc="https://images.feel-good.io/report/right_arrow.svg"
                    />
                );
            case ResultType.GUIDE:
                return (
                    <BannerContent
                        testerName={testerName}
                        type={bannerType}
                        description="learningCardGuide"
                        bannerName='learningCard'
                        buttonText="checkLearningCard"
                        iconSrc="https://images.feel-good.io/guide/download_icon.svg"
                    />
                );
            default:
                return null;
        }
        //eslint-disable-next-line
    }, [bannerType, testerName]);

    return (
        <>
            <BannerFragment/>
        </>
    )
}