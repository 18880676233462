import {Panel} from "../../layout/content/detail/Panel";
import style from "../style/grantModal.module.scss";
import {LabelAndTextField} from "./hooks/LabelAndTextField";
import {PanelWrapper} from "../../layout/content/detail/PanelWrapper";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useExternalVoucherGetter} from "./hooks/useExternalVoucherGetter";
import {useExternalVoucherConverter} from "./hooks/useExternalVoucherConverter";
import {useParams} from "react-router-dom";
import {Label104x46} from "../../components/Label";
import {useLanguage} from "../../hooks/hooks";
import {useExternalUserColumns} from "./hooks/useExternalUserColumns";
import {BodyScollTable} from "../../layout/content/list/table/BodyScrollTable";
import {useExternalVoucherFunctions} from "./hooks/useExternalVoucherFunctions";
import {AssignVoucherDTO} from "../../types/externalVoucher";
import {widowOpenerReload} from "../../util/etcUtil";

export function VoucherAssignment() {
    /**
     * 이용권 코드 등록
     */
    const {t} = useTranslation("voucher");
    const {id} = useParams<"id">();
    const idx = Number(id);

    const {isKorean} = useLanguage();
    const [isFirstLoad, setIsFirstLoad] = useState(true);

    const [grantedProductName, setGrantedProductName] = useState("")
    const [searchWord, setSearchWord] = useState("")
    const [selectedUser, setSelectedUser] = useState<number>(0)
    const {getVoucherChannelConverter} = useExternalVoucherConverter();
    const { assignVoucher } = useExternalVoucherFunctions();
    const {getVoucherDetail, voucherDetail, getNormalUserList, normalUserList}
        = useExternalVoucherGetter();

    const columns = useExternalUserColumns({
        selectedUser,
        setSelectedUser,
    });

    useEffect(() => {
        getVoucherDetail(idx).then();
        voucherDetail && setGrantedProductName(voucherDetail.productNames.join(", "))
        //eslint-disable-next-line
    }, [id]);

    useEffect(() => {
        if (voucherDetail) {
            setGrantedProductName(voucherDetail.productNames.join(", "));
        }
    }, [voucherDetail]);

    const onKeyUpSearchInput = (e: React.KeyboardEvent) => {
        if (e.key === "Enter") {
            onClickSearchBtn();
        }
    };

    const onClickSearchBtn = () => {
        setIsFirstLoad(false);
        getNormalUserList(searchWord)()
    }

    const onClickAssignVoucher = () => {
        if (selectedUser === 0) {
            alert("회원을 선택해 주세요.");
            return;
        }

        if (window.confirm("선택하신 회원에게 이용권을 등록하시겠습니까?")) {
            const dto:AssignVoucherDTO = {
                userIdx: selectedUser,
                voucherIdx: idx,
            }

            assignVoucher(dto).then(() =>{
                alert("이용권 등록이 완료되었습니다.");
                widowOpenerReload();
            });
        }
    }

    useEffect(() => {
        getNormalUserList()()
        //eslint-disable-next-line
    }, [id]);

    return (
        <PanelWrapper>
            <Panel title={t("assign.title")} panelClassName={style.panel}>
                {voucherDetail && (
                    <>
                        {/*채널 정보 ㅡㅡㅡㅡㅡ*/}
                        <div className={style.flexStartWrapper}>
                            <LabelAndTextField title={t("assign.channel")}
                                               value={getVoucherChannelConverter(voucherDetail.externalChannel)}/>
                            <LabelAndTextField title={t("assign.code")}
                                               value={voucherDetail.code}/>
                        </div>

                        <LabelAndTextField title={t("assign.grantedName")}
                                           value={grantedProductName}/>


                        {/*등록 회원 검색 ㅡㅡㅡㅡㅡ*/}
                        <div className={style.searchArea}>
                            <div className={style.search}>
                                <Label104x46
                                    text={t("assign.search.label")}
                                    className={`${style.externalVoucherModalLabel} ${isKorean ? "" : style.notKO}`}/>
                                <div className={style.textInput}>
                                    <input
                                        type={"text"}
                                        placeholder={t("assign.search.placeholder")}
                                        value={searchWord}
                                        onKeyUp={onKeyUpSearchInput}
                                        onChange={(e) => setSearchWord(e.target.value)}
                                        onBlur={(e) => setSearchWord(e.target.value.trim())}
                                    />
                                    <button onClick={onClickSearchBtn}>{t("assign.search.button")}</button>
                                </div>
                            </div>
                        </div>
                        <div className={style.productResults}>
                            {normalUserList ? (
                                <BodyScollTable
                                    listData={normalUserList}
                                    columns={columns}
                                    getList={() => getNormalUserList()}
                                    noDataText={
                                        isFirstLoad ? t("assign.search.noGrantableProduct")
                                            : t("assign.search.noSearchData")
                                    }
                                    tableClassName={style.grantProductModalTable}
                                    tbodyClassName={style.tableBody}
                                    noResultRowClassName={style.noResultRow}
                                />
                            ) : (
                                <></>
                            )}
                        </div>

                        <div className={style.buttonArea}>
                            <button onClick={onClickAssignVoucher}>{t("assign.button")}</button>
                        </div>

                    </>
                )}
            </Panel>
        </PanelWrapper>
    );
}