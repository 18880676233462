import {Panel} from "../../layout/content/detail/Panel";
import style from "../style/grantModal.module.scss";
import {useTranslation} from "react-i18next";
import {Label104x46} from "../../components/Label";
import {PanelWrapper} from "../../layout/content/detail/PanelWrapper";
import React, {useEffect, useMemo, useState} from "react";
import {useLanguage} from "../../hooks/hooks";
import {ExternalChannel, ExternalVoucherPostDTO} from "../../types/externalVoucher";
import {useExternalVoucherConverter} from "./hooks/useExternalVoucherConverter";
import {useConditionalProductList} from "../product/hooks/useConditionalProductList";
import {DisplayPosition, GrantableProductListObj, ProductStatus} from "../../types/product";
import {BodyScollTable} from "../../layout/content/list/table/BodyScrollTable";
import {PageResponse} from "../../types/page";
import {useGrantableProductColumns} from "../product/hooks/useGrantableProductColumns";
import {useExternalVoucherFunctions} from "./hooks/useExternalVoucherFunctions";
import {widowOpenerReload} from "../../util/etcUtil";

export function VoucherGeneration() {
    const {t} = useTranslation("voucher");
    const {getVoucherChannelConverter} = useExternalVoucherConverter();
    const [searchWord, setSearchWord] = useState("");
    const [selectedChannel, setSelectedChannel] = useState<ExternalChannel>(ExternalChannel.NAVER)
    const [quantity, setQuantity] = useState<number | ''>('');
    const [selectedProducts, setSelectedProducts] = useState<GrantableProductListObj[]>([]);
    const [isFirstLoad, setIsFirstLoad] = useState(true);
    const {isKorean} = useLanguage();

    const columns = useGrantableProductColumns({
        selectedProducts,
        setSelectedProducts,
    });

    const { createVouchers } = useExternalVoucherFunctions();
    const {getGrantableProductList, grantableProductList} = useConditionalProductList({
        searchWord,
        displayPosition: DisplayPosition.GRANTABLE_PRODUCT,
        status: ProductStatus.IS_SELLING,
    });

    const grantableSellingProductList: PageResponse<GrantableProductListObj> | undefined = useMemo(() => {
        if (grantableProductList) {
            return {
                ...grantableProductList,
                content: grantableProductList?.content.filter((gp) => gp.status === ProductStatus.IS_SELLING) ?? [],
            };
        } else {
            return undefined;
        }
    }, [grantableProductList]);

    const onKeyUpSearchInput = (e: React.KeyboardEvent) => {
        if (e.key === "Enter") {
            onClickSearchBtn();
        }
    };

    const handleSelectedChannel = (newChannel: ExternalChannel) => {
        setSelectedChannel(() => newChannel)
    }

    const onClickSearchBtn = () => {
        setIsFirstLoad(false);
        getGrantableProductList({})();
    }

    const onClickGenerateVoucher = () => {

        if (selectedProducts.length === 0) {
            alert("부여 상품을 선택해주세요.")
            return;
        }

        if (quantity === 0 || quantity === undefined || quantity === "") {
            alert("생성 수량을 작성해주세요.")
            return;
        }

        const dto:ExternalVoucherPostDTO = {
            channel: selectedChannel,
            voucherQuantity: quantity,
            grantProductVouchersRequest: selectedProducts.map((data) =>({
                productId: data.idx,
                quantity: data.qty
            }))
        }

        createVouchers(dto).then(() =>{
            alert("이용권 코드 생성이 완료되었습니다.")
            widowOpenerReload();
        })
    }

    useEffect(() => {
        getGrantableProductList({}, ProductStatus.IS_SELLING)();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <PanelWrapper>
            <Panel title={t("externalVoucher.title")} panelClassName={style.panel}>
                <>
                    {/*채널 정보 ㅡㅡㅡㅡㅡ*/}
                    <div className={style.radioArea}>
                        <Label104x46
                            text={t("externalVoucher.channel.label")}
                            className={`${style.externalVoucherModalLabel} ${isKorean ? "" : style.notKO}`}/>
                        <div className={style.radioWrapper}>
                            {Object.values(ExternalChannel).map((channel) => (
                                <div key={channel} className={style.radioItems}
                                     onClick={() => handleSelectedChannel(channel)}>
                                    <input
                                        type={"radio"}
                                        name={"voucher-channel"}
                                        required={true}
                                        value={channel}
                                        readOnly={true}
                                        checked={selectedChannel === channel}/>
                                    {getVoucherChannelConverter(channel)}
                                </div>
                            ))}
                        </div>
                    </div>

                    {/*부여상픔 검색 ㅡㅡㅡㅡㅡ*/}
                    <div className={style.searchArea}>
                        <div className={style.search}>
                            <Label104x46
                                text={t("externalVoucher.search.label")}
                                className={`${style.externalVoucherModalLabel} ${isKorean ? "" : style.notKO}`}/>
                            <div className={style.textInput}>
                                <input
                                    type={"text"}
                                    placeholder={t("externalVoucher.search.placeholder")}
                                    value={searchWord}
                                    onKeyUp={onKeyUpSearchInput}
                                    onChange={(e) => setSearchWord(e.target.value)}
                                    onBlur={(e) => setSearchWord(e.target.value.trim())}
                                />
                                <button onClick={onClickSearchBtn}>{t("externalVoucher.search.button")}</button>
                            </div>
                        </div>
                    </div>
                    <div className={style.productResults}>
                        {grantableSellingProductList ? (
                            <>
                                <BodyScollTable
                                    listData={grantableSellingProductList}
                                    columns={columns}
                                    getList={getGrantableProductList}
                                    noDataText={
                                        isFirstLoad ? t("externalVoucher.search.noGrantableProduct")
                                            : t("externalVoucher.search.noSearchData")
                                    }
                                    tableClassName={style.grantProductModalTable}
                                    tbodyClassName={style.tableBody}
                                    noResultRowClassName={style.noResultRow}
                                />
                            </>
                        ) : (
                            <></>
                        )}
                    </div>

                    {/*코드 생성 개수 검색 ㅡㅡㅡㅡㅡ*/}
                    <div className={style.numberArea}>
                        <label className={style.label}>{t("externalVoucher.addCodeNumber.label")}</label>
                        <div className={style.inputWrapper}>
                            <input
                                type="number"
                                min={0}
                                max={1000}
                                value={quantity}
                                className={style.input}
                                placeholder={t("externalVoucher.addCodeNumber.placeholder")}
                                onChange={(e) => {
                                    const value = e.target.value === '' ? '' : parseInt(e.target.value);
                                    setQuantity(value === '' ? '' : (value > 1000 ? 1000 : value));
                                }}
                            />
                            <span className={style.number}>개</span>
                        </div>
                    </div>

                    {/*등록 버튼 */}
                    <div className={style.buttonArea}>
                        <button onClick={onClickGenerateVoucher}>{t("externalVoucher.button")}</button>
                    </div>
                </>
            </Panel>
        </PanelWrapper>
    );
}