import {useCallback} from "react";
import {BrainType} from "../../../types/testResult";
import {RecommendationBalancedJob} from "./RecommendationBalancedJob";
import {RecommendationLeftJob} from "./RecommendationLeftJob";
import {RecommendationRightJob} from "./RecommendationRightJob";

interface Args{
    brainType:BrainType
}
export function GuideRecommendationJob({brainType}:Args) {

    /**
     * 두뇌유형별 추천 직업군
     */
    const RecommendationByBrainType = useCallback(() => {
        switch (brainType) {
            case BrainType.BALANCED:
                return <RecommendationBalancedJob/>
            case BrainType.SIMULTANEOUS:
                return <RecommendationRightJob />;
            case BrainType.SUCCESSIVE:
                return <RecommendationLeftJob/>
            default:
                return <></>;
        }
    }, [brainType]);
    return(
        <>
            <RecommendationByBrainType />
        </>
    )
}