import style from "../../style/test_result/testResult.module.scss";
import {TestResultPath} from "../../../types/testResult";
import React, {useState} from "react";
import {TestGuideModal} from "./TestGuideModal";
import {Trans, useTranslation} from "react-i18next";

interface Args {
    testResultPath: TestResultPath
    testerName?: string;
}

export function TestSummaryHeader({testResultPath, testerName}: Args) {
    const {t} = useTranslation("testResultHeader");
    const [isModalOpen, setIsModalOpen] = useState(false);

    const testPathValue = [
        {
            idx: "1",
            name: t('testSummaryHeader.totalScale'),
            subText:
                (
                    <Trans t={t} i18nKey="testSummaryHeader.totalScaleSub"
                           values={{name: testerName,}}
                           components={{
                               strong: <span className={style.strong} />,
                               br: <br />,
                           }}
                    />
                ),
        },
        {
            idx: "2",
            name: t('testSummaryHeader.lowLevelScale'),
            subText:
                (
                    <Trans t={t} i18nKey="testSummaryHeader.lowLevelScaleSub"
                           values={{name: testerName,}}
                           components={{
                               strong: <span className={style.strong} />,
                               br: <br />,
                           }}
                    />
                ),
        },
        {
            idx: "3",
            name: t('testSummaryHeader.averageStrengthAndWeakness'),
            subText:
                (
                    <Trans t={t} i18nKey="testSummaryHeader.averageStrengthAndWeaknessSub"
                           values={{name: testerName,}}
                           components={{
                               strong: <span className={style.strong} />,
                               br: <br />,
                           }}
                    />
                ),
                t,
        },
        {
            idx: "4",
            name: t('testSummaryHeader.personalStrengthAndWeakness'),
            subText:
                (
                    <Trans t={t} i18nKey="testSummaryHeader.personalStrengthAndWeaknessSub"
                           values={{name: testerName,}}
                           components={{
                               strong: <span className={style.strong} />,
                               br: <br />,
                           }}
                    />
                ),
        },
        {
            idx: "5",
            name:
                (
                    <Trans t={t} i18nKey="testSummaryHeader.profileAnalysis"
                           values={{name: testerName,}}
                           components={{
                               strong: <span className={style.strong} />,
                               normal: <span style={{fontWeight: 'normal'}} />,
                               preLine: <span style={{whiteSpace: 'pre-line'}} />,
                               br: <br />,
                           }}
                    />
                )
            ,
            subText:
                (
                    <Trans t={t} i18nKey="testSummaryHeader.profileAnalysisSub"
                           values={{name: testerName,}}
                           components={{
                               strong: <span className={style.strong} />,
                               br: <br />,
                           }}
                    />
                ),
        },
        {
            idx: "6",
            subName: "",
            name: t('testSummaryHeader.introductionAboutKpass'),
            subText: "",
        },
    ]

    // 해당 결과지 패스의 헤더값 추출
    const index = Object.keys(TestResultPath).indexOf(testResultPath);

    const handleModalOpen = () => {
        document.documentElement.style.overflow = 'hidden';
        setIsModalOpen(() => true);
    }

    return (
        <>
            <div className={style.title}>
                <h2>
                    <span className={style.number}>0
                        <span className={style.strong}>{testPathValue[index].idx}</span>
                        {testPathValue[index].idx !== '6' && (
                          <div className={style.explainContainer}
                               onClick={handleModalOpen}>
                            <div className={style.explanationGuideButton}></div>
                            <span className={style.explainText}>{t('summaryGuideTitle')}</span>
                          </div>
                        )}
                    </span>
                    {/*<span style={{fontWeight: 'normal'}}>{testPathValue[index].subName}</span>*/}
                    <span style={{whiteSpace: 'pre-line'}}>{testPathValue[index].name}</span>
                </h2>
                <div className={style.subtitle}>
                    {testerName && (
                        <>
                            {testPathValue[index].subText}
                        </>
                    )}
                </div>
                {isModalOpen && (
                    <TestGuideModal testResultPath={testResultPath} setIsModalOpen={setIsModalOpen}/>
                )}
            </div>
        </>
    );
}