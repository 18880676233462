import {useNewWindow, useTimeConverter} from "../../../../hooks/hooks";
import {TableColumnData} from "../../../../types/common";
import {ConsultantDTO} from "../../../../types/counselor";
import React, {useMemo, useState} from "react";
import style from "../../../../layout/content/list/list.module.scss"
import {useCounselorConverter} from "./useCounselorConverter";
import {CounselorStatusConverter} from "./CounselorStatusConverter";
import {CounselorAdditionalFunction} from "./CounselorAdditionalFunction";
import {AdminStatus} from "../../../../types/admin";
import {BlockReasonModal} from "../../../component/modal/BlockReasonModal";

export function useCounselorColumns() {
    const {timeConverter} = useTimeConverter();

    const {openNewWindowWithURL} = useNewWindow();
    const {getInfoSearchPositionConverter} = useCounselorConverter();
    const [showBlockedReason, setShowBlockedReason] = useState(false);

    const handleGroupDetail = (idx: number) => {
        openNewWindowWithURL(`/detail/counselor_group/${idx}`, {
            width: 1000,
            height: 800,
        });
    }

    const handleCounselorDetail = (idx: number) => {
        openNewWindowWithURL(`/detail/counselor/${idx}`, {
            width: 1000,
            height: 800,
        });
    }

    const registerAtColumn: TableColumnData<ConsultantDTO> = useMemo(
        () => ({
            accessor: "createAt",
            header: "등록일",
            sortable: false,
            getNode: (vo: ConsultantDTO) => <span>{timeConverter.convertToLocalTime(vo.createAt)}</span>,
        }), [timeConverter]);

    const groupColumn: TableColumnData<ConsultantDTO> = useMemo(
        () => ({
            accessor: "affiliationName",
            header: "상담소속",
            sortable: false,
            getNode: (vo: ConsultantDTO) => (
                vo.affiliationName
                    ? (<a className={style.underline}
                          onClick={() => handleGroupDetail(vo.affiliationIdx)}>
                        {vo.affiliationName}</a>)
                    : <span>무소속</span>
            ),
            //eslint-disable-next-line
        }), []);

    const consultantRankColumn: TableColumnData<ConsultantDTO> = useMemo(
        () => ({
            accessor: "consultantRank",
            header: "직급",
            sortable: false,
            getNode: (vo: ConsultantDTO) => <span>{getInfoSearchPositionConverter(vo.consultantRank)}</span>,
            //eslint-disable-next-line
        }), []);

    const nameColumn: TableColumnData<ConsultantDTO> = useMemo(
        () => ({
            accessor: "name",
            header: "이름",
            sortable: false,
            getNode: (vo: ConsultantDTO) =>
                <span className={style.underline}
                      onClick={() => handleCounselorDetail(vo.idx)}>
                    {vo.name}
                </span>,
            //eslint-disable-next-line
        }), []);

    const idColumn: TableColumnData<ConsultantDTO> = useMemo(
        () => ({
            accessor: "email",
            header: "아이디",
            sortable: false,
            getNode: (vo: ConsultantDTO) => <span>{vo.email}</span>,
        }), []);

    const cellPhoneColumn: TableColumnData<ConsultantDTO> = useMemo(
        () => ({
            accessor: "phoneNumber",
            header: "휴대전화",
            sortable: false,
            getNode: (vo: ConsultantDTO) => <span>{`(${vo.codePhone})${vo.phoneNumber}`}</span>,
        }), []);

    const totalCounselorCountColumn: TableColumnData<ConsultantDTO> = useMemo(
        () => ({
            accessor: "totalCounselorCount",
            header: "총 상담횟수",
            sortable: false,
            getNode: (vo: ConsultantDTO) => <span>{vo.status === AdminStatus.DEFAULT ? `${vo.testRound}회` : '-'}</span>,
        }), []);

    const statusColumn: TableColumnData<ConsultantDTO> = useMemo(
        () => ({
            accessor: "status",
            header: "상태",
            sortable: false,
            getNode: (vo: ConsultantDTO) => (
                <div style={{position: "relative"}}>
                    <CounselorStatusConverter
                        status={vo.status}
                        blockAt={vo.blockAt}
                        reasonForBlocking={vo.reasonForBlocking}
                    />
                    <BlockReasonModal
                        isShow={showBlockedReason}
                        callbackCloseModal={() => setShowBlockedReason(false)}
                        blockAt={vo.blockAt}
                        reasonForBlocking={vo.reasonForBlocking}
                    />
                </div>
            )
            ,
            //eslint-disable-next-line
        }), []);

    const idxColumn: TableColumnData<ConsultantDTO> = useMemo(
        () => ({
            accessor: "idx",
            header: "관련 기능",
            sortable: false,
            getNode: (vo: ConsultantDTO) => (
                <>
                    {vo.status === AdminStatus.BEFORE_ADMIN_AUTHENTICATION ? (
                        <CounselorAdditionalFunction dto={vo}/>
                    ) : '-'}
                </>
            ),
        }), []);


    const columns: TableColumnData<ConsultantDTO>[] = useMemo(
        () => [
            registerAtColumn,
            groupColumn,
            consultantRankColumn,
            nameColumn,
            idColumn,
            cellPhoneColumn,
            totalCounselorCountColumn,
            statusColumn,
            idxColumn,
        ],
        [
            registerAtColumn,
            groupColumn,
            consultantRankColumn,
            nameColumn,
            idColumn,
            cellPhoneColumn,
            totalCounselorCountColumn,
            statusColumn,
            idxColumn,
        ]
    );


    return {
        columns,
    };

}