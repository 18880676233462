import style from "../../component/memo/memo.module.scss";
import {Panel} from "../../../layout/content/detail/Panel";
import {Pagination} from "../../../layout/content/list/Pagination";
import {MemoTargetType} from "../../../types/memo";
import {ReviewWritePanel} from "./ReviewWritePanel";
import React, {useEffect} from "react";
import {MemoTitle} from "../../component/memo/MemoComponent";
import {useTranslation} from "react-i18next";
import {CounselingHistoryDetailDTO} from "../../../types/counselor";
import {useCounselorMemoGetter} from "../memo/hooks/useCounselorMemoGetter";
import {CounselorAMemo} from "../memo/CounselorAMemo";
import {CounselingMemoWriterPanel} from "../memo/CounselingMemoWritingPanel";

interface Args {
    counselorHistory: CounselingHistoryDetailDTO;
    type: string;
}

export function CounselorHistoryInfoMenus({counselorHistory, type}: Args) {
    /**
     * 상담 메모, 리뷰 등
     * 상담내역 상세에 보여지는 메뉴들 관리 컴포넌트
     */
    const {t} = useTranslation("counselorInfo");
    const memoTitle: MemoTitle = {memoWriteTitle: '상담메모 작성', memoListTitle: '상담메모'}
    const {getCounselorGroupDetail, counselorMemo} = useCounselorMemoGetter();

    useEffect(() => {
        const status = counselorHistory.status
        const allowedCase =
            (type === 'memo' && status === 'ALLOTTED') ||
            (type === 'pre-question' && status === 'NOT_ALLOTTED') ||
            (type === 'review' && status === 'FINISHED')
        ;
        if(!allowedCase) {
            alert('잘못된 접근입니다. 다시 시도해주세요.')
            window.close()
        }

        getCounselorGroupDetail({targetIdx:counselorHistory.idx}).then()
        //eslint-disable-next-line
    }, []);

    // 메모 리스트 영역
    const MemoListArea = () =>{
        return (
            <div className={style.panelWrap}>
                <Panel title={memoTitle.memoListTitle} panelClassName={style.memoList}>
                    <>
                        <ul className={style.list}>
                            {counselorMemo?.content?.length === 0 ? (
                                <li className={style.noDataItem}>{t("memoComponent.noMemo")}</li>
                            ) : (
                                counselorMemo?.content?.map((memo) => (
                                    <CounselorAMemo
                                        key={`memoIdx-${memo.idx}`}
                                        memo={memo}
                                        onRefresh={
                                        () => getCounselorGroupDetail(
                                            {targetIdx: counselorHistory.idx}).then()
                                    }
                                        memoListTitle={memoTitle.memoListTitle}
                                    />
                                ))
                            )}
                        </ul>
                        {(counselorMemo?.content?.length ?? 0) > 0 ? (
                            <div className={style.pagingWrapper}>
                                <Pagination page={counselorMemo!}
                                            goSpecificPage={(num: number) => getCounselorGroupDetail(
                                                {
                                                    targetIdx: counselorHistory.idx,
                                                    pageCondition: {page: num}
                                                }
                                            )}/>
                            </div>
                        ) : (
                            <></>
                        )}
                    </>
                </Panel>
            </div>
        )
    }

    return (
        <>
            {type === 'review' && counselorMemo &&  (
                <div className={style.memoArea}>
                    <MemoListArea />

                    <ReviewWritePanel
                        title={"상담리뷰"}
                        starCount={counselorHistory.reviewStar}
                        review={counselorHistory.review}
                    />
                </div>
            )}
            {type === 'memo' && counselorMemo && (
                <div className={style.memoArea}>
                    {/*  상담메모 영역  */}
                    <MemoListArea />

                    <CounselingMemoWriterPanel
                        targetIdx={counselorHistory.idx}
                        memoTitle={memoTitle}
                        targetType={MemoTargetType.USER}
                        onRefresh={() => getCounselorGroupDetail(
                            {targetIdx: counselorHistory.idx}
                        ).then()}
                        isWritable={true}
                    />
                </div>
            )}

        </>
    )
}