import style from "../../style/test_result/testResult.module.scss";
import {TestResultReportDTO} from "../../../types/testResult";
import React, {useEffect, useRef, useState} from "react";
import {Trans, useTranslation} from "react-i18next";
import {useLanguage} from "../../../hooks/hooks";

interface Args {
    testResult: TestResultReportDTO
}

/* eslint-disable */
export function ResultSummaryContent({testResult}: Args) {
    const {t} = useTranslation("totalScale");
    const {isKorean} = useLanguage();
    const [isValidity, setIsValidity] = useState(testResult.testResultComputedDataVO.r >= 8);
    const [summaryHide, setSummaryHide] = useState(true);

    const toggleContent = () => {
        if (setSummaryHide) {
            setSummaryHide(!summaryHide)
        }
    };

    const contentRef = useRef<HTMLDivElement>(null);
    const [height, setHeight] = useState('0px');

    useEffect(() => {
        const content = contentRef.current;

        if (content) {
            if (summaryHide) {
                setHeight('0px');
            } else {
                setHeight(`${content.scrollHeight + 20}px`);
            }
        }

        setIsValidity(testResult?.testResultComputedDataVO.r < 23);
    }, [summaryHide, testResult]);

    const validateText = [
        (
            // R<23 인 경우(밸리디티)
            <>
                <p className={style.paragraph}>
                    <Trans t={t} i18nKey="totalScale.summaryContent.validityDetailDescription"
                           values={{
                               name: testResult?.testResultUserProfileVO.name,
                               lt: '<',
                               blank: '\u00A0'
                           }}
                           components={{
                               strong: <span className={style.strong}/>,
                               blue: <span className={style.blue}/>,
                           }}
                    />
                </p>
            </>
        ), (
            // R>=23 인 경우(넌밸리디티)
            <>
                <p className={style.paragraph}>
                    <Trans t={t} i18nKey="totalScale.summaryContent.nonValidityDetailDescription"
                           values={{
                               name: testResult?.testResultUserProfileVO.name,
                               gt: '>',
                               blank: '\u00A0'
                           }}
                           components={{
                               strong: <span className={style.strong}/>,
                               blue: <span className={style.blue}/>,
                           }}
                    />
                </p>
            </>
        )
    ];


    return (
        <>
            <article className={style.mainWrapper}>
                <h3>{t('totalScale.summaryContent.title')}</h3>
                <ul className={style.unorderedList}>
                    <li>{t('totalScale.summaryContent.validityDescription')}</li>
                    <li>{t('totalScale.summaryContent.nonValidityDescription')}</li>
                </ul>
                <table className={`${style.table} ${style.fullScale}`}>
                    <thead>
                    <tr>
                        <th rowSpan={2} colSpan={2}>{t('table.lowLevelScale')}</th>
                        <th rowSpan={2}>{t('table.lowLevelScaleRange')}</th>
                        <th colSpan={2}>{t('table.validityDescription')}</th>
                    </tr>
                    <tr>
                        <th>
                          <span className={style.light}>
                            <span>{t('totalScale.summaryContent.validity')}</span>
                            <br/>{'R < 23'}
                          </span>
                        </th>
                        <th>
                          <span className={style.light}>
                            <span>{t('totalScale.summaryContent.nonValidity')}</span>
                            <br/>R≥23
                          </span>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>{t('table.successive')}</td>
                        <td>
                            <span className={style.red}>{testResult?.testResultVO.successiveScore.score}</span>
                        </td>
                        <td rowSpan={4} className={style.twice}>
                              <span className={style.strong}>
                                <span>{t('table.lowLevelRangeDescription')}</span>
                                <br/>=
                              </span>
                            <span className={style.red}>{testResult?.testResultComputedDataVO.r}</span>
                        </td>
                        <td rowSpan={4} className={style.validity}>
                            {isValidity ? <span className={style.red}>O</span> : null}
                        </td>
                        <td rowSpan={4} className={style.validity}>
                            {!isValidity ? <span className={style.red}>O</span> : null}
                        </td>
                    </tr>
                    <tr>
                        <td>{t('table.simultaneous')}</td>
                        <td>
                            <span className={style.red}>{testResult?.testResultVO.simultaneousScore.score}</span>
                        </td>
                    </tr>
                    <tr>
                        <td>{t('table.planning')}</td>
                        <td>
                            <span className={style.red}>{testResult?.testResultVO.planningScore.score}</span>
                        </td>
                    </tr>
                    <tr>
                        <td>{t('table.attention')}</td>
                        <td>
                            <span className={style.red}>{testResult?.testResultVO.attentionScore.score}</span>
                        </td>
                    </tr>
                    </tbody>
                </table>
                {isValidity ? validateText[0] : validateText[1]}

                {/*  전체지수는 ~~  */}
                {/*todo*/}
                {isKorean && (
                    <div className={`${style.summaryAndExplanation} ${style.noPrint}`}>
                        <button
                            className={style.summaryAndExplanationHeader}
                            onClick={toggleContent}
                        >
                            <h4 className={style.title}>{t('summary')}</h4>
                            <span className={`${style.spreadButton} ${!summaryHide ? '' : style.hide}`}>
                        <span>{t('fold')}</span>
                        <img src="https://images.feel-good.io/report/minus_icon.svg" alt="접기"/>
                    </span>
                            <span className={`${style.spreadButton} ${!summaryHide ? style.hide : ''}`}>
                        <span>{t('open')}</span>
                        <img src="https://images.feel-good.io/report/plus_icon.svg" alt="자세히보기"/>
                    </span>
                        </button>
                        <div style={{height: height}}
                             className={`${style.contentParagraphWrapper} ${summaryHide ? style.hide : ''}`}>
                            <div className={style.contentParagraph} ref={contentRef}>
                                <span>{t('totalScale.summaryContent.summary.mainDescription')}</span>
                                <ol className={style.orderingList}>
                                    <li>
                                        <span
                                            className={style.strong}>{t('totalScale.summaryContent.summary.cognitiveDescription')}</span>
                                        <ul className={style.secondOrderingList}>
                                            <li>
                                                <span className={style.alphaOrdering}>{t('table.planning')}</span>
                                                <p>
                                                    {t('totalScale.summaryContent.summary.planningDetail')}
                                                </p>
                                            </li>
                                            <li>
                                                <span>{t('table.successiveAbility')}</span>
                                                <p>
                                                    {t('totalScale.summaryContent.summary.successiveDetail')}
                                                </p>
                                            </li>
                                            <li>
                                                <span>{t('table.simultaneousAbility')}</span>
                                                <p>
                                                    {t('totalScale.summaryContent.summary.simultaneousDetail')}
                                                </p>
                                            </li>
                                            <li>
                                                <span>{t('table.attention')}</span>
                                                <p>
                                                    {t('totalScale.summaryContent.summary.attentionDetail')}
                                                </p>
                                            </li>
                                        </ul>
                                    </li>
                                    <br/>
                                    <li>
                                        <span>{isValidity ? '벨리디티(Validity)' : '넌 벨리디티(Non validity)'}</span>
                                        <p className={style.itemContent}>
                                            {isValidity
                                                ? '각 영역의 점수 중 가장 높은 점수와 가장 낮은 점수의 차가 23점을 넘지 않으므로 전체 지능지수로 판단하면 된다는 의미이다.'
                                                : '각 영역의 점수 중 가장 높은 점수와 가장 낮은 점수의 차가 23점보다 높으므로 전체 지능지수로 판단하면 안 된다는 의미이다.' +
                                                ' 즉, 각각의 인지능력(계획력, 순차처리능력, 동시처리능력, 집중력)으로 판단해야 한다.'}
                                        </p>
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                )}
            </article>
        </>
    )
}