import {useCounselorHistoryFilterValues} from "./hooks/useCounselorHistoryFilterValues";
import {ListFilterValuesContext} from "../../../hooks/list/ListFilterValuesContext";
import {CounselorHistoryList} from "./CounselorHistoryList";

export function CounselorHistoryListWrapper() {
    const { pageInfoOnFilter, filterValues, searchList, listQueryType} = useCounselorHistoryFilterValues();

    return(
        <ListFilterValuesContext.Provider
            value={{
                filterValues,
                pageInfoOnFilter,
                searchList,
                listQueryType,
            }}
        >
            <CounselorHistoryList />
        </ListFilterValuesContext.Provider>
    )
}