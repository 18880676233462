import style from "../../style/test_result/guide/learningCard.module.scss";
import {TestResultGuideBanner} from "../common/TestResultGuideBanner";
import {ResultType, TestResultReportDTO} from "../../../types/testResult";
import React from "react";
import {LearningCardFragment} from "./LearningCardFragment";
import {GuideRefProvider} from "./hooks/GuideRefProvider";

interface Args {
    testResult: TestResultReportDTO
}

/* eslint-disable max-len */
export function GuideLearningCard({testResult}: Args) {
    const background3 = "https://images.feel-good.io/guide/background3.png"
    const background4 = "https://images.feel-good.io/guide/background4.png"

    const testerName = testResult.testResultUserProfileVO.name

    return (
        <>
            {/*  다운로드 버튼 참조공유를 위한 프로바이더 추가 */}
            <GuideRefProvider>
                <div className={`${style.pageWrapper} ${style.noPrint}`}>
                    <img className={style.background3} src={background3} alt="background3"/>
                    <img className={style.background4} src={background4} alt="background4"/>

                    {/*  학습진로카드 다운 배너  */}
                    <TestResultGuideBanner testerName={testerName}
                                           bannerType={ResultType.GUIDE}/>

                    <LearningCardFragment testResult={testResult}/>
                </div>
            </GuideRefProvider>
        </>
    )
}