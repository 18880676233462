import style from "../../style/test_result/guide/guideCover.module.scss";
import {TestResultReportDTO} from "../../../types/testResult";
import {useGuideFunctions} from "./hooks/useGuideFunctions";
import {useTranslation} from "react-i18next";

interface Args{
    testResult:TestResultReportDTO
}

/* eslint-disable max-len */
export function GuideCover({testResult}:Args) {
    const {convertBrainType} = useGuideFunctions();

    const {t} = useTranslation("guideCommon");

    // ex) 좌뇌우세형(순차처리우세형) -> 좌뇌우세형 + 순차처리우세형)으로 분리
    const brainType = convertBrainType(testResult.testResultComputedDataVO.brainType).split('(');

    const browserLanguage = navigator.language.slice(0, 2);

    return (
        <>
            <div className={style.first}
                // className="first" th:fragment="guideCover (type, typeDescription, isBalanceType)"
                //  th:with="languageClass=${#locale.getLanguage() eq 'ko' ? '' : 'notKO'}"
            >
                <div className={style.pageCoverHeader}>
                    <span className={style.textWrap}>kpass.feel-good.io</span>
                    <img className={style.kpassIcon} src="https://images.feel-good.io/guide/K-PASS-page-logo-white.svg"
                         alt="logo"/>
                </div>

                <div className={style.pageBody}>
                    <div className={style.pageBodyContent}>
                        <img id="kpass-logo" alt="kpass-logo" className={style.kpassLogo}
                             src={browserLanguage === 'ko'
                                 ? 'https://images.feel-good.io/guide/K-PASS-logo-white.svg'
                                 : 'https://images.feel-good.io/guide/K-PASS-logo-white-without-text.svg'}/>

                        <h1 className={style.guideTitle}>
                            <span className={style.bold}>{brainType[0]}</span>
                            <span className={`
                            ${style.light} 
                            ${testResult.testResultComputedDataVO.brainType === 'BALANCED' ? style.balance : ''}
                           `}>({brainType[1]}</span>
                            <span className={style.map}>{t('cover.title')}</span>
                        </h1>

                        <div className={style.writerWrap}>
                            <span className={style.title}>{t('cover.writerLabel')}</span>
                            <span className={style.name}>{t('cover.writer')}</span>
                        </div>
                    </div>

                    <div className={style.testerProfile}>
                        <div className={style.row}>
                            <span className={style.title}>{t('cover.brainTypeLabel')}</span>
                            <span className={style.content}>{brainType[0]}</span>
                            <span className={style.title}>{t('cover.nameLabel')}</span>
                            <span className={style.content}>{testResult.testResultUserProfileVO.name}</span>
                        </div>
                    </div>
                </div>

                <div className={style.pageCoverFooter}>
                    <span>Copyright 2023. FEELGOOD. All rights reserved.</span>
                    <img className="feel-good-logo.svg" src="https://images.feel-good.io/guide/feel-good-logo.svg"
                         alt="feel-good-logo"/>
                </div>

                <img className={style.background1} src="https://images.feel-good.io/guide/background1.svg"
                     alt="background1"/>
                <img className={style.background2} src="https://images.feel-good.io/guide/background2.svg"
                     alt="background2"/>
            </div>
        </>
    );
}