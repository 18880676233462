import {BrainType} from "../../../types/testResult";
import {RecommendationRightJobDetail} from "./RecommendationRightJobDetail";
import style from "../../style/test_result/guide/learningStrategy.module.scss";
import {Trans, useTranslation} from "react-i18next";
import React from "react";
import {useLanguage} from "../../../hooks/hooks";

/* eslint-disable max-len */
export function RecommendationRightJob() {
    const {t} = useTranslation("guideRecommendationJob");
    const {isKorean} = useLanguage();

    return(
        <>
            <div className={style.pageBody}>
                <article className={style.summary}>
                    <div className={style.titleWrapper}>
                        {isKorean? (
                            <h2 className={style.title}>
                                <span className={style.bold}>{t('summary.right.title')}</span>
                                <span className={`${style.light} ${style.small}`}>{t('summary.right.subTitle')}</span>
                                <span>{t('summary.right.job')}</span>
                            </h2>

                        ) : (
                            <h2 className={`${style.title} ${style.notKO}`}>
                                <span className={style.normalText}>{t('summary.right.job')}</span>
                                <span className={style.bold}>{t('summary.right.title')}</span>
                                <span className={`${style.light} ${style.small}`}>{t('summary.right.subTitle')}</span>
                            </h2>
                        )}
                        <div className={style.descriptionWrapper}>
                            <Trans t={t} i18nKey='summary.right.description'
                                   values={{blank: '\u00A0'}}
                                   components={{
                                       br: <br/>,
                                       bold: <p className={`${style.description} ${style.bold}`}/>,
                                       description: <p className={style.description}/>,
                                   }}/>
                        </div>
                    </div>
                    <hr className={style.underline}/>
                </article>

                <RecommendationRightJobDetail brainType={BrainType.SIMULTANEOUS} />
            </div>
        </>
    )
}