import {CounselingHistoryDetailDTO} from "../../../../types/counselor";
import {DescriptionValue} from "../../../../layout/content/detail/DescriptionList";
import {useTranslation} from "react-i18next";
import {useTimeConverter} from "../../../../hooks/hooks";
import {LargeInputWrapper} from "../../style/CounselorDataStyle";

export function useHistoryInfoDetailUtil() {
    const {t} = useTranslation("counselorInfo");
    const {timeConverter} = useTimeConverter();

    const getHistoryInfo = (data: CounselingHistoryDetailDTO): DescriptionValue[][] => [
        [
            {key: t("history.title"), value: data?.applicantName,},
            {key: t("history.registeredAt"), value: timeConverter.convertToLocalTime(data.createAt)},
        ],
        [
            {key: t("history.wishConsultantDay"), value: timeConverter.convertToLocalTime(data.desiredDate),},
            {key: t("history.wishTime"), value: data.desiredTime},
        ],
        [
            {key: t("history.cellPhone"), value:(
                <>
                    {data.codePhone+ ' ' + data.phoneNumber}
                </>
                )
                ,},
            {key: t("history.testerName"), value: data.applicantName},
        ],
        [
            {key: t("history.testType"), value: data.testType,},
            {key: t("history.age"), value: (<>{`${data.ageYear} (${data.ageMonth || 0})`}</>)},
        ],
        [
            {key: t("history.preQuestion"), value: (
                <LargeInputWrapper >
                    {data?.preQuestioning}
                </LargeInputWrapper>
                )},
        ],

    ]

    return {
        getHistoryInfo
    }
}