import {useTimeConverter} from "../../../hooks/hooks";
import {useMemo} from "react";
import {TableColumnData} from "../../../types/common";
import {ExternalVoucherListDTO, ExternalVoucherStatus} from "../../../types/externalVoucher";
import {useExternalVoucherConverter} from "./useExternalVoucherConverter";
import {ExternalVoucherButtons} from "./ExternalVoucherButtons";
import listStyle from "../../../layout/content/list/list.module.scss";

interface Args{
    refreshList: () => void;
}
export function useVoucherColumns({refreshList}:Args) {
    const {timeConverter} = useTimeConverter();

    const { getVoucherStatusConverter, getVoucherChannelConverter} = useExternalVoucherConverter();
    const statusStyle = (status: ExternalVoucherStatus) =>{
        switch (status) {
            case ExternalVoucherStatus.DELETED:
                return listStyle.deleted;
            case ExternalVoucherStatus.REGISTERED:
                return listStyle.blue;
            default:
                return ""
        }
    }

    const createAtColumn: TableColumnData<ExternalVoucherListDTO> = useMemo(
        () => ({
            accessor: "createAt",
            header: "등록일",
            sortable: false,
            getNode: (vo: ExternalVoucherListDTO) => <span>{timeConverter.convertToLocalTime(vo.createAt)}</span>,
        }), [timeConverter]);

    const channelColumn: TableColumnData<ExternalVoucherListDTO> = useMemo(
        () => ({
            accessor: "externalChannel",
            header: "채널",
            sortable: false,
            getNode: (vo: ExternalVoucherListDTO) => <>{getVoucherChannelConverter(vo.externalChannel)}</>,
            //eslint-disable-next-line
        }), []);

    const codeColumn: TableColumnData<ExternalVoucherListDTO> = useMemo(
        () => ({
            accessor: "code",
            header: "코드",
            sortable: false,
            getNode: (vo: ExternalVoucherListDTO) => (
                <span className={statusStyle(vo.externalVoucherStatus)}>
                    {vo.code}
                </span>
            )
            ,
            //eslint-disable-next-line
        }), []);

    const productNameColumn: TableColumnData<ExternalVoucherListDTO> = useMemo(
        () => ({
            accessor: "productNames",
            header: "부여상품명",
            sortable: false,
            getNode: (vo: ExternalVoucherListDTO) =><>{vo.productNames}</>,
            //eslint-disable-next-line
        }), []);

    const purchasedUsernameColumn: TableColumnData<ExternalVoucherListDTO> = useMemo(
        () => ({
            accessor: "purchasedUserName",
            header: "이름",
            sortable: false,
            getNode: (vo: ExternalVoucherListDTO) => <span>
                {(vo.userName === "" && vo.purchasedUserName === null) ? "-" :
                    vo.userName ? vo.userName : vo.purchasedUserName || ""}
            </span>,
        }), []);

    const userIdColumn: TableColumnData<ExternalVoucherListDTO> = useMemo(
        () => ({
            accessor: "idx",
            header: "아이디",
            sortable: false,
            getNode: (vo: ExternalVoucherListDTO) => <span>{vo.userId === "" ? "-" : vo.userId }</span>,
        }), []);

    const statusColumn: TableColumnData<ExternalVoucherListDTO> = useMemo(
        () => ({
            accessor: "externalVoucherStatus",
            header: "상태",
            sortable: false,
            getNode: (vo: ExternalVoucherListDTO) => (
                <span className={statusStyle(vo.externalVoucherStatus)}>
                    {getVoucherStatusConverter(vo.externalVoucherStatus)}
                </span>),
            //eslint-disable-next-line
        }), []);


    const idxColumn: TableColumnData<ExternalVoucherListDTO> = useMemo(
        () => ({
            accessor: "idx",
            header: "관련 기능",
            sortable: false,
            getNode: (vo: ExternalVoucherListDTO) => (
                <ExternalVoucherButtons dto={vo} refreshList={refreshList}/>
            ),
            //eslint-disable-next-line
        }), []);


    const columns: TableColumnData<ExternalVoucherListDTO>[] = useMemo(
        () => [
            createAtColumn,
            channelColumn,
            codeColumn,
            productNameColumn,
            purchasedUsernameColumn,
            userIdColumn,
            statusColumn,
            idxColumn,
        ],
        [
            createAtColumn,
            channelColumn,
            codeColumn,
            productNameColumn,
            purchasedUsernameColumn,
            userIdColumn,
            statusColumn,
            idxColumn,
        ]
    );


    return {
        columns,
    };

}