import {useCallback} from "react";
import {CounselorGroupDTO, CounselorManagerDTO} from "../../../../types/counselor";
import {ValidStatus} from "../../../../types/common";
import {emailRegExp, pwdRegExp} from "../../../../util/regexpUtil";

interface Args {
    groupDTO?: CounselorGroupDTO;
    counselorDTO?: CounselorManagerDTO;

    groupNameValidate?: ValidStatus;
    setGroupNameValidate?: (_: ValidStatus) => void;
    addressValidate?: ValidStatus;
    setAddressValidate?: (_: ValidStatus) => void;
    affiliationDistributionValidate?: ValidStatus;
    setAffiliationDistributionValidate?: (_: ValidStatus) => void;
    consultantDistributionValidate?: ValidStatus;
    setConsultantDistributionValidate?: (_: ValidStatus) => void;

    idValidate: ValidStatus;
    setIdValidate: (_: ValidStatus) => void;
    cellPhoneValidate: ValidStatus;
    setCellPhoneValidate: (_: ValidStatus) => void;
    nameValidate: ValidStatus;
    setNameValidate: (_: ValidStatus) => void;
    passwordValidate: ValidStatus;
    setPasswordValidate: (_: ValidStatus) => void;
    passwordConfirmValidate: ValidStatus;
    setPasswordConfirmValidate: (_: ValidStatus) => void;
    affiliationIdxValidate?: ValidStatus;
    setAffiliationIdxValidate?: (_: ValidStatus) => void;
}

export function useCounselorGroupValidator(
    {
        groupDTO, counselorDTO,
        groupNameValidate, setGroupNameValidate,
        addressValidate, setAddressValidate,
        affiliationDistributionValidate, setAffiliationDistributionValidate,
        consultantDistributionValidate, setConsultantDistributionValidate,
        idValidate, setIdValidate,
        cellPhoneValidate, setCellPhoneValidate,
        nameValidate,passwordValidate,passwordConfirmValidate,
        setNameValidate, setPasswordValidate, setPasswordConfirmValidate,
        affiliationIdxValidate, setAffiliationIdxValidate,
    }
        : Args) {

    // 무소속 상담사 등록 여부
    const isGroupValidate =
        setGroupNameValidate && setAddressValidate && setAffiliationDistributionValidate && setConsultantDistributionValidate;


    const validate = useCallback(() => {
        let result = true;

        // 상담 소속 유효성 검사
        if (groupDTO && isGroupValidate) {

            // 상담소속 이름 길이
            if (groupDTO.name.length < 2) {
                setGroupNameValidate(ValidStatus.INPUT_LENGTH)
                result = false;
            }

            // 상담소속 중복확인 체크
            if (groupNameValidate !== ValidStatus.VALID) {
                setGroupNameValidate(ValidStatus.UNCHECKED);
                result = false;
            }

            // 상담소속 주소 기입1
            if (groupDTO.address.address1 === '') {
                setAddressValidate(ValidStatus.UNWRITTEN);
                result = false;
            }

            // 상담소속 주소 기입2
            if (groupDTO.address.address2 === '') {
                setAddressValidate(ValidStatus.DETAIL_UNWRITTEN);
                result = false;
            }

            // 상담소속 소속분배금 기입
            if (groupDTO.affiliationDistributionAmount === 0 ||
                Number.isNaN(groupDTO.affiliationDistributionAmount)) {
                setAffiliationDistributionValidate(ValidStatus.UNWRITTEN)
                result = false;
            }

            // 상담소속 상담사 분배금
            if (groupDTO.consultantDistributionAmount === 0 ||
                Number.isNaN(groupDTO.consultantDistributionAmount)) {
                setConsultantDistributionValidate(ValidStatus.UNWRITTEN)
                result = false;
            }
        }

        if (counselorDTO) {
            const isCounselorWithoutAffiliation = !groupDTO && counselorDTO.counselingAffiliationIdx === ""

            if (counselorDTO.email.length < 6) {
                setIdValidate(ValidStatus.INPUT_LENGTH);
                result = false;
            }

            if (!emailRegExp.test(counselorDTO.email) && counselorDTO.email !=='') {
                setIdValidate(ValidStatus.WRONG_TYPE);
                return false;
            }

            if (idValidate !== ValidStatus.VALID) {
                setIdValidate(ValidStatus.UNCHECKED);
                result = false;
            }

            if (counselorDTO.name.length < 2) {
                setNameValidate(ValidStatus.INPUT_LENGTH);
                result = false;
            }

            if (counselorDTO.phoneNumber.length < 11) {
                setCellPhoneValidate(ValidStatus.INPUT_LENGTH);
                result = false;
            }

            if (cellPhoneValidate !== ValidStatus.VALID) {
                setCellPhoneValidate(ValidStatus.UNCHECKED);
                result = false;
            }

            if (!pwdRegExp.test(counselorDTO.password)) {
                setPasswordValidate(ValidStatus.WRONG_TYPE);
                result = false;
            }

            if (counselorDTO.password !== counselorDTO.passwordConfirm) {
                setPasswordConfirmValidate(ValidStatus.MISMATCH);
                result = false;
            }

            if (isCounselorWithoutAffiliation && setAffiliationIdxValidate) {
                setAffiliationIdxValidate(ValidStatus.UNWRITTEN);
                result = false;
            }

            if (isCounselorWithoutAffiliation && setConsultantDistributionValidate) {
                setConsultantDistributionValidate(ValidStatus.UNWRITTEN)
                result = false;
            }

        }

            return result;

        //eslint-disable-next-line
        }, [
        groupDTO, counselorDTO, groupNameValidate, addressValidate,
        consultantDistributionValidate, affiliationDistributionValidate,
        idValidate, cellPhoneValidate, nameValidate, passwordValidate, passwordConfirmValidate,
        affiliationIdxValidate, setAffiliationIdxValidate
    ])

    return {
        validate
    }
}