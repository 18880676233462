import {CounselorAffiliationDTO} from "../../../../types/counselor";
import {ValidStatus} from "../../../../types/common";
import {useTranslation} from "react-i18next";
import style from "../../style/registration.module.scss";
import {Label104x46} from "../../../../components/Label";
import {CounselorPriceField} from "../../CounselorPriceField";
import React from "react";

interface DistributionAmountProps {
    affiliationInfo: CounselorAffiliationDTO | null;
    affiliationAmount: number;
    consultantAmount: number;
    setConsultantAmount: (amount: number) => void;
    consultantValidate?: ValidStatus;
    setConsultantValidate?: (_: ValidStatus) => void;
}

export function DistributionAmount (
    {
        affiliationInfo,
        affiliationAmount,
        consultantAmount,
        setConsultantAmount,
        consultantValidate,
        setConsultantValidate
    }: DistributionAmountProps){
    const {t} = useTranslation("counselorGroup");

    return (
        <div className={style.priceMainWrapper}>
            {affiliationInfo && (
                <div className={style.priceInputWrapper}>
                    <Label104x46 text={t("registration.group.groupDistribution")} isRequired={true}/>
                    <span className={style.text}>{affiliationAmount}</span>
                </div>
            )}
            <div className={style.priceInputWrapper}>
                <Label104x46 text={t("registration.group.counselorDistribution")} isRequired={true}/>
                {affiliationInfo?.consultantDistributionAmount !== undefined ? (
                    <span className={style.text}>{consultantAmount}</span>
                ) : (
                    <CounselorPriceField
                        value={consultantAmount}
                        setValue={setConsultantAmount}
                        validate={consultantValidate}
                        setValidate={setConsultantValidate}
                    />
                )}
            </div>
        </div>
    );
};