import React from "react";
import {RadioGroupField} from "../../../components/RadioGroupField";
import {TaxSelectedValue} from "../../../types/counselor";

interface Args {
    selectedValue: TaxSelectedValue;
    setSelectedValue: (_: TaxSelectedValue) => void
    groupName: string;
}

export function CounselorRadioField({selectedValue, setSelectedValue, groupName}: Args) {
    /**
     * 라디오 버튼을 포함한 컴포넌트
     */

    const counselorRadioCase = [
        TaxSelectedValue.SELECT, TaxSelectedValue.UNSELECT
    ];

    const getLabel = (value: TaxSelectedValue) => {
        switch (value) {
            case TaxSelectedValue.SELECT:
                return "선택";
            case TaxSelectedValue.UNSELECT:
                return "미선택";
        }
    }

    return (
        <>
            <RadioGroupField
                enumValues={counselorRadioCase}
                getLabel={getLabel}
                value={selectedValue} setValue={setSelectedValue}
                groupName={groupName} isRequired={true}
            />

        </>
    )
}