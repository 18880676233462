import React from "react";
import {callAsync} from "../../../../util/sliceUtil";
import {adminDeleteCounselorAsync, consultantManagerDeleteCounselorAsync} from "../../../../store/counselorSlice";
import {useAppDispatch} from "../../../../hooks/hooks";
import {useTranslation} from "react-i18next";
import {ConsultantDTO} from "../../../../types/counselor";
import {AdminStatus} from "../../../../types/admin";
import {AdminRoleCategory} from "../../../../types/adminRole";
import {ActionButton} from "../../../../components/Buttons";

export const DeleteAction = ({ dto, role }: { dto: ConsultantDTO; role: string | null }) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation("admin");

    if (dto.status !== AdminStatus.BEFORE_SELF_AUTHENTICATION &&
        dto.status !== AdminStatus.BEFORE_ADMIN_AUTHENTICATION) return null;

    const getDeleteDispatcher = (role: string) => {
        switch (role) {
            case AdminRoleCategory.ADMIN_ROOT:
                return adminDeleteCounselorAsync;
            case AdminRoleCategory.CONSULTANT_MANAGER:
                return consultantManagerDeleteCounselorAsync;
        }
    };

    const handleDelete = async () => {
        if (!role) {
            console.warn("권한이 없습니다. 다시 시도해주세요.");
            return;
        }

        const dispatchFunction = getDeleteDispatcher(role);

        if (window.confirm("해당 상담사를 삭제 하시겠습니까?") && dispatchFunction) {
            await callAsync(
                dispatch(dispatchFunction(String(dto.idx))).unwrap(),
                () => {
                    alert("삭제가 완료되었습니다.")
                    window.location.reload()
                },
                () => alert('일시적인 문제가 발생했습니다.\n다시 시도해주세요.')
            );
        }
    };

    return (
        <ActionButton onClick={handleDelete}>
            {t("buttons.delete.label")}
        </ActionButton>
    );
};