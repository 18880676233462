import {useTranslation} from "react-i18next";
import {useAppDispatch} from "../../../../hooks/hooks";
import {useUtil} from "../../../../util/hooks/useUtil";
import {useCallback} from "react";
import {callAsync} from "../../../../util/sliceUtil";
import {updateCounselingMemoAsync} from "../../../../store/counselorSlice";
import {CounselingMemoPutDTO} from "../../../../types/counselor";

interface Args {
    onRefresh: () => void;
}
export function useCounselorMemoUpdater({ onRefresh }: Args) {
    const { t } = useTranslation("memo");
    const dispatch = useAppDispatch();
    const { defaultErrorMessage } = useUtil();

    const updateMemo = useCallback(
        (body: CounselingMemoPutDTO) => {
            if (window.confirm(t("update.confirm"))) {
                callAsync(
                    dispatch(updateCounselingMemoAsync(body)).unwrap(),
                    () => {
                        onRefresh();
                        alert(t("update.result"));
                    },
                    () => alert(defaultErrorMessage)
                ).then();
            }
        },
        [onRefresh, defaultErrorMessage, dispatch, t]
    );

    return {
        updateMemo,
    };
}