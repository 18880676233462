import style from "../../../style/test_result/guide/learningStrategy.module.scss";
import {Trans, useTranslation} from "react-i18next";
import React from "react";

export function useJobInfoFunctions() {
    const {t} = useTranslation("guideRecommendationJob");

    const renderListItems = (length:number, groupNumber:number, job:string) => {
        const items = [];
        for (let i = 1; i <= length; i++) {
            items.push(
                <li key={i}>
                    <h4 className={style.title}>
                        <Trans t={t} i18nKey={`${job}.group${groupNumber}.subTitle${i}`} />
                    </h4>
                    <Trans
                        t={t}
                        i18nKey={`${job}.group${groupNumber}.subDesc${i}`}
                        values={{ blank: '\u00A0' }}
                    />
                </li>
            );
        }
        return items;
    };

    return{
        renderListItems
    }
}