import {useCallback, useMemo} from "react";
import {ExternalChannel, ExternalVoucherFilterWordType, ExternalVoucherStatus} from "../../../types/externalVoucher";

export function useExternalVoucherConverter() {

    // 검색어 enum 정보 변경 함수
    const voucherSearchWordConverter = useMemo(
        () => ({
            [ExternalVoucherFilterWordType.CODE.toString()]: "코드",
            [ExternalVoucherFilterWordType.GRANTED_NAME.toString()]: "부여상품명",
            [ExternalVoucherFilterWordType.USER_NAME.toString()]: "이름",
            [ExternalVoucherFilterWordType.ID.toString()]: "아이디",
        }),
        []
    );
    const getVoucherSearchWordConverter = useCallback(
        (status: ExternalVoucherFilterWordType) => voucherSearchWordConverter[status.toString()],
        [voucherSearchWordConverter]
    );

    // 외부 채널
    const voucherChannelConverter = useMemo(
        () => ({
            [ExternalChannel.NAVER.toString()]: "네이버",
            [ExternalChannel.KAKAO.toString()]: "카카오",
            [ExternalChannel.ETC.toString()]: "기타",
        }),
        []
    );
    const getVoucherChannelConverter = useCallback(
        (status: ExternalChannel) => voucherChannelConverter[status.toString()],
        //eslint-disable-next-line
        [voucherSearchWordConverter]
    );

    // 상태
    const voucherStatusConverter = useMemo(
        () => ({
            [ExternalVoucherStatus.BEFORE_REGISTERED.toString()]: "미등록",
            [ExternalVoucherStatus.ALARMED.toString()]: "안내",
            [ExternalVoucherStatus.REGISTERED.toString()]: "등록",
            [ExternalVoucherStatus.DELETED.toString()]: "삭제",
        }),
        []
    );
    const getVoucherStatusConverter = useCallback(
        (status: ExternalVoucherStatus) => voucherStatusConverter[status.toString()],
        //eslint-disable-next-line
        [voucherSearchWordConverter]
    );

    return {
        getVoucherSearchWordConverter,
        getVoucherChannelConverter,
        getVoucherStatusConverter
    }
}