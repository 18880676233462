import {FilterType, FilterValue} from "../../../types/filter";
import {
    ExternalChannel,
    ExternalVoucherFilterWordType,
    ExternalVoucherStatus,
    VoucherListQueryType
} from "../../../types/externalVoucher";
import {useCallback, useMemo, useState} from "react";
import {PageSearchCondition} from "../../../types/page";
import {useListSearchParams} from "../../../hooks/list/useListSearchParams";
import {useFilterValues} from "../../../hooks/list/useFilterValues";

export interface ExternalVoucherFilterValues {
    searchWordTypeFilterValue: FilterValue<ExternalVoucherFilterWordType>;
    searchWordFilterValue: FilterValue<string>;
    createdFromFilterValue: FilterValue<Date | undefined>;
    createdToFilterValue: FilterValue<Date | undefined>;
    channelFilterValue: FilterValue<ExternalChannel>;
    statusFilterValue: FilterValue<ExternalVoucherStatus>;
}

export function useExternalVoucherFilterValues() {
    const [searchWordType, setSearchWordType] = useState('');
    const [searchWord, setSearchWord] = useState("");
    const [createdFrom, setCreatedFrom] = useState<Date | undefined>();
    const [createdTo, setCreatedTo] = useState<Date | undefined>();
    const [channel, setChannel] = useState('');
    const [status, setStatus] = useState('');
    const [pageInfoOnFilter, setPageInfoOnFilter] = useState<PageSearchCondition>({});

    const {listQueryType, setUrlSearchParams} = useListSearchParams<VoucherListQueryType>();

    // 필터 영역
    const filterValueArray: FilterValue<any>[] = useMemo(
        () => [
            {
                key: "searchWordType",
                value: searchWordType,
                setter: setSearchWordType,
                // defaultValue: CounselorFilterWordType.ALL,
                defaultValue: '',
                type: FilterType.OTHER,
            },
            {key: "searchWord", value: searchWord, setter: setSearchWord, defaultValue: "", type: FilterType.STRING},
            {
                key: "createdFrom",
                value: createdFrom,
                setter: setCreatedFrom,
                defaultValue: undefined,
                type: FilterType.DATE,
            },
            {
                key: "createdTo",
                value: createdTo,
                setter: setCreatedTo,
                defaultValue: undefined,
                type: FilterType.DATE,
            },
            {
                key: "channel",
                value: channel,
                setter: setChannel,
                defaultValue: '',
                type: FilterType.OTHER,
            },
            {
                key: "status",
                value: status,
                setter: setStatus,
                defaultValue: '',
                type: FilterType.OTHER,
            },
        ],
        [searchWordType, searchWord, createdFrom, createdTo, channel, status]
    );

    const {getFilterValueByKey} = useFilterValues({
        filterValueArray,
        listQueryType: listQueryType,
        setPageInfoOnFilter,
    });

    const filterValues = useMemo(
        () =>
            ({
                searchWordTypeFilterValue: getFilterValueByKey("searchWordType") as FilterValue<ExternalVoucherFilterWordType>,
                searchWordFilterValue: getFilterValueByKey("searchWord") as FilterValue<string>,
                createdFromFilterValue: getFilterValueByKey("createdFrom") as FilterValue<Date | undefined>,
                createdToFilterValue: getFilterValueByKey("createdTo") as FilterValue<Date | undefined>,
                channelFilterValue: getFilterValueByKey("channel") as FilterValue<ExternalChannel>,
                statusFilterValue: getFilterValueByKey("status") as FilterValue<ExternalVoucherStatus>,
            } as ExternalVoucherFilterValues),
        [getFilterValueByKey]
    );
    // 필터 영역 종료

    // searchList 영역
    const searchList = useCallback(
        (pageSearchCondition: PageSearchCondition, listQueryTypeParam?: VoucherListQueryType) => {
            const param: VoucherListQueryType = {
                searchWordType: listQueryTypeParam?.searchWordType,
                searchWord: listQueryTypeParam?.searchWord,
                createdFrom: listQueryTypeParam?.createdFrom,
                createdTo: listQueryTypeParam?.createdTo,
                channel: listQueryTypeParam?.channel,
                status: listQueryTypeParam?.status,
            };

            return () => setUrlSearchParams(param, pageSearchCondition);
        },
        [setUrlSearchParams]
    );

    return {
        pageInfoOnFilter,
        filterValues, searchList,
        listQueryType
    }
}