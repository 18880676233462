import style from "../../style/test_result/testResultHeader.module.scss";
import {useTranslation} from "react-i18next";

export function TestResultHeader() {
    /**
     * 검사결과 보고서 헤더
     */
    const {t} = useTranslation("testResult");
    // todo kpass / dcas 분리 필요함 (12세 이상 / 미만 처리)
    const print = () =>{
            window.print();
        }

    return(
        <>
            <header className={style.headerWrap} >
                <div className={style.header}>
                    <div className={style.headerLogoWrapper}>
                        <img className={style.logo} src="https://images.feel-good.io/kpass-logo-transparent.svg"
                             alt="kpass-logo"/>
                        <h4 className={style.text}>{t('testResultHeader.title')}</h4>
                    </div>

                    <div className={style.headerFunctionsWrapper}>
                        <button onClick={print}>
                            <img
                                className="icon"
                                src="https://images.feel-good.io/icon/printer_icon.svg"
                                alt="close"/>
                        </button>
                        <button id="closeButton">
                            <img
                                className="icon"
                                src="https://images.feel-good.io/report/close-icon.svg"
                                alt="close"/>
                        </button>
                    </div>
                </div>
            </header>
        </>
    )
}