import {Panel} from "../../layout/content/detail/Panel";
import style from "../style/grantModal.module.scss";
import {PanelWrapper} from "../../layout/content/detail/PanelWrapper";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {LabelAndTextField} from "./hooks/LabelAndTextField";
import {LabelAndTextInputField} from "./hooks/LabelAndTextInputField";
import {useExternalVoucherFunctions} from "./hooks/useExternalVoucherFunctions";
import {useParams} from "react-router-dom";
import {useExternalVoucherConverter} from "./hooks/useExternalVoucherConverter";
import {useExternalVoucherGetter} from "./hooks/useExternalVoucherGetter";
import {ExternalInformationPutDTO} from "../../types/externalVoucher";
import {widowOpenerReload} from "../../util/etcUtil";

export function VoucherInformation() {
    /**
     * 이용권 안내 기본 개별창
     */
    const {t} = useTranslation("voucher");
    const {id} = useParams<"id">();
    const idx = Number(id);

    const [grantedProductName, setGrantedProductName] = useState("")
    const [purchasedUserName, setPurchasedUserName] = useState("")
    const [purchasedUserCellphone, setPurchasedUserCellphone] = useState("")
    const {getVoucherChannelConverter} = useExternalVoucherConverter();

    const {informationVoucher} = useExternalVoucherFunctions();
    const {getVoucherDetail, voucherDetail} = useExternalVoucherGetter();
    const isInformed = Boolean(voucherDetail && voucherDetail.purchasedUserName && voucherDetail.purchasedUserCellphone);


    useEffect(() => {
        getVoucherDetail(idx).then();
        voucherDetail && setGrantedProductName(voucherDetail.productNames.join(", "))
        //eslint-disable-next-line
    }, [id]);

    useEffect(() => {
        if (voucherDetail) {
            setGrantedProductName(voucherDetail.productNames.join(", "));
        }
    }, [voucherDetail]);

    const onClickGenerateVoucher = () => {
        if (purchasedUserName === "") {
            alert("구매자 정보를 기입해주세요.");
            return;
        }
        if (purchasedUserCellphone === "") {
            alert("구매자 휴대전화 정보를 기입해주세요.");
            return;
        }

        const dto: ExternalInformationPutDTO = {
            idx: idx,
            purchasedUserName: purchasedUserName,
            purchasedUserCellphone: purchasedUserCellphone
        }

        if (window.confirm("해당 정보의 유저에게 이용권 코드를 안내하시겠습니까?"))
            informationVoucher(dto).then(() => {
                alert("이용권 안내가 완료되었습니다.")
                widowOpenerReload();
            })
    }


    return (
        <PanelWrapper>
            <Panel title={t("information.title")} panelClassName={style.panel}>
                {voucherDetail && (
                    <>
                        {/*채널 정보 ㅡㅡㅡㅡㅡ*/}
                        <div className={style.flexStartWrapper}>
                            <LabelAndTextField title={t("information.channel")}
                                               value={getVoucherChannelConverter(voucherDetail.externalChannel)}/>
                            <LabelAndTextField title={t("information.code")}
                                               value={voucherDetail.code}/>
                        </div>

                        <LabelAndTextField title={t("information.grantedName")}
                                           value={grantedProductName}/>

                        <div className={style.flexStartWrapper}>
                            {isInformed ? (
                                <>
                                    <LabelAndTextField
                                        title={t("information.purchased.nameLabel")}
                                        value={voucherDetail.purchasedUserName}/>
                                    <LabelAndTextField
                                        title={t("information.purchased.cellphoneLabel")}
                                        value={voucherDetail.purchasedUserCellphone}/>
                                </>
                            ) : (
                                <>
                                    <LabelAndTextInputField
                                        title={t("information.purchased.nameLabel")}
                                        placeholder={t("information.purchased.namePlaceholder")}
                                        value={purchasedUserName} setValue={setPurchasedUserName}/>
                                    <LabelAndTextInputField
                                        title={t("information.purchased.cellphoneLabel")}
                                        placeholder={t("information.purchased.cellphonePlaceholder")}
                                        value={purchasedUserCellphone} setValue={setPurchasedUserCellphone}/>
                                </>
                            )}
                        </div>

                        {/*등록 버튼 */}
                        {!isInformed && (
                            <div className={style.buttonArea}>
                                <button onClick={onClickGenerateVoucher}>{t("information.button")}</button>
                            </div>
                        )}

                    </>
                )}
            </Panel>
        </PanelWrapper>
    )
}