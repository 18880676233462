import {useNavigate} from "react-router-dom";
import {useEffect} from "react";
import {useMyInfoGetter} from "../../features/account/mypage/hooks/useMyInfoGetter";
import {AdminRoleCategory} from "../../types/adminRole";

export function Home() {
  const navigate = useNavigate();
  const {myInfo} = useMyInfoGetter();

  const isConsultantUser = (role: AdminRoleCategory) =>{
      const consultantRole = [AdminRoleCategory.CONSULTANT, AdminRoleCategory.CONSULTANT_MANAGER];
      return consultantRole.includes(role);
  }

  // consultant 권한 사용자는 member/user를 볼 수 없기 때문에 counselor/manager로 이동.
  useEffect(
    () => {
        if (myInfo && isConsultantUser(myInfo.role.category)) {
            navigate(`/counselor/list`);
        } else {
            navigate(`/member/user`);
        }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return <></>;
}
