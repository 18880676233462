import style from "../../style/test_result/guide/guideBody.module.scss";
import {Trans, useTranslation} from "react-i18next";
import React from "react";
import {useLanguage} from "../../../hooks/hooks";

export function GuideImproveWeakness() {

    const {t} = useTranslation("guideCommon");
    const {isKorean} = useLanguage()

    return(
        <>
            <>
                <div className={`${style.pageBody} ${style.narrowGap}`}>
                    <article className={style.summary}>
                        <div className={style.titleWrapper}>
                            <h2 className={`${style.title} ${isKorean ? '' : style.notKO}`}
                                // th:classappend="${languageClass}"
                            >
                                <span className={style.bold}>
                                    <Trans t={t} i18nKey="improveWeakness.title"
                                           components={{br: <br/>}}/>
                                </span>
                            </h2>

                            <div className={style.descriptionWrapper}>
                                <p className={style.description}>
                                    <Trans t={t} i18nKey="improveWeakness.subTitle"
                                           values={{blank: '\u00A0'}}
                                           components={{br: <br/>}}/>
                                </p>
                            </div>
                        </div>

                        <hr className={style.underline}/>
                    </article>

                    <article className={style.article}>
                        <article className={style.subArticle}>
                            <img src="https://images.feel-good.io/guide/compensation_left.png" alt={"compensation"}/>
                            <section>
                                <h3 className={style.title}>
                                    <span className={style.number}>1</span>
                                    <span>{t('improveWeakness.article1.title')}</span>
                                </h3>
                                <div className={style.description}>
                                    <dt className={style.floatLeft}>{t('improveWeakness.article1.left1')}</dt>
                                    <dd>{t('improveWeakness.article1.right1')}</dd>
                                    <dt className={style.floatLeft}>{t('improveWeakness.article1.left2')}</dt>
                                    <dd>{t('improveWeakness.article1.right2')}</dd>
                                </div>
                            </section>
                            <section>
                                <h3 className={style.title}>
                                    <span className={style.number}>2</span>
                                    <span>{t('improveWeakness.article2.title')}</span>
                                </h3>
                                <div className={style.description}>
                                    <dt className={style.floatLeft}>{t('improveWeakness.article2.left1')}</dt>
                                    <dd>{t('improveWeakness.article2.right1')}</dd>
                                </div>
                            </section>
                            <section>
                                <h3 className={style.title}>
                                    <span className={style.number}>3</span>
                                    <span>{t('improveWeakness.article3.title')}</span>
                                </h3>
                                <div className={style.description}>
                                    <dt className={style.floatLeft}>{t('improveWeakness.article3.left1')}</dt>
                                    <dd>{t('improveWeakness.article3.right1')}</dd>
                                </div>
                            </section>
                            <section>
                                <h3 className={style.title}>
                                    <span className={style.number}>4</span>
                                    <span>{t('improveWeakness.article4.title')}</span>
                                </h3>
                                <div className={style.description}>
                                    <dt className={style.floatLeft}>{t('improveWeakness.article4.left1')}</dt>
                                    <dd>{t('improveWeakness.article4.right2')}</dd>
                                    <dt className={style.floatLeft}>{t('improveWeakness.article4.left2')}</dt>
                                    <dd>{t('improveWeakness.article4.right2')}</dd>
                                </div>
                            </section>
                            <section>
                                <h3 className={style.title}>
                                    <span className={style.number}>5</span>
                                    <span>{t('improveWeakness.article5.title')}</span>
                                </h3>
                                <div className={style.description}>
                                    <dt className={style.floatLeft}>{t('improveWeakness.article5.left1')}</dt>
                                    <dd>{t('improveWeakness.article5.right2')}</dd>
                                    <dt className={style.floatLeft}>{t('improveWeakness.article5.left2')}</dt>
                                    <dd>{t('improveWeakness.article5.right2')}</dd>
                                </div>
                            </section>
                            <section>
                                <h3 className={style.title}>
                                    <span className={style.number}>6</span>
                                    <span>{t('improveWeakness.article6.title')}</span>
                                </h3>
                                <div className={style.description}>
                                    <dt className={style.floatLeft}>{t('improveWeakness.article6.left1')}</dt>
                                    <dd>{t('improveWeakness.article6.right1')}</dd>
                                </div>
                            </section>
                        </article>
                    </article>
                </div>
            </>
        </>
    )
}