import {Trans, useTranslation} from "react-i18next";
import React from "react";
import style from "../../style/test_result/guide/guideContent.module.scss";

interface Args {
    titleValue?: string,
    jsonKey?: string
}

export function PersonalTitle({titleValue, jsonKey}: Args) {
    const {t} = useTranslation("guideCommon");

    const LightTitleWrapper = ({value, children}: { value?: string, children?: React.ReactNode }) => {
        return (
            <span className={style.light} data-text={value} style={{fontSize: "65px"}}>
                <span className={style.realText}>{children}</span>
             </span>
        );
    }

    return (
        <>
            {titleValue && (
                <>
                    <span data-text={titleValue ? titleValue.replace(/ /g, '\u00A0') : undefined}>
                        <Trans t={t} i18nKey={jsonKey} components={{light: <LightTitleWrapper value={titleValue}/>,}}/>
                    </span>
                    <br/>
                </>
            )}
        </>
    )
}