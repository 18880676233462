import {useListFilterValues, useTimeConverter} from "../../../hooks/hooks";
import {useTranslation} from "react-i18next";
import {useMemo} from "react";
import {FilterContentType, TableFilter} from "../../../types/tableFilter";
import {ExternalChannel, ExternalVoucherFilterWordType, ExternalVoucherStatus} from "../../../types/externalVoucher";
import {useExternalVoucherConverter} from "./useExternalVoucherConverter";

interface Args {
    search: () => void;
}

export function useVoucherFilters({search}: Args) {
    const {t} = useTranslation(["filter", "common"]);
    const {filterValues} = useListFilterValues();
    const {timeConverter} = useTimeConverter();
    const {getVoucherSearchWordConverter, getVoucherChannelConverter, getVoucherStatusConverter}
        = useExternalVoucherConverter();


    const searchWordTypeFilter = useMemo(
        () => filterValues.searchWordTypeFilterValue,
        [filterValues.searchWordTypeFilterValue]
    );
    const searchWordFilter = useMemo(() => filterValues.searchWordFilterValue, [filterValues.searchWordFilterValue]);
    const createdFromFilter = useMemo(
        () => filterValues.createdFromFilterValue,
        [filterValues.createdFromFilterValue]
    );
    const createdToFilter = useMemo(
        () => filterValues.createdToFilterValue,
        [filterValues.createdToFilterValue]
    );
    const channelFilter = useMemo(
        () => filterValues.channelFilterValue,
        [filterValues.channelFilterValue]
    );
    const statusFilter = useMemo(() => filterValues.statusFilterValue,
        [filterValues.statusFilterValue]);

    const filters: TableFilter[][] = [
        [
            {
                labelTitle: "검색어",
                content: {
                    type: FilterContentType.TEXT,
                    value: searchWordFilter?.value,
                    maxLength: 100,
                    placeholderVal: t("searchWord.placeholder"),
                    onChangeFunc: (v) => searchWordFilter.setter(v),
                    onEnterKeyUpFunc: search,
                    typeSelector: {
                        type: FilterContentType.SELECT,
                        value: searchWordTypeFilter?.value,
                        options: [
                            {label: "전체", value: ""},
                            {
                                value: ExternalVoucherFilterWordType.CODE,
                                label: getVoucherSearchWordConverter(ExternalVoucherFilterWordType.CODE),
                            },
                            {
                                value: ExternalVoucherFilterWordType.GRANTED_NAME,
                                label: getVoucherSearchWordConverter(ExternalVoucherFilterWordType.GRANTED_NAME),
                            },
                            {
                                value: ExternalVoucherFilterWordType.USER_NAME,
                                label: getVoucherSearchWordConverter(ExternalVoucherFilterWordType.USER_NAME),
                            },
                            {
                                value: ExternalVoucherFilterWordType.ID,
                                label: getVoucherSearchWordConverter(ExternalVoucherFilterWordType.ID),
                            },
                        ],
                        onChangeFunc: (v) => searchWordTypeFilter?.setter(v as ExternalVoucherFilterWordType),
                    },
                },
            },
        ],
        [
            {
                labelTitle: "등록일",
                content: {
                    type: FilterContentType.CALENDAR_RANGE,
                    from: {
                        type: FilterContentType.CALENDAR,
                        value: timeConverter.convertToFilterDateString(createdFromFilter?.value),
                        onChangeFunc: (v) =>
                            createdFromFilter?.setter(v ? timeConverter.convertToFilterStartOfDay(v) : undefined),
                        placeholderVal: t("inputDatePlaceholder"),
                    },
                    to: {
                        type: FilterContentType.CALENDAR,
                        value: timeConverter.convertToFilterDateString(createdToFilter?.value),
                        onChangeFunc: (v) => createdToFilter?.setter(
                            v ? timeConverter.convertToFilterEndOfDay(v) : undefined),
                        placeholderVal: t("inputDatePlaceholder"),
                    },
                },
            },
            {
                labelTitle: "채널",
                content: {
                    type: FilterContentType.SELECT,
                    value: channelFilter.value,
                    options: [
                        {label: "전체", value: ""},
                        {
                            value: ExternalChannel.KAKAO.toString(),
                            label: getVoucherChannelConverter(ExternalChannel.KAKAO),
                        },
                        {
                            value: ExternalChannel.NAVER.toString(),
                            label: getVoucherChannelConverter(ExternalChannel.NAVER),
                        },
                        {
                            value: ExternalChannel.ETC.toString(),
                            label: getVoucherChannelConverter(ExternalChannel.ETC),
                        },
                    ],
                    onChangeFunc: (v) => channelFilter.setter(v as unknown as ExternalChannel),
                },
            },
            {
                labelTitle: "상태",
                content: {
                    type: FilterContentType.SELECT,
                    value: statusFilter.value,
                    options: [
                        {label: "전체", value: ""},
                        {
                            value: ExternalVoucherStatus.BEFORE_REGISTERED.toString(),
                            label: getVoucherStatusConverter(ExternalVoucherStatus.BEFORE_REGISTERED),
                        },
                        {
                            value: ExternalVoucherStatus.REGISTERED.toString(),
                            label: getVoucherStatusConverter(ExternalVoucherStatus.REGISTERED),
                        },
                        {
                            value: ExternalVoucherStatus.DELETED.toString(),
                            label: getVoucherStatusConverter(ExternalVoucherStatus.DELETED),
                        },
                        {
                            value: ExternalVoucherStatus.ALARMED.toString(),
                            label: getVoucherStatusConverter(ExternalVoucherStatus.ALARMED),
                        }
                    ],
                    onChangeFunc: (v) => statusFilter.setter(v as unknown as ExternalVoucherStatus),
                },
            },
        ],
    ];

    return {
        filters,
    };
}