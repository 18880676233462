import {useCallback, useMemo} from "react";
import {CounselorFilterWordType, CounselorPosition} from "../../../../types/counselor";
import {AdminStatus} from "../../../../types/admin";

export function useCounselorConverter() {

    // 검색어 enum 정보 변경 함수
    const infoSearchWordConverter = useMemo(
        () => ({
            [CounselorFilterWordType.COUNSELING_AFFILIATION.toString()]: "상담소속",
            [CounselorFilterWordType.NAME.toString()]: "이름",
            [CounselorFilterWordType.ID.toString()]: "아이디",
            [CounselorFilterWordType.CELLPHONE.toString()]: "휴대전화",
        }),
        []
    );
    const getInfoSearchWordConverter = useCallback(
        (status: CounselorFilterWordType) => infoSearchWordConverter[status.toString()],
        [infoSearchWordConverter]
    );

    // 검색어 직급 정보 변경 함수
    const infoSearchPositionConverter = useMemo(
        () => ({
            [CounselorPosition.CONSULTANT_MANAGER.toString()]: "상담관리자",
            [CounselorPosition.CONSULTANT.toString()]: "상담사",
        }),
        []
    );
    const getInfoSearchPositionConverter = useCallback(
        (status: CounselorPosition) => infoSearchPositionConverter[status.toString()],
        [infoSearchPositionConverter]
    );

    // 검색어 상태 정보 변경 함수
    const infoSearchStatusConverter = useMemo(
        () => ({
            [AdminStatus.DEFAULT.toString()]: "인증",
            [AdminStatus.BEFORE_ADMIN_AUTHENTICATION.toString()]: "미인증",
            [AdminStatus.BLOCKED.toString()]: "차단회원",
            [AdminStatus.LEAVE.toString()]: "탈퇴",
        }),
        []
    );
    const getInfoSearchStatusConverter = useCallback(
        (status: AdminStatus) => infoSearchStatusConverter[status.toString()],
        [infoSearchStatusConverter]
    );

    return{
        getInfoSearchWordConverter,
        getInfoSearchPositionConverter,
        getInfoSearchStatusConverter
    }
}