import {createAsyncThunk} from "@reduxjs/toolkit";
import {executePromise} from "../util/sliceUtil";
import {ExternalVoucherApi} from "../api/ExternalVoucherApi";
import {
    AssignVoucherDTO,
    ExternalInformationPutDTO,
    ExternalVoucherPostDTO,
    VoucherDeleteDTO,
    VoucherListSearchCondition
} from "../types/externalVoucher";

// 이용권 목록 조회
export const getExternalVoucherListAsync = createAsyncThunk(
    "externalVoucher/getExternalVoucherList",
    (condition: VoucherListSearchCondition) =>
        executePromise(ExternalVoucherApi.getExternalVoucherList(condition))
);

// 이용권 상세 조회
export const getExternalVoucherDetailAsync = createAsyncThunk(
    "externalVoucher/getExternalVoucherDetail",
    (idx: number) =>
        executePromise(ExternalVoucherApi.getExternalVoucherDetail(idx))
);

// 이용권 등록
export const createVouchersAsync = createAsyncThunk(
    "externalVoucher/createVouchers",
    (dto: ExternalVoucherPostDTO) =>
        executePromise(ExternalVoucherApi.createVouchers(dto))
);

// 이용권 안내 등록
export const informationVoucherAsync = createAsyncThunk(
    "externalVoucher/informationVoucher",
    (dto: ExternalInformationPutDTO) =>
        executePromise(ExternalVoucherApi.informationVoucher(dto))
);

// 이용권 삭제
export const deleteVoucherAsync = createAsyncThunk(
    "externalVoucher/deleteVoucher",
    (dto: VoucherDeleteDTO) =>
        executePromise(ExternalVoucherApi.deleteVoucher(dto))
);

// 이용권 등록
export const assignVoucherAsync = createAsyncThunk(
    "externalVoucher/assignVoucher",
    (dto: AssignVoucherDTO) =>
        executePromise(ExternalVoucherApi.assignVoucher(dto))
);