import { ErrorType } from "./error";

interface AuthContextType {
  userEmail: string | null;
  signIn: (user: LoginInfo, successCallback: VoidFunction, errorCallback: (_: ErrorType) => void) => void;
  signOut: (callback: VoidFunction) => void;
  setUserEmail: (_: string | null) => void;
  setAccessToken: (token: string) => void;
  setRefreshToken: (token: string) => void;
  isLoggedIn: boolean;
  setIsLoggedIn: (_: boolean) => void;
  getRefreshToken: () => string | undefined;
  getAccessTokenFromRefreshToken: (_: string) => Promise<void>;
  getAccessTokenFromRefreshTokenCookie: () => Promise<void>;
}

interface LoginInfo {
  email: string;
  password: string;
}

interface LoginResponse {
  accessToken: string;
  refreshToken: string;
  tokenType: string;
}

interface SignUpRequest {
  email: string;
  password: string;
  name: string;
  codePhone: string;
  phoneNumber: string;
  adminType: AdminType;
  roleIdx: number;
  nationality: string;
}

interface FindIdRequest {
  codePhone: string;
  adminType: AdminType;
  phoneNumber: string;
}

interface FindPwdRequest {
  codePhone: string;
  email: string;
  adminType: AdminType;
  phoneNumber: string;
}

interface CheckDuplicatedPhoneNumberRequest {
  adminType: AdminType;
  phoneNumber: string;
  codePhone: string;
}

interface ResendTemporaryPasswordRequest {
  email: string;
  adminType: AdminType;
}

interface ResendAuthConfirmMailRequest {
  email: string;
}

enum AdminType {
  GENERAL = "GENERAL",
  CONSULTANT = "CONSULTANT",
  EXPERT = "EXPERT",
}

export enum FindAccountErrorCode {
  CODE_404 = "CODE_404",
  CODE_477 = "CODE_477",
  CODE_493 = "CODE_493",
}

export { AdminType };
export type {
  AuthContextType,
  LoginInfo,
  LoginResponse,
  SignUpRequest,
  FindIdRequest,
  FindPwdRequest,
  CheckDuplicatedPhoneNumberRequest,
  ResendTemporaryPasswordRequest,
  ResendAuthConfirmMailRequest,
};
