import style from "../style/registration.module.scss";
import {Label104x46} from "../../../components/Label";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useDaumPostcodePopup} from "react-daum-postcode";
import {Address} from "../../../types/user";
import {ValidStatus} from "../../../types/common";

interface Args {
    value: Address;
    setValue: (_: Address) => void
    validate: ValidStatus;
    setValidate: (_: ValidStatus) => void
}

export function CounselorInfoAddressField({value, setValue, validate, setValidate}: Args) {
    /**
     * 상담 소속주소 영역
     */
    const open = useDaumPostcodePopup();
    const {t} = useTranslation("counselorGroup");
    const [detailAddress, setDetailAddress] = useState('')

    const handleComplete = (data: any) => {
        const newAddress: Address = {
            address1: `${data.roadname} ${data.roadAddressEnglish.split(",")[0]}`,
            address2: detailAddress,
            address3: "",
            city: data.sigungu,
            state: data.sido,
            country: "KR",
            postalCode: data.zonecode,
        }
        setValue(newAddress)
    }

    const handleAddress = () => {
        open({onComplete: handleComplete}).then(() => setValidate(ValidStatus.VALID));
    };

    const validationMessage = () => {
        switch (validate) {
            case ValidStatus.UNWRITTEN:
                return <div className={style.inValidateText}>주소를 검색해 주세요.</div>;
            case ValidStatus.DETAIL_UNWRITTEN:
                return <div className={style.inValidateText}>상세주소를 입력해 주세요.</div>;
            case ValidStatus.VALID:
                return <></>;
            default:
                return <></>;
        }
    };
    return (
        <>
            <div className={style.mainInputWrapper}>
                <Label104x46 text={t("registration.group.address")} isRequired={true}/>
                <div className={style.addressInputWrapper}>
                    <div className={style.addressWrapper}>
                        <div className={style.addressArea}>
                            <input className={`${style.address} ${style.text}`}
                                   value={value.address1}
                                   placeholder={t('registration.defaultAddressPlaceHolder')}
                                   readOnly={true}
                            />
                            <div className={style.addressSearchArea}>
                                <span className={style.text}
                                      onClick={handleAddress}>
                                    주소찾기
                                </span>

                            </div>
                        </div>
                        <div className={`${style.addressArea} ${style.close}`}>
                            <input className={`${style.address} ${style.text}`}
                                   placeholder={t('registration.detailAddressPlaceHolder')}
                                   minLength={2}
                                   maxLength={60}
                                   onChange={(e) => {
                                       setValue({
                                           ...value,
                                           address2: e.target.value
                                       });
                                       setDetailAddress((e.target as HTMLInputElement).value)
                                       setValidate(ValidStatus.NONE)
                                   }}
                            />
                        </div>
                    </div>
                    {validationMessage()}
                </div>
            </div>
        </>
    )
}