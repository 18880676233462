import style from "../style/button.module.scss";
import {useNewWindow} from "../../../hooks/hooks";
import {ConsultStatus, CounselorHistoryFunctions} from "../../../types/counselor";
import {useOpenTestResult} from "../../../hooks/useOpenTestResult";
import {VoucherType} from "../../../types/voucher";
import {useHistoryFunctions} from "./hooks/useHistoryFunctions";

interface Args{
    consultStatus:ConsultStatus;
    testType?: VoucherType;
    contentExecutedInfoIdx?: number;
    counselingIdx?: number;
    isMemoWritten?: boolean;
    isTestResultRemoved?: boolean;
}
export function CounselorHistoryAdditionalButtonField(
    {consultStatus, testType, contentExecutedInfoIdx, counselingIdx, isMemoWritten, isTestResultRemoved}:Args) {
    const {openNewWindowWithURL} = useNewWindow();
    const openTestResult = useOpenTestResult();
    const {finishCounseling} = useHistoryFunctions();

    // 상담 상태에 따른 노출 버튼 항목 저장
    const essentialFunctions = ():CounselorHistoryFunctions[] => {
        let functions: CounselorHistoryFunctions[] = [];

        switch (consultStatus) {
            case ConsultStatus.NOT_ALLOTTED:
                functions = [CounselorHistoryFunctions.TEST_RESULT, CounselorHistoryFunctions.PRE_QUESTION];
                break;
            case ConsultStatus.ALLOTTED:
                // 메모가 작성되었을때만 상담종료 활성화
                functions = [CounselorHistoryFunctions.TEST_RESULT, CounselorHistoryFunctions.MEMO,
                    ...(isMemoWritten ? [CounselorHistoryFunctions.CONSULTING_END] : [])
                ]
                break;
            case ConsultStatus.FINISHED:
                functions = [CounselorHistoryFunctions.TEST_RESULT, CounselorHistoryFunctions.REVIEW]
                break;
            case ConsultStatus.CANCELED:
                break;
            case ConsultStatus.REFUNDED:
                break;
            default:
                return [];
        }

        if (isTestResultRemoved) {
            functions = functions.filter(func => func !== CounselorHistoryFunctions.TEST_RESULT);
        }
        return functions;
    }

    const ButtonBlock = ({text, additionalClass, onClickEvent}
                             : { text: string, additionalClass: string, onClickEvent: () => void }) => (
        <button className={`${style.additionalButtonWrapper} ${additionalClass}`}
                onClick={onClickEvent}>
            {text}
        </button>
    );

    const handleTestResult = () => {
        if(!contentExecutedInfoIdx || !testType) return false;

        openTestResult(contentExecutedInfoIdx, testType)
    }

    const commonWindowUrl = `/detail/counselor/history/${counselingIdx}`
    const handleNewWindow = (type:string) =>{
        openNewWindowWithURL(`${commonWindowUrl}?type=${type}`, {
            width: 1000,
            height: 800,
        });
    }

    // 상담 종료 호출
    const handleConsultingEnd = () =>{
        if (window.confirm('정말로 상담을 종료하시겠습니까?') && counselingIdx) {
            finishCounseling(counselingIdx).then(() =>{
                window.location.reload()
            });
        }
    }

    const buttonResult = () => {

        // 전체 버튼 케이스
        const buttons = [
            { function: CounselorHistoryFunctions.TEST_RESULT, text: '검사결과',
                onClick: handleTestResult, additionalClass: '' },
            { function: CounselorHistoryFunctions.PRE_QUESTION, text: '사전문의',
                onClick: () => handleNewWindow('pre-question'), additionalClass: '' },
            { function: CounselorHistoryFunctions.MEMO, text: '상담메모',
                onClick: () => handleNewWindow('memo'), additionalClass: isMemoWritten ? style.blue : '' },
            { function: CounselorHistoryFunctions.REVIEW, text: '상담리뷰',
                onClick: () => handleNewWindow('review'), additionalClass: style.blue },
            { function: CounselorHistoryFunctions.CONSULTING_END, text: '상담종료',
                onClick: handleConsultingEnd, additionalClass: style.blue },
        ];

        // 버튼 케이스 중 필요한 요소들만 추출하여 리턴
        return essentialFunctions().length === 0 ? ('-')
            : buttons
                .filter(button => essentialFunctions().includes(button.function))
                .map((button, index) => (
                    <ButtonBlock
                        key={index}
                        text={button.text}
                        additionalClass={button.additionalClass}
                        onClickEvent={button.onClick}
                    />
                ));
    };

    // 임시로 모든 버튼 보이게 처리함
    return (
        <>
            <div className={style.buttonsWrapper}>
                {buttonResult()}
            </div>
        </>
    )

}