import {TableColumnData} from "../../../../types/common";
import {useTranslation} from "react-i18next";
import {ConsultantDTO, CounselorPosition} from "../../../../types/counselor";
import {useCounselorConverter} from "./useCounselorConverter";
import {AdminStatus} from "../../../../types/admin";
import styled from "styled-components/macro";
import {useNavigate} from "react-router-dom";
import {CounselorStatusConverter} from "./CounselorStatusConverter";

const UnderlineTextStyle = styled.div`
    text-decoration-line: underline;
    cursor: pointer;
`;

interface Args {
    selectedId: number;
    setSelectedId: (_: number) => void;
}

export function useCounselorManagerColumns({selectedId, setSelectedId}: Args) {
    const {t} = useTranslation(["counselorInfo"]);
    const {getInfoSearchPositionConverter} = useCounselorConverter();
    const navigate = useNavigate();

    const columns: TableColumnData<ConsultantDTO>[] = [
        {
            accessor: "idx",
            header: '',
            sortable: false,
            getNode: (it) =>
                <>
                    <input
                        style={{display: it.status === AdminStatus.DEFAULT ? "" : "none"}}
                        onChange={() => setSelectedId(it.idx)}
                        checked={it.idx === selectedId}
                        type={"radio"}
                    />
                </>,
        },
        {
            accessor: "createAt",
            header: t("manager.createAt"),
            sortable: false,
            // getNode: (it) => <AnchorComponent onClickFunc={() => setSelectedProfileIdx(it.idx)} content={it.name} />,
            getNode: (it) => <>{it.createAt}</>,
        },
        {
            accessor: "consultantInfoDTO",
            header: t("manager.groupName"),
            sortable: false,
            // getNode: (it) => <AnchorComponent onClickFunc={() => setSelectedProfileIdx(it.idx)} content={it.name} />,
            getNode: (it) => <>{it.consultantInfoDTO.counselingAffiliationName}</>,
        },
        {
            accessor: "consultantRank",
            header: t("manager.position"),
            sortable: false,
            getNode: (it) => <>{getInfoSearchPositionConverter(it.consultantRank as CounselorPosition)}</>,
        },
        {
            accessor: "name",
            header: t("manager.name"),
            sortable: false,
            getNode: (it) =>
                <UnderlineTextStyle onClick={() => navigate(`/detail/counselor/${it.idx}`)}>
                    {it.name}
                </UnderlineTextStyle>,
        },
        {
            accessor: "roleIdx",
            header: t("manager.id"),
            sortable: false,
            getNode: (it) => <>{it.email}</>,
        },
        {
            accessor: "phoneNumber",
            header: t("manager.cellPhone"),
            sortable: false,
            getNode: (it) => <>{(it.codePhone) + it.phoneNumber}</>,
        },
        {
            accessor: "status",
            header: t("manager.authStatus"),
            sortable: false,
            getNode: (it) => <CounselorStatusConverter status={it.status as AdminStatus}/>,
        },
    ];

    return {
        columns,
    };
}