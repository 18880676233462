import {useAppDispatch, useListFilterValues, useTimeConverter} from "../../../hooks/hooks";
import {useCallback, useEffect, useMemo, useState} from "react";
import {ExternalVoucherListDTO, VoucherListQueryType, VoucherListSearchCondition} from "../../../types/externalVoucher";
import {getExternalVoucherDetailAsync, getExternalVoucherListAsync} from "../../../store/externalVoucherSlice";
import {
    DEFAULT_PAGE,
    DEFAULT_SIZE,
    DEFAULT_SORT,
    ordersToSortArr,
    PageResponse,
    PageSearchCondition
} from "../../../types/page";
import {dateToString} from "../../../util/dateUtil";
import {useSortParam} from "../../../hooks/list/useSortParam";
import {NormalUser} from "../../../types/user";
import {getNormalUserListAsync} from "../../../store/userSlice";
import {SearchByKeyword} from "../../../types/common";

export function useExternalVoucherGetter() {
    const dispatch = useAppDispatch();
    const [voucherList, setVoucherList] = useState<PageResponse<ExternalVoucherListDTO>>()
    const [voucherDetail, setVoucherDetail] = useState<ExternalVoucherListDTO>()
    const [normalUserList, setNormalUserList] = useState<PageResponse<NormalUser>>()
    const [totalVoucherCount, setTotalVoucherCount] = useState(0)

    // 이용권 목록 조회 API
    const getVoucherList = useCallback(
        async (condition: VoucherListSearchCondition, isFirstLoad?: boolean) => {
            try {
                const result: PageResponse<ExternalVoucherListDTO> = await dispatch(
                    getExternalVoucherListAsync(condition)
                ).unwrap();
                setVoucherList(result)
                setTotalVoucherCount(result.totalElements);

                if (isFirstLoad) {
                    setTotalVoucherCount(result.totalElements);
                } else {
                    setVoucherList(result);
                }
            } catch
                (e) {
                alert('일시적인 문제가 발생했습니다.\n다시 시도해주세요.')
            }
        }, [dispatch]);

    // 이용권 상세 조회 API
    const getVoucherDetail = useCallback(
        async (idx: number) => {
            try {
                const result: ExternalVoucherListDTO = await dispatch(
                    getExternalVoucherDetailAsync(idx)
                ).unwrap();
                setVoucherDetail(result)
            } catch
                (e) {
                alert('일시적인 문제가 발생했습니다.\n다시 시도해주세요.')
            }
        }, [dispatch]);

    // 이용권 등록에 필요한 유저 정보 조회
    const getNormalUserList = (query?: string) => {
        const dto: SearchByKeyword = query ? { query } : {};
        return () => {
            dispatch(getNormalUserListAsync(dto))
                .unwrap()
                .then((result: PageResponse<NormalUser>) => {
                    setNormalUserList(result);
                })
                .catch((error) => {
                    console.error('Error fetching user list:', error);
                    alert('일시적인 문제가 발생했습니다.\n다시 시도해주세요.');
                });
        };
    };

    const {toSortArray} = useSortParam();
    const {timeConverter} = useTimeConverter();
    const {filterValues, searchList, listQueryType} = useListFilterValues() ?? {};

    const {
        searchWordTypeFilterValue,
        searchWordFilterValue,
        createdFromFilterValue,
        createdToFilterValue,
        channelFilterValue,
        statusFilterValue,
    } = filterValues || {};

    const searchExternalVoucher = (pageSearchCondition: PageSearchCondition) => {
        const param: VoucherListQueryType = {
            searchWordType: searchWordTypeFilterValue.value,
            searchWord: searchWordFilterValue.value ? searchWordFilterValue.value.trim() : undefined,
            createdFrom: createdFromFilterValue.value ? dateToString(createdFromFilterValue.value) : undefined,
            createdTo: createdToFilterValue.value ? dateToString(createdToFilterValue.value) : undefined,
            channel: channelFilterValue.value ? channelFilterValue.value : undefined,
            status: statusFilterValue.value ? statusFilterValue.value : undefined,
        };

        return () => {
            searchList(pageSearchCondition, param)();
        }
    };

    const refreshList = () => {
        getVoucherList({}).then();
    };

    const getVoucherListWithParam = useCallback(
        (isFirstLoad?: boolean) => {
            if (isFirstLoad) {
                getVoucherList({}, true).then();
            }

            const voucherListQueryType = listQueryType as VoucherListQueryType;
            getVoucherList({
                pageCondition: {
                    page: voucherListQueryType.page ? Number(voucherListQueryType.page) : DEFAULT_PAGE,
                    size: voucherListQueryType.size ? Number(voucherListQueryType.size) : DEFAULT_SIZE,
                    sort: voucherListQueryType.sort ? toSortArray(voucherListQueryType.sort) : DEFAULT_SORT,
                },

                query: searchWordFilterValue.value ? searchWordFilterValue.value.trim() : undefined,
                query_type: searchWordTypeFilterValue.value,
                created_from: voucherListQueryType.createdFrom ?
                    timeConverter.convertToFilterStartOfDay(voucherListQueryType.createdFrom)
                    : undefined,
                created_to: voucherListQueryType.createdTo ?
                    timeConverter.convertToFilterStartOfDay(voucherListQueryType.createdTo)
                    : undefined,
                external_voucher_status: statusFilterValue.value ? statusFilterValue.value : undefined,
                external_channel: channelFilterValue.value ? channelFilterValue.value : undefined,
            }).then();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [listQueryType]);

    useEffect(() => {
        if (listQueryType) {
            getVoucherListWithParam();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getVoucherListWithParam]);

    const pageCondition = useMemo(
        () => ({
            page: voucherList?.number ?? DEFAULT_PAGE,
            size: voucherList?.size ?? DEFAULT_SIZE,
            sort: ordersToSortArr(voucherList?.orders),
        }),
        [voucherList]
    );

    return {
        getVoucherList, voucherList, totalVoucherCount, searchExternalVoucher,
        pageCondition, refreshList,
        getVoucherDetail, voucherDetail,
        getNormalUserList, normalUserList
    }
}