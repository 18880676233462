import {useCallback, useMemo} from "react";
import {ConsultStatus, CounselorHistoryFilterWordType} from "../../../../types/counselor";
import {TestType} from "../../../../types/common";

export function useHistoryConverter() {

    // 검색어 enum 정보 변경 함수
    const historySearchWordConverter = useMemo(
        () => ({
            [CounselorHistoryFilterWordType.APPLICANT_NAME.toString()]: "신청자 이름",
            [CounselorHistoryFilterWordType.TESTER_NAME.toString()]: "피검사자 이름",
            [CounselorHistoryFilterWordType.PHONE_NUMBER.toString()]: "휴대전화",
        }),
        []
    );
    const getHistorySearchWordConverter = useCallback(
        (status: CounselorHistoryFilterWordType) => historySearchWordConverter[status.toString()],
        [historySearchWordConverter]
    );

    // 검사 선택요소
    const historyTestTypeWordConverter = useMemo(
        () => ({
            [TestType.KPASS.toString()]: "K-PASS",
            [TestType.DCAS.toString()]: "D-CAS",
        }),
        []
    );
    const getHistoryTestTypeWordConverter = useCallback(
        (status: TestType) => historyTestTypeWordConverter[status.toString()],
        [historyTestTypeWordConverter]
    )

    // 상태 선택요소
    const historyStatusWordConverter = useMemo(
        () => ({
            [ConsultStatus.NOT_ALLOTTED.toString()]: "상담사 미배정",
            [ConsultStatus.ALLOTTED.toString()]: "상담사 배정완료",
            [ConsultStatus.FINISHED.toString()]: "상담종료",
            [ConsultStatus.CANCELED.toString()]: "취소처리",
            [ConsultStatus.REFUNDED.toString()]: "환불처리",
        }),
        []
    );
    const getHistoryStatusWordConverter = useCallback(
        (status: ConsultStatus) => historyStatusWordConverter[status.toString()],
        //eslint-disable-next-line
        [historyTestTypeWordConverter]
    )


    return{
        getHistorySearchWordConverter,
        getHistoryTestTypeWordConverter,
        getHistoryStatusWordConverter
    }
}