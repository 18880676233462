import {AdminRoleCategory} from "../../../../types/adminRole";
import {
    adminCounselorAllocationAsync,
    consultantManagerCounselorAllocationAsync,
    counselorAllocationAsync
} from "../../../../store/counselorSlice";
import {ConsultantAllocationPostDTO} from "../../../../types/counselor";
import {useAppDispatch} from "../../../../hooks/hooks";

export function useCounselorAllocator() {
    /**
     * 권한별 상담사 배정 요청 API
     */
    const dispatch = useAppDispatch();

    // 권한별 API 요청 처리 함수
    const getCounselorAllocationDispatcher = (role: string) => {
        switch (role) {
            case AdminRoleCategory.CONSULTANT_MANAGER:
                return consultantManagerCounselorAllocationAsync;
            case AdminRoleCategory.CONSULTANT:
                return counselorAllocationAsync;
            default:
                return adminCounselorAllocationAsync;
        }
    };

    const allocateCounselor = async (param: ConsultantAllocationPostDTO) => {
        try {
            const role = localStorage.getItem('role');

            if (!role) {
                console.warn("권한이 없습니다. 다시 시도해주세요.");
                return;
            }

            const dispatchFunction = getCounselorAllocationDispatcher(role);
            await dispatch(dispatchFunction(param)).unwrap().then();
        } catch (error) {
            console.error("Error fetching counselor info list:", error);
        }
    };

    return {
        allocateCounselor
    }
}