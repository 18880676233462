import style from "../../component/memo/memo.module.scss";
import React from "react";
import {Panel} from "../../../layout/content/detail/Panel";

interface Args {
    title: string;
    starCount: number;
    review: string;
}

export function ReviewWritePanel({title, starCount, review}: Args) {

    const isReviewWrite = review !== null;
    return (
        <>
            <div className={style.panelWrap}>
                <Panel title={title} panelClassName={style.reviewInputField}>

                    {isReviewWrite
                        ? (
                            <>
                                <div className={style.reviewStarArea}>
                                    {Array.from({length: starCount}, (_, index) => (
                                        <div key={index} className={style.star}/>
                                    ))}
                                </div>
                                <div className={style.reviewContentArea}>
                                    <span className={style.text}>{review}</span>
                                </div>
                            </>
                        )
                        : (
                            <span>
                                아직 상담리뷰가 작성되지 않았습니다.
                            </span>
                        )}


                </Panel>
            </div>
        </>
    )
}