import {useAppDispatch} from "../../../../hooks/hooks";
import {useCallback} from "react";
import {AffiliationSettlementDTO, MonthlySettlementDTO, UpdateSettlementStatusDTO} from "../../../../types/counselor";
import {
    adminMonthlySettlementsAsync,
    adminMonthlyStaticsAsync, consultantManagerMonthlySettlementsAsync,
    consultantManagerMonthlyStaticsAsync, consultantMonthlySettlementsAsync,
    consultantMonthlyStaticsAsync,
    updateSettlementsStatusAsync
} from "../../../../store/counselorSlice";
import {AdminRoleCategory} from "../../../../types/adminRole";

export function useCounselorSettlementGetter() {
    const dispatch = useAppDispatch();

    // 월별 상담 내역 통계 조회 API
    const getAdminMonthlyStatics = useCallback(
        async (year: number, month: number) => {
            const role = localStorage.getItem('role');

            if (!role) {
                console.warn("권한이 없습니다. 다시 시도해주세요.");
                return;
            }

            const api = getMonthlyStaticsDispatcher(role);
            const result: MonthlySettlementDTO = await dispatch(api({year, month})).unwrap();
            return result;
        },
        //eslint-disable-next-line
        []
    );

    // 월별 상담 정산 내역 조회 API
    const getAdminMonthlySettlement = useCallback(
        async (year: number, month: number) => {
            const role = localStorage.getItem('role');

            if (!role) {
                console.warn("권한이 없습니다. 다시 시도해주세요.");
                return[];
            }

            const api = getMonthlySettlementDispatcher(role)
            const result: AffiliationSettlementDTO[] = await dispatch(api({
                year,
                month
            })).unwrap();
            return result;
        },
        //eslint-disable-next-line
        []
    );

    // 정산상태 업데이트 함수
    const updateSettlementsStatus = useCallback(
        async (body: UpdateSettlementStatusDTO) => {
            return await dispatch(updateSettlementsStatusAsync(body)).unwrap();
        },
        //eslint-disable-next-line
        []
    );

    // 권한별 월별 상담내역 API 요청 처리 함수
    const getMonthlyStaticsDispatcher = (role: string) => {
        switch (role) {
            case AdminRoleCategory.CONSULTANT_MANAGER:
                return consultantManagerMonthlyStaticsAsync;
            case AdminRoleCategory.CONSULTANT:
                return consultantMonthlyStaticsAsync;
            default:
                return adminMonthlyStaticsAsync;
        }
    };

    // 권한별 월별 정산 상세 내역 통계 API 처리
    const getMonthlySettlementDispatcher = (role: string) => {
        switch (role) {
            case AdminRoleCategory.CONSULTANT_MANAGER:
                return consultantManagerMonthlySettlementsAsync;
            case AdminRoleCategory.CONSULTANT:
                return consultantMonthlySettlementsAsync;
            default:
                return adminMonthlySettlementsAsync;
        }
    };

    return {
        getAdminMonthlyStatics,
        getAdminMonthlySettlement,
        updateSettlementsStatus,

    }
}