import {DetailSubMenus, DetailTab} from "../../../layout/content/detail/DetailSubMenus";
import {useCallback, useState} from "react";
import {CounselorMenus} from "../../../types/counselor";
import {useTranslation} from "react-i18next";
import {PanelWrapper} from "../../../layout/content/detail/PanelWrapper";
import {GroupInfoDetail} from "./GroupInfoDetail";

export function CounselorGroupDetail() {
    /**
     * 상담 소속 개별창
     * 상세
     */
    // const { type } = queryString.parse(window.location.search);
    const [selectedTab, ] = useState<CounselorMenus>(CounselorMenus.COUNSELOR_GROUP_INFO);
    const {t} = useTranslation("counselorInfo");

    const onClickTabMenu = (type: CounselorMenus) => {
        alert('기능 개발중입니다.')
        // navigate(`/detail/member/user/${id}?${queryString.stringify({ type })}`);
    };

    const getContentDetail = useCallback((
        tab: CounselorMenus
    ) =>{
        switch (tab) {
            case CounselorMenus.COUNSELOR_GROUP_INFO:
                return <GroupInfoDetail />
        }
    },[])

    const tabMenus: DetailTab<CounselorMenus>[] = [
        {
            tabValue: CounselorMenus.COUNSELOR_GROUP_INFO,
            tabName: t('menus.groupInfo'),
        },
    ];
    return(
        <>
            <DetailSubMenus
                tabs={tabMenus}
                onClickTab={onClickTabMenu}
                selectedTab={selectedTab}
            />
            <PanelWrapper>{getContentDetail(selectedTab)}</PanelWrapper>
        </>
    )
}