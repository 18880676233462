import {Gender, ListObject, TestResultFormType} from "./common";
import {Address, UserStatus} from "./user";
import {SearchCondition} from "./page";
import {VoucherType} from "./voucher";

interface AdminUserProfileTesterOrganSummary {
  idx: number;
  organName: string;
  organManagerIdx: number;
}

interface AdminUserProfileTesterListObj extends ListObject {
  name: string;
  createAt: string;
  age: number;
  gender: Gender;
  state: string;
  userCode: string;
  organ: AdminUserProfileTesterOrganSummary[];
  organVoucherCnt: number;
  testCnt: number;
  isOwnerProfile: boolean;
  consultingCnt: number,
}

interface TesterDetailOrgan {
  idx: number;
  isDeleted: boolean;
  organName: string;
  organManagerIdx: number;
}

interface TesterDetailOrganVoucher {
  idx: number;
  organName: string;
}

interface AdminUserProfileTesterDetail {
  idx: number;
  createAt: string;
  name: string;
  birthday: string;
  age: number;
  gender: Gender;
  state: string;
  userCode: string;
  isOwnerProfile: boolean;
  organ: TesterDetailOrgan[];
  organVoucher: TesterDetailOrganVoucher | null;
  address: Address;
  isPassedPretest: boolean;
  pretestPassAt: string;
  organSignInCode: string;
  nationality: string;
}

interface AdminTestHistoryListObjByProfile extends ListObject {
  idx: number;
  endedAt: string;
  name: string;
  userCode: string;
  ageYear: number;
  ageMonth: number;
  gender: Gender;
  region: string;
  testRound: number;
  isDeleted: boolean;
  unitVoucherType: VoucherType;
  kpassResultForm: TestResultFormType;
  dcasResultForm: TestResultFormType;
}

interface UserProfileDetail {
  idx: number;
  userIdx: number;
  currentTestType: string;
  name: string;
  isDeleted: boolean;
  isOwnerProfile: boolean;
  address: Address;
  birthday: string;
  createAt: string;
  userCode: string;
  gender: Gender;
  testCnt: number;
  testHistoryCheck: boolean;
  voucherCheck: boolean;
  isPassedPretest: boolean;
  needPeriodCheck: boolean;
}

export enum ProfileQueryType {
  NAME = "NAME",
  USER_CODE = "USER_CODE",
  PHONE_NUMBER = "PHONE_NUMBER",
  REGION = "REGION",
}

export interface ProfileSearchCondition extends SearchCondition {
  q?: string;
  queryType?: ProfileQueryType;
  ageFrom?: number;
  ageTo?: number;
  createdAtFrom?: Date;
  createdAtTo?: Date;
  country?: string;
  nationality?: string;
  status?: UserStatus;
  testCntFrom?: number;
  testCntTo?: number;
  hasOrganVoucher?: boolean;
}

export enum ProfileParentType {
  USER = "USER",
  ORGAN = "ORGAN",
}

export interface AdminUserProfileListDTO {
  idx: number;
  createAt: string;
  name: string;
  userCode: string;
  codePhone: string;
  phoneNumber: string;
  age: number;
  country: string;
  nationality: string;
  region: string;
  testCount: number;
  parentStatus: UserStatus;
  organCount: number;
  productVoucherIdx: number;
  reasonForBlocking: string;
  blockAt: string;
  reasonForLeave: string;
  leaveAt: string;
  parentType?: ProfileParentType;
  userIdx?: number;
  organSignInCode: string;
  isOwnerProfile: boolean;
  birthday: string;
}

export namespace AdminUserProfileListDTO {
  export const toTesterListVO = (dto: AdminUserProfileListDTO): TesterListVO => {
    const copiedDto = { ...dto };
    delete copiedDto.parentType;
    return {
      ...copiedDto,
      isCreatedMember: dto.parentType === ProfileParentType.ORGAN,
    };
  };
}

export interface TesterListVO extends ListObject {
  createAt: string;
  name: string;
  userCode: string;
  codePhone: string;
  phoneNumber: string;
  age: number;
  country: string;
  nationality: string;
  region: string;
  testCount: number;
  parentStatus: UserStatus;
  organCount: number;
  productVoucherIdx: number;
  reasonForBlocking: string;
  blockAt: string;
  reasonForLeave: string;
  leaveAt: string;
  isCreatedMember: boolean;
  userIdx?: number;
  organSignInCode: string;
  isOwnerProfile: boolean;
  birthday: string;
}

export type {
  AdminUserProfileTesterListObj,
  TesterDetailOrgan,
  TesterDetailOrganVoucher,
  AdminUserProfileTesterDetail,
  AdminTestHistoryListObjByProfile,
  UserProfileDetail,
};
