import React, {useEffect, useMemo} from "react";
import {AdminUserProfileTesterDetail} from "../../../../../types/userProfile";
import {useTranslation} from "react-i18next";
import {DescriptionValue} from "../../../../../layout/content/detail/DescriptionList";
import {dateStringToDottedString} from "../../../../../util/dateUtil";
import {CopiableText} from "../../../../../components/CopiableText";
import {AdminMenuCode} from "../../../../../types/adminRole";
import userDetailStyle from "../../memberUserDetail.module.scss";
import {RoleChecker} from "../../../../role/RoleChecker";
import {AnchorComponent} from "../../../../../layout/content/AnchorComponent";
import {toAddressLine1} from "../../../../../types/user";
import {CategoryTab} from "../../../../../layout/content/detail/DetailSubMenuCategories";
import {useGenderType} from "../../../../../types/hooks/useGenderType";
import {useCountry, useNewWindow, useTimeConverter} from "../../../../../hooks/hooks";
import {useTesterOrganVoucherStatusGetter} from "./useTesterOrganVoucherStatusGetter";
import {MenuCategory} from "../../../../../types/test";
import styled from "styled-components";
import {useTesterCounselorGetter} from "./useTesterCounselorGetter";
import {useTesterHistoryGetter} from "./useTesterHistoryGetter";

const OrganVoucherWrapperStyle = styled.div`
    display: flex;
    gap: 10px;
`;

interface Args {
    profileIdx: number
    profile?: AdminUserProfileTesterDetail;
}

export function useTesterDetailFunctions({profileIdx, profile}: Args) {
    const {toStringFromGender} = useGenderType();
    const {openNewWindowWithURL} = useNewWindow();
    const {getCountryNameByCodeISOAlpha2} = useCountry();
    const {timeConverter} = useTimeConverter();
    const {userOrganVoucherNewStatusInfo} = useTesterOrganVoucherStatusGetter({profileIdx});
    const {totalCounseling, getCounselorVoucherHistory,} = useTesterCounselorGetter();
    const {getTestHistoryList, totalTesting} = useTesterHistoryGetter();
    const {t} = useTranslation(["user", "common"]);

    useEffect(() => {
        if (profile && profileIdx !== 0) {
            const counselorVoucherHistory =
                getCounselorVoucherHistory({profileIdx: profileIdx});
            const testingVoucherHistory =
                getTestHistoryList({profileIdx: profileIdx});

            counselorVoucherHistory();
            testingVoucherHistory();
        }
        //eslint-disable-next-line
    }, [profile, profileIdx]);

    const descriptionValue: DescriptionValue[][] = useMemo(() => {
        if (profile) {
            return [
                [
                    {key: t("detail.profile.detail.name"), value: profile.name},
                    {
                        key: t("detail.profile.detail.createdAt"),
                        value: <>{timeConverter.convertToLocalTime(profile.createAt)}</>,
                    },
                ],
                [
                    {
                        key: t("detail.profile.detail.age.label"),
                        value: (
                            <span>
                                {dateStringToDottedString(profile.birthday)}
                    {profile.age ? <> ({t("detail.profile.detail.age.unit", {age: profile.age})})</> : <></>}
            </span>
        ),
        },
            {
                key: t("detail.profile.detail.userCode"),
                    value: <>{profile.userCode ?
                    <CopiableText text={profile.userCode} isBlue={false}/> : <>-</>}</>,
                menuCode: AdminMenuCode.USER_DETAIL_TESTER_USER_CODE_READ,
            },
        ],
            [
                {key: t("detail.profile.detail.gender"), value: toStringFromGender(profile.gender)},
                {
                    key: t("nationality.label", {ns: "common"}),
                    value: <>{getCountryNameByCodeISOAlpha2(profile.nationality)}</>,
        },
        ],
            [
                {
                    key: t("detail.profile.detail.organ"),
                    value: (
                        <ul className={userDetailStyle.listElement}>
                            {!profile.organ || profile.organ.length === 0
                        ? "-"
                        : profile.organ.map((o) => (
                            <li key={`organ-idx-${o.idx}`}>
                <RoleChecker
                    menuCode={AdminMenuCode.USER_DETAIL_ORGAN_READ}
                alternativeChildren={<>{o.organName}</>}
                >
                <AnchorComponent
                    onClickFunc={(e) => {
                e.preventDefault();
                openNewWindowWithURL(
                    // eslint-disable-next-line max-len
                    `/detail/member/organ-manager/${o.organManagerIdx}?tab=ORGAN&organ=${o.idx}`,
                    {
                        width: 1000,
                        height: 800,
                    }
                );
            }}
            content={o.organName}
            />
            </RoleChecker>
            </li>
        ))}
            </ul>
        ),
        },
            {
                key: t("detail.profile.detail.organVoucher.label"),
                    value: profile.organVoucher ? (
                <OrganVoucherWrapperStyle>
                    {t("voucherUnit", {count: 1})} ({profile.organVoucher.organName})
            <CopiableText text={profile.organSignInCode} isBlue={true}/>
            </OrganVoucherWrapperStyle>
            ) : (
                <>
                    {profile.organSignInCode ? (
                            <OrganVoucherWrapperStyle>
                                {t("voucherUnit", {count: 0})} (-) <CopiableText text={profile.organSignInCode}
                isBlue={true}/>
            </OrganVoucherWrapperStyle>
            ) : (
                <>-</>
            )}
                </>
            ),
            },
        ],
            [
                {
                    key: t("detail.profile.detail.country"),
                    value: getCountryNameByCodeISOAlpha2(profile?.address?.country) ?? "",
                },
                {key: t("detail.profile.detail.region"), value: profile.state},
            ],
                [
                    {
                        key: t("detail.profile.detail.address"),
                        value: profile.address ? toAddressLine1(profile.address) : t("noInputData", {ns: "common"}),
                    },
                ],
                [
                    {
                        key: t("detail.profile.detail.pretestPassedOrNot.label"),
                        value: (
                            <>
                                {profile.isPassedPretest === null
                                        ? `-`
                                        : profile.isPassedPretest
                                            ? `${t("detail.profile.detail.pretestPassedOrNot.passed")}
                                         (${timeConverter.convertToLocalTime(profile.pretestPassAt)})`
                                            : t("detail.profile.detail.pretestPassedOrNot.notPassed")}
                            </>
                        ),
                    },
                ],
        ];
        }

            return [];
        }, [getCountryNameByCodeISOAlpha2, t, timeConverter, toStringFromGender, profile, openNewWindowWithURL]);

        const categoryMenus: CategoryTab<MenuCategory>[] = useMemo(
            () => [
                {
                    tabName: t("detail.profile.detail.testHistory.label"),
                    tabRightValue: t("detail.profile.detail.testHistory.unit", {
                        count: totalTesting ?? 0
                    }),
                    tabValue: MenuCategory.TEST_HISTORY,
                },
                {
                    tabName: t("detail.profile.detail.organVoucher.label"),
                    tabRightValue: t("voucherNoUnit", {
                        count: (
                            (userOrganVoucherNewStatusInfo?.voucherDTOs[0]?.availableOrganVoucherCount ?? 0) +
                            (userOrganVoucherNewStatusInfo?.voucherDTOs[1]?.availableOrganVoucherCount ?? 0)
                        )
                    }),
                    tabValue: MenuCategory.ORGAN_VOUCHER,
                    menuCode: AdminMenuCode.USER_DETAIL_TESTER_ORGAN_VOUCHER,
                },
                {
                    tabName: t("detail.profile.detail.counselorVoucher.label"),
                    tabRightValue: t("detail.profile.detail.counselorVoucher.unit", {count: totalCounseling ?? 0}),
                    tabValue: MenuCategory.COUNSELOR_VOUCHER,
                },
            ],
            //eslint-disable-next-line
            [t, totalTesting,totalCounseling, userOrganVoucherNewStatusInfo]
        );

        return {
            categoryMenus, descriptionValue
        }
    }