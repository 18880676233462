import {useCallback, useMemo, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../../../../hooks/hooks";
import {VoucherHistorySearchCondition, VoucherStatusInfo} from "../../../../../types/voucher";
import {
    consultingVoucherNewStatus,
    getCounselorVoucherHistoryAsync,
    getCounselorVoucherStatusAsync,
    setConsultingVoucherStatusProfileIdx
} from "../../../../../store/voucherSlice";
import {ordersToSortArr, PageResponse, PageSearchCondition} from "../../../../../types/page";
import {useUtil} from "../../../../../util/hooks/useUtil";
import {callAsync} from "../../../../../util/sliceUtil";
import {CounselorCouponVoucherListVO} from "../../../../../types/counselor";

const LIST_SIZE = 5;
export function useCounselorVoucherGetter() {
    const counselorVoucherStatus = useAppSelector(consultingVoucherNewStatus)
    const [counselorVoucherHistory, setCounselorVoucherHistory] = useState<PageResponse<CounselorCouponVoucherListVO>>();
    const dispatch = useAppDispatch();

    const {defaultErrorMessage} = useUtil();

    const pageCondition = useMemo(
        () => ({
            page: counselorVoucherHistory?.number ?? 0,
            size: LIST_SIZE,
            sort: ordersToSortArr(counselorVoucherHistory?.orders),
        }),
        [counselorVoucherHistory]
    );

    const getCounselorVoucherStatus = useCallback(
        async (param: number) => {
            try {
                const result: VoucherStatusInfo =
                    await dispatch(getCounselorVoucherStatusAsync(param)).unwrap();
                dispatch(setConsultingVoucherStatusProfileIdx(result))
                console.log(result)
            } catch (e) {
                console.error(e);
                alert('일시적인 문제가 발생했습니다. \n다시 시도해주세요.');
            }
        },
        //eslint-disable-next-line
        [dispatch]
    );

    const getCounselorVoucherHistory = (
        {page, sort, userIdx, usable}:
            PageSearchCondition & { userIdx: number; usable?: boolean }) => {
        const param: VoucherHistorySearchCondition = {
            pageCondition: {
                page: page ?? 0,
                size: LIST_SIZE,
                sort: sort ?? pageCondition.sort,
            },
            userIdx: userIdx,
            usable: usable,
        };

        return () => {
            callAsync<PageResponse<CounselorCouponVoucherListVO>>(
                dispatch(getCounselorVoucherHistoryAsync(param)).unwrap(),
                (result: PageResponse<CounselorCouponVoucherListVO>) => {
                    setCounselorVoucherHistory(result);
                },
                () => alert(defaultErrorMessage)
            ).then();
        };
    }

    return {
        getCounselorVoucherStatus, counselorVoucherStatus,
        getCounselorVoucherHistory, counselorVoucherHistory
    }

}