import style from "../../style/test_result/guide/guideContent.module.scss";
import {Trans, useTranslation} from "react-i18next";
import React from "react";
import {useGuidePersonalTitleFunctions} from "./hooks/useGuidePersonalTitleFunctions";
import {PersonalTitle} from "./PersonalTitle";

interface Args {
    currentResult: string;
    rightImg: string;
    testerName: string;
}

export function GuidePersonalTitle({currentResult, rightImg, testerName}: Args) {
    const {t} = useTranslation("guideCommon");

    const{ content } = useGuidePersonalTitleFunctions({currentResult});

    return (
        <>
            <article className={style.characterSummary}>
                <section className={style.left}>
                    <div className={style.titleWrapper}>
                        <div className={style.whatType}>
                            <Trans t={t} i18nKey='cover.whatTypeOfPerson'
                                   components={{bold:<span className={style.typeBold} />}}
                            />
                            {/*<span>나는 <span className={style.typeBold}>어떤 유형</span>의 사람일까?</span>*/}
                            <img className={style.bubbleTail}
                                 src="https://images.feel-good.io/guide/speech-bubble-tail.svg"
                                 alt="bubble tail"/>
                        </div>
                        <h2 className={style.title}
                            // th:classappend="${languageClass}"
                        >
                            <PersonalTitle titleValue={content.title1And} jsonKey={content.jsonKey + '1'}/>
                            <PersonalTitle titleValue={content.title2And} jsonKey={content.jsonKey + '2'}/>
                            <PersonalTitle titleValue={content.title3And} jsonKey={content.jsonKey + '3'}/>
                            <PersonalTitle titleValue={content.title4And} jsonKey={content.jsonKey + '4'}/>
                        </h2>
                    </div>
                    <div className={style.paragraphWrapper}>
                        <p>
                            <Trans t={t} i18nKey={content.desc}
                                   values={{blank: '\u00A0', name: testerName}}
                                   components={{br: <br/>, bold:<span className={style.deepBold} />}}
                            />
                        </p>
                        <ul className={style.tags}>
                            {content.hashTags?.map((data, idx) => (
                                <li>{data}</li>
                            ))}
                        </ul>
                    </div>
                </section>

                <section className={style.rightImage}>
                    <img src={rightImg} alt="#{tGuide.left.type5.name}"/>
                </section>

            </article>
        </>
    )
}