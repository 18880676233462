import React, {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import style from "../style/registration.module.scss";
import {CounselorCheckboxField} from "./CounselorCheckboxField";
import {CounselorAffiliationDTO, CounselorCheckOptions, TaxSelectedValue} from "../../../types/counselor";
import {ValidStatus} from "../../../types/common";
import {AdminRoleCategory} from "../../../types/adminRole";
import {useCounselorGroup} from "./hooks/useCounselorGroup";
import {TaxSettings} from "./hooks/TaxSettings";
import {DistributionAmount} from "./hooks/DistributionAmount";


interface Args {
    counselorGroup: string;
    setCounselorGroup: (_: string) => void;
    consultantDistributionAmount: number;
    setConsultantDistributionAmount: (_: number) => void;
    affiliationDistributionAmount: number;
    setAffiliationDistributionAmount: (_: number) => void;
    isVatInclude: TaxSelectedValue;
    setIsVatInclude: (_: TaxSelectedValue) => void;
    isTaxDeducted: TaxSelectedValue;
    setIsTaxDeducted: (_: TaxSelectedValue) => void;
    affiliationIdxValidate?: ValidStatus;
    setAffiliationIdxValidate?: (_: ValidStatus) => void;
    consultantDistributionValidate?: ValidStatus;
    setConsultantDistributionValidate?: (_: ValidStatus) => void;
}

export const CounselorAffiliationSelector = React.memo((
    {
        counselorGroup,
        setCounselorGroup,
        consultantDistributionAmount,
        setConsultantDistributionAmount,
        affiliationDistributionAmount,
        setAffiliationDistributionAmount,
        isVatInclude,
        setIsVatInclude,
        isTaxDeducted,
        setIsTaxDeducted,
        affiliationIdxValidate,
        setAffiliationIdxValidate,
        consultantDistributionValidate,
        setConsultantDistributionValidate
    }: Args) => {
    const {t} = useTranslation("counselorGroup");
    const {getAffiliationInfo} = useCounselorGroup();
    const [affiliationInfo, setAffiliationInfo] = useState<CounselorAffiliationDTO | null>(null);
    const isAdmin = localStorage.getItem('role') === AdminRoleCategory.ADMIN_ROOT

    const isManager = useMemo(() =>
            localStorage.getItem('role') === AdminRoleCategory.CONSULTANT_MANAGER,
        []
    );

    // 관리자 정보 로드
    useEffect(() => {
        const loadManagerInfo = async () => {
            if (!isManager) return;

            const result = await getAffiliationInfo();
            if (!result) return;

            setAffiliationInfo(result);
            setCounselorGroup(String(result.idx));
        };

        loadManagerInfo();
    }, [isManager, getAffiliationInfo, setCounselorGroup]);

    // 소속 정보에 따른 금액 설정
    useEffect(() => {
        if (affiliationInfo) {
            setConsultantDistributionAmount(affiliationInfo.consultantDistributionAmount);
            setAffiliationDistributionAmount(affiliationInfo.affiliationDistributionAmount);
        }
    }, [affiliationInfo, setConsultantDistributionAmount, setAffiliationDistributionAmount]);

    return (
        <>
            {isAdmin && (
                <>
                    <span className={style.mainTitle}>{t('registration.group.title')}</span>

                    <CounselorCheckboxField
                        label={t("registration.counselor.counselorGroup")}
                        isRequired={true}
                        placeHolder={t('registration.nationalityPlaceHolder')}
                        checkboxOption={CounselorCheckOptions.COUNSELOR_GROUP}
                        option={counselorGroup}
                        setOption={setCounselorGroup}
                        validate={affiliationIdxValidate}
                        setValidate={setAffiliationIdxValidate}
                        setConsultantDistributionAmount={setConsultantDistributionAmount}
                        setAffiliationInfo={setAffiliationInfo}
                    >

                    </CounselorCheckboxField>

                    <DistributionAmount
                        affiliationInfo={affiliationInfo}
                        affiliationAmount={affiliationDistributionAmount}
                        consultantAmount={consultantDistributionAmount}
                        setConsultantAmount={setConsultantDistributionAmount}
                        consultantValidate={consultantDistributionValidate}
                        setConsultantValidate={setConsultantDistributionValidate}
                    />

                    <TaxSettings
                        affiliationInfo={affiliationInfo}
                        isVatInclude={isVatInclude}
                        setIsVatInclude={setIsVatInclude}
                        isTaxDeducted={isTaxDeducted}
                        setIsTaxDeducted={setIsTaxDeducted}
                    />
                </>
            )}
        </>
    );
});