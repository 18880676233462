import style from "../style/registration.module.scss";
import {Label104x46} from "../../../components/Label";
import React, {useCallback} from "react";
import {useTranslation} from "react-i18next";
import {ValidStatus} from "../../../types/common";
import {CounselorDuplicateType} from "../../../types/counselor";
import {useDuplicateChecker} from "./hooks/useDuplicateChecker";

interface Response {
    result: boolean;
}

interface Args {
    duplicateType: CounselorDuplicateType;
    minLength: number;
    maxLength: number;
    label: string;
    placeHolder: string;
    isLabelVisible: boolean;
    value: string;
    setValue: (_: string) => void;
    subValue?: string;
    validate: ValidStatus;
    setValidate: (_: ValidStatus) => void
}

export function CounselorDuplicateCheckButtonFiled(
    {
        duplicateType, minLength, maxLength, label, placeHolder, isLabelVisible,
        value, setValue, subValue, validate, setValidate
    }: Args) {

    const {t} = useTranslation("counselorGroup");

    const {checkEmail, checkCellPhone, checkAffiliation} = useDuplicateChecker();

    const apiChecker = (type: CounselorDuplicateType) => {
        if (type === CounselorDuplicateType.EMAIL) {
            return checkEmail(value);
        } else if (type === CounselorDuplicateType.GROUP_NAME) {
            return checkAffiliation(value);
        } else {
            if (subValue === '') {
                return false;
            }
            return subValue && checkCellPhone({codePhone: subValue, phoneNumber: value});
        }

    };
    const checkWhetherDuplicated = useCallback(
        async (e: React.MouseEvent) => {
            e.preventDefault();

            if (value === '') {
                setValidate(ValidStatus.UNWRITTEN)
                return false;
            }

            try {
                const result: Response = await apiChecker(duplicateType);
                console.log(result)
                result.result ? setValidate(ValidStatus.DUPLICATED) : setValidate(ValidStatus.VALID)
            } catch (error) {
                setValidate(ValidStatus.INPUT_LENGTH)
                console.error('Error occurred while checking email:', error);
            }

            if (!value) {
                return;
            }
        },
        //eslint-disable-next-line
        [checkEmail]
    );

    const validationMessage = () => {
        switch (validate) {
            case ValidStatus.UNCHECKED:
                return <div className={style.inValidateText}>중복확인 버튼을 눌러 주세요.</div>;
            case ValidStatus.INPUT_LENGTH:
                return <div className={style.inValidateText}>최소 {minLength}자리 이상으로 입력해 주세요.</div>;
            case ValidStatus.UNWRITTEN:
                return <div className={style.inValidateText}>최소 {minLength}자리 이상으로 입력해 주세요.</div>;
            case ValidStatus.DUPLICATED:
                return <div className={style.inValidateText}>중복된 {duplicateType} 입니다. 다름 {duplicateType}으로
                    시도해주세요.</div>;
            case ValidStatus.WRONG_TYPE:
                return <div className={style.inValidateText}>잘못된 형식의 이메일 주소입니다.</div>;
            case ValidStatus.VALID:
                return <div className={style.validateText}>사용 가능합니다.</div>;
            case ValidStatus.NONE:
                return <></>;
            default:
                return <></>;
        }
    };

    return (
        <>
            <div className={style.mainInputWrapper}>

                {isLabelVisible ? (
                        <Label104x46 text={label} isRequired={true}/>
                    )
                    : (
                        <div className={style.invisibleBox}/>
                    )}
                <div className={style.inputWrapper}>
                    <div className={style.inputArea}>
                        <input
                            className={`${style.input} ${style.inputText}`}
                            placeholder={placeHolder}
                            value={value}
                            onChange={(e) => {
                                setValue((e.target as HTMLInputElement).value)
                                setValidate(ValidStatus.NONE)
                            }}
                            minLength={minLength}
                            maxLength={maxLength}
                        />
                    </div>

                    {/*  유효성 검사 텍스트 영역  */}
                    {validationMessage()}
                </div>
                {/*  중복확인버튼  */}
                <div className={style.duplicateCheckButton}
                     onClick={checkWhetherDuplicated}>
                    {t('duplicateCheck')}
                </div>
            </div>
        </>
    );

}