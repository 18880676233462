import style from "../style/registration.module.scss";
import {Label104x46} from "../../../components/Label";
import React, {ReactNode, useMemo} from "react";
import banks from "../../../types/banks";
import {Selector} from "../../../layout/content/selector/Selector";
import {CounselorAffiliationDTO, CounselorCheckOptions} from "../../../types/counselor";
import {useCountry} from "../../../hooks/hooks";
import {useTranslation} from "react-i18next";
import {useOrganManagerType} from "../../../types/hooks/useOrganManagerType";
import {useCounselorGroup} from "./hooks/useCounselorGroup";
import {ValidStatus} from "../../../types/common";

interface Args {
    label: string;
    children: ReactNode;
    isRequired: boolean;
    placeHolder: string;
    additionalText?: string;
    checkboxOption: CounselorCheckOptions;
    option: string;
    setOption: (_: string) => void
    validate?: ValidStatus;
    setValidate?: (_: ValidStatus) => void
    setAffiliationInfo?: (_: CounselorAffiliationDTO | null) => void
    setConsultantDistributionAmount?: (_: number) => void;
}

export function CounselorCheckboxField(
    {
        children, label, isRequired, placeHolder, additionalText, checkboxOption,
        option, setOption, validate, setValidate, setAffiliationInfo, setConsultantDistributionAmount
    }: Args) {
    /**
     * 체크박스를 포함한 컴포넌트
     * 은행, 국가, 국적 필요
     */

    const {nationOptions} = useOrganManagerType();
    const {t: tCommon} = useTranslation("common");
    const {phoneNumberOptions} = useCountry();
    const {counselorGroupOptions, affiliationList} = useCounselorGroup();

    // 국가 선택 옵션 메모이제이션
    const phoneNumberOption = useMemo(
        () => [{value: "", label: tCommon("selectCountry")}, ...phoneNumberOptions],
        [phoneNumberOptions, tCommon]
    );

    // 케이스 별 데이터 할당
    const optionMap = useMemo(() => {
        switch (checkboxOption) {
            case CounselorCheckOptions.ACCOUNT:
                const bankOptions = banks.map(it => ({
                    label: it,
                    value: it,
                }));
                return [{value: '', label: '은행선택'}, ...bankOptions];
            case CounselorCheckOptions.COUNTRY:
                return nationOptions;
            case CounselorCheckOptions.NATIONALITY:
                return phoneNumberOption;
            case CounselorCheckOptions.COUNSELOR_GROUP:
                return counselorGroupOptions;
            default:
                return [{label: '', value: ''}];
        }
    }, [checkboxOption, nationOptions, phoneNumberOption, counselorGroupOptions]);

    // 선택한 소속이 있을경우 데이터 저장
    const allocateAffiliation = (idx: number) => {
        if(!setAffiliationInfo) return false;
        setAffiliationInfo(affiliationList.find(data => data.idx === idx) || null);
    };

    return (
        <>
            <div className={style.mainInputWrapper}>
                <Label104x46 text={label} isRequired={isRequired}/>
                <div className={style.inputWrapper}>
                    <div className={style.accountWrapper}>
                        <Selector
                            isPageSizeSelector={false}
                            options={optionMap}
                            selectedValue={option}
                            setSelectorOption={(it) => {
                                setOption(it.value);
                                setValidate?.(ValidStatus.NONE);
                                setConsultantDistributionAmount?.(0);
                                allocateAffiliation(Number(it.value));
                            }}
                            width={300}
                        />
                        <span className={style.descriptionText}>{additionalText}</span>
                    </div>
                    {validate === ValidStatus.UNWRITTEN && (
                        <div className={style.inValidateText}>상담소속을 선택해 주세요.</div>
                    )}
                </div>
            </div>
            {children}
        </>
    );
}