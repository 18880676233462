import {GuideResultPath, ResultType, TestResultReportDTO} from "../../../types/testResult";
import React, {useCallback} from "react";
import style from "../../style/test_result/guide/guideContent.module.scss";
import {useGuideFunctions} from "./hooks/useGuideFunctions";
import {GuideImproveWeakness} from "./GuideImproveWeakness";
import {GuidePersonalCharacterType} from "./GuidePersonalCharacterType";
import {GuideLearningStrategy} from "./GuideLearningStrategy";
import {GuideRecommendationJob} from "./GuideRecommendationJob";
import {FixedHeaderWithLine} from "../common/FixedHeaderWithLine";

interface Args{
    testResult:TestResultReportDTO
    guideStep: GuideResultPath
}
export function GuideContent({testResult, guideStep}:Args) {
    const {convertBrainType} = useGuideFunctions();

    const brainType = testResult.testResultComputedDataVO.brainType;
    // ex) 좌뇌우세형(순차처리우세형) -> 좌뇌우세형 + 순차처리우세형)으로 분리
    const convertedBrainType = convertBrainType(testResult.testResultComputedDataVO.brainType).split('(');

    const isWeaknessVisible = () =>{
        const simultaneous =  testResult.testResultComputedDataVO.a2OfSimultaneousScoreCode
        const successive =  testResult.testResultComputedDataVO.a2OfSuccessiveScoreCode
        const planning =  testResult.testResultComputedDataVO.a2OfPlanningScoreCode

        if (brainType === 'SUCCESSIVE') {
            return simultaneous !== 'NS' && planning !== 'NS'
        }else if (brainType === 'SIMULTANEOUS') {
            return successive !== 'NS' && planning !== 'NS';
        } else {
            return false;
        }
    }

    const CurrentGuideStepDetails = useCallback(() => {
        switch (guideStep) {
            case GuideResultPath.IMPROVE_WEAKNESS:
                return (
                    <>
                        {isWeaknessVisible() && (
                            <GuideImproveWeakness/>
                        )}
                    </>
                )
            case GuideResultPath.PERSONAL_CHARACTER_TYPE:
                return<GuidePersonalCharacterType testResult={testResult} />
            case GuideResultPath.LEARNING_STRATEGY:
                return <GuideLearningStrategy brainType={brainType} />
            case GuideResultPath.RECOMMENDATION_JOB:
                return <GuideRecommendationJob brainType={brainType} />

            default:
                return (
                    <>
                    </>
                );
        }
        //eslint-disable-next-line
    }, [guideStep]);

    // 상대적 약점 컴포넌트 미노출 상태에서는 null 반환
    if (!isWeaknessVisible() && guideStep === GuideResultPath.IMPROVE_WEAKNESS ) {
        return null;
    }

    return (
        <>
            <div className={style.page}>
                <FixedHeaderWithLine resultType={ResultType.GUIDE}
                                     brainType={convertedBrainType[0]}/>

                <CurrentGuideStepDetails />

                <hr className={style.contentFooter}></hr>
            </div>
        </>
    );
}