import style from "../../style/test_result/testResultInfo.module.scss";
import {TestHeaderWithLine} from "./TestHeaderWithLine";
import React from "react";
import {TestFooterWithLine} from "./TestFooterWithLine";
import {Trans, useTranslation} from "react-i18next";
import {ResultType} from "../../../types/testResult";

interface Args {
    testerName: string;
}

export function TestResultInfo({testerName}: Args) {
    /**
     * 검사결과 안내 페이지
     */
    const {t} = useTranslation("testResultInfo");

    // todo k-pass / d-cas 분리 처리, 한국어 외국어 케이스 구분 필요


    return (
        <>
            <div className={style.infoCover}>
                {/*  최상단 헤더부분  */}
                <TestHeaderWithLine resultType={ResultType.TEST_RESULT}/>

                <div className={style.content}>
                    <div className={style.titleWrapper}>
                        <div>
                            <img className={style.kidsImg}
                                 src="https://images.feel-good.io/logo/test_info_kids.png" alt="test_info_kids"
                            />
                        </div>
                        <div className={style.mainWrapper}>
                            {/*    한국어케이스        */}
                            <span className={style.text}>
                                {/*검사 결과 안내*/}
                                {t('testResultInfo.title')}

                            </span>

                        </div>
                        <div className={style.subText}>
                            {/*님의 검사 결과를 확인하기 전 아래 내용을 꼭 확인해 주세요!*/}
                            <Trans t={t} i18nKey="testResultInfo.checkBeforeWatch"
                                   values={{name: testerName,}}
                                   components={{strong: <span className={style.strong}/>,}}
                            />
                        </div>
                    </div>
                    <div className={style.underline}></div>

                    {/*    지능지수 안내    */}
                    <div className={style.contentWrapper}>
                        <div className={style.contentBox}>

                            {/*  타이틀 부분 (K-PASS 검사 결과 내용)  */}
                            <div className={style.contentHeader}>
                                <div className={style.numberBox}>
                                    <span className={style.number}> 1 </span>
                                </div>
                                <span className={style.text}>
                                    {/*K-PASS 검사 결과 내용*/}
                                    {t('testResultInfo.kpassTestResultDetail.part1.title')}
                                </span>
                            </div>

                            {/*  내용 부분 (K-PASS 지능검사의 결과지는~~)  */}
                            <div className={style.contents}>
                                <ul className={style.firstContentContainer}>
                                    <li className={style.firstContentItem}>
                                        {/*K-PASS 지능검사의 결과지는 전문가의 학술적 해석 내용이 포함되어 있어 용어와 문장이 다소 생소하고 어려울 수 있습니다.*/}
                                        {t('testResultInfo.kpassTestResultDetail.part1.list1')}
                                    </li>
                                    <li className={style.firstContentItem}>
                                        {/*학술적인 해석 내용을 수정하는 과정에서 내용이 왜곡될 수 있기 때문에 전문 결과지의 내용은 원본 그대로 수록하였습니다.*/}
                                        {t('testResultInfo.kpassTestResultDetail.part1.list2')}
                                    </li>
                                    <div className={style.firstContentWithButtonContainer}>
                                        <div className={style.firstContentShortContainer}>
                                            <li className={style.firstContentItem}>
                                                {/*결과지 내용의 이해를 돕기 위해 부연 설명 내용을 준비 하였습니다.*/}
                                                {t('testResultInfo.kpassTestResultDetail.part1.list1')}
                                            </li>
                                            <li className={style.firstContentItem}>
                                                {/*부연 설명 내용은 각 결과지의 제목 우측 상단에 마련한*/}
                                                {/*<span className={style.strongBold}> [ 해설 가이드 ] 버튼</span>*/}
                                                {/*을 눌러 확인해 보실 수 있습니다.*/}
                                                <Trans t={t} i18nKey="summaryGuideDescription"
                                                       components={{strong: <span className={style.strongBold}/>}}/>
                                            </li>
                                        </div>

                                        {/* 해설가이드 */}
                                        <div className={style.explainContainer}>
                                            <div className={style.explanationGuideButton}/>
                                            <span className={style.explainText}>
                                                {/*해설 가이드*/}
                                                {t('summaryGuideTitle')}
                                            </span>
                                        </div>
                                    </div>
                                </ul>
                            </div>
                        </div>

                        {/*    검사 결과 활용 안내    */}
                        <div className={style.contentBox}>
                            <div className={style.contentHeader}>
                                <div className={style.numberBox}>
                                    <span className={style.number}> 2 </span>
                                </div>
                                <span className={style.text}>
                                    {/*K-PASS 검사 만의 특징 */}
                                    {t('testResultInfo.kpassTestResultDetail.part2.title')}
                                </span>
                            </div>
                            <div className={style.contents}>
                                <ul className={style.firstContentContainer}>
                                    <li className={style.firstContentItem}>
                                        {/*K-PASS는 피검사자의 <span*/}
                                        {/*className={style.strong}>두뇌유형을 세계 최초로 디지털 검사를 통해 진단하는 검사 도구</span>입니다.*/}
                                        <Trans t={t} i18nKey="testResultInfo.kpassTestResultDetail.part2.list1"
                                               components={{strong: <span className={style.strong}/>}}/>
                                    </li>

                                    <li className={style.firstContentItem}>
                                        {/*    또한,<span className={style.strong}>두뇌유형과 함께 계획력과 주의력(집중력)을 판단할 수 있는 것이 특징*/}
                                        {/*</span>입니다.*/}
                                        <Trans t={t} i18nKey="testResultInfo.kpassTestResultDetail.part2.list2"
                                               components={{strong: <span className={style.strong}/>}}/>
                                    </li>

                                    <li className={style.firstContentItem}>
                                        {/*두뇌유형과 함께 계획력과 주의력 수준을 평가하는 것은 굉장히 중요한 요소입니다.*/}
                                        {t('testResultInfo.kpassTestResultDetail.part2.list3')}
                                    </li>

                                    <li className={style.firstContentItem}>
                                        {/*두뇌유형에 따라 학습자의 선호 학습법, 습득법이 나누어지며, 계획력 수준에 따라 타고난 기질이 분석됩니다.
                                        또한, 주의력의 현 상태를 알아야 학습의 효율을 높일 방안을 분석할 수 있습니다.*/}
                                        {t('testResultInfo.kpassTestResultDetail.part2.list4')}
                                    </li>
                                </ul>
                            </div>
                        </div>

                        {/*    검사 결과 용어 해석     */}
                        <div className={style.contentBox}>
                            <div className={style.contentHeader}>
                                <div className={style.numberBox}>
                                    <span className={style.number}> 3 </span>
                                </div>
                                <span className={style.text}>
                                    {/*K-PASS 검사 결과 포인트!*/}
                                    {t('testResultInfo.kpassTestResultDetail.part3.title')}
                                </span>
                            </div>
                            <div className={style.contents}>
                                <ul className={style.firstContentContainer}>
                                    <li className={style.firstContentItem}>
                                        {/*4개의 검사 항목별 점수(하위척도별 지수)를 제공합니다.*/}
                                        {t('testResultInfo.kpassTestResultDetail.part3.list1')}
                                    </li>

                                    <li className={style.firstContentItem}>
                                        {/*동일 연령대의 피검사자와 상대적으로 비교하여 규준적 강점/약점을 분석합니다.*/}
                                        {t('testResultInfo.kpassTestResultDetail.part3.list2')}
                                    </li>

                                    <li className={style.firstContentItem}>
                                        {/*4개의 검사 항목별 점수를 상호 비교하여 개인의 내적 강점/약점을 분석합니다.*/}
                                        {t('testResultInfo.kpassTestResultDetail.part3.list3')}
                                    </li>

                                    <li className={style.firstContentItem}>
                                        {/*피검사자의 두뇌유형을 판별하고, 유형별 맞춤형 학습·진로 가이드를 제시합니다.*/}
                                        {t('testResultInfo.kpassTestResultDetail.part3.list4')}
                                    </li>

                                    <li className={style.firstContentItem}>
                                        {/*웩xx, 카xxx 검사와 같이 신뢰성과 타당성을 확보한 이론을 바탕으로 진행되는 검사입니다.*/}
                                        {t('testResultInfo.kpassTestResultDetail.part3.list5')}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <TestFooterWithLine/>
            </div>

        </>
    )
}