import {useCounselorConverter} from "./useCounselorConverter";
import style from "../../../member/user/memberUserDetail.module.scss"
import {AdminStatus} from "../../../../types/admin";
import {BlockReasonModal} from "../../../component/modal/BlockReasonModal";
import {useState} from "react";

interface Args {
    status: AdminStatus;
    blockAt?: string;
    reasonForBlocking?: string;
}

export function CounselorStatusConverter({status, blockAt, reasonForBlocking}: Args) {
    const {getInfoSearchStatusConverter} = useCounselorConverter();
    const [showBlockedReason, setShowBlockedReason] = useState(false);

    const converter = () => {
        const currentStatus = getInfoSearchStatusConverter(status);
        const handleClick = (e: React.MouseEvent) => {
            if (status === AdminStatus.BLOCKED) {
                e.stopPropagation();
                setShowBlockedReason(true);
            }
        };

        switch (status) {
            case AdminStatus.DEFAULT:
                return <span>{currentStatus}</span>;
            case AdminStatus.BLOCKED:
                return (
                    <span
                        className={style.blocked}
                        onClick={handleClick}
                        style={{cursor: 'pointer'}}
                    >
                       {currentStatus}
                   </span>
                );
            case AdminStatus.BEFORE_ADMIN_AUTHENTICATION:
                return <span className={style.beforeAuthentication}>{currentStatus}</span>;
            case AdminStatus.LEAVE:
                return <span className={style.leave}>{currentStatus}</span>;
        }
    };

    return (
        <div style={{position: "relative"}}>
            {converter()}
            {status === AdminStatus.BLOCKED && blockAt && reasonForBlocking && (
                <BlockReasonModal
                    isShow={showBlockedReason}
                    callbackCloseModal={() => setShowBlockedReason(false)}
                    blockAt={blockAt}
                    reasonForBlocking={reasonForBlocking}
                />
            )}
        </div>
    );
}