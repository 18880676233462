import { SearchCondition } from "./page";
import { CustomerType, ListObject } from "./common";
import { ActionUser } from "./dataCommon";
import { UserProfileDetail } from "./userProfile";
import { OrganDTO } from "./organ";
import { UnitItemSummary } from "./unitItem";
import moment from "moment";
import {ConsultStatus} from "./counselor";
import {ExternalChannel} from "./externalVoucher";

interface VoucherStatusInfo {
  purchasedVoucher: number;
  freeVoucher: number;
  availableVoucher: number;
  collectedVoucher: number;
}

interface VoucherStatusNewDTO{
  voucherDTOs: VoucherStatusInfo[];
}

interface OrganVoucherStatusInfo {
  receivedOrganVoucherCnt: number;
  availableOrganVoucher: number;
  collectedOrganVoucher: number;
}

interface OrganVoucherResponse {
  unitVoucherType: VoucherType;
  receivedOrganVoucherCount: number;
  availableOrganVoucherCount: number;
  collectedOrganVoucherCount: number;
}

export interface OrganVoucherNewStatus {
  voucherDTOs: OrganVoucherResponse[];
}


export interface UserProfileOrganVoucherStatusDTO {
  receivedOrganVoucherCount: number;
  availableOrganVoucherCount: number;
  collectedOrganVoucherCount: number;
}

export namespace UserProfileOrganVoucherStatusDTO {
  export const toOrganVoucherStatusVO = (dto: UserProfileOrganVoucherStatusDTO): OrganVoucherStatusVO => ({
    receivedCount: dto.receivedOrganVoucherCount,
    availableCount: dto.availableOrganVoucherCount,
    collectedCount: dto.collectedOrganVoucherCount,
  });
}

export interface OrganVoucherStatusVO {
  receivedCount: number;
  availableCount: number;
  collectedCount: number;
}

interface VoucherHistorySearchCondition extends SearchCondition {
  userIdx: number;
  usable?: boolean;
}

export interface ProfileVoucherHistorySearchCondition extends SearchCondition {
  profileIdx: number;
  usable?: boolean;
}

export interface OrganManagerVoucherSearchCondition extends SearchCondition {}

enum VoucherStatus {
  USABLE = "USABLE",
  USED = "USED",
  CANCELLED = "CANCELLED",
  COLLECTED = "COLLECTED",
}

export enum VoucherStatusVO {
  USABLE = "USABLE",
  COLLECTED = "COLLECTED",
  EXPIRED = "EXPIRED",
  USED = "USED",
  CANCELLED = "CANCELLED",
  UNDEFINED = "UNDEFINED",
}

export interface VoucherHistoryListObj extends ListObject {
  unitVoucherType: VoucherType;
  createAt: string;
  availableUntil: string;
  voucherIssuer: ActionUser;
  grantReason: string;
  voucherCollector: ActionUser;
  collectReason: string;
  unitVoucherStatus: VoucherStatus;
  orderIdx: number | null;
  externalVoucherIdx: number;
  externalVoucherChannel: ExternalChannel;
}

export interface VoucherHistoryListVO extends ListObject {
  unitVoucherType: VoucherType;
  createAt: string;
  availableUntil: string;
  voucherIssuer: ActionUser;
  grantReason: string;
  voucherCollector: ActionUser;
  collectReason: string;
  unitVoucherStatus: VoucherStatus;
  voucherVOStatus: VoucherStatusVO;
  orderIdx: number | null;
  externalVoucherIdx: number;
  externalVoucherChannel: ExternalChannel;
}

export interface OrganVoucherListDTO {
  idx: number;
  grantedAt: string;
  availableUntil: string;
  profileIdx: number;
  profileName: string;
  organIdx: number;
  organManagerIdx: number;
  issuerOrganName: string;
  voucherCnt: number;
  unitVoucherStatus: VoucherStatus;
  organSignInCode: string;
  collectReason: string;
  unitVoucherType: VoucherType;
}

export namespace OrganVoucherListDTO {
  const voucherStatusVORouter = [
    {
      check: (status: VoucherStatus, _: boolean) => status === VoucherStatus.COLLECTED,
      toVoucherStatusVO: VoucherStatusVO.COLLECTED,
    },
    {
      check: (status: VoucherStatus, isExpired: boolean) => status === VoucherStatus.USABLE && isExpired,
      toVoucherStatusVO: VoucherStatusVO.EXPIRED,
    },
    {
      check: (status: VoucherStatus, _: boolean) => status === VoucherStatus.USED,
      toVoucherStatusVO: VoucherStatusVO.USED,
    },
    {
      check: (status: VoucherStatus, isExpired: boolean) => status === VoucherStatus.USABLE && !isExpired,
      toVoucherStatusVO: VoucherStatusVO.USABLE,
    },
  ];

  export const toOrganVoucherListVO = (dto: OrganVoucherListDTO): OrganVoucherListVO => ({
    ...dto,
    voucherStatusVO:
        voucherStatusVORouter.find((router) =>
            router.check(dto.unitVoucherStatus, moment.utc(dto.availableUntil).isBefore(moment()))
        )?.toVoucherStatusVO ?? VoucherStatusVO.UNDEFINED,
  });

  export const toProfileOrganVoucherListVO = (dto: OrganVoucherListDTO): ProfileOrganVoucherListVO => ({
    ...dto,
    voucherStatusVO:
        voucherStatusVORouter.find((router) =>
            router.check(dto.unitVoucherStatus, moment.utc(dto.availableUntil).isBefore(moment()))
        )?.toVoucherStatusVO ?? VoucherStatusVO.UNDEFINED,
  });
}

interface OrganVoucherListVO extends ListObject {
  grantedAt: string;
  availableUntil: string;
  profileIdx: number;
  profileName: string;
  organIdx: number;
  organManagerIdx: number;
  issuerOrganName: string;
  voucherCnt: number;
  unitVoucherStatus: VoucherStatus;
  voucherStatusVO: VoucherStatusVO;
  organSignInCode: string;
  collectReason: string;
  unitVoucherType: VoucherType;
}

export interface ProfileOrganVoucherListVO extends ListObject {
  grantedAt: string;
  availableUntil: string;
  organIdx: number;
  organManagerIdx: number;
  issuerOrganName: string;
  unitVoucherStatus: VoucherStatus;
  voucherStatusVO: VoucherStatusVO;
  organSignInCode: string;
  unitVoucherType: VoucherType;
}

interface GrantProductVoucherRequestParam {
  productId: number;
  quantity: number;
}

interface GrantVoucherRequestParam {
  targetIdxes: number[];
  targetType: CustomerType;
  grantProductVouchersRequest: GrantProductVoucherRequestParam[];
  grantReason: string;
}

interface GrantOrganManagerVoucherRequestParam {
  organIdx: number;
  memberIdxes: number[];
}

interface CollectVoucherRequestParam {
  userIdx: number;
  collectReason: string;
  unitVoucherIdxes: number[];
}

enum VoucherGrantedFrom {
  ORDER = "ORDER",
  ADMIN = "ADMIN",
}

enum OriginalOwnerType {
  USER = "USER",
  ORGAN_MANAGER = "ORGAN_MANAGER",
}

enum VoucherType {
  KPASS = "K-PASS",
  DCAS = "D-CAS",
  CONSULTING = "CONSULTING",
}

enum VoucherPurchaseRestrictions {
  KPASS = "KPASS",
  DCAS = "DCAS",
  NONE = "NONE",
}

enum ProductVoucherStatus {
  NOT_GRANTED = "NOT_GRANTED",
  GRANTED_TO_PROFILE = "GRANTED_TO_PROFILE",
  PARTIALLY_USED = "PARTIALLY_USED",
  ALL_USED = "ALL_USED",
  ALL_COLLECTED = "ALL_COLLECTED",
  PARTIALLY_COLLECTED = "PARTIALLY_COLLECTED",
  CANCELLED = "CANCELLED",
}

interface UnitVoucherDetail {
  idx: number;
  unitItem: UnitItemSummary;
  usedAt: string;
  voucherType: VoucherType;
  availableUntil: string;
  unitVoucherStatus: VoucherStatus;
}

interface ProductVoucherDTO {
  idx: number;
  grantedFrom: VoucherGrantedFrom;
  productVoucherStatus: ProductVoucherStatus;
  originalOwnerType: OriginalOwnerType;
  originalOwnerUser: ActionUser;
  originalOwnerOrganManager: ActionUser;
  originalOwnerOrganIdx: number;
  grantedToProfile: UserProfileDetail;
  grantReason: string;
  grantedAt: string;
  createAt: string;
  unitVouchers: UnitVoucherDetail[];
  productDTO: {
    idx: number;
    name: string;
    language: string;
  };
}

export interface ProfileOrganVoucherVO {
  idx: number;
  availableUntil: string;
  grantedAt: string;
  loginCode: string;
  organName: string;
  voucherType: VoucherType;
}

enum OrganVoucherHistoryCategory {
  GRANTED = "GRANTED",
  COLLECTED = "COLLECTED",
  CANCELLED = "CANCELLED",
  GRANTED_FROM_ADMIN = "GRANTED_FROM_ADMIN",
  BOUGHT = "BOUGHT",
}

interface OrganManagerVoucherListDTO {
  idx: number;
  organName: string;
  historyCount: number;
  organVoucherHistoryCategory: OrganVoucherHistoryCategory;
  createdAt: string;
}

interface OrganManagerVoucherStatus {
  purchasedVoucherCount: number;
  receivedVoucherCount: number;
  grantableProductVoucherCount: number;
  grantedProductVoucherCount: number;
}

interface OrganManagerVoucherStatusDTO {
  voucherDTOs: OrganManagerVoucherStatus[];
}

interface OrganVoucherHistorySetListDTO {
  idx: number;
  organMangerIdx: number;
  organIdx: number | null;
  organName: string | null;
  historyCount: number;
  organVoucherHistoryCategory: OrganVoucherHistoryCategory;
  createAt: string;
  voucherType: VoucherType;
}

interface OrganVoucherHistoryDTO {
  idx: number;
  organVoucherHistorySetIdx: number;
  profileIdx: number;
  profileName: string;
  productVoucherIdx: number;
  organManagerIdx: number;
  organIdx: number;
  organName: string;
  organVoucherHistoryCategory: OrganVoucherHistoryCategory;
}

interface OrganManagerVoucherMemberVO {
  idx: number;
  profileName: string;
}

interface AdminTesterCounselingHistoryDTO{
  idx: number;
  createAt:string;
  isMemoWritten: boolean;

  // reservation
  desiredDate:string;
  desiredTime:string;

  // content_executed_info
  contentExecutedInfoIdx: number;
  ageYear: number;
  ageMonth: number;
  testType:VoucherType;
  testRound: number;

  //admin
  consultantIdx: number
  consultantName:string;
  consultingStatus:ConsultStatus;
}

export namespace VoucherTranslator {
  export function createOrganManagerVoucherMemberVO(dto: OrganVoucherHistoryDTO): OrganManagerVoucherMemberVO {
    return dto as OrganManagerVoucherMemberVO;
  }

  export function createProfileOrganVoucherVO(
      voucherDTO: ProductVoucherDTO,
      organDTO: OrganDTO,
      loginCode: string
  ): ProfileOrganVoucherVO {
    return {
      idx: voucherDTO.idx,
      availableUntil: voucherDTO.unitVouchers.find((uv) => uv.voucherType === VoucherType.KPASS)?.availableUntil ?? "",
      grantedAt: voucherDTO.grantedAt,
      loginCode,
      organName: organDTO.organName,
      voucherType: voucherDTO.unitVouchers[0].voucherType,
    };
  }
}

export type {
  VoucherStatusInfo,
  VoucherStatusNewDTO,
  OrganVoucherStatusInfo,
  VoucherHistorySearchCondition,
  GrantProductVoucherRequestParam,
  GrantVoucherRequestParam,
  ProductVoucherDTO,
  UnitVoucherDetail,
  CollectVoucherRequestParam,
  OrganVoucherListVO,
  OrganManagerVoucherListDTO,
  OrganManagerVoucherStatus,
  OrganManagerVoucherStatusDTO,
  OrganVoucherHistorySetListDTO,
  OrganVoucherHistoryDTO,
  OrganManagerVoucherMemberVO,
  GrantOrganManagerVoucherRequestParam,
  AdminTesterCounselingHistoryDTO,
};

export {
  VoucherStatus,
  VoucherType,
  OriginalOwnerType,
  VoucherGrantedFrom,
  ProductVoucherStatus,
  OrganVoucherHistoryCategory,
  VoucherPurchaseRestrictions,
};
