import style from "../../style/test_result/testResult.module.scss";
import {TestSummaryHeader} from "../common/TestSummaryHeader";
import {TestResultPath, TestResultReportDTO} from "../../../types/testResult";
import {useState} from "react";
import {ProfileTestResultCaseField} from "./ProfileTestResultCaseField";
import {useTranslation} from "react-i18next";

interface Args {
    testResult?:TestResultReportDTO
}

/* eslint-disable max-len */
export function KpassTestResultProfile({testResult}:Args) {
    /**
     * 검사 결과 중 05 학습유형 판단을 위한 ~~
     */
    const {t} = useTranslation("testResult");

    const [difference, ]
        = useState((testResult?.testResultVO.successiveScore.score ?? 0) -
        (testResult?.testResultVO.simultaneousScore.score ?? 0))

    return(
        <>
            <article className={style.summaryWrapper}>
                <TestSummaryHeader testResultPath={TestResultPath.PROFILE_ANALYSIS}
                                   testerName={testResult?.testResultUserProfileVO.name}/>

                <div className={style.underline} />
            </article>

            <article className={style.mainWrapper}>
                <table className={`${style.table} ${style.individualAnalysis}`}>
                    <thead>
                    <tr>
                        <th>{t('table.informationType')}</th>
                        <th>{t('table.standardScore')}</th>
                        <th className={style.scoreDiff}>{t('table.scoreDifference')}</th>
                        <th className={style.learningStyle}>{t('table.determineLearningType')}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <th>{t('table.successive')}</th>
                        <td><span className={style.red}>{testResult?.testResultVO.successiveScore.score}</span></td>
                        <td rowSpan={2}>
                            <span className={style.red}>{difference}</span>
                        </td>
                        <td rowSpan={2}>
                            <span className={style.red}>{testResult?.testResultComputedDataVO.brainTypeText}</span>
                        </td>
                    </tr>
                    <tr>
                        <th>{t('table.simultaneous')}</th>
                        <td><span className={style.red}>{testResult?.testResultVO.simultaneousScore.score}</span></td>
                    </tr>
                    </tbody>
                </table>
            </article>

            {testResult&& (
                <ProfileTestResultCaseField
                    name={testResult?.testResultUserProfileVO.name}
                    brainType={testResult?.testResultComputedDataVO.brainType}
                    difference={difference}
                />
            )}
        </>
    )
}