import React from "react";
import {CounselorVoucherInfoStatus} from "./CounselorVoucherInfoStatus";
import {CounselorVoucherInfoList} from "./CounselorVoucherInfoList";

interface Props {
  userIdx: number;
  name: string;
}

export function CounselorVoucherInfo({ userIdx, name }: Props) {
  return (
    <>
      <CounselorVoucherInfoStatus userIdx={userIdx} />
      <CounselorVoucherInfoList userIdx={userIdx} name={name} />
    </>
  );
}
