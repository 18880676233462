import styled from "styled-components";
import variables from "../assets/scss/variable.module.scss";
import React, {useMemo} from "react";
import {GridInputTel, InputSelector} from "./Inputs";
import {useCountry} from "../hooks/hooks";
import {useTranslation} from "react-i18next";

const InputPhoneNumberWrapper = styled.div`
    display: flex;
    height: 100%;
`;

const Splitter = styled.div`
    border-right: 1px solid ${variables.colorDE};
`;

interface Props {
    codePhone: string;
    phoneNumber: string;
    onChangeCodePhone: (codePhone: string) => void;
    onChangePhoneNumber: (phoneNumber: string) => void;
    min?: number
    max?: number
}

export function InputPhoneNumber(
    {
        codePhone, phoneNumber, onChangeCodePhone, onChangePhoneNumber,
        min, max
    }: Props) {
    const {phoneNumberOptions} = useCountry();
    const {t} = useTranslation("common");

    const isKoreanPhoneNumber = useMemo(() => codePhone === "+82", [codePhone]);

    return (
        <InputPhoneNumberWrapper>
            <InputSelector

                selectedValue={codePhone}
                options={phoneNumberOptions}
                setSelectorOption={(it) => onChangeCodePhone(it.value)}
                isPageSizeSelector={false}
                width={132}
                optionWidth={"270px"}
            />

            <Splitter/>

            <GridInputTel
                minLength={min}
                maxLength={max}
                value={phoneNumber}
                required={true}
                pattern={isKoreanPhoneNumber ? "[0-9]{10,11}" : "[0-9]{5,15}"}
                title={isKoreanPhoneNumber ? t("inputPhoneNumber.placeholder1") : t("inputPhoneNumber.placeholder2")}
                placeholder={isKoreanPhoneNumber ? t("inputPhoneNumber.placeholder1") : t("inputPhoneNumber.placeholder2")}
                onChange={(it) => onChangePhoneNumber(it.target.value)}
            />
        </InputPhoneNumberWrapper>
    );
}
