import {FilterType, FilterValue} from "../../../../types/filter";
import {useCallback, useMemo, useState} from "react";
import {PageSearchCondition} from "../../../../types/page";
import {useListSearchParams} from "../../../../hooks/list/useListSearchParams";
import {
    CounselorFilterWordType, CounselorHistoryFilterValues,
    CounselorHistoryListQueryType,
    CounselorInfoListQueryType
} from "../../../../types/counselor";
import {useFilterValues} from "../../../../hooks/list/useFilterValues";
import {TestType} from "../../../../types/common";
import {AdminStatus} from "../../../../types/admin";

export function useCounselorHistoryFilterValues() {

    const [searchWordType, setSearchWordType] = useState('');
    const [searchWord, setSearchWord] = useState("");
    const [registerStartAt, setRegisterStartAt] = useState<Date | undefined>();
    const [registerEndAt, setRegisterEndAt] = useState<Date | undefined>();
    const [consultationStartAt, ] = useState<Date | undefined>();
    const [consultationEndAt, ] = useState<Date | undefined>();
    const [testType, setTestType] = useState('')
    const [status, setStatus] = useState(''); // 상담 상태
    const [pageInfoOnFilter, setPageInfoOnFilter] = useState<PageSearchCondition>({});

    const { listQueryType, setUrlSearchParams } = useListSearchParams<CounselorInfoListQueryType>();

    // 필터 영역
    const filterValueArray: FilterValue<any>[] = useMemo(
        () => [
            {
                key: "searchWordType",
                value: searchWordType,
                setter: setSearchWordType,
                defaultValue: '',
                type: FilterType.OTHER,
            },
            {key: "searchWord", value: searchWord, setter: setSearchWord, defaultValue: "", type: FilterType.STRING},
            {
                key: "registerStartAt",
                value: registerStartAt,
                setter: setRegisterStartAt,
                defaultValue: undefined,
                type: FilterType.DATE,
            },
            {
                key: "registerEndAt",
                value: registerEndAt,
                setter: setRegisterEndAt,
                defaultValue: undefined,
                type: FilterType.DATE,
            },
            {
                key: "consultationStartAt",
                value: consultationStartAt,
                setter: setRegisterStartAt,
                defaultValue: undefined,
                type: FilterType.DATE,
            },
            {
                key: "consultationEndAt",
                value: consultationStartAt,
                setter: setRegisterEndAt,
                defaultValue: undefined,
                type: FilterType.DATE,
            },
            {
                key: "testType",
                value: testType,
                setter: setTestType,
                defaultValue: '',
                type: FilterType.OTHER,
            },
            {
                key: "status",
                value: status,
                setter: setStatus,
                defaultValue: '',
                type: FilterType.OTHER,
            },
        ],
        //eslint-disable-next-line
        [searchWord, searchWordType, registerStartAt, registerEndAt, consultationStartAt, consultationEndAt,
            testType, status]
    );

    const { getFilterValueByKey } = useFilterValues({
        filterValueArray,
        listQueryType: listQueryType,
        setPageInfoOnFilter,
    });

    const filterValues = useMemo(
        () =>
            ({
                searchWordTypeFilterValue: getFilterValueByKey("searchWordType") as FilterValue<CounselorFilterWordType>,
                searchWordFilterValue: getFilterValueByKey("searchWord") as FilterValue<string>,
                registerStartAtFilterValue: getFilterValueByKey("registerStartAt") as FilterValue<Date | undefined>,
                registerEndAtFilterValue: getFilterValueByKey("registerEndAt") as FilterValue<Date | undefined>,
                consultationStartAtFilterValue: getFilterValueByKey("consultationStartAt") as FilterValue<Date | undefined>,
                consultationEndAtFilterValue: getFilterValueByKey("consultationEndAt") as FilterValue<Date | undefined>,
                testTypeFilterValue: getFilterValueByKey("testType") as FilterValue<TestType>,
                statusFilterValue: getFilterValueByKey("status") as FilterValue<AdminStatus>,
            } as CounselorHistoryFilterValues),
        [getFilterValueByKey]
    );
    // 필터 영역 종료


    // searchList 영역
    const searchList = useCallback(
        (pageSearchCondition: PageSearchCondition, listQueryTypeParam?: CounselorHistoryListQueryType) => {
            const param: CounselorHistoryListQueryType = {
                queryType: listQueryTypeParam?.queryType,
                query: listQueryTypeParam?.query,
                createdAtFrom: listQueryTypeParam?.createdAtFrom,
                createdAtTo: listQueryTypeParam?.createdAtTo,
                desiredAtFrom: listQueryTypeParam?.desiredAtFrom,
                desiredAtTo: listQueryTypeParam?.desiredAtTo,
                testType: listQueryTypeParam?.testType,
                status: listQueryTypeParam?.status,
            };

            return () => setUrlSearchParams(param, pageSearchCondition);
        },
        [setUrlSearchParams]
    );

    return{
        pageInfoOnFilter,
        filterValues, searchList,
        listQueryType
    }
}