import style from "../../style/test_result/guide/learningStrategy.module.scss";
import {BrainType} from "../../../types/testResult";
import {Trans, useTranslation} from "react-i18next";
import {useJobInfoFunctions} from "./hooks/useJobInfoFunctions";
import React from "react";

interface Args {
    brainType: BrainType;
}

/* eslint-disable max-len */
export function RecommendationRightJobDetail({brainType}:Args) {

    const {t} = useTranslation("guideRecommendationJob");
    const brainValue = brainType === BrainType.BALANCED ? `${t('summary.right.title')} -` : '';

    const job = 'recommendation.right.futureJob'
    const {renderListItems} = useJobInfoFunctions();
    return(
        <>
            {/*우뇌우세형 - 적성 및 주성향 판단 */}
            {brainValue && (
                <article className={style.article}>
                    <h2 className={style.title}>{t('recommendation.right.aptitude.title')}</h2>
                    <Trans t={t} i18nKey='recommendation.right.aptitude.description'
                           values={{blank: '\u00A0'}}
                           components={{
                               br: <br/>,
                               description: <p className={style.description}/>,
                           }}/>

                </article>
            )}

            {/*우뇌우세형 - 추천 직업군 */}
            <article className={style.article}>
                <h2 className={style.title}>
                    <Trans t={t} i18nKey='recommendation.right.job.title' values={{name: brainValue}}/>
                </h2>

                <p className={style.description}>
                    &nbsp;{t('recommendation.right.job.description')}
                </p>
            </article>

            {/*우뇌우세형 - 미래 예상 직업군 */}
            <article className={style.article}>
                <h2 className={style.title}>
                    <Trans t={t} i18nKey='recommendation.right.futureJob.title' values={{name: brainValue}}/>
                </h2>

                <article className={style.subArticle}>
                    <section>
                        <h3 className={style.title}>
                            <span className={style.number}>1</span>
                            <span>{t('recommendation.right.futureJob.group1.title')}</span>
                        </h3>
                        <img src="https://images.feel-good.io/guide/right/right_06.png" alt={'6'}/>

                        <ul className={style.description}>
                            {renderListItems(14, 1, job)}
                        </ul>
                    </section>
                </article>

                <article className={style.subArticle}>
                    <section>
                        <h3 className={style.title}>
                            <span className={style.number}>2</span>
                            <span>{t('recommendation.right.futureJob.group2.title')}</span>
                        </h3>
                        <img src="https://images.feel-good.io/guide/right/right_07.png" alt={'7'}/>
                        <ul className={style.description}>
                            {renderListItems(21, 2, job)}
                        </ul>
                    </section>
                </article>

                <article className={style.subArticle}>
                    <section>
                        <h3 className={style.title}>
                            <span className={style.number}>3</span>
                            <span>{t('recommendation.right.futureJob.group3.title')}</span>

                        </h3>
                        <img src="https://images.feel-good.io/guide/right/right_08.png" alt={'8'}/>

                        <ul className={style.description}>
                            {renderListItems(15, 3, job)}
                        </ul>
                    </section>
                </article>
            </article>
        </>
    )
}