import {useTranslation} from "react-i18next";
import {useCountry, useTimeConverter} from "../../../hooks/hooks";
import React from "react";
import {TableColumnData} from "../../../types/common";
import {NormalUser} from "../../../types/user";

interface Args {
    selectedUser: number;
    setSelectedUser: (_: number) => void;
}

export function useExternalUserColumns({selectedUser, setSelectedUser}: Args) {
    const {t} = useTranslation("voucher");
    const {timeConverter} = useTimeConverter();
    const {getCountryNameByCodeISOAlpha2} = useCountry();

    const onClickCurrentUser = (idx: number) => {
        setSelectedUser(idx);
    };

    const columns: TableColumnData<NormalUser>[] = [
        {
            accessor: "idx",
            header: "",
            sortable: false,
            width: 78,
            getNode: (a) => {
                return (
                    <input
                        checked={selectedUser === a.idx}
                        type={"radio"}
                        onChange={() => onClickCurrentUser(a.idx)}
                    />
                );
            },
        },
        {
            accessor: "createAt",
            header: t("assign.columns.createdAt"),
            sortable: true,
            getNode: (a) => <>{timeConverter.convertToLocalTime(a.createAt)}</>,
            width: 112,
        },
        {
            accessor: "name",
            header: t("assign.columns.name"),
            sortable: false,
            isLeftAlign: true,
            getNode: (a) => <>{a.name}</>,
            width: 180,
        },
        {
            accessor: "userEmail",
            header: t("assign.columns.id"),
            sortable: false,
            getNode: (a) => <>{a.userEmail}</>,
            width: 289,
        },
        {
            accessor: "age",
            header: t("assign.columns.age"),
            sortable: false,
            getNode: (a) => <>{a.age}</>,
            width: 120,
        },
        {
            accessor: "nationality",
            header: t("assign.columns.nationality"),
            sortable: false,
            getNode: (a) => <>{getCountryNameByCodeISOAlpha2(a.nationality)}</>,
            width: 120,
        },
    ];

    return columns;
}
