import {BrainType} from "../../../../types/testResult";
import {useTranslation} from "react-i18next";

export interface ContentMapDTO{
    subtitle: string;
    title: string;
    leftCardImgUrl: string;
    rightCardImgUrl: string;
    guideRightImg: string;
    hashTags: string[];
    studyStrategy: string,
    recommendJob: string,
    imgIdx: number;
}
export function useGuideFunctions() {

    const {t} = useTranslation("guideCommon");

    const contentMap = {
        // left - right - planning
        // balance
        'NS-NS-NS': {
            subtitle: '체계적인 혁신가이자',
            title: '융합적 리더',
            leftCardImgUrl: 'https://images.feel-good.io/kpass_cards/card_balance_1',
            rightCardImgUrl: 'https://images.feel-good.io/kpass_cards/card2_balance_1.png',
            guideRightImg: 'https://images.feel-good.io/guide/characters/balance_1.png',
            hashTags:['# 전략가','# 실용적','# 데이터 분석'],
            studyStrategy: '언어중심, 순차적, 정확성과 세부사항 강조, 논리적 사고강화, 미션위주, 시각, 체험, 참여형 학습',
            recommendJob: '프로젝트 관리자, 데이터 분석가, 재무 분석가, 소프트웨어 개발자, 법률 고문, 컨설턴트, 디렉터, 이벤트 기획자, 마케팅 전문가, 제품 개발 관리자,  기획 연출 감독&작가',
            imgIdx: 1,
        },
        'NS-NS-N': {
            subtitle: '다양한 분야에서 다재다능한',
            title: '하모닉 브레인 리더',
            leftCardImgUrl: 'https://images.feel-good.io/kpass_cards/card_balance_2',
            rightCardImgUrl: 'https://images.feel-good.io/kpass_cards/card2_balance_2.png',
            hashTags:['# 다재다능','# 창의적','# 논리적 분석'],
            studyStrategy: '언어중심, 순차적, 정확성과 세부사항 강조, 논리적 사고강화, 미션위주, 시각, 체험, 참여형 학습',
            recommendJob: '프로젝트 관리자, 데이터 분석가, 재무 분석가, 소프트웨어 개발자, 법률 고문, 컨설턴트, 디렉터, 이벤트 기획자, 마케팅 전문가, 제품 개발 관리자,  기획 연출 감독&작가',
            guideRightImg: 'https://images.feel-good.io/guide/characters/balance_2.png'
        },
        'NS-NS-NW': {
            subtitle: '창의적인 탐험가이자',
            title: '감각적인 리더',
            leftCardImgUrl: 'https://images.feel-good.io/kpass_cards/card_balance_3',
            rightCardImgUrl: 'https://images.feel-good.io/kpass_cards/card2_balance_3.png',
            hashTags:['# 리더십','# 호기심','# 감성적'],
            studyStrategy: '언어중심, 순차적, 정확성과 세부사항 강조, 논리적 사고강화, 미션위주, 시각, 체험, 참여형 학습',
            recommendJob: '연구원, 개발자, IT 지원 전문가, 공급망 관리자, 품질 관리자, 프로듀서, 예술가, 창작자, 광고 크리에이티브, 게임 디자이너, 소셜 미디어 인플루언서',
            guideRightImg: 'https://images.feel-good.io/guide/characters/balance_3.png',
        },
        'N-N-NS': {
            subtitle: '로직 크리에이터이자',
            title: '프로 비전 전문가',
            leftCardImgUrl: 'https://images.feel-good.io/kpass_cards/card_balance_4',
            rightCardImgUrl: 'https://images.feel-good.io/kpass_cards/card2_balance_4.png',
            hashTags:['# 계획적','# 체계적','# 미래 대비'],
            studyStrategy: '언어중심, 순차적, 정확성과 세부사항 강조, 논리적 사고강화, 미션위주, 시각, 체험, 참여형 학습',
            recommendJob: '프로젝트 관리자, 데이터 분석가, 재무 분석가, 소프트웨어 개발자, 법률 고문, 컨설턴트, 디렉터, 이벤트 기획자, 마케팅 전문가, 제품 개발 관리자,  기획 연출 감독&작가',
            guideRightImg: 'https://images.feel-good.io/guide/characters/balance_4.png'
        },
        'N-N-N': {
            subtitle: '안정과 중립을 사랑하는',
            title: '평화주의자',
            leftCardImgUrl: 'https://images.feel-good.io/kpass_cards/card_balance_5',
            rightCardImgUrl: 'https://images.feel-good.io/kpass_cards/card2_balance_5.png',
            hashTags:['# 안정적','# 중립','# 다양한 취미'],
            studyStrategy: '언어중심, 순차적, 정확성과 세부사항 강조, 논리적 사고강화, 미션위주, 시각, 체험, 참여형 학습',
            recommendJob: '프로젝트 관리자, 데이터 분석가, 재무 분석가, 소프트웨어 개발자, 법률 고문, 컨설턴트, 디렉터, 이벤트 기획자, 마케팅 전문가, 제품 개발 관리자,  기획 연출 감독&작가',
            guideRightImg: 'https://images.feel-good.io/guide/characters/balance_5.png'
        },
        'N-N-NW': {
            subtitle: '안정과 중립을 사랑하는',
            title: '유연한 균형가',
            leftCardImgUrl: 'https://images.feel-good.io/kpass_cards/card_balance_6',
            rightCardImgUrl: 'https://images.feel-good.io/kpass_cards/card2_balance_6.png',
            hashTags:['# 안정적','# 균형가','# 유연함'],
            studyStrategy: '언어중심, 순차적, 정확성과 세부사항 강조, 논리적 사고강화, 미션위주, 시각, 체험, 참여형 학습',
            recommendJob: '연구원, 개발자, IT 지원 전문가, 공급망 관리자, 품질 관리자, 프로듀서, 예술가, 창작자, 광고 크리에이티브, 게임 디자이너, 소셜 미디어 인플루언서',
            guideRightImg: 'https://images.feel-good.io/guide/characters/balance_6.png',
        },

        'NW-NW-NS': {
            subtitle: '은유하는 철학가이자',
            title: '플랜 퍼펙셔니스트',
            leftCardImgUrl: 'https://images.feel-good.io/kpass_cards/card_balance_7',
            rightCardImgUrl: 'https://images.feel-good.io/kpass_cards/card2_balance_7.png',
            hashTags:['# 계획적','# 내향적','# 결과 지향'],
            studyStrategy: '언어중심, 순차적, 정확성과 세부사항 강조, 논리적 사고강화, 미션위주, 시각, 체험, 참여형 학습',
            recommendJob: '프로젝트 관리자, 데이터 분석가, 재무 분석가, 소프트웨어 개발자, 법률 고문, 컨설턴트, 디렉터, 이벤트 기획자, 마케팅 전문가, 제품 개발 관리자,  기획 연출 감독&작가',
            guideRightImg: 'https://images.feel-good.io/guide/characters/balance_7.png',
        },
        'NW-NW-N': {
            subtitle: '안정된',
            title: '핵심가치 지향자',
            leftCardImgUrl: 'https://images.feel-good.io/kpass_cards/card_balance_8',
            rightCardImgUrl: 'https://images.feel-good.io/kpass_cards/card2_balance_8.png',
            hashTags:['# 안정된 환경','# 실용적','# 직관적'],
            studyStrategy: '언어중심, 순차적, 정확성과 세부사항 강조, 논리적 사고강화, 미션위주, 시각, 체험, 참여형 학습',
            recommendJob: '프로젝트 관리자, 데이터 분석가, 재무 분석가, 소프트웨어 개발자, 법률 고문, 컨설턴트, 디렉터, 이벤트 기획자, 마케팅 전문가, 제품 개발 관리자,  기획 연출 감독&작가',
            guideRightImg: 'https://images.feel-good.io/guide/characters/balance_8.png',
        },
        'NW-NW-NW': {
            subtitle: '안정된',
            title: '핵심가치 지향자',
            leftCardImgUrl: 'https://images.feel-good.io/kpass_cards/card_balance_9',
            rightCardImgUrl: 'https://images.feel-good.io/kpass_cards/card2_balance_9.png',
            hashTags:['# 안정된 환경','# 실용적','# 직관적'],
            studyStrategy: '언어중심, 순차적, 정확성과 세부사항 강조, 논리적 사고강화, 미션위주, 시각, 체험, 참여형 학습',
            recommendJob: '연구원, 개발자, IT 지원 전문가, 공급망 관리자, 품질 관리자, 프로듀서, 예술가, 창작자, 광고 크리에이티브, 게임 디자이너, 소셜 미디어 인플루언서',
            guideRightImg: 'https://images.feel-good.io/guide/characters/balance_9.png',
        },
        // SUCCESSIVE
        'NS-N-NS': {
            subtitle: '논리의 전사이자',
            title: '계획의 마술사',
            leftCardImgUrl: 'https://images.feel-good.io/kpass_cards/card_left_1',
            rightCardImgUrl: 'https://images.feel-good.io/kpass_cards/card2_left_1.png',
            hashTags:['# 논리적','# 분석적','# 이론적 학습'],
            studyStrategy: '언어중심, 순차적, 정확성과 세부사항 강조 학습,  논리적 사고강화,',
            recommendJob: '프로젝트 관리자, 데이터 분석가, 통계학자, 회계사, 재무 분석가, 소프트웨어 개발자, 엔지니어, 변호사, 법률 고문, 컨설턴트',
            guideRightImg: 'https://images.feel-good.io/guide/characters/left_1.png',
        },
        'NS-N-N': {
            subtitle: '세상을 새로운 시각으로 바라보는',
            title: '분석적인 혁신가',
            leftCardImgUrl: 'https://images.feel-good.io/kpass_cards/card_left_2',
            rightCardImgUrl: 'https://images.feel-good.io/kpass_cards/card2_left_2.png',
            hashTags:['# 데이터 분석','# 체계적 학습','# 반복 학습'],
            studyStrategy: '언어중심, 순차적, 정확성과 세부사항 강조 학습,  논리적 사고강화,',
            recommendJob: '프로젝트 관리자, 데이터 분석가, 통계학자, 회계사, 재무 분석가, 소프트웨어 개발자, 엔지니어, 변호사, 법률 고문, 컨설턴트',
            guideRightImg: 'https://images.feel-good.io/guide/characters/left_2.png',
        },
        'NS-N-NW': {
            subtitle: '세상을 새로운 시각으로 바라보는',
            title: '분석적인 혁신가',
            leftCardImgUrl: 'https://images.feel-good.io/kpass_cards/card_left_3',
            rightCardImgUrl: 'https://images.feel-good.io/kpass_cards/card2_left_3.png',
            hashTags:['# 데이터 분석','# 체계적 학습','# 논리적'],
            studyStrategy: '언어중심, 순차적, 정확성과 세부사항 강조 학습,  논리적 사고강화,',
            recommendJob: '연구원, 개발자, IT 지원 전문가, 네트워크 관리자, 공급망 관리자, 물류 전문가, 품질 관리자',
            guideRightImg: 'https://images.feel-good.io/guide/characters/left_3.png',
        },

        'N-NW-NS': {
            subtitle: '논리적이고 체계적인',
            title: '실용주의 탐구가',
            leftCardImgUrl: 'https://images.feel-good.io/kpass_cards/card_left_4',
            rightCardImgUrl: 'https://images.feel-good.io/kpass_cards/card2_left_4.png',
            hashTags:['# 실용주의','# 분석적 사고','# 논리적'],
            studyStrategy: '언어중심, 순차적, 정확성과 세부사항 강조 학습,  논리적 사고강화,',
            recommendJob: '프로젝트 관리자, 데이터 분석가, 통계학자, 회계사, 재무 분석가, 소프트웨어 개발자, 엔지니어, 변호사, 법률 고문, 컨설턴트',
            guideRightImg: 'https://images.feel-good.io/guide/characters/left_4.png',
        },
        'N-NW-N': {
            subtitle: '실용적인 사고를 선호하는',
            title: '논리 전략가',
            leftCardImgUrl: 'https://images.feel-good.io/kpass_cards/card_left_5',
            rightCardImgUrl: 'https://images.feel-good.io/kpass_cards/card2_left_5.png',
            hashTags:['# 전략가','# 실용적','# 데이터 분석'],
            studyStrategy: '언어중심, 순차적, 정확성과 세부사항 강조 학습,  논리적 사고강화,',
            recommendJob: '프로젝트 관리자, 데이터 분석가, 통계학자, 회계사, 재무 분석가, 소프트웨어 개발자, 엔지니어, 변호사, 법률 고문, 컨설턴트',
            guideRightImg: 'https://images.feel-good.io/guide/characters/left_5.png',
        },
        'N-NW-NW': {
            subtitle: '세상을 새로운 시각으로 바라보는',
            title: '캐쥬얼 네비게이터',
            leftCardImgUrl: 'https://images.feel-good.io/kpass_cards/card_left_6',
            rightCardImgUrl: 'https://images.feel-good.io/kpass_cards/card2_left_6.png',
            hashTags:['# 직관적','# 실용적','# 즉흥적'],
            studyStrategy: '언어중심, 순차적, 정확성과 세부사항 강조 학습,  논리적 사고강화,',
            recommendJob: '연구원, 개발자, IT 지원 전문가, 네트워크 관리자, 공급망 관리자, 물류 전문가, 품질 관리자',
            guideRightImg: 'https://images.feel-good.io/guide/characters/left_6.png',
        },
        'NS-NW-NS': {
            subtitle: '분석적인 계획가이자',
            title: '완벽주의 전문가',
            leftCardImgUrl: 'https://images.feel-good.io/kpass_cards/card_left_7',
            rightCardImgUrl: 'https://images.feel-good.io/kpass_cards/card2_left_7.png',
            hashTags:['# 목표 지향','# 과학적 연구','# 논리적 학습'],
            studyStrategy: '언어중심, 순차적, 정확성과 세부사항 강조 학습,  논리적 사고강화,',
            recommendJob: '프로젝트 관리자, 데이터 분석가, 통계학자, 회계사, 재무 분석가, 소프트웨어 개발자, 엔지니어, 변호사, 법률 고문, 컨설턴트',
            guideRightImg: 'https://images.feel-good.io/guide/characters/left_7.png',
        },
        'NS-NW-N': {
            subtitle: '분석적인 계획가이자',
            title: '전문가',
            leftCardImgUrl: 'https://images.feel-good.io/kpass_cards/card_left_8',
            rightCardImgUrl: 'https://images.feel-good.io/kpass_cards/card2_left_8.png',
            hashTags:['# 논리적 사고','# 전략적','# 순차적 학습'],
            studyStrategy: '언어중심, 순차적, 정확성과 세부사항 강조 학습,  논리적 사고강화,',
            recommendJob: '프로젝트 관리자, 데이터 분석가, 통계학자, 회계사, 재무 분석가, 소프트웨어 개발자, 엔지니어, 변호사, 법률 고문, 컨설턴트',
            guideRightImg: 'https://images.feel-good.io/guide/characters/left_8.png',
        },
        'NS-NW-NW': {
            subtitle: '분석적인',
            title: '지식 탐험가',
            leftCardImgUrl: 'https://images.feel-good.io/kpass_cards/card_left_9',
            rightCardImgUrl: 'https://images.feel-good.io/kpass_cards/card2_left_9.png',
            hashTags:['# 논리적 사고','# 유연성','# 독립적 학습'],
            studyStrategy: '언어중심, 순차적, 정확성과 세부사항 강조 학습,  논리적 사고강화,',
            recommendJob: '연구원, 개발자, IT 지원 전문가, 네트워크 관리자, 공급망 관리자, 물류 전문가, 품질 관리자',
            guideRightImg: 'https://images.feel-good.io/guide/characters/left_9.png',
        },
        // SIMULTANEOUS
        'N-NS-NS': {
            subtitle: '창조적인 전략가이자',
            title: '융합적인 계획가',
            leftCardImgUrl: 'https://images.feel-good.io/kpass_cards/card_right_1',
            rightCardImgUrl: 'https://images.feel-good.io/kpass_cards/card2_right_1.png',
            hashTags:['# 창의적','# 다양한 관점','# 융합적 사고'],
            studyStrategy: '미션위주, 시각, 체험, 참여형 학습',
            recommendJob: '스타트업 창업자, 크리에이티브 디렉터, 아트 디렉터, UI/UX 디자이너, 이벤트 기획자, 마케팅 전문가, 제품 개발 관리자, 아키텍처&인테리어 디자이너, 기획 연출 감독&작가',
            guideRightImg: 'https://images.feel-good.io/guide/characters/right_1.png',
        },
        'N-NS-N': {
            subtitle: '감상적인',
            title: '예술가적 창조자',
            leftCardImgUrl: 'https://images.feel-good.io/kpass_cards/card_right_2',
            rightCardImgUrl: 'https://images.feel-good.io/kpass_cards/card2_right_2.png',
            hashTags:['# 창의적','# 다양한 관점','# 융합적 사고'],
            studyStrategy: '미션위주, 시각, 체험, 참여형 학습',
            recommendJob: '스타트업 창업자, 크리에이티브 디렉터, 아트 디렉터, UI/UX 디자이너, 이벤트 기획자, 마케팅 전문가, 제품 개발 관리자, 아키텍처&인테리어 디자이너, 기획 연출 감독&작가',
            guideRightImg: 'https://images.feel-good.io/guide/characters/right_2.png',
        },
        'N-NS-NW': {
            subtitle: '세상을 새로운 시각으로 바라보는',
            title: '창조적 예술가',
            leftCardImgUrl: 'https://images.feel-good.io/kpass_cards/card_right_3',
            rightCardImgUrl: 'https://images.feel-good.io/kpass_cards/card2_right_3.png',
            hashTags:['# 창의적','# 직관적','# 순발력'],
            studyStrategy: '미션위주, 시각, 체험, 참여형 학습',
            recommendJob: '프로듀서, 예술가, 창작자, 광고 크리에이티브, 게임 디자이너, 콘텐츠 크리에이터, 소셜 미디어 인플루언서',
            guideRightImg: 'https://images.feel-good.io/guide/characters/right_3.png',
        },
        'NW-N-NS': {
            subtitle: '현실주의적인 창조자이자',
            title: '목표 지향적 전문가',
            leftCardImgUrl: 'https://images.feel-good.io/kpass_cards/card_right_4',
            rightCardImgUrl: 'https://images.feel-good.io/kpass_cards/card2_right_4.png',
            hashTags:['# 계획적','# 웰니스','# 책임감'],
            studyStrategy: '미션위주, 시각, 체험, 참여형 학습',
            recommendJob: '스타트업 창업자, 크리에이티브 디렉터, 아트 디렉터, UI/UX 디자이너, 이벤트 기획자, 마케팅 전문가, 제품 개발 관리자, 아키텍처&인테리어 디자이너, 기획 연출 감독&작가',
            guideRightImg: 'https://images.feel-good.io/guide/characters/right_4.png',
        },
        'NW-N-N': {
            subtitle: '다재다능한',
            title: '실용주의자',
            leftCardImgUrl: 'https://images.feel-good.io/kpass_cards/card_right_5',
            rightCardImgUrl: 'https://images.feel-good.io/kpass_cards/card2_right_5.png',
            hashTags:['# 다양한 분야','# 실용적','# 과정 중심'],
            studyStrategy: '미션위주, 시각, 체험, 참여형 학습',
            recommendJob: '스타트업 창업자, 크리에이티브 디렉터, 아트 디렉터, UI/UX 디자이너, 이벤트 기획자, 마케팅 전문가, 제품 개발 관리자, 아키텍처&인테리어 디자이너, 기획 연출 감독&작가',
            guideRightImg: 'https://images.feel-good.io/guide/characters/right_5.png',
        },
        'NW-N-NW': {
            subtitle: '자유로운',
            title: '영혼의 소유자',
            leftCardImgUrl: 'https://images.feel-good.io/kpass_cards/card_right_6',
            rightCardImgUrl: 'https://images.feel-good.io/kpass_cards/card2_right_6.png',
            hashTags:['# 직관적','# 창의적','# 예술활동'],
            studyStrategy: '미션위주, 시각, 체험, 참여형 학습',
            recommendJob: '프로듀서, 예술가, 창작자, 광고 크리에이티브, 게임 디자이너, 콘텐츠 크리에이터, 소셜 미디어 인플루언서',
            guideRightImg: 'https://images.feel-good.io/guide/characters/right_6.png',
        },
        'NW-NS-NS': {
            subtitle: '은유하는 철학가이자',
            title: '창의적인 전략가',
            leftCardImgUrl: 'https://images.feel-good.io/kpass_cards/card_right_7',
            rightCardImgUrl: 'https://images.feel-good.io/kpass_cards/card2_right_7.png',
            hashTags:['# 체계적','# 전략적 사고','# 시각적 학습'],
            studyStrategy: '미션위주, 시각, 체험, 참여형 학습',
            recommendJob: '스타트업 창업자, 크리에이티브 디렉터, 아트 디렉터, UI/UX 디자이너, 이벤트 기획자, 마케팅 전문가, 제품 개발 관리자, 아키텍처&인테리어 디자이너, 기획 연출 감독&작가',
            guideRightImg: 'https://images.feel-good.io/guide/characters/right_7.png',
        },
        'NW-NS-N': {
            subtitle: '은유하는 철학가이자',
            title: '창의적인 전략가',
            leftCardImgUrl: 'https://images.feel-good.io/kpass_cards/card_right_8',
            rightCardImgUrl: 'https://images.feel-good.io/kpass_cards/card2_right_8.png',
            hashTags:['# 체계적','# 전략적 사고','# 시각적 학습'],
            studyStrategy: '미션위주, 시각, 체험, 참여형 학습',
            recommendJob: '스타트업 창업자, 크리에이티브 디렉터, 아트 디렉터, UI/UX 디자이너, 이벤트 기획자, 마케팅 전문가, 제품 개발 관리자, 아키텍처&인테리어 디자이너, 기획 연출 감독&작가',
            guideRightImg: 'https://images.feel-good.io/guide/characters/right_8.png',
        },
        'NW-NS-NW': {
            subtitle: '자유로운',
            title: '감각 예술가',
            leftCardImgUrl: 'https://images.feel-good.io/kpass_cards/card_right_9',
            rightCardImgUrl: 'https://images.feel-good.io/kpass_cards/card2_right_9.png',
            hashTags:['# 즉흥적','# 창의적','# 직관적'],
            studyStrategy: '미션위주, 시각, 체험, 참여형 학습',
            recommendJob: '프로듀서, 예술가, 창작자, 광고 크리에이티브, 게임 디자이너, 콘텐츠 크리에이터, 소셜 미디어 인플루언서',
            guideRightImg: 'https://images.feel-good.io/guide/characters/right_9.png',
        }
    };

    const convertBrainType = (brainType: BrainType) => {
        switch (brainType) {
            case BrainType.BALANCED:
                return t('cover.balance');
            case BrainType.SIMULTANEOUS:
                return t('cover.simultaneous');
            case BrainType.SUCCESSIVE:
                return t('cover.successive');

            // 디폴트 수정필요
            default:
                return '좌우밸런스형';
        }
    }

    const convertBrainTypeEn = (brainType: BrainType) => {
        switch (brainType) {
            case BrainType.BALANCED:
                return 'left';
            case BrainType.SIMULTANEOUS:
                return 'right';
            case BrainType.SUCCESSIVE:
                return 'balance';
        }
    }

    return {
        contentMap, convertBrainType, convertBrainTypeEn
    }
}