import { MainApi } from "./MainApi";
import {
    CollectVoucherRequestParam,
    GrantOrganManagerVoucherRequestParam,
    GrantVoucherRequestParam,
    OrganManagerVoucherSearchCondition,
    OrganManagerVoucherStatusDTO,
    OrganVoucherHistoryDTO,
    OrganVoucherHistorySetListDTO,
    ProductVoucherDTO,
    ProfileVoucherHistorySearchCondition,
    VoucherHistorySearchCondition, VoucherStatusInfo,
} from "../types/voucher";
import { PageResponse } from "../types/page";

export class VoucherApi {
    static baseUrl = `${MainApi.urlPrefix}/voucher`;

    static getCurrentStatus = (userIdx: number) => () =>
        MainApi.api.get(`${VoucherApi.baseUrl}/current-status/${userIdx}`);

    static getUserOrganVoucherCurrentStatus = (idx: number) => () =>
        MainApi.api.get(`${VoucherApi.baseUrl}/organ-voucher/current-status/${idx}/user`);

    static getProfileOrganVoucherCurrentStatus = (idx: number) => () =>
        MainApi.api.get(`${VoucherApi.baseUrl}/organ-voucher/current-status/${idx}/user-profile`);

    static getVoucherHistoryList = (condition: VoucherHistorySearchCondition) => () =>
        MainApi.api.get(
            `${VoucherApi.baseUrl}/${condition.userIdx}${MainApi.toParamStringFromObject({
                usable: condition.usable ?? undefined,
                pageCondition: condition.pageCondition,
            })}`
        );

    static getOrganVoucherHistoryList = (condition: VoucherHistorySearchCondition) => () =>
        MainApi.api.get(
            `${VoucherApi.baseUrl}/organ-voucher/${condition.userIdx}/user${MainApi.toParamStringFromObject({
                usable: condition.usable ?? undefined,
                pageCondition: condition.pageCondition,
            })}`
        );

    static getProfileOrganVoucherHistoryList = (condition: ProfileVoucherHistorySearchCondition) => () =>
        MainApi.api.get(
            `${VoucherApi.baseUrl}/organ-voucher/${condition.profileIdx}/user-profile${MainApi.toParamStringFromObject({
                usable: condition.usable ?? undefined,
                pageCondition: condition.pageCondition,
            })}`
        );

    static grantVoucher = (param: GrantVoucherRequestParam) => () =>
        MainApi.api.post(`${VoucherApi.baseUrl}/grant`, param);

    static grantOrganManagerVoucher = (param: GrantOrganManagerVoucherRequestParam) => () =>
        MainApi.api.post(`${VoucherApi.baseUrl}/grant/organ-member`, param);

    static collectVoucher = (voucherIdx: number, param: CollectVoucherRequestParam) => () =>
        MainApi.api.delete(`${VoucherApi.baseUrl}/${voucherIdx}/collect`, {
            data: param,
        });

    static getVoucherDetail = (idx: number) => () => MainApi.api.get(`${VoucherApi.baseUrl}/detail/${idx}`);

    static getVouchersByOrder = (orderIdx: number) => () =>
        MainApi.api.get<ProductVoucherDTO[]>(`${VoucherApi.baseUrl}?order_idx=${orderIdx}`);

    static getOrganManagerVoucherStatus = (organManagerIdx: number) => () =>
        MainApi.api.get<OrganManagerVoucherStatusDTO>(`${VoucherApi.baseUrl}/organ-managers/${organManagerIdx}/status`);

    static getOrganManagerVouchers = (organManagerIdx: number, condition: OrganManagerVoucherSearchCondition) => () =>
        MainApi.api.get<PageResponse<OrganVoucherHistorySetListDTO>>(
            `${VoucherApi.baseUrl}/organ-managers/${organManagerIdx}/search${MainApi.toParamStringFromObject(condition)}`
        );

    static getVoucherHistories = (voucherHistorySetIdx: number) => () =>
        MainApi.api.get<OrganVoucherHistoryDTO[]>(`${VoucherApi.baseUrl}/history-set/${voucherHistorySetIdx}/histories`);

    static collectOrganVoucher = (productVoucherIdx: number, organManagerIdx: number) => () =>
        MainApi.api.delete(`${VoucherApi.baseUrl}/${productVoucherIdx}/collect/organ-managers/${organManagerIdx}/collect`);

    // 일반회원 - 쿠폰/이용권 - 상담이용권 현황
    static getCounselorVoucherStatus =(idx:number) => () =>
        MainApi.api.get<VoucherStatusInfo>(`${VoucherApi.baseUrl}/consultant/current-status/${idx}`);

    // 일반회원 - 쿠폰/이용권 - 상담이용권 목록
    static getCounselorVoucherHistory =(condition: VoucherHistorySearchCondition) => () =>
        MainApi.api.get(
            `${VoucherApi.baseUrl}/consultant/${condition.userIdx}/user${MainApi.toParamStringFromObject({
                usable: condition.usable ?? undefined,
                pageCondition: condition.pageCondition,
            })}`
        );

    // 일반회원 - 피검사자 - 상담 목록
    static getTesterCounselorVoucher =(condition :ProfileVoucherHistorySearchCondition) => () =>
        MainApi.api.get(`${VoucherApi.baseUrl}/consultant/${condition.profileIdx}/tester${MainApi.toParamStringFromObject({
                usable: condition.usable ?? undefined,
                pageCondition: condition.pageCondition,
                profileIdx: condition.profileIdx
            })}`
        );
}