import {useAppDispatch} from "../../../../../hooks/hooks";
import {useMemo, useState} from "react";
import {ordersToSortArr, PageResponse, PageSearchCondition} from "../../../../../types/page";
import {AdminTesterCounselingHistoryDTO, ProfileVoucherHistorySearchCondition} from "../../../../../types/voucher";
import {getTesterCounselorVoucherAsync} from "../../../../../store/voucherSlice";

const LIST_SIZE = 5;

export function useTesterCounselorGetter() {
    const dispatch = useAppDispatch();
    const [totalCounseling, setTotalCounseling] = useState(0)
    const [testerCounselingList, setTesterCounselingList] =
        useState<PageResponse<AdminTesterCounselingHistoryDTO>>();

    const pageCondition = useMemo(
        () => ({
            page: testerCounselingList?.number ?? 0,
            size: LIST_SIZE,
            sort: ordersToSortArr(testerCounselingList?.orders),
        }),
        [testerCounselingList]
    );

    const getCounselorVoucherHistory = (
        {page, sort, usable, profileIdx}:
            PageSearchCondition & { usable?: boolean, profileIdx: number }) => {
        const param: ProfileVoucherHistorySearchCondition = {
            pageCondition: {
                page: page ?? 0,
                size: LIST_SIZE,
                sort: sort ?? pageCondition.sort,
            },
            profileIdx: profileIdx,
            usable: usable,
        };

        return () => {
            dispatch(getTesterCounselorVoucherAsync(param))
                .unwrap()
                .then((result: PageResponse<AdminTesterCounselingHistoryDTO>) => {
                    setTesterCounselingList(result);
                    setTotalCounseling(result.totalElements);
                })
                .catch(error => {
                    console.error('Voucher history request failed', error);
                    alert('일시적인 오류가 발생했습니다.\n 다시 시도해주세요');
                });
        }
    }

    return {
        getCounselorVoucherHistory, testerCounselingList, pageCondition, totalCounseling,
    }
}