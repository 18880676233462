import style from "../../style/test_result/testResult.module.scss";
import {TestSummaryHeader} from "../common/TestSummaryHeader";
import {ResultSummaryContent} from "../common/ResultSummaryContent";
import {TestResultPath, TestResultReportDTO} from "../../../types/testResult";
import React, {useEffect, useRef, useState} from "react";
import {Trans, useTranslation} from "react-i18next";
import {useLanguage} from "../../../hooks/hooks";

interface Args {
    testResult?: TestResultReportDTO
}

/* eslint-disable */
export function KpassTestResultTotalScale({testResult}: Args) {
    /**
     * 검사 결과 중 01 전체 척도
     */
    const {t} = useTranslation("totalScale");
    const {isKorean} = useLanguage();
    const [summaryHide, setSummaryHide] = useState(true);
    const toggleContent = () => {
        if (setSummaryHide) {
            setSummaryHide(!summaryHide)
        }
    };

    const contentRef = useRef<HTMLDivElement>(null);
    const [height, setHeight] = useState('0px');

    useEffect(() => {
        const content = contentRef.current;

        if (content) {
            if (summaryHide) {
                setHeight('0px');
            } else {
                setHeight(`${content.scrollHeight + 20}px`);
            }
        }
    }, [summaryHide, testResult]);
    return (
        <>
            <article className={style.summaryWrapper}>

                {/*  01 전체척도  {testResult?.testResultUserProfileVO.name}의 전반적인 지능의 ~~ 어느정도인가?  */}
                <TestSummaryHeader testResultPath={TestResultPath.TOTAL_SCALE}
                                   testerName={testResult?.testResultUserProfileVO.name}/>

                <div className={style.underline}></div>

                {/*  전체척도 표준점수 표  */}
                <table className={`${style.table} ${style.fullScaleSummary}`}>
                    <thead>
                    <tr>
                        <th>
                            <span>
                                {/*표준점수*/}
                                {t('table.standardScore')}
                            </span>
                            <br/>
                            <span className={style.light}>(Mean=100, SD=15)</span>
                        </th>
                        <th>
                            {/*95% 신뢰구간*/}
                            {t('table.confidenceInterval')}
                        </th>
                        <th>
                            {/*백분위*/}
                            {t('table.percentile')}
                        </th>
                        <th>
                            {/*서술적 범주*/}
                            {t('table.descriptiveCategory')}
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td className={style.tdStyle}>
                            <span className={style.red}> {testResult?.testResultVO.score}</span>
                        </td>
                        <td>
                            <span className={style.red}>
                                {testResult?.testResultVO.trustMin} ~ {testResult?.testResultVO.trustMax}
                            </span>
                        </td>
                        <td>
                            <span className={style.red}>{testResult?.testResultVO.percent}</span>
                        </td>
                        <td>
                            <span className={style.red}>{testResult?.testResultComputedDataVO.a1}</span>
                        </td>
                    </tr>
                    </tbody>
                </table>

                {/*  측정결과 해설 : 순차적 처리는 문제해결을~~~  */}
                <p className={style.paragraph}>
                    <Trans t={t} i18nKey="totalScale.mainContent.tableParagraph"
                           values={{
                               name: testResult?.testResultUserProfileVO.name,
                               score: testResult?.testResultVO.score,
                               trustMin: testResult?.testResultVO.trustMin,
                               trustMax: testResult?.testResultVO.trustMax,
                               percent: testResult?.testResultVO.percent,
                               blank: '\u00A0'
                           }}
                           components={{
                               strong: <span className={style.strong}/>,
                               blue: <span className={style.blue}/>,
                           }}
                    />
                </p>

                {/*  측정결과 요약 & 풀이 K-PASS를 통해 ~~의 ~~처리 능력을 측정한 결과~~  */}
                {/*todo*/}
                {isKorean && (
                    <div className={`${style.summaryAndExplanation} ${style.noPrint}`}>
                        <button
                            className={style.summaryAndExplanationHeader}
                            onClick={toggleContent}
                        >
                            <h4 className={style.title}>{t('summary')}</h4>
                            <span className={`${style.spreadButton} ${!summaryHide ? '' : style.hide}`}>
                        <span>{t('fold')}</span>
                        <img src="https://images.feel-good.io/report/minus_icon.svg" alt="접기"/>
                    </span>
                            <span className={`${style.spreadButton} ${!summaryHide ? style.hide : ''}`}>
                        <span>{t('open')}</span>
                        <img src="https://images.feel-good.io/report/plus_icon.svg" alt="자세히보기"/>
                    </span>
                        </button>
                        <div style={{height: height}}
                             className={`${style.contentParagraphWrapper} ${summaryHide ? style.hide : ''}`}>
                            <p className={`${style.contentParagraph}`} ref={contentRef}>

                                <Trans t={t} i18nKey="totalScale.mainContent.totalParagraph"
                                       values={{
                                           name: testResult?.testResultUserProfileVO.name,
                                           score: testResult?.testResultVO.score,
                                           percent: testResult?.testResultVO.percent,
                                           blank: '\u00A0'
                                       }}
                                       components={{
                                           strong: <span className={style.strong}/>,
                                           blue: <span className={style.blue}/>,
                                       }}
                                />
                            </p>
                        </div>
                    </div>
                )}
            </article>

            {/*  케이스에 따른 타당성 결과 처리  */}
            {testResult && (
                <ResultSummaryContent testResult={testResult}/>
            )}
        </>
    );
}