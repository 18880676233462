import {HighGraph} from "./HighGraph";
import style from "../../style/test_result/testResult.module.scss";
import {TestSummaryHeader} from "../common/TestSummaryHeader";
import {TestResultPath, TestResultReportDTO, TestTypes} from "../../../types/testResult";
import React, {useEffect, useRef, useState} from "react";
import {useGraphFunctions} from "./hooks/useGraphFunctions";
import {ScoreExposureCase} from "./ScoreExposureCase";
import {Trans, useTranslation} from "react-i18next";

interface Args {
    testResult?: TestResultReportDTO
}

/* eslint-disable */
export function KpassTestResultPersonal({testResult}: Args) {
    /**
     * 검사 결과 중 04 개인 내적 강/약 분석
     */
    const {t} = useTranslation("personal");

    const ref = useRef(null);
    const defaultSuccessive = testResult?.testResultVO.successiveScore.score;
    const defaultSimultaneous = testResult?.testResultVO.simultaneousScore.score;
    const defaultPlanning = testResult?.testResultVO.planningScore.score;
    const defaultAttention = testResult?.testResultVO.attentionScore.score;

    const [successivePointer, setSuccessivePointer] = useState(0);
    const [simultaneousPointer, setSimultaneousPointer] = useState(0);
    const [planningPointer, setPlanningPointer] = useState(0);
    const [attentionPointer, setAttentionPointer] = useState(0);
    const [averagePointer, setAveragePointer] = useState(0);

    const {drawLine, drawCircle, drawAverageLine, adjustValues, calculateScores} = useGraphFunctions()

    const canvasRef = useRef<HTMLCanvasElement>(null);
    const result = adjustValues(successivePointer, simultaneousPointer, planningPointer, attentionPointer);
    const avgRef = useRef<HTMLDivElement>(null);

    const [avgWidth, setAvgWidth] = useState(0);
    const [avgLeft, setAvgLeft] = useState(0);
    const name = testResult?.testResultUserProfileVO.name;
    const colors = {
        successive: '#08012C',
        simultaneous: '#352388',
        attention: '#462FB5',
        planning: '#7762DF',
        average: '#E60063',
    };

    // 그래프 배치를 위한 변수 초기화
    useEffect(() => {
        if (defaultSuccessive && defaultSimultaneous && defaultPlanning && defaultAttention) {
            setSuccessivePointer(calculateScores(defaultSuccessive))
            setSimultaneousPointer(calculateScores(defaultSimultaneous))
            setPlanningPointer(calculateScores(defaultPlanning))
            setAttentionPointer(calculateScores(defaultAttention))
            setAveragePointer(
                Math.ceil((defaultSuccessive + defaultSimultaneous + defaultPlanning + defaultAttention) / 4)
            )
        }
    }, []);

    useEffect(() => {
        if (successivePointer !== 0) {
            setSuccessivePointer(result.firstValue);
            setSimultaneousPointer(result.secondValue);
            setPlanningPointer(result.thirdValue);
            setAttentionPointer(result.fourthValue)

            // 선, x축에 점 처리
            if (defaultSuccessive && defaultSimultaneous && defaultPlanning && defaultAttention &&
                result.firstValue === successivePointer) {

                // 그래프에 선 그리기 위한 캔버스 세팅
                const canvas = canvasRef.current;
                if (canvas) {
                    const scale = window.devicePixelRatio;
                    canvas.width = canvas.clientWidth * scale;
                    canvas.height = canvas.clientHeight * scale;

                    const context = canvas.getContext('2d');
                    if (context) {
                        context.scale(scale, scale); // 스케일링 설정
                    }
                }

                const defaultAverage = Math.ceil((
                    calculateScores(defaultSuccessive) +
                    calculateScores(defaultSimultaneous) +
                    calculateScores(defaultPlanning) +
                    calculateScores(defaultAttention)) / 4)

                // 각 처리 검사 결과에 대한 직선
                drawLine(calculateScores(defaultSuccessive), result.firstValue, colors.successive, canvasRef);
                drawLine(calculateScores(defaultSimultaneous), result.secondValue, colors.simultaneous, canvasRef);
                drawLine(calculateScores(defaultPlanning), result.thirdValue, colors.planning, canvasRef);
                drawLine(calculateScores(defaultAttention), result.fourthValue, colors.attention, canvasRef);

                // 각 처리 검사 결과에 대한 x축 점
                drawCircle(calculateScores(defaultSuccessive), colors.successive, canvasRef);
                drawCircle(calculateScores(defaultSimultaneous), colors.simultaneous, canvasRef);
                drawCircle(calculateScores(defaultPlanning), colors.planning, canvasRef);
                drawCircle(calculateScores(defaultAttention), colors.attention, canvasRef);

                // 평균값에 대한 직선
                drawAverageLine(defaultAverage, canvasRef, avgRef)

                drawCircle(defaultAverage, colors.average, canvasRef)

                setAvgWidth(
                    Math.max(calculateScores(defaultSuccessive), calculateScores(defaultSimultaneous), calculateScores(defaultPlanning), calculateScores(defaultAttention)) -
                    Math.min(calculateScores(defaultSuccessive), calculateScores(defaultSimultaneous), calculateScores(defaultPlanning), calculateScores(defaultAttention)
                    ));
                setAvgLeft(
                    Math.min(calculateScores(defaultSuccessive), calculateScores(defaultSimultaneous), calculateScores(defaultPlanning), calculateScores(defaultAttention))
                    + 43
                )
            }

        }
    }, [drawLine]);


    return (
        <>
            <article className={style.summaryWrapper}>
                <TestSummaryHeader testResultPath={TestResultPath.PERSONAL_STRENGTH_AND_WEAKNESS}
                                   testerName={testResult?.testResultUserProfileVO.name}/>

                <div className={style.underline}></div>
            </article>

            {/*  개인 내적 강약 판적 표  */}
            <article className={style.mainWrapper}>
                <table className={`${style.table} ${style.individualAnalysis}`}>
                    <thead>
                    <tr>
                        <th colSpan={2}>
                            <Trans t={t} i18nKey="table.lowLevelStandardScore"
                                   components={{
                                       br: <br/>
                                   }}
                            />
                        </th>
                        <th>{t('table.average')}</th>
                        <th>
                            <Trans t={t} i18nKey="table.differenceBetweenStandardAndAverage"
                                   components={{
                                       br: <br/>
                                   }}/>
                        </th>
                        <th>
                            <Trans t={t} i18nKey="table.statisticalTest"
                                   components={{
                                       br: <br/>,
                                       light: <span className={style.light}/>
                                   }}/>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <th>{t('table.successive')}</th>
                        <td><span className={style.red}>{testResult?.testResultVO.successiveScore.score}</span></td>
                        <td rowSpan={4}><span
                            className={style.red}>{testResult?.testResultComputedDataVO.average}</span></td>
                        <td><span
                            className={style.red}>{testResult?.testResultComputedDataVO.differenceAvgOfSuccessiveScore}</span>
                        </td>
                        <td><span className={style.red}>{testResult?.testResultComputedDataVO.psOrPwOfSuccessive}</span>
                        </td>
                    </tr>
                    <tr>
                        <th>{t('table.simultaneous')}</th>
                        <td><span className={style.red}>{testResult?.testResultVO.simultaneousScore.score}</span></td>
                        <td><span
                            className={style.red}>{testResult?.testResultComputedDataVO.differenceAvgOfSimultaneousScore}</span>
                        </td>
                        <td><span
                            className={style.red}>{testResult?.testResultComputedDataVO.psOrPwOfSimultaneous}</span>
                        </td>
                    </tr>
                    <tr>
                        <th>{t('table.planning')}</th>
                        <td><span className={style.red}>{testResult?.testResultVO.planningScore.score}</span></td>
                        <td><span
                            className={style.red}>{testResult?.testResultComputedDataVO.differenceAvgOfPlanningScore}</span>
                        </td>
                        <td><span className={style.red}>{testResult?.testResultComputedDataVO.psOrPwOfPlanning}</span>
                        </td>
                    </tr>
                    <tr>
                        <th>{t('table.attention')}</th>
                        <td><span className={style.red}>{testResult?.testResultVO.attentionScore.score}</span></td>
                        <td><span
                            className={style.red}>{testResult?.testResultComputedDataVO.differenceAvgOfAttentionScore}</span>
                        </td>
                        <td><span className={style.red}>{testResult?.testResultComputedDataVO.psOrPwOfAttention}</span>
                        </td>
                    </tr>
                    </tbody>
                </table>


                {/*  평균과 비교 그래프  */}
                <div className={style.individualAnalysisCanvasWrapper} id="individual-analysis-canvas-wrapper">
                    <div className={style.line}></div>
                    <div className={style.individualAnalysisPoints}>
                        <div className={style.point} style={{left: planningPointer}} ref={ref}>
                            <label className={style.label}>{t('personal.planning')}</label>
                            <span className={style.value}
                                  style={{backgroundColor: colors.planning}}>{testResult?.testResultVO.planningScore.score}</span>
                        </div>
                        <div className={style.point} style={{left: attentionPointer}}>
                            <label className={style.label}>{t('personal.attention')}</label>
                            <span className={style.value}
                                  style={{backgroundColor: colors.attention}}>{testResult?.testResultVO.attentionScore.score}</span>
                        </div>
                        <div className={style.point} style={{left: simultaneousPointer}}>
                            <label className={style.label}>
                                <Trans t={t} i18nKey="personal.simultaneous"
                                       components={{
                                           br: <br/>
                                       }}
                                />
                            </label>
                            <span className={style.value}
                                  style={{backgroundColor: colors.simultaneous}}>{testResult?.testResultVO.simultaneousScore.score}</span>
                        </div>
                        <div className={style.point} style={{left: successivePointer}}>
                            <label className={style.label}>
                                <Trans t={t} i18nKey="personal.successive"
                                       components={{
                                           br: <br/>
                                       }}
                                />
                            </label>
                            <span className={style.value}
                                  style={{backgroundColor: colors.successive}}>{testResult?.testResultVO.successiveScore.score}</span>
                        </div>
                    </div>
                    <canvas className={style.individualAnalysisCanvas} ref={canvasRef}>
                    </canvas>
                    <div className={style.individualAnalysisAvg} style={{width: avgWidth, left: avgLeft}} ref={avgRef}>
                        <label className={style.label}>{t('table.average')}</label>
                        <span className={style.avgPoint}>{averagePointer}</span>
                    </div>
                </div>

                {testResult && (
                    <>
                        <ScoreExposureCase exposureDTO={{
                            idx: 1,
                            type: t('table.successiveAbility'),
                            testType: TestTypes.SUCCESSIVE,
                            pureScore: testResult?.testResultVO.successiveScore.score,
                            averageScore: testResult?.testResultComputedDataVO.average,
                            scoreDifference: testResult?.testResultComputedDataVO.differenceAvgOfSuccessiveScore,
                            minimumDifference: testResult?.minimumScoreDifferenceVO.successiveMinDifference,
                        }} name={name}
                        />

                        <ScoreExposureCase exposureDTO={{
                            idx: 2,
                            type: t('table.simultaneousAbility'),
                            testType: TestTypes.SIMULTANEOUS,
                            pureScore: testResult?.testResultVO.simultaneousScore.score,
                            averageScore: testResult?.testResultComputedDataVO.average,
                            scoreDifference: testResult?.testResultComputedDataVO.differenceAvgOfSimultaneousScore,
                            minimumDifference: testResult?.minimumScoreDifferenceVO.simultaneousMinDifference,
                        }} name={name}
                        />

                        <ScoreExposureCase exposureDTO={{
                            idx: 3,
                            type: t('table.planningAbility'),
                            testType: TestTypes.PLANNING,
                            pureScore: testResult?.testResultVO.planningScore.score,
                            averageScore: testResult?.testResultComputedDataVO.average,
                            scoreDifference: testResult?.testResultComputedDataVO.differenceAvgOfPlanningScore,
                            minimumDifference: testResult?.minimumScoreDifferenceVO.planningMinDifference,
                        }} name={name}
                        />

                        <ScoreExposureCase exposureDTO={{
                            idx: 4,
                            type: t('table.attentionAbility'),
                            testType: TestTypes.ATTENTION,
                            pureScore: testResult?.testResultVO.attentionScore.score,
                            averageScore: testResult?.testResultComputedDataVO.average,
                            scoreDifference: testResult?.testResultComputedDataVO.differenceAvgOfAttentionScore,
                            minimumDifference: testResult?.minimumScoreDifferenceVO.attentionMinDifference,
                        }} name={name}
                        />
                    </>
                )}
            </article>

            {testResult && (
                <HighGraph testResult={testResult}/>
            )}
        </>
    );
}