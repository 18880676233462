import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {Panel} from "../../../layout/content/detail/Panel";
import {DescriptionList} from "../../../layout/content/detail/DescriptionList";
import {useGroupInfoDetailUtil} from "../info/hooks/useGroupInfoDetailUtil";
import {DetailActionButton} from "../../../components/Buttons";
import confirmIcon from "../../../assets/images/button_icons/confirm_icon.svg";
import editIcon from "../../../assets/images/button_icons/person_icon.svg";
import deleteIcon from "../../../assets/images/button_icons/delete_icon.svg";
import {Table} from "../../../layout/content/list/table/Table";
import {PageResponse} from "../../../types/page";
import {ConsultantDTO, CounselorGroupPutDTO, CounselorPosition} from "../../../types/counselor";
import {useCounselorManagerColumns} from "../info/hooks/useCounselorManagerColumns";
import {PaginationWrapper} from "../../../layout/content/list/PaginationWrapper";
import style from "../../member/user/memberUserDetail.module.scss";
import {RoleChecker} from "../../role/RoleChecker";
import {AdminMenuCode} from "../../../types/adminRole";
import {Pagination} from "../../../layout/content/list/Pagination";
import {useCounselorDetailGetter} from "./hooks/useCounselorDetailGetter";
import {useParams} from "react-router-dom";
import {useCounselorDetailFunctions} from "./hooks/useCounselorDetailFunctions";
import {isAdmin} from "../../../util/etcUtil";

export function GroupInfoDetail() {
    // 상담소속 정보 파라미터 취득
    const {id} = useParams<'id'>() as { id: string };

    // 소속 정보변경
    const [isEditMode, setIsEditMode] = useState(false);

    // 관리자 변경
    const [isManagerChangeMode, setIsManagerChangeMode] = useState(false);
    const [groupInfoPutParam, setGroupInfoPutParam] = useState<CounselorGroupPutDTO | null>(null);
    const formRef = useRef<HTMLFormElement>(null);

    // 상담 소속 개별 정보 조회
    const {
        getCounselorGroupDetail, groupDetail, getAffiliationConsultants, getAffiliationProfile,
        affiliationConsultants} = useCounselorDetailGetter(id);

    // 상담 소속, 관리자 변경
    const {onClickAffiliationUpdateButton, onClickManagerUpdateButton, onClickAffiliationDeleteButton}
        = useCounselorDetailFunctions();
    const {getOrganGroupInfo, getOrganManagerInfo, resetData} =
        useGroupInfoDetailUtil({groupInfoPutParam: setGroupInfoPutParam});



    const {t} = useTranslation("counselorInfo");
    const [selectedId, setSelectedId] = useState(0);
    const [consultants, setConsultants] = useState<PageResponse<ConsultantDTO>>()
    const [consultantManager, setConsultantManager] = useState<ConsultantDTO>()
    const isAffiliationEditable = id && groupInfoPutParam
    const isAffiliationManagerChangeable = id && selectedId

    useEffect(() => {
        getAffiliationConsultants()
        getCounselorGroupDetail().then();
        //eslint-disable-next-line
    }, [id, isEditMode]);

    useEffect(() => {
        if (!affiliationConsultants) return;

        setConsultants(affiliationConsultants);

        const manager = affiliationConsultants.content.find(
            data => data.consultantRank === CounselorPosition.CONSULTANT_MANAGER
        );

        if (manager) {
            setConsultantManager(manager);
        }
    }, [affiliationConsultants]);

    const {columns} = useCounselorManagerColumns({selectedId, setSelectedId});

    return (
        <>
            {/*  상담 소속정보 (/detail/counselor_group/1)  */}
            <Panel title={t('menus.groupInfo')}
                   additionalButtons={isAdmin ? (
                       isEditMode ? (
                           <>
                               <DetailActionButton
                                   onClick={() => {
                                       setIsEditMode(false);
                                       resetData();
                                   }}
                               >
                                   {t("button.cancel")}
                               </DetailActionButton>
                               <DetailActionButton
                                   onClick={() => isAffiliationEditable && onClickAffiliationUpdateButton(id, groupInfoPutParam)}>
                                   <img src={confirmIcon} alt={'confirm'}/>
                                   {t("button.save")}
                               </DetailActionButton>
                           </>
                       ) : (
                           <DetailActionButton onClick={() => setIsEditMode(true)}>
                               <img src={editIcon} alt={'edit'}/>
                               {t('button.edit')}
                           </DetailActionButton>
                       )
                       ):<></>}
            >
                <form ref={formRef} onSubmit={(e) => e.preventDefault()}>
                    <DescriptionList
                        data={getOrganGroupInfo(groupDetail)}
                        isEditMode={isEditMode}
                        labelWidth={132}
                    />
                </form>
            </Panel>

            {/*  상담 소속관리자 (/detail/counselor_group/1)  */}
            <Panel title={t('manager.groupManagerTitle')}
                   additionalButtons={isAdmin ? (
                       isManagerChangeMode ? (
                           <>
                               <DetailActionButton
                                   onClick={() => {
                                       setIsManagerChangeMode(false);
                                       // resetData();
                                   }}
                               >
                                   {t("button.cancel")}
                               </DetailActionButton>
                               <DetailActionButton
                                   onClick={() => isAffiliationManagerChangeable &&
                                       onClickManagerUpdateButton({idx :id, managerIdx: selectedId.toString()})}>
                                   <img src={confirmIcon} alt={'confirm'}/>
                                   {t("button.save")}
                               </DetailActionButton>
                           </>
                       ) : (
                           <DetailActionButton onClick={() => setIsManagerChangeMode(true)}>
                               <img src={editIcon} alt={'edit'}/>
                               {t('button.edit')}
                           </DetailActionButton>
                       )
                   ):<></>}
            >
                <form ref={formRef} onSubmit={(e) => e.preventDefault()}>
                    {!isManagerChangeMode && consultantManager ? (
                        <DescriptionList
                            data={getOrganManagerInfo(consultantManager)}
                            isEditMode={isEditMode}
                            labelWidth={132}
                        />
                    ) : <>
                        {consultants && id && (
                            <>
                                <Table
                                    listData={consultants as
                                        PageResponse<ConsultantDTO>}
                                    columns={columns}
                                    getList={getAffiliationProfile}
                                    noDataText={'affiliation'}
                                />

                                <PaginationWrapper>
                                    <Pagination page={consultants} goSpecificPage={(pageNum: number) =>
                                        getAffiliationProfile({page: pageNum})()}/>
                                </PaginationWrapper>
                            </>
                        )}
                    </>
                    }
                </form>
            </Panel>

            {/*  상담 소속 삭제 버튼  */}
            {isAdmin && (
                <div className={style.buttonArea}>
                    <RoleChecker menuCode={AdminMenuCode.USER_DETAIL_BLOCK}>
                        <button className={style.blockBtn}
                                onClick={() => id &&onClickAffiliationDeleteButton(id)}>
                            <img src={deleteIcon} alt={'block'}/>
                            {t("button.counselorDelete")}
                        </button>
                    </RoleChecker>
                </div>
            )}
        </>
    )
}