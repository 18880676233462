enum AdminRoleCategory {
  ADMIN_ROOT = "ADMIN_ROOT",
  ADMIN_GENERAL = "ADMIN_GENERAL",
  ADMIN_MANAGER = "ADMIN_MANAGER",
  SPECIAL_ADMIN = "SPECIAL_ADMIN",
  CONSULTANT_MNG = "CONSULTANT_MNG",
  CONSULTANT_GENERAL = "CONSULTANT_GENERAL",
  CONSULTANT_MANAGER = "CONSULTANT_MANAGER",
  CONSULTANT = "CONSULTANT",
}

enum AdminMenuCode {
  ADMIN = "ADMIN",
  ADMIN_ROLE = "ADMIN_ROLE",
  PAYMENT = "PAYMENT",
  PRODUCT = "PRODUCT",
  COUPON = "COUPON",
  USER = "USER",
  ORGAN_MANAGER = "ORGAN_MANAGER",
  CONSULTANT = "CONSULTANT",
  CONSULTANT_MANAGER = "CONSULTANT_MANAGER",
  EXPERT = "EXPERT",
  KPASS = "KPASS",
  CONSULTING = "CONSULTING",
  USER_MEMO = "USER_MEMO",
  REVERT_BLOCKED_USER = "REVERT_BLOCKED_USER",
  ISSUE_COUPON = "ISSUE_COUPON",
  USER_DETAIL_WITHDRAW_CONSENT = "USER_DETAIL_WITHDRAW_CONSENT",
  USER_DETAIL_UPDATE = "USER_DETAIL_UPDATE",
  USER_DETAIL_BLOCK = "USER_DETAIL_BLOCK",
  USER_DETAIL_ORGAN_READ = "USER_DETAIL_ORGAN_READ",
  USER_DETAIL_ORGAN_CODE_READ = "USER_DETAIL_ORGAN_CODE_READ",
  USER_DETAIL_USER_CODE_READ = "USER_DETAIL_USER_CODE_READ",
  USER_DETAIL_ACCESS_LOG = "USER_DETAIL_ACCESS_LOG",
  USER_DETAIL_GRANT_PRODUCT = "USER_DETAIL_GRANT_PRODUCT",
  USER_DETAIL_PAYMENTS = "USER_DETAIL_PAYMENTS",
  USER_DETAIL_COUPON_VOUCHER = "USER_DETAIL_COUPON_VOUCHER",
  USER_DETAIL_TESTER_ORGAN_VOUCHER = "USER_DETAIL_TESTER_ORGAN_VOUCHER",
  USER_DETAIL_TESTER_CONSULTING_LOG = "USER_DETAIL_TESTER_CONSULTING_LOG",
  USER_DETAIL_TESTER_ORGAN_CODE_READ = "USER_DETAIL_TESTER_ORGAN_CODE_READ",
  USER_DETAIL_TESTER_USER_CODE_READ = "USER_DETAIL_TESTER_USER_CODE_READ",
  USER_DETAIL_REFUND_ACCOUNT_READ = "USER_DETAIL_REFUND_ACCOUNT_READ",
  COLLECT_GRANTED_PRODUCT = "COLLECT_GRANTED_PRODUCT",
  GRANT_PRODUCT = "GRANT_PRODUCT",
  BLOCK_ADMIN = "BLOCK_ADMIN",
  PROFILE_STATUS_READ = "PROFILE_STATUS_READ",
  PROFILE_CODE_READ = "PROFILE_CODE_READ",
  ADMIN_UPDATE_INFO = "ADMIN_UPDATE_INFO",
  ADMIN_ISSUE_TEMPORARY_PASSWORD = "ADMIN_ISSUE_TEMPORARY_PASSWORD",
  EDIT_MEMO = "EDIT_MEMO",
  TESTERS = "TESTERS",
  TEST_HISTORY_STATUS_READ = "TEST_HISTORY_STATUS_READ",
  CHANGE_NATIONALITY = "CHANGE_NATIONALITY",
  AFFILIATE_CODE = "AFFILIATE_CODE",
  CATALOG_POP_UP = "CATALOG_POP_UP",
  CATALOG_BANNER = "CATALOG_BANNER",
}

interface AdminRoleListObj {
  idx: number;
  category: AdminRoleCategory;
  name: string;
  isDeleted: boolean;
  createAt: string;
}

interface AdminRoleSummary {
  idx: number;
  category: AdminRoleCategory;
  name: string;
  isDeleted: boolean;
}

interface AdminMenu {
  code: AdminMenuCode;
  name: string;
  isDeleted: boolean;
}

interface AccessibleMenu {
  idx: number;
  menu: AdminMenu;
  isDeleted: boolean;
}

export { AdminRoleCategory, AdminMenuCode };
export type { AdminRoleSummary, AdminMenu, AccessibleMenu, AdminRoleListObj };
