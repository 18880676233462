import {useTranslation} from "react-i18next";
import style from "../style/registration.module.scss";
import React, {useEffect, useState} from "react";
import {CounselorCheckboxField} from "./CounselorCheckboxField";
import {CounselorShortInputField} from "./CounselorShortInputField";
import {AddedFile, FileType} from "../../../types/file";
import {CounselorImageField} from "./CounselorImageField";
import {CounselorLongInputField} from "./CounselorLongInputField";
import {
    CounselorCheckOptions,
    CounselorDuplicateType,
    CounselorManagerDTO,
    TaxSelectedValue
} from "../../../types/counselor";
import {ValidStatus} from "../../../types/common";
import {CounselorDuplicateCheckButtonFiled} from "./CounselorDuplicateCheckButtonFiled";
import {CounselorAffiliationSelector} from "./CounselorAffiliationSelector";

interface Args {
    title?: string
    counselorDTO?: CounselorManagerDTO;
    setCounselorDTO?: (_: CounselorManagerDTO) => void;

    idValidate: ValidStatus;
    setIdValidate: (_: ValidStatus) => void;
    cellPhoneValidate: ValidStatus;
    setCellPhoneValidate: (_: ValidStatus) => void;
    nameValidate: ValidStatus;
    setNameValidate: (_: ValidStatus) => void;
    passwordValidate: ValidStatus;
    setPasswordValidate: (_: ValidStatus) => void;
    passwordConfirmValidate: ValidStatus;
    setPasswordConfirmValidate: (_: ValidStatus) => void;
    affiliationIdxValidate?: ValidStatus;
    setAffiliationIdxValidate?: (_: ValidStatus) => void;
    consultantDistributionValidate?: ValidStatus;
    setConsultantDistributionValidate?: (_: ValidStatus) => void;
}

export function CounselorField(
    {
        title, counselorDTO, setCounselorDTO,
        idValidate, setIdValidate, cellPhoneValidate, setCellPhoneValidate, nameValidate, setNameValidate,
        passwordValidate, setPasswordValidate, passwordConfirmValidate, setPasswordConfirmValidate,
        affiliationIdxValidate, setAffiliationIdxValidate, consultantDistributionValidate, setConsultantDistributionValidate
    }: Args) {

    /**
     * 상담관리자 정보 영역
     */
    const {t} = useTranslation("counselorGroup");
    const [nationality, setNationality] = useState('KR');
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [codePhone, setCodePhone] = useState('+82');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [briefHistory, setBriefHistory] = useState('');
    const [bankName, setBankName] = useState('');
    const [accountNumber, setAccountNumber] = useState('');
    const [accountHolder, setAccountHolder] = useState('');
    const [affiliationDistributionAmount, setAffiliationDistributionAmount] = useState(0);
    const [consultantDistributionAmount, setConsultantDistributionAmount] = useState(0);
    const [thumbnail, setThumbnail] = useState<AddedFile>();
    // const [addedThumbnailImage, setAddedThumbnailImage] = useState<FileType>();
    const [counselorGroup, setCounselorGroup] = useState('');

    const [profileImage, setProfileImage] = useState<FileType | undefined>();

    const [isVatInclude, setIsVatInclude] = useState(TaxSelectedValue.UNSELECT)
    const [isTaxDeducted, setIsTaxDeducted] = useState(TaxSelectedValue.UNSELECT)


    // 소속 등록인지 상담사 등록인지 분리 (true인 경우 => 상담사 등록 / false => 상담소속 등록)
    const isCounselorRegistration = !title;

    useEffect(() => {
        // const newCounselorDTO:CounselorManagerDTO = {
        if (setCounselorDTO) {
            setCounselorDTO({
                nationality: nationality,
                email: email,
                name: name,
                codePhone: codePhone,
                phoneNumber: phoneNumber,
                password: password,
                passwordConfirm: passwordConfirm,
                addedImage: profileImage,
                briefHistory: briefHistory,
                bankName: bankName,
                counselingAffiliationIdx: counselorGroup,
                accountNumber: accountNumber === '' ? null : accountNumber,
                accountName: accountHolder === '' ? null : accountHolder,
                affiliationDistributionAmount: affiliationDistributionAmount,
                consultantDistributionAmount: consultantDistributionAmount,
                isVatInclude: isVatInclude === TaxSelectedValue.SELECT,
                isTaxDeduction: isTaxDeducted === TaxSelectedValue.SELECT
            });
        }
        //eslint-disable-next-line
    }, [nationality, email, name, codePhone, phoneNumber, password, passwordConfirm, briefHistory,
        bankName, accountNumber, accountHolder, affiliationDistributionAmount, consultantDistributionAmount,
        isVatInclude, isTaxDeducted, counselorGroup, profileImage]);

    return (
        <>
            {/*  조건부 제목 수정필요  */}
            {!isCounselorRegistration && (<span className={style.mainTitle}>{title}</span>
            )}

            {/*  국적 영역  */}
            <CounselorCheckboxField
                label={t("registration.counselor.nationality")}
                isRequired={true}
                placeHolder={t('registration.nationalityPlaceHolder')}
                checkboxOption={CounselorCheckOptions.COUNTRY}
                option={nationality}
                setOption={setNationality}
            >
            </CounselorCheckboxField>

            {/*  아이디  */}
            <CounselorDuplicateCheckButtonFiled
                duplicateType={CounselorDuplicateType.EMAIL}
                minLength={6} maxLength={60}
                label={t('registration.counselor.id')}
                placeHolder={t('registration.idPlaceHolder')}
                isLabelVisible={true}
                value={email} setValue={setEmail}
                validate={idValidate} setValidate={setIdValidate}
            />

            {/*  이름  */}
            <CounselorShortInputField
                label={t('registration.counselor.name')}
                placeHolder={t('registration.namePlaceHolder')}
                inputType={'text'}
                minLength={2} maxLength={50}
                isRequired={true} isLabelVisible={true}
                value={name} setValue={setName}
                validate={nameValidate} setValidate={setNameValidate}
            />

            {/*  휴대폰번호  */}
            <CounselorCheckboxField
                label={t('registration.counselor.codePhone')}
                isRequired={true} placeHolder={''}
                checkboxOption={CounselorCheckOptions.NATIONALITY}
                option={codePhone}
                setOption={setCodePhone}
            >
                <CounselorDuplicateCheckButtonFiled
                    duplicateType={CounselorDuplicateType.CELL_PHONE}
                    minLength={11} maxLength={11} label={''}
                    placeHolder={t('registration.cellPhoneLengthPlaceHolder')}
                    isLabelVisible={false}
                    value={phoneNumber}
                    subValue={phoneNumber}
                    setValue={setPhoneNumber}
                    validate={cellPhoneValidate} setValidate={setCellPhoneValidate}
                />
            </CounselorCheckboxField>

            {/*  비밀번호  */}
            <CounselorShortInputField
                label={t('registration.counselor.password')}
                placeHolder={t('registration.passwordPlaceHolder')}
                inputType={'password'}
                minLength={8} maxLength={16}
                isRequired={true} isLabelVisible={true}
                additionalText={'영문 대소문자/숫자/특수문자를 조합한 8~16자리 입력'}
                value={password} setValue={setPassword}
                validate={passwordValidate} setValidate={setPasswordValidate}
            />

            {/*  비밀번호 확인  */}
            <CounselorShortInputField
                label={t('registration.counselor.passwordConfirm')}
                placeHolder={t('registration.passwordPlaceHolder')}
                inputType={'password'}
                minLength={8} maxLength={16}
                isRequired={true} isLabelVisible={true}
                value={passwordConfirm} setValue={setPasswordConfirm}
                validate={passwordConfirmValidate} setValidate={setPasswordConfirmValidate}
            />

            {/*  프로필 이미지  */}
            <CounselorImageField
                thumbnail={thumbnail} setThumbnail={setThumbnail}
                addedProfileImage={profileImage}
                setAddedProfileImage={setProfileImage}/>

            {/*  상담사 약력  */}
            <CounselorLongInputField
                label={t('registration.counselor.counselorProfile')}
                placeHolder={t('registration.counselorPlaceHolder')}
                isRequired={false}
                value={briefHistory}
                setValue={setBriefHistory}
            />

            {/*  계좌정보  */}
            <CounselorCheckboxField
                label={t('registration.counselor.accountInfo')}
                placeHolder={t('registration.accountPlaceHolder')}
                isRequired={false}
                additionalText={"정산 받으실 은행을 입력해주세요."}
                checkboxOption={CounselorCheckOptions.ACCOUNT}
                option={bankName}
                setOption={setBankName}
            >
                <div className={style.childrenWrapper}>
                    <CounselorShortInputField
                        label={''}
                        placeHolder={(t('registration.accountNumberPlaceHolder'))}
                        inputType={'text'}
                        minLength={2} maxLength={20}
                        isRequired={true}
                        isLabelVisible={false}
                        value={accountNumber} setValue={setAccountNumber}
                    />
                    <CounselorShortInputField
                        label={''}
                        placeHolder={(t('registration.accountNamePlaceHolder'))}
                        inputType={'text'}
                        minLength={2} maxLength={20}
                        isRequired={true}
                        isLabelVisible={false}
                        value={accountHolder} setValue={setAccountHolder}
                    />
                </div>

                {/*  상담소속 선택  */}
                {isCounselorRegistration &&  (
                    <CounselorAffiliationSelector
                        counselorGroup={counselorGroup} setCounselorGroup={setCounselorGroup}
                        consultantDistributionAmount={consultantDistributionAmount}
                        isVatInclude={isVatInclude} setIsVatInclude={setIsVatInclude}
                        isTaxDeducted={isTaxDeducted} setIsTaxDeducted={setIsTaxDeducted}
                        setConsultantDistributionAmount={setConsultantDistributionAmount}
                        affiliationDistributionAmount={affiliationDistributionAmount}
                        setAffiliationDistributionAmount={setAffiliationDistributionAmount}
                        affiliationIdxValidate={affiliationIdxValidate}
                        setAffiliationIdxValidate={setAffiliationIdxValidate}
                        consultantDistributionValidate={consultantDistributionValidate}
                        setConsultantDistributionValidate={setConsultantDistributionValidate}
                    />
                )}

            </CounselorCheckboxField>
        </>
    );
}