import style from "../../style/test_result/guide/learningStrategy.module.scss";
import {RightLearningStrategyGuide} from "./RightLearningStrategyGuide";
import {LeftLearningStrategyGuide} from "./LeftLearningStrategyGuide";
import {BrainType} from "../../../types/testResult";
import {Trans, useTranslation} from "react-i18next";
import React from "react";
import {useLanguage} from "../../../hooks/hooks";

/* eslint-disable max-len */
export function BalancedStrategy() {
    const {t} = useTranslation("guideLearningStrategy");
    const {isKorean} = useLanguage();

    return (
        <>
            <div className={style.pageBody}>
                <article className={style.summary}>
                    <div className={style.titleWrapper}>
                        <h2 className={`${style.title} ${isKorean ? '' : style.notKO}`}>
                            <span className={style.bold}>{t('summary.balanced.title')}</span>
                            <span className={`${style.light} ${style.small}`}>{t('summary.balanced.subTitle')}</span>
                            <span>{t('summary.balanced.strategy')}</span>
                        </h2>

                        <div className={style.descriptionWrapper}>
                            <Trans t={t} i18nKey="summary.balanced.description"
                                   values={{blank: '\u00A0'}}
                                   components={{
                                       br: <br/>,
                                       description: <p className={style.description}/>,
                                       bold: <span className={style.bold}/>,
                                   }}/>
                        </div>
                    </div>

                    <hr className={style.underline}/>

                    <div>
                        <p className={style.description}>
                            <Trans t={t} i18nKey="summary.balanced.subDescription"
                                   values={{blank: '\u00A0'}}
                                   components={{
                                       br: <br/>,
                                       bold: <span className={style.bold}/>,
                                   }}/>
                        </p>
                    </div>
                </article>


                <LeftLearningStrategyGuide brainType={BrainType.BALANCED}/>

                <RightLearningStrategyGuide brainType={BrainType.BALANCED}/>

            </div>
        </>
    )
}