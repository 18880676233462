import {useAppDispatch, useAppSelector} from "../../../../hooks/hooks";
import {adminMyInfo, getPersonalInfoAsync, setMyAdminInfo} from "../../../../store/myPageSlice";
import {callAsync} from "../../../../util/sliceUtil";
import {AdminVO} from "../../../../types/admin";
import {useCallback} from "react";
import {useTranslation} from "react-i18next";
import {ErrorType} from "../../../../types/error";
import {AdminRoleCategory} from "../../../../types/adminRole";

export function useMyInfoGetter() {
  const { t } = useTranslation("account");
  const dispatch = useAppDispatch();
  const myInfo = useAppSelector(adminMyInfo);

  const getMyInfo = useCallback(
    (resolve?: () => void, reject?: (_: ErrorType) => void) => {
      callAsync<AdminVO>(
        dispatch(getPersonalInfoAsync()).unwrap(),
        (result) => {
          localStorage.setItem('role', result.role.category);
          dispatch(setMyAdminInfo(result));
          resolve?.();
          return result;
        },
        (error) => {
          reject?.(error);
          console.error(t("myInfo.errorMessageLoadingUserInfo"));
        }
      ).then();
    },
    [dispatch, t]
  );

  const isConsultant = useCallback(() =>{
      if(!myInfo) return;

      const consultantRoles = [AdminRoleCategory.CONSULTANT, AdminRoleCategory.CONSULTANT_MANAGER];
      return consultantRoles.includes(myInfo.role.category);
  },[myInfo])

  return {
    myInfo,
    getMyInfo,
    isConsultant,
  };
}
