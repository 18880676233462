import style from "./style/registration.module.scss";
import React from "react";
import {ValidStatus} from "../../types/common";

interface Args {
    value: number;
    setValue: (_: number) => void
    validate?: ValidStatus;
    setValidate?: (_: ValidStatus) => void
}

export function CounselorPriceField({value, setValue, validate, setValidate}: Args) {
    /**
     * 금액 입력용 컴포넌트
     */

    return (
        <div>
            <div className={style.priceArea}>
                <input
                    value={value === 0 ? '' : value}
                    type={"number"}
                    onChange={(e) => {
                        setValue(parseFloat(e.target.value))
                        setValidate && setValidate(ValidStatus.NONE)
                    }}
                    className={style.input}
                    placeholder={'숫자 입력'}
                />원
            </div>
            {validate === ValidStatus.UNWRITTEN && (
                <div className={style.inValidateText}>숫자를 입력해주세요.</div>
            )}
        </div>
    );
}