import style from "../../style/grantModal.module.scss";
import {Label104x46} from "../../../components/Label";
import React from "react";
import {useLanguage} from "../../../hooks/hooks";

interface Args{
    title: string;
    value: string;
}
export function LabelAndTextField({title, value}:Args) {
    const {isKorean} = useLanguage();
    return (
        <div className={style.textWrapper}>
            <Label104x46
                text={title}
                className={`${style.informationModalLabel} ${isKorean ? "" : style.notKO}`}/>
            <div className={style.text}>{value}</div>
        </div>
    )
}