import style from "../../style/test_result/commonStyle.module.scss";
import {TestResultReportDTO} from "../../../types/testResult";
import {useTimeConverter} from "../../../hooks/hooks";
import {useTranslation} from "react-i18next";
import React from "react";

interface Args {
    testResult: TestResultReportDTO;
}
export function TestHeaderWithInfo({testResult}: Args) {
    /**
     * 날짜와 검사자 정보가 포함된 헤더
     */
    const {timeConverter} = useTimeConverter();
    const {t} = useTranslation("testResultHeader");

    return (
        <>
            {/*<div className={style.fixedHeaderSpace} />*/}
            <div className={style.fixedHeaderWithInfo}>
                <div className={style.header1}>
                    <span className={style.date}>
                        {timeConverter.convertDateWithWords(testResult.testDate)}
                    </span>
                    <img className={style.secondaryLogo}
                         src="https://images.feel-good.io/logo/kpass_logo_secondary.svg"
                         alt="K-PASS secondary logo"/>
                </div>
                <div className={style.header2}>
                    <div className={style.strong}>{t('kpassHeaderTitle')}</div>
                    <div className={style.profile}>
                        <span className={style.strong}>{testResult.testResultUserProfileVO.name}</span>
                        <span>|</span>
                        <span>{testResult.testResultUserProfileVO.gender === 'MALE' ? '남' : '여'}</span>
                        <span>|</span>
                        <span>만 {testResult.testResultVO.yearOfAge}세</span>
                        <span>|</span>
                        <span>{testResult.testResultVO.monthOfAge}개월</span>
                    </div>
                </div>
            </div>
        </>
    )
}