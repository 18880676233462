import style from "../../style/test_result/guide/learningStrategy.module.scss";
import {RecommendationLeftJobDetail} from "./RecommendationLeftJobDetail";
import {RecommendationRightJobDetail} from "./RecommendationRightJobDetail";
import {BrainType} from "../../../types/testResult";
import {Trans, useTranslation} from "react-i18next";
import React from "react";
import {useLanguage} from "../../../hooks/hooks";


/* eslint-disable max-len */
export function RecommendationBalancedJob() {
    const {t} = useTranslation("guideRecommendationJob");
    const {isKorean} = useLanguage();

    return(
        <>
            <div className={style.pageBody}>
                <article className={style.summary}>
                    <div className={style.titleWrapper}>
                        {isKorean? (
                            <h2 className={style.title}>
                                <span className={style.bold}>{t('summary.balanced.title')}</span>
                                <span className={`${style.light} ${style.small}`}>{t('summary.balanced.subTitle')}</span>
                                <span>{t('summary.balanced.job')}</span>
                            </h2>

                        ) : (
                            <h2 className={`${style.title} ${style.notKO}`}>
                                <span className={style.normalText}>{t('summary.balanced.job')}</span>
                                <span className={style.bold}>{t('summary.balanced.title')}</span>
                                <span className={`${style.light} ${style.small}`}>{t('summary.balanced.subTitle')}</span>
                            </h2>
                        )}

                        <div className={style.descriptionWrapper}>
                            <Trans t={t} i18nKey='summary.balanced.description'
                                   values={{blank: '\u00A0'}}
                                   components={{
                                       br: <br/>,
                                       description: <p className={style.description}/>,
                                   }}/>
                        </div>
                    </div>
                    <hr className={style.underline}/>
                    <p className={style.description}>
                        <Trans t={t} i18nKey='summary.balanced.subDescription' values={{blank: '\u00A0'}}/>

                    </p>
                </article>

                <RecommendationLeftJobDetail brainType={BrainType.BALANCED} />

                <RecommendationRightJobDetail brainType={BrainType.BALANCED} />
            </div>
        </>
    )
}