import style from "./style/registration.module.scss";
import {CounselorField} from "./registration/CounselorField";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {Post} from "../../layout/content/post/Post";
import {CounselorManagerDTO} from "../../types/counselor";
import {ValidStatus} from "../../types/common";
import {useCounselorGroupValidator} from "./registration/hooks/useCounselorGroupValidator";
import {useCounselorCreator} from "./registration/hooks/useCounselorCreator";

export function CounselorRegistration() {
    /**
     * 상담사 등록 페이지
     */
    const {t} = useTranslation("counselorGroup");
    const [counselorDTO, setCounselorDTO] = useState<CounselorManagerDTO>();
    const {createCounselor} = useCounselorCreator();
    const [idValidate, setIdValidate] = useState(ValidStatus.NONE);
    const [cellPhoneValidate, setCellPhoneValidate] = useState(ValidStatus.NONE);
    const [nameValidate, setNameValidate] = useState(ValidStatus.NONE);
    const [passwordValidate, setPasswordValidate] = useState(ValidStatus.NONE);
    const [passwordConfirmValidate, setPasswordConfirmValidate] =
        useState(ValidStatus.NONE);
    const [affiliationIdxValidate, setAffiliationIdxValidate] = useState(ValidStatus.NONE);
    const [consultantDistributionValidate, setConsultantDistributionValidate] = useState(ValidStatus.NONE);

    const {validate} = useCounselorGroupValidator({
        counselorDTO: counselorDTO,
        idValidate, setIdValidate,
        cellPhoneValidate, setCellPhoneValidate,
        nameValidate, setNameValidate,
        passwordValidate, setPasswordValidate,
        passwordConfirmValidate, setPasswordConfirmValidate,
        affiliationIdxValidate, setAffiliationIdxValidate,
        consultantDistributionValidate, setConsultantDistributionValidate
    });

    const onClickCreateButton = () => {
        const result = validate();
        if(!result) return false;
        if (!counselorDTO) return false;
        const isConfirm = window.confirm('상담사를 등록하시겠습니까?');

        if (isConfirm) {
            createCounselor({
                nationality: counselorDTO.nationality,
                email: counselorDTO.email,
                name: counselorDTO.name,
                codePhone: counselorDTO.codePhone,
                phoneNumber: counselorDTO.phoneNumber,
                password: counselorDTO.password,
                profileImageUrl: counselorDTO.profileImageUrl,
                addedImage:counselorDTO.addedImage,
                briefHistory: counselorDTO.briefHistory, // counselorProfile
                bankName: counselorDTO.bankName,
                accountNumber: counselorDTO.accountNumber,
                accountName: counselorDTO.accountName,
                affiliationDistributionAmount: counselorDTO.affiliationDistributionAmount,
                consultantDistributionAmount: counselorDTO.consultantDistributionAmount,
                isVatInclude: counselorDTO.isVatInclude,
                isTaxDeduction: counselorDTO.isTaxDeduction,
                ...(counselorDTO.counselingAffiliationIdx !== "independent" && {
                    counselingAffiliationIdx: counselorDTO.counselingAffiliationIdx
                })
            }).then(() => {
                alert('상담사가 등록되었습니다.')
                window.location.href = "/counselor/manager"
            });
        }
    }

    return (
        <>
            <div className={style.postWrapper}>
                <Post buttonTitle={t("registration.counselor.buttonTitle")}
                      title={t("registration.counselor.counselorTitle")}
                      buttonOnClickAction={onClickCreateButton} formClassName={style.postForm}>

                    {/*  상담관리자 정보  */}
                    <CounselorField title={''}
                                    counselorDTO={counselorDTO}
                                    setCounselorDTO={setCounselorDTO}
                                    idValidate={idValidate} setIdValidate={setIdValidate}
                                    cellPhoneValidate={cellPhoneValidate} setCellPhoneValidate={setCellPhoneValidate}
                                    nameValidate={nameValidate} setNameValidate={setNameValidate}
                                    passwordValidate={passwordValidate} setPasswordValidate={setPasswordValidate}
                                    passwordConfirmValidate={passwordConfirmValidate}
                                    setPasswordConfirmValidate={setPasswordConfirmValidate}
                                    affiliationIdxValidate={affiliationIdxValidate}
                                    setAffiliationIdxValidate={setAffiliationIdxValidate}
                                    consultantDistributionValidate={consultantDistributionValidate}
                                    setConsultantDistributionValidate={setConsultantDistributionValidate}
                    />
                </Post>
            </div>
        </>
    )
}