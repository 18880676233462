import style from "../popup.module.scss";
import React, { useCallback, useRef, useState } from "react";
import { PanelWrapper } from "../../../../../layout/content/detail/PanelWrapper";
import { Panel } from "../../../../../layout/content/detail/Panel";
import queryString from "query-string";
import { callAsync } from "../../../../../util/sliceUtil";
import { blockUsersAsync } from "../../../../../store/userSlice";
import { useAppDispatch, useNewWindow } from "../../../../../hooks/hooks";
import { ActionUserType } from "../../../../../types/dataCommon";
import { blockAdminsAsync } from "../../../../../store/adminSlice";
import { WindowMessage } from "../../../../../types/newWindow";
import { blockOrganManagersAsync } from "../../../../../store/organManagerSlice";
import styled from "styled-components";
import { AsyncThunkAction } from "@reduxjs/toolkit";
import { useTranslation } from "react-i18next";
import { useUtil } from "../../../../../util/hooks/useUtil";
import {adminConsultantBlockAsync} from "../../../../../store/counselorSlice";

const FormStyle = styled.form`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
export function BlockMember() {
  const { t } = useTranslation("member");
  const { userType, memberIdxArr } = queryString.parse(window.location.search);
  const [reason, setReason] = useState("");
  const dispatch = useAppDispatch();
  const { closeWindowAndNotifyToParentWindow } = useNewWindow();
  const { defaultErrorMessage } = useUtil();
  const formRef = useRef<HTMLFormElement>(null);

  const onClickBlockButton = useCallback(() => {
    if (!formRef.current?.reportValidity()) return;
    if (!window.confirm(t("block.confirmMessage"))) return;

    const memberIdxes =
      typeof memberIdxArr === "object"
        ? (memberIdxArr as string[]).map((it) => Number(it))
        : [Number(memberIdxArr as string)];

    let blockAsync: AsyncThunkAction<any, any, any> | null = null;
    switch (userType) {
      case ActionUserType.ADMIN:
        blockAsync = blockAdminsAsync({ adminIdxArr: memberIdxes, blockReason: reason });
        break;
      case ActionUserType.USER:
        blockAsync = blockUsersAsync({ userIdxArr: memberIdxes, blockReason: reason });
        break;
      case ActionUserType.ORGAN_MANAGER:
        blockAsync = blockOrganManagersAsync({ organManagerIdxes: memberIdxes, blockReason: reason });
        break;
      case ActionUserType.CONSULTANT:
        blockAsync = adminConsultantBlockAsync({ idx: memberIdxes, blockReason: reason });
        break;
    }

    if (blockAsync) {
      callAsync(
        dispatch(blockAsync).unwrap(),
        () => {
          alert(t("block.resultMessage"));
          closeWindowAndNotifyToParentWindow(WindowMessage.BLOCK_MEMBER);
        },
        () => alert(defaultErrorMessage)
      ).then();
    }
  }, [dispatch, memberIdxArr, t, userType, formRef, reason, closeWindowAndNotifyToParentWindow, defaultErrorMessage]);

  return (
    <PanelWrapper>
      <Panel title={t("block.title")} description={t("block.description")}>
        <FormStyle onSubmit={(e) => e.preventDefault()} ref={formRef}>
          <div className={style.inputArea}>
            <label className={style.label}>{t("block.reason.label")}</label>
            <textarea
              required={true}
              minLength={2}
              maxLength={255}
              className={style.textarea}
              placeholder={t("block.reason.placeholder")}
              defaultValue={""}
              onChange={(e) => setReason(e.target.value)}
            ></textarea>
          </div>
          <div className={style.buttonArea}>
            <button onClick={onClickBlockButton} className={style.blockButton}>
              {t("block.buttonLabel")}
            </button>
          </div>
        </FormStyle>
      </Panel>
    </PanelWrapper>
  );
}
