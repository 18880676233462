import {TestResultHeader} from "./common/TestResultHeader";
import {TestTopButton} from "./common/TestTopButton";
import React, {useEffect} from "react";
import style from "../style/test_result/testResultReport.module.scss";
import {CookieKeys, useCookie} from "../../hooks/auth/useCookie";
import {useKpassTestResultGetter} from "./kpass/hooks/useKpassTestResultGetter";
import {useLanguage} from "../../hooks/hooks";
import {Language} from "../../types/language";
import {GuideContent} from "./guide/GuideContent";
import {GuideResultPath} from "../../types/testResult";
import {GuidePrintAssistant} from "./guide/GuidePrintAssistant";
import {GuideLearningCard} from "./guide/GuideLearningCard";
import {GuideCover} from "./guide/GuideCover";
import {useParams} from "react-router-dom";

export function TestResultGuide() {
    const {getValueFromCookie} = useCookie();
    const {setLang} = useLanguage()
    const {id} = useParams<"id">();
    const testIdx = Number(id);
    let accessToken = getValueFromCookie(CookieKeys.ACCESS_TOKEN);
    const { isKorean } = useLanguage();
    const {getKpassTestResult, kpassTestResult} = useKpassTestResultGetter();

    useEffect(() => {
        accessToken && getKpassTestResult(testIdx, accessToken).then()

        //eslint-disable-next-line
    }, [getKpassTestResult]);

    // 브라우저 언어 변경 시 언어 수정
    useEffect(() => {
        const browserLanguage = navigator.language.slice(0, 2);
        const language = browserLanguage === 'en' ? Language.EN : Language.KO
        setLang(language)

        // eslint-disable-next-line
    }, [navigator]);


    return (
        <>
            {/*  최상단 헤던  */}
            <TestResultHeader/>

            {/*  플로팅 탑 버튼  */}
            <TestTopButton isLinkeyInvisible={true}/>

            {kpassTestResult && (
                <div className={style.pageWrapper}>
                    {/*/!*진로 카드 영역*!/*/}
                    {isKorean && (
                        <GuideLearningCard testResult={kpassTestResult}/>
                    )}

                    {/*/!*  학습진로가이드 커버  *!/*/}
                    <GuideCover testResult={kpassTestResult}/>

                    <table>
                        {/*/!*  프린트시 여백 처리  *!/*/}
                        <GuidePrintAssistant/>

                        {/*  학습진로가이드 상세 내용들  */}
                        {/*  상대적 약점 지능의 보완  */}
                        <GuideContent testResult={kpassTestResult}
                                      guideStep={GuideResultPath.IMPROVE_WEAKNESS}/>

                        {/*나는 어떤 유형의 사람일까?  */}
                        <GuideContent testResult={kpassTestResult}
                                      guideStep={GuideResultPath.PERSONAL_CHARACTER_TYPE}/>

                        {/*좌뇌우세형(순차처리 우세형) 학습법  */}
                        <GuideContent testResult={kpassTestResult}
                                      guideStep={GuideResultPath.LEARNING_STRATEGY}/>

                        {/*  좌뇌우세형 (순차처리우세형) 추천 직업군  */}
                        <GuideContent testResult={kpassTestResult}
                                      guideStep={GuideResultPath.RECOMMENDATION_JOB}/>

                    </table>
                </div>
            )}
        </>
    );
}