import {AddedFile, FileType} from "../../../../types/file";
import {useValidator} from "../../../../hooks/useValidator";
import {useCallback} from "react";
import {WebsiteType} from "../../../../types/common";
import {BannerType} from "../../../../types/banner";

interface Props {
    name: string;
    availableEndAt: string;
    isAvailableAlways: boolean;
    image: AddedFile | undefined;
    addedImage: FileType | undefined;
    description1?: string;
    description2?: string;
    buttonDescription: string;
    confirmMessage: string;
    websiteType: WebsiteType;
    bannerType: BannerType;
}

export function useBannerValidator({
                                       name,
                                       availableEndAt,
                                       isAvailableAlways,
                                       image,
                                       addedImage,
                                       description1,
                                       description2,
                                       buttonDescription,
                                       confirmMessage,
                                       websiteType,
                                       bannerType
                                   }: Props) {

    const validator = useValidator();
    const validate = useCallback(() => {

        if (validator.getTotalLength(name) > 50) {
            alert('배너명이 너무 깁니다.\n※ 띄어쓰기 특수문자 포함, 최대 영문 50자, 한글 25자');
            return false;
        }

        if (!Boolean(isAvailableAlways) && availableEndAt === null) {
            alert('유효기간 종료일을 선택하세요.');
            return false;
        }

        if (!image && !addedImage) {
            alert("배너 이미지 파일을 업로드해 주세요.");
            return false;
        }

        if ((description1 && validator.getTotalLength(description1) > 100) ||
            (description2 && validator.getTotalLength(description2) > 100)) {
            alert("배너 문구가 너무 깁니다.\n※ 띄어쓰기 특수문자 포함, 최대 영문 100자, 한글 50자");
            return false
        }

        if (validator.getTotalLength(buttonDescription) > 50) {
            alert("버튼 문구가 너무 깁니다.\n※ 띄어쓰기 특수문자 포함, 최대 영문 50자, 한글 25자");
            return false
        }

        if ((bannerType === BannerType.RUSSIA && websiteType === WebsiteType.DCAS) ||
            (bannerType === BannerType.TAIWAN && websiteType === WebsiteType.KPASS)) {
            alert("배너 유형을 다시 선택해주세요.");
            return false;
        }

        return window.confirm(confirmMessage);
    // eslint-disable-next-line
    }, [name,
        availableEndAt,
        isAvailableAlways,
        image,
        addedImage,
        description1,
        description2,
        buttonDescription,
        confirmMessage,
        websiteType,
        bannerType,
    ]);
    return {validate};

}