import {AdminRoleCategory} from "../../../../types/adminRole";
import {getConsultantManagerConsultantsAsync, getConsultantsAsync} from "../../../../store/counselorSlice";
import {useAppDispatch} from "../../../../hooks/hooks";
import {useCallback, useState} from "react";
import {PageResponse, PageSearchCondition} from "../../../../types/page";
import {ConsultantDTO, CounselorInfoSearchCondition} from "../../../../types/counselor";

export function useCounselorInfoGetter() {
    /**
     * 배정하기를 위한 상담사 정보 조회 API
     */
    const dispatch = useAppDispatch();
    const [totalCounselorInfoCount, setTotalCounselorInfoCount] = useState<number | null>(null);
    const [counselorInfoList, setCounselorInfoList] =
        useState<PageResponse<ConsultantDTO>>();

    // 권한별 API 요청 처리 함수
    const getCounselorInfoListDispatcher = (role: string) => {
        switch (role) {
            case AdminRoleCategory.CONSULTANT_MANAGER:
                return getConsultantManagerConsultantsAsync;
            default:
                return getConsultantsAsync;
        }
    };

    const fetchCounselorInfoList = async (param: PageSearchCondition) => {
        try {
            const role = localStorage.getItem('role');
            const params: CounselorInfoSearchCondition = { pageCondition: param };

            if (!role) {
                console.warn("권한이 없습니다. 다시 시도해주세요.");
                return;
            }

            const dispatchFunction = getCounselorInfoListDispatcher(role);
            const result: PageResponse<ConsultantDTO> = await dispatch(dispatchFunction(params)).unwrap();

            // 상태 업데이트
            setCounselorInfoList(result);
            setTotalCounselorInfoCount(result.totalElements);
        } catch (error) {
            console.error("Error fetching counselor info list:", error);
        }
    };

    const getCounselorInfoList = useCallback(
        async (param: PageSearchCondition) => {
            await fetchCounselorInfoList(param);
        },
        //eslint-disable-next-line
        []
    );

    const getCounselorInfoLists = (param: PageSearchCondition) => () => {
        fetchCounselorInfoList(param).then();
    };

    return {
        getCounselorInfoList,
        counselorInfoList,
        totalCounselorInfoCount,
        getCounselorInfoLists
    };
}