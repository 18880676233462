import {DescriptionValue} from "../../../../layout/content/detail/DescriptionList";
import {GridInputText} from "../../../../components/Inputs";
import {useTranslation} from "react-i18next";
import React, {useCallback, useEffect, useState} from "react";
import {
    ConsultantDTO,
    CounselorGroupInfoDTO,
    CounselorGroupPutDTO,
    TaxSelectedValue
} from "../../../../types/counselor";
import {useTimeConverter} from "../../../../hooks/hooks";
import {InputRefundAccount} from "../../../../components/InputRefundAccount";
import {
    ChangeAddressButton,
    CounselorInputWrapperStyle,
    LargeInputWrapper,
    NumberInputStyle,
    RadioWrapperStyle
} from "../../style/CounselorDataStyle";
import {InputPhoneNumber} from "../../../../components/InputPhoneNumber";
import {CounselorRadioField} from "../../registration/CounselorRadioField";
import {useCounselorDetailGetter} from "../../detail/hooks/useCounselorDetailGetter";
import {useParams} from "react-router-dom";
import {Address} from "../../../../types/user";
import {useCounselorConverter} from "./useCounselorConverter";
import {AdminStatus} from "../../../../types/admin";

interface Args {
    groupInfoPutParam: (_: CounselorGroupPutDTO) => void
}

export function useGroupInfoDetailUtil({groupInfoPutParam}: Args) {
    const {id} = useParams<'id'>() as { id: string };

    const {t} = useTranslation("counselorInfo");
    const {timeConverter} = useTimeConverter();
    const {groupDetail, getCounselorGroupDetail} = useCounselorDetailGetter(id);

    const [groupName, setGroupName] = useState('');
    const [address, setAddress] = useState<Address>(groupDetail.address);
    const [cellPhone, setCellPhone] = useState('');
    const [nationality,] = useState('+82');
    const [briefHistory, setBriefHistory] = useState('');
    const [bankName, setBankName] = useState('');
    const [accountNumber, setAccountNumber] = useState('');
    const [accountName, setAccountName] = useState('');
    const [groupDistribution, setGroupDistribution] = useState(0);
    const [counselorDistribution, setCounselorDistribution] = useState(0);
    const [isVatInclude, setIsVatInclude] = useState(TaxSelectedValue.UNSELECT);
    const [isTaxDeduction, setIsTaxDeduction] = useState(TaxSelectedValue.UNSELECT);


    const {getInfoSearchStatusConverter} = useCounselorConverter();

    useEffect(() => {
        if (id) getCounselorGroupDetail().then();
        //eslint-disable-next-line
    }, [id]);


    // 값 초기화
    const resetData = useCallback(() => {
        setGroupName(groupDetail.name)
        setAddress(groupDetail.address)
        setCellPhone(groupDetail.phoneNumber ?? "");
        setBriefHistory(groupDetail.briefHistory ?? "");
        setBankName(groupDetail.bankName ?? "");
        setAccountNumber(groupDetail.accountNumber ?? "");
        setAccountName(groupDetail.accountName ?? "");
        setGroupDistribution(groupDetail.affiliationDistributionAmount ?? 0);
        setCounselorDistribution(groupDetail.consultantDistributionAmount ?? 0);
        setIsVatInclude(groupDetail.isVatInclude
            ? TaxSelectedValue.SELECT
            : TaxSelectedValue.UNSELECT)
        setIsTaxDeduction(groupDetail.isTaxDeduction
            ? TaxSelectedValue.SELECT
            : TaxSelectedValue.UNSELECT)
        //eslint-disable-next-line
    }, [groupDetail, id]);

    useEffect(() => {
        resetData();
    }, [resetData]);

    // 수정하려는 값 대입
    useEffect(() => {
        groupInfoPutParam({
            address: JSON.parse(
                JSON.stringify(
                    address ?? {
                        address1: "",
                        address2: "",
                        address3: "",
                        city: "",
                        state: "",
                        country: "",
                        postalCode: "",
                    }
                )
            ),
            codePhone: '+82',
            phoneNumber: cellPhone,
            briefHistory,
            bankName,
            accountNumber,
            accountName,
            affiliationDistributionAmount: groupDistribution,
            consultantDistributionAmount: counselorDistribution,
            isVatInclude: isVatInclude === TaxSelectedValue.SELECT,
            isTaxDeduction: isTaxDeduction === TaxSelectedValue.SELECT,
        })
        //eslint-disable-next-line
    }, [groupName, address, cellPhone, briefHistory, bankName, accountNumber, accountName,
        groupDistribution, counselorDistribution, isVatInclude, isTaxDeduction]);


    const getOrganGroupInfo = (data: CounselorGroupInfoDTO): DescriptionValue[][] => [
        [
            {key: t("group.groupName"), value: groupName,},
            {key: t("group.registeredAt"), value: timeConverter.convertToLocalTime('20241212')},
        ],
        [
            {
                key: t("group.address"),
                value: data?.address.address1,
                editModeValue: (
                    <CounselorInputWrapperStyle>
                        <GridInputText
                            value={address?.address1}
                            required={true}
                            minLength={2}
                            maxLength={50}
                            onChange={(e) => setAddress(prevAddress => ({
                                ...prevAddress,
                                address1: e.target.value
                            }))}
                        />
                        <ChangeAddressButton onComplete={(data) => {
                            setAddress(prevAddress => ({
                                ...prevAddress,
                                address1: data.address1
                            }))
                        }}>
                            {t('group.searchAddress')}
                        </ChangeAddressButton>
                    </CounselorInputWrapperStyle>
                ),
            },
            {
                key: '', value: data?.address.address2,
                editModeValue: (
                    <GridInputText
                        value={address.address2}
                        required={true}
                        minLength={2}
                        maxLength={50}
                        onChange={(e) => setAddress(prevAddress => ({
                            ...prevAddress,
                            address2: e.target.value
                        }))}
                    />
                ),
            }
        ],
        [
            {
                key: t("group.cellPhone"),
                value: <>{data?.phoneNumber}</>,
                editModeValue: (
                    <InputPhoneNumber codePhone={nationality} onChangeCodePhone={(data) => {}}
                                      phoneNumber={cellPhone} onChangePhoneNumber={(data) => setCellPhone(data)}/>
                ),
            },
        ],
        [
            {
                key: t("group.counselorProfile"),
                value: data?.briefHistory,
                editModeValue: (
                    <GridInputText
                        value={briefHistory}
                        required={true}
                        minLength={2}
                        maxLength={50}
                        onChange={(e) => setBriefHistory(e.target.value)}
                    />
                ),

            },
        ],
        [
            {
                key: t("group.accountInfo"),
                value: <span>
                    환불은행: {data?.bankName} / 환불계좌: {data?.accountNumber} / 예금주: {data?.accountName}
                </span>,
                editModeValue: (
                    <InputRefundAccount
                        bankName={bankName}
                        onChangeBankName={setBankName}
                        accountNumber={accountNumber}
                        onChangeAccountNumber={setAccountNumber}
                        accountName={accountName}
                        onChangeAccountName={setAccountName}
                        required={true}
                    />
                ),
            },
        ],
        [
            {
                key: t("group.groupDistribution"),
                value: data?.affiliationDistributionAmount + '원',
                editModeValue: (
                    <LargeInputWrapper>
                        <NumberInputStyle
                            type={"number"}
                            value={groupDistribution}
                            onChange={(e) =>
                                setGroupDistribution(Number(e.target.value))
                            }
                        /> 원
                    </LargeInputWrapper>
                ),
            },
            {
                key: t("group.counselorDistribution"),
                value: data?.consultantDistributionAmount + '원',
                editModeValue: (
                    <LargeInputWrapper>
                        <NumberInputStyle
                            type={"number"}
                            value={counselorDistribution}
                            onChange={(e) =>
                                setCounselorDistribution(Number(e.target.value))
                            }
                        /> 원
                    </LargeInputWrapper>
                ),
            },
        ],
        // 데이터 타입 수정 필요 (TaxSelectedValue)
        [
            {
                key: t("group.addedTax"),
                value:
                    <>
                        {data.isVatInclude ?
                            '11%' : '-'
                        }
                    </>,
                editModeValue: (
                    <RadioWrapperStyle>
                        <CounselorRadioField
                            selectedValue={isVatInclude} setSelectedValue={setIsVatInclude}
                            groupName={'testAdded'}
                        />
                    </RadioWrapperStyle>
                )
            },
            {
                key: t("group.taxDeduction"),
                value:
                    <>
                        {data.isTaxDeduction ?
                            '11%' : '-'
                        }
                    </>,
                editModeValue: (
                    <RadioWrapperStyle>
                        <CounselorRadioField
                            selectedValue={isTaxDeduction} setSelectedValue={setIsTaxDeduction}
                            groupName={'testDeducted'}
                        />
                    </RadioWrapperStyle>
                )
            },
        ]
    ];

    const getOrganManagerInfo = (data: ConsultantDTO): DescriptionValue[][] => [
        [
            {key: t("manager.name"), value: data?.name,},
            {key: t("manager.authStatus"), value: getInfoSearchStatusConverter(data.status as AdminStatus)}
        ],
        [
            {key: t("manager.id"), value: data?.email,},
            {key: t("manager.cellPhone"), value: <>({data.codePhone}) {data.phoneNumber}</>},
        ],
    ];


    return {
        getOrganGroupInfo,
        getOrganManagerInfo,
        resetData
    }
}