import {useTranslation} from "react-i18next";

interface Args{
    currentResult: string;
}
export function useGuidePersonalTitleFunctions({currentResult}:Args) {
    const {t} = useTranslation("guideCommon");


    const hashTags = (prefix: string, count: number): string[] =>
        Array.from({length: count}, (_, i) => t(`${prefix}.hashTags${i + 1}`));

    const createTitleData = (key: string, hashTagCount: number) => ({
        title1: t(`personal.${key}.title1`),
        title1And: t(`personal.${key}.title1And`),
        title2: t(`personal.${key}.title2`),
        title2And: t(`personal.${key}.title2And`),
        title3: t(`personal.${key}.title3`),
        title3And: t(`personal.${key}.title3And`),
        title4: t(`personal.${key}.title4`),
        title4And: t(`personal.${key}.title4And`),
        desc: t(`personal.${key}.desc`),
        hashTags: hashTags(`personal.${key}`, hashTagCount),
        jsonKey: `personal.${key}.title`
    });

    const titleData: {
        [key: string]: {
            title1?: string;
            title1And?: string;
            title2?: string;
            title2And?: string;
            title3?: string;
            title3And?: string;
            title4?: string;
            title4And?: string;
            desc?: string;
            hashTags?: string[];
            jsonKey: string;
        }
    } = {
        // left - right - planning
        // balance ~
        'NS-NS-NS': createTitleData('NS_NS_NS', 5),
        'NS-NS-N': createTitleData('NS_NS_N', 6),
        'NS-NS-NW': createTitleData('NS_NS_NW', 5),
        'N-N-NS': createTitleData('N_N_NS', 5),
        'N-N-N': createTitleData('N_N_N', 5),
        'N-N-NW': createTitleData('N_N_NW', 5),
        'NW-NW-NS': createTitleData('NW_NW_NS', 5),
        'NW-NW-N': createTitleData('NW_NW_N', 5),
        'NW-NW-NW': createTitleData('NW_NW_NW', 5),

        // SUCCESSIVE ~
        'NS-N-NS': createTitleData('NS_N_NS', 5),
        'NS-N-N': createTitleData('NS_N_N', 5),
        'NS-N-NW': createTitleData('NS_N_NW', 5),
        'N-NW-NS': createTitleData('N_NW_NS', 5),
        'N-NW-N': createTitleData('N_NW_N', 5),
        'N-NW-NW': createTitleData('N_NW_NW', 5),
        'NS-NW-NS': createTitleData('NS_NW_NS', 5),
        'NS-NW-N': createTitleData('NS_NW_N', 5),
        'NS-NW-NW': createTitleData('NS_NW_NW', 5),

        // SIMULTANEOUS ~
        'N-NS-NS': createTitleData('N_NS_NS', 4),
        'N-NS-N': createTitleData('N_NS_N', 5),
        'N-NS-NW': createTitleData('N_NS_NW', 5),
        'NW-N-NS': createTitleData('NW_N_NS', 5),
        'NW-N-N': createTitleData('NW_N_N', 5),
        'NW-N-NW': createTitleData('NW_N_NW', 5),
        'NW-NS-NS': createTitleData('NW_NS_NS', 5),
        'NW-NS-N': createTitleData('NW_NS_N', 5),
        'NW-NS-NW': createTitleData('NW_NS_NW', 6),
    }

    const content = titleData[currentResult as keyof typeof titleData]

    return{
        content
    }
}