import {ExternalVoucherListDTO, ExternalVoucherStatus} from "../../../types/externalVoucher";
import {ActionButton, ActiveActionButton} from "../../../components/Buttons";
import styled from "styled-components";
import {useNewWindow} from "../../../hooks/hooks";
import {useExternalVoucherFunctions} from "./useExternalVoucherFunctions";

interface Args{
    dto:ExternalVoucherListDTO
    refreshList: () => void
}

const ButtonsWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    gap: 6px;
    flex: 1 0 0;
    flex-wrap: wrap;
`;

export function ExternalVoucherButtons({dto, refreshList}:Args) {
    const { openVoucherInformation, openVoucherAssignee } = useNewWindow();
    const { deleteVoucherCode } = useExternalVoucherFunctions();
    const status = dto.externalVoucherStatus;
    if (status === ExternalVoucherStatus.REGISTERED || status === ExternalVoucherStatus.DELETED) {
        return <div>-</div>
    }

    const onClickInformation = () =>{
        openVoucherInformation(dto.idx)
    }

    const onClickDelete = () =>{
        if (window.confirm("선택한 이용권을 삭제하시겠습니까?")) {
            const number = dto.idx
            const dtos = [number];
            deleteVoucherCode({idx: dtos}).then(() =>{
                alert("삭제가 완료되었습니다.");
                refreshList();
            });
        }
    }

    const onClickGeneration = () => {
        openVoucherAssignee(dto.idx);
    }

    return (
        <ButtonsWrapper>
            {/* 안내 버튼: ALARMED일 때 Active */}
            {status === ExternalVoucherStatus.ALARMED ? (
                <ActiveActionButton onClick={onClickInformation}>안내</ActiveActionButton>
            ) : (
                <ActionButton onClick={onClickInformation}>안내</ActionButton>
            )}

            <ActionButton onClick={onClickDelete}>삭제</ActionButton>
            <ActionButton onClick={onClickGeneration}>등록</ActionButton>
        </ButtonsWrapper>
    )
}