import style from "../../style/test_result/testResultReport.module.scss";
import React from "react";

export function GuidePrintAssistant() {
    return(
        <>
            <thead className={style.guidePrintHeader}>
            <tr>
                <td>
                    <div className={style.guidePrintHeaderSpace}></div>
                </td>
            </tr>
            </thead>
            <tfoot className={style.guidePrintFooterSpace}>
            <tr>
                <td>
                    <div className={style.guidePrintFooter}></div>
                </td>
            </tr>
            </tfoot>
        </>
    )
}