import {useCallback, useEffect, useMemo, useState} from "react";
import {
    DEFAULT_PAGE,
    DEFAULT_SIZE,
    DEFAULT_SORT,
    OrderDirection,
    ordersToSortArr,
    PageResponse,
    PageSearchCondition
} from "../../../../types/page";
import {ConsultantDTO, CounselorInfoListQueryType, CounselorInfoSearchCondition} from "../../../../types/counselor";
import {useUtil} from "../../../../util/hooks/useUtil";
import {useAppDispatch, useListFilterValues, useTimeConverter} from "../../../../hooks/hooks";
import {dateToString} from "../../../../util/dateUtil";
import {getConsultantManagerConsultantsAsync, getConsultantsAsync} from "../../../../store/counselorSlice";
import {useSortParam} from "../../../../hooks/list/useSortParam";
import {AdminRoleCategory} from "../../../../types/adminRole";

export function useCounselorInfoList() {
    const {defaultErrorMessage} = useUtil();
    const dispatch = useAppDispatch();

    // 응답 결과에 따라서 수정해야함
    const [totalCounselorInfoCount, setTotalCounselorInfoCount] = useState<number | null>(null);
    const [counselorInfoList, setCounselorInfoList] =
        useState<PageResponse<ConsultantDTO>>(
            {
                totalPages: 0,
                totalElements: 0,
                number: 0,
                size: 0,
                numberOfElements: 0,
                first: true,
                last: false,
                empty: true,
                orders: [{
                    direction: OrderDirection.ASC,
                    property: '',
                    ignoreCase: false,
                    nullHandling: "NATIVE",
                    ascending: false,
                    descending: true,
                }],
                pageable: {
                    offset: 0,
                    sort: {
                        sorted: false,
                        unsorted: true,
                        empty: true,
                    },
                    pageNumber: 0,
                    pageSize: 0,
                    paged: true,
                    unpaged: false,

                },
                content: [],
            }
        );

    const {filterValues, searchList, listQueryType} = useListFilterValues();
    const {timeConverter} = useTimeConverter();
    const {toSortArray} = useSortParam();

    // 권한별 API 요청 처리 함수
    const getCounselorInfoListDispatcher = (role: string) => {
        switch (role) {
            case AdminRoleCategory.CONSULTANT_MANAGER:
                return getConsultantManagerConsultantsAsync;
            default:
                return getConsultantsAsync;
        }
    };

    // 상담사 정보 조회 API
    const getCounselorInfoList = useCallback(
        async (param: CounselorInfoSearchCondition, isFirstLoad?: boolean) => {
            try {

                const role = localStorage.getItem('role')
                // 권한별 조회 분기처리
                if (role) {
                    const dispatchFunction = getCounselorInfoListDispatcher(role)
                    const result: PageResponse<ConsultantDTO> = await dispatch(dispatchFunction(param)).unwrap();
                    // const result: PageResponse<ConsultantDTO> = await dispatch(getConsultantsAsync(param)).unwrap();
                    setCounselorInfoList(result)
                    setTotalCounselorInfoCount(result.totalElements);

                    if (isFirstLoad) {
                        setTotalCounselorInfoCount(result.totalElements);
                    } else {
                        setCounselorInfoList(result);
                    }
                }

            } catch (e) {
                alert(defaultErrorMessage);
            }
        },
        //eslint-disable-next-line
        []
    );

    // 검색 파라미터
    const {
        searchWordTypeFilterValue,
        searchWordFilterValue,
        registerStartAtFilterValue,
        registerEndAtFilterValue,
        positionFilterValue,
        statusFilterValue,
    } = filterValues;

    const searchCounselorInfoList = (pageSearchCondition: PageSearchCondition) => {
        const param: CounselorInfoListQueryType = {
            searchWordType: searchWordTypeFilterValue.value,
            searchWord: searchWordFilterValue.value ? searchWordFilterValue.value.trim() : undefined,
            registerStartAt: registerStartAtFilterValue.value ? dateToString(registerStartAtFilterValue.value) : undefined,
            registerEndAt: registerEndAtFilterValue.value ? dateToString(registerEndAtFilterValue.value) : undefined,
            position: positionFilterValue.value ? positionFilterValue.value : undefined,
            status: statusFilterValue.value ? statusFilterValue.value : undefined,
        };

        return () => {
            searchList(pageSearchCondition, param)();
        }
    };

    const getCounselorListWithParam = useCallback(
        (isFirstLoad?: boolean) => {
            if (isFirstLoad) {
                getCounselorInfoList({}, true).then();
            }

            const counselorListQueryType = listQueryType as CounselorInfoListQueryType;
            getCounselorInfoList({
                pageCondition: {
                    page: counselorListQueryType.page ? Number(counselorListQueryType.page) : DEFAULT_PAGE,
                    size: counselorListQueryType.size ? Number(counselorListQueryType.size) : DEFAULT_SIZE,
                    sort: counselorListQueryType.sort ? toSortArray(counselorListQueryType.sort) : DEFAULT_SORT,
                },

                query: searchWordFilterValue.value ? searchWordFilterValue.value.trim() : undefined,
                query_type: searchWordTypeFilterValue.value,
                created_at_from: counselorListQueryType.registerStartAt ?
                    timeConverter.convertToFilterStartOfDay(counselorListQueryType.registerStartAt)
                    : undefined,
                created_at_to: counselorListQueryType.registerEndAt ?
                    timeConverter.convertToFilterStartOfDay(counselorListQueryType.registerEndAt)
                    : undefined,
                status: statusFilterValue.value ? statusFilterValue.value : undefined,
                consultantRank: positionFilterValue.value ? positionFilterValue.value : undefined,
            }).then();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [listQueryType]);

    useEffect(() => {
        if (listQueryType) {
            getCounselorListWithParam();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getCounselorListWithParam]);

    // page condition
    const pageCondition = useMemo(
        () => ({
            page: counselorInfoList?.number ?? DEFAULT_PAGE,
            size: counselorInfoList?.size ?? DEFAULT_SIZE,
            sort: ordersToSortArr(counselorInfoList?.orders),
        }),
        [counselorInfoList]
    );

    return {
        counselorInfoList,
        pageCondition,
        totalCounselorInfoCount,
        searchCounselorInfoList,
        getCounselorInfoList,
    };
}