import {Panel} from "../../../../layout/content/detail/Panel";
import {AdminTestHistoryListObjByProfile, AdminUserProfileTesterDetail} from "../../../../types/userProfile";
import {Table} from "../../../../layout/content/list/table/Table";
import {TableColumnData} from "../../../../types/common";
import {ActionButtonWrapper} from "../../../../layout/content/ActionButtonWrapper";
import {PaginationWrapper} from "../../../../layout/content/list/PaginationWrapper";
import {Pagination} from "../../../../layout/content/list/Pagination";
import {ActionButton} from "../../../../components/Buttons";
import {useOpenTestResult} from "../../../../hooks/useOpenTestResult";
import {useTimeConverter} from "../../../../hooks/hooks";
import {useTranslation} from "react-i18next";
import {useGenderType} from "../../../../types/hooks/useGenderType";
import {VoucherType} from "../../../../types/voucher";
import {useEffect} from "react";
import {useTesterHistoryGetter} from "./hooks/useTesterHistoryGetter";

interface Props {
  profileIdx: number;
  profile:AdminUserProfileTesterDetail
}

export function ProfileTestHistory(
    { profileIdx, profile }: Props) {
  const { t } = useTranslation(["user", "common"]);
  const openTestResult = useOpenTestResult();
  const { timeConverter } = useTimeConverter();
  const { toStringFromGender } = useGenderType();



  const columns: TableColumnData<AdminTestHistoryListObjByProfile>[] = [
    {
      accessor: "unitVoucherType",
      header: t("detail.profile.testHistory.type"),
      sortable: false,
      getNode: (it) => <span>{VoucherType[it.unitVoucherType as keyof typeof VoucherType]}</span>,
    },
    {
      accessor: "endedAt",
      header: t("detail.profile.testHistory.endedAt"),
      sortable: true,
      getNode: (it) => <span>{timeConverter.convertToLocalTime(it.endedAt) ?? ""}</span>,
    },
    {
      accessor: "name",
      header: t("detail.profile.testHistory.name"),
      sortable: false,
    },
    {
      accessor: "ageYear",
      header: t("detail.profile.testHistory.age"),
      sortable: false,
      getNode: (it) => <span>{it.ageYear ?? t("noInputData", { ns: "common" })}</span>,
    },
    {
      accessor: "gender",
      header: t("detail.profile.testHistory.gender"),
      sortable: false,
      getNode: (it) => <span>{it.gender ? toStringFromGender(it.gender) : t("noInputData", { ns: "common" })}</span>,
    },
    {
      accessor: "region",
      header: t("detail.profile.testHistory.region"),
      sortable: false,
      getNode: (it) => <span>{it.region ?? t("noInputData", { ns: "common" })}</span>,
    },
    {
      accessor: "testRound",
      header: t("detail.profile.testHistory.testCount.label"),
      sortable: false,
      getNode: (it) => (
          <span>
          {it.testRound}
            {t("detail.profile.testHistory.testCount.unit")}
        </span>
      ),
    },
    {
      accessor: "idx",
      header: t("detail.profile.testHistory.additionalFunctions"),
      sortable: false,
      getNode: (it) => (
          <ActionButtonWrapper>
            <>
              <ActionButton onClick={() => openTestResult(
                  it.idx, VoucherType[it.unitVoucherType as keyof typeof VoucherType],
                  VoucherType[it.unitVoucherType as keyof typeof VoucherType] === VoucherType.KPASS
                      ? it.kpassResultForm
                      : it.dcasResultForm)
              }>
                {t("detail.profile.testHistory.testResult")}
              </ActionButton>
            </>
          </ActionButtonWrapper>
      ),
    },
  ];

  const {getTestHistoryList, testHistoryList} = useTesterHistoryGetter();

  useEffect(() => {
    const testHistory = getTestHistoryList({profileIdx:profileIdx});
    testHistory();
    //eslint-disable-next-line
  }, [profileIdx, profile]);

  return (
      <Panel title={t("detail.profile.testHistory.title")} subtitle={`(${profile.name})`}>
        {testHistoryList ? (
            <>
              <Table
                  listData={testHistoryList}
                  columns={columns}
                  getList={() =>getTestHistoryList({profileIdx:profileIdx})}
                  noDataText={t("detail.profile.testHistory.noData")}
              />
              {testHistoryList.content.length === 0 ? (
                  <></>
              ) : (
                  <PaginationWrapper>
                    <Pagination page={testHistoryList} goSpecificPage={(pageNum) => {
                      getTestHistoryList(
                          { page: pageNum, profileIdx: profileIdx }
                      )();
                    }} />
                  </PaginationWrapper>
              )}
            </>
        ) : (
            <></>
        )}
      </Panel>
  );
}
