import {Panel} from "../../../layout/content/detail/Panel";
import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {DescriptionList} from "../../../layout/content/detail/DescriptionList";
import {useHistoryInfoDetailUtil} from "./hooks/useHistoryInfoDetailUtil";
import {useLocation, useParams} from "react-router-dom";
import {useHistoryPreQuestionGetter} from "./hooks/useHistoryPreQuestionGetter";
import {CounselorHistoryInfoMenus} from "./CounselorHistoryInfoMenus";

export function HistoryInfoDetail() {
    /**
     * 상담 정보 개별창
     * 상세
     */

    const {id} = useParams();
    const idx = Number(id);
    const {t} = useTranslation("counselorInfo");
    // 파라미터 타입 추출
    const type = new URLSearchParams(useLocation().search).get('type');
    const [isEditMode, ] = useState(false);
    const formRef = useRef<HTMLFormElement>(null);

    const {getHistoryInfo} = useHistoryInfoDetailUtil();

    const {getCounselorHistory, counselorHistory} = useHistoryPreQuestionGetter();

    useEffect(() => {
        getCounselorHistory(idx).then();
        //eslint-disable-next-line
    }, [idx]);


    return (
        <>
            {/*상담 소속정보 (/detail/counselor_group/1)*/}
            {counselorHistory && type && (
                <>
                    <Panel title={t('history.title')}
                           additionalButtons={<></>}
                    >
                        <form ref={formRef} onSubmit={(e) => e.preventDefault()}>
                            <DescriptionList
                                data={getHistoryInfo(counselorHistory)}
                                isEditMode={isEditMode}
                                labelWidth={132}
                            />
                        </form>
                    </Panel>
                    <CounselorHistoryInfoMenus
                        counselorHistory={counselorHistory}
                        type={type}/>
                </>
            )}
        </>
    );
}