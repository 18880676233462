import {useAppDispatch, useListFilterValues} from "../../../../hooks/hooks";
import {useUtil} from "../../../../util/hooks/useUtil";
import {DEFAULT_PAGE, DEFAULT_SIZE, ordersToSortArr, PageResponse, PageSearchCondition} from "../../../../types/page";
import {CounselorHistoryListQueryType, CounselorHistoryListVO} from "../../../../types/counselor";
import {dateToString} from "../../../../util/dateUtil";
import {useCallback, useEffect, useMemo, useState} from "react";
import {
    adminCounselingHistoryAsync,
    consultantCounselingHistoryAsync,
    consultantManagerCounselingHistoryAsync
} from "../../../../store/counselorSlice";
import {AdminRoleCategory} from "../../../../types/adminRole";

export function useHistoryList() {
    const { defaultErrorMessage} = useUtil();
    const dispatch = useAppDispatch();

    // 응답 결과에 따라서 수정해야함
    const [totalCounselorHistoryCount, setTotalCounselorHistoryCount] = useState<number | null>(null);
    const [counselorHistoryList, setCounselorHistoryList] =
        useState<PageResponse<CounselorHistoryListVO>>();

    // 권한별 API 요청 처리 함수
    const getCounselingHistoryDispatcher = (role: string) => {
        switch (role) {
            case AdminRoleCategory.CONSULTANT_MANAGER:
                return consultantManagerCounselingHistoryAsync;
            case AdminRoleCategory.CONSULTANT:
                return consultantCounselingHistoryAsync;
            default:
                return adminCounselingHistoryAsync;
        }
    };

    // 상담 내역 조회 API
    const getCounselorHistoryList = useCallback(
        async (param: CounselorHistoryListQueryType, isFirstLoad?: boolean) => {
            try {
                const role = localStorage.getItem('role')
                // 권한별 조회 분기처리
                if (role) {
                    const dispatchFunction = getCounselingHistoryDispatcher(role);
                    const result: PageResponse<CounselorHistoryListVO> = await dispatch(dispatchFunction(param)).unwrap();
                    setCounselorHistoryList(result);
                    setTotalCounselorHistoryCount(result.totalElements)
                }
            } catch (e) {
                alert(defaultErrorMessage);
            }
        },
        //eslint-disable-next-line
        []
    );

    const { filterValues, searchList, listQueryType } = useListFilterValues();

    // 검색 파라미터
    const {
        searchWordTypeFilterValue,
        searchWordFilterValue,
        registerStartAtFilterValue,
        registerEndAtFilterValue,
        testTypeFilterValue,
        statusFilterValue,
    } = filterValues;

    const searchCounselorHistoryList = (pageSearchCondition: PageSearchCondition) => {
        const param: CounselorHistoryListQueryType = {
            queryType: searchWordTypeFilterValue.value,
            query: searchWordFilterValue.value ? searchWordFilterValue.value.trim() : undefined,
            createdAtFrom: registerStartAtFilterValue.value ? dateToString(registerStartAtFilterValue.value) : undefined,
            createdAtTo: registerEndAtFilterValue.value ? dateToString(registerEndAtFilterValue.value) : undefined,
            desiredAtFrom: registerStartAtFilterValue.value ? dateToString(registerStartAtFilterValue.value) : undefined,
            desiredAtTo: registerEndAtFilterValue.value ? dateToString(registerEndAtFilterValue.value) : undefined,
            testType: testTypeFilterValue.value ? testTypeFilterValue.value : undefined,
            status: statusFilterValue.value ? statusFilterValue.value : undefined,
        };

        return () =>{
            searchList(pageSearchCondition, param)();
        }
    };

    const getCounselorHistoryListWithParam = useCallback(
        (isFirstLoad?: boolean) => {
            if (isFirstLoad) {
                getCounselorHistoryList({}, true).then();
            }

            const counselorHistoryQueryType = listQueryType as CounselorHistoryListQueryType;
            getCounselorHistoryList(counselorHistoryQueryType).then();
            //eslint-disable-next-line
        },[listQueryType])

    useEffect(() => {
        if (listQueryType) {
            getCounselorHistoryListWithParam();
        }
    }, [listQueryType,getCounselorHistoryListWithParam]);


    // page condition
    const pageCondition = useMemo(
        () => ({
            page: counselorHistoryList?.number ?? DEFAULT_PAGE,
            size: counselorHistoryList?.size ?? DEFAULT_SIZE,
            sort: ordersToSortArr(counselorHistoryList?.orders),
        }),
        [counselorHistoryList]
    );
    return{
        counselorHistoryList,
        pageCondition,
        totalCounselorHistoryCount,
        searchCounselorHistoryList,
        getCounselorHistoryList
    }

}