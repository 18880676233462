import React, {MutableRefObject, useCallback, useEffect, useState} from "react";
import {
    CalculatePanelStyle,
    CalculatePanelTitleStyle,
    StatisticsPanelContentWrapperStyle,
    StatisticsPanelItemStyle,
    StatisticsPanelItemTitleStyle,
    StatisticsPanelItemWrapperStyle
} from "../../affiliate_code/settlement/SettlementStyle";
import {StrongTextStyle} from "../../../components/Texts";
import {numberWithCommas} from "../../../util/etcUtil";
import {useCounselorSettlementGetter} from "./hooks/useCounselorSettlementGetter";
import {MonthlySettlementDTO} from "../../../types/counselor";
import {useUtil} from "../../../util/hooks/useUtil";
import {
    AffiliateCodeSettlementListFilterValues
} from "../../affiliate_code/settlement/hooks/useAffiliateCodeSettlementFilterValues";
import {useListFilterValues} from "../../../hooks/hooks";

interface Props {
    refreshRef: MutableRefObject<(y: string, m: string) => void>;
}
export function CounselorSettlementStatistics({refreshRef}:Props) {

    const [statics, setStatics] = useState<MonthlySettlementDTO>();
    const { defaultErrorMessage } = useUtil();
    const {getAdminMonthlyStatics} = useCounselorSettlementGetter();
    const { filterValues } = useListFilterValues();
    const { yearFilterValue, monthFilterValue } = filterValues as AffiliateCodeSettlementListFilterValues;

    const refresh = useCallback(
        (y: string, m: string) => {
            getAdminMonthlyStatics(Number(y), Number(m))
                .then((result) => setStatics(result))
                .catch(() => alert(defaultErrorMessage));
        },
        [getAdminMonthlyStatics, defaultErrorMessage]
    );

    useEffect(() => {
        refreshRef.current = refresh;
    }, [refresh, refreshRef]);

    useEffect(() => {
        if (Boolean(yearFilterValue.value && monthFilterValue.value)) {
            refresh(yearFilterValue.value, monthFilterValue.value);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [yearFilterValue.value, monthFilterValue.value]);

    return(
        <>
            <CalculatePanelStyle>
                <CalculatePanelTitleStyle>월별 상담내역 통계</CalculatePanelTitleStyle>
                <StatisticsPanelContentWrapperStyle>
                    <StatisticsPanelItemWrapperStyle>
                        <StatisticsPanelItemTitleStyle>정산 완료 회원</StatisticsPanelItemTitleStyle>
                        <StatisticsPanelItemStyle className={"completed"}>
                            <StrongTextStyle>{statics?.completeCount ?? 0}</StrongTextStyle>
                            <span>명</span>
                        </StatisticsPanelItemStyle>
                    </StatisticsPanelItemWrapperStyle>
                    <StatisticsPanelItemWrapperStyle>
                        <StatisticsPanelItemTitleStyle>정산 미완료 회원</StatisticsPanelItemTitleStyle>
                        <StatisticsPanelItemStyle className={"uncompleted"}>
                            <StrongTextStyle>{statics?.incompleteCount ?? 0}</StrongTextStyle>
                            <span>명</span>
                        </StatisticsPanelItemStyle>
                    </StatisticsPanelItemWrapperStyle>
                    <StatisticsPanelItemWrapperStyle>
                        <StatisticsPanelItemTitleStyle>총 상담수</StatisticsPanelItemTitleStyle>
                        <StatisticsPanelItemStyle>
                            <StrongTextStyle>{statics?.totalCounselingCount ?? 0}</StrongTextStyle>
                            <span>건</span>
                        </StatisticsPanelItemStyle>
                    </StatisticsPanelItemWrapperStyle>
                    <StatisticsPanelItemWrapperStyle className={"total"}>
                        <StatisticsPanelItemTitleStyle>총 정산금액</StatisticsPanelItemTitleStyle>
                        <StatisticsPanelItemStyle className={"totalAmount"}>
                            <StrongTextStyle>{numberWithCommas(statics?.totalSettlementValue ?? 0)}</StrongTextStyle>
                            <span>원</span>
                        </StatisticsPanelItemStyle>
                    </StatisticsPanelItemWrapperStyle>
                </StatisticsPanelContentWrapperStyle>
            </CalculatePanelStyle>
        </>
    )
}