import { ListFilterValuesContext } from "../../hooks/list/ListFilterValuesContext";
import {useExternalVoucherFilterValues} from "./hooks/useExternalVoucherFilterValues";
import {ExternalVoucherList} from "./ExternalVoucherList";

export function VoucherListWrapper() {
    const {pageInfoOnFilter, filterValues, searchList, listQueryType}
        = useExternalVoucherFilterValues();

    return(
        <ListFilterValuesContext.Provider
            value={{
                filterValues,
                pageInfoOnFilter,
                searchList,
                listQueryType,
            }}
        >
            <ExternalVoucherList />
        </ListFilterValuesContext.Provider>
    )
}