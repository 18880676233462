import {useTranslation} from "react-i18next";
import {
    ConsultantDetailDTO,
    CounselorInfoPutDTO,
    CounselorPosition,
    TaxSelectedValue
} from "../../../../types/counselor";
import {DescriptionValue} from "../../../../layout/content/detail/DescriptionList";
import React, {useCallback, useEffect, useState} from "react";
import {GridInputNumber, GridInputText} from "../../../../components/Inputs";
import {InputPhoneNumber} from "../../../../components/InputPhoneNumber";
import {ImageWrapperStyle, LargeInputWrapper, RadioWrapperStyle} from "../../style/CounselorDataStyle";
import {InputRefundAccount} from "../../../../components/InputRefundAccount";
import {AddedFile, FileType} from "../../../../types/file";
import {AnImageAddition} from "../../../../components/AnImageAddition";
import {CounselorRadioField} from "../../registration/CounselorRadioField";
import styled from "../../../member/user/memberUserDetail.module.scss";
import {BlockReasonModal} from "../../../component/modal/BlockReasonModal";
import {useCounselorConverter} from "./useCounselorConverter";
import {useCounselorDetailGetter} from "../../detail/hooks/useCounselorDetailGetter";
import {useParams} from "react-router-dom";
import {useCountry} from "../../../../hooks/hooks";
import {NormalImageButton} from "../../../../components/NormalImageButton";
import {AdminStatus} from "../../../../types/admin";

interface Args {
    counselorInfoPutParam: (_: CounselorInfoPutDTO) => void
}
export function useCounselorInfoDetailUtil({counselorInfoPutParam}:Args) {
    const {id} = useParams<'id'>() as { id: string };

    const {t} = useTranslation("counselorInfo");
    const [showBlockedReason, setShowBlockedReason] = useState(false);
    const {getInfoSearchPositionConverter, getInfoSearchStatusConverter} = useCounselorConverter();
    const {getConsultantDetail, consultantDetail} = useCounselorDetailGetter(id);

    const {getCountryNameByCodeISOAlpha2} = useCountry();

    const [name, setName] = useState('');
    const [codePhone, setCodePhone] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [briefHistory, setBriefHistory] = useState('');
    const [bankName, setBankName] = useState('');
    const [accountNumber, setAccountNumber] = useState('');
    const [accountName, setAccountName] = useState('');
    const [addedFile, setAddedFile] = useState<AddedFile | undefined>();
    const [image, setImage] = useState<FileType | undefined>();
    const [distributionAmount, setDistributionAmount] = useState(0);
    const [isVatInclude, setIsVatInclude] = useState(TaxSelectedValue.UNSELECT);
    const [isTaxDeduction, setIsTaxDeduction] = useState(TaxSelectedValue.UNSELECT);

    useEffect(() => {
        if (id) getConsultantDetail().then();
        //eslint-disable-next-line
    }, [id]);

    const resetData = useCallback(() => {
        setName(consultantDetail.name)
        setCodePhone(consultantDetail.codePhone);
        setPhoneNumber(consultantDetail.phoneNumber);
        setBriefHistory(consultantDetail.consultantInfoDTO.briefHistory);
        setBankName(consultantDetail.consultantInfoDTO.bankName);
        consultantDetail.image &&
        setAddedFile({fileIdx: consultantDetail.image?.idx, uri: consultantDetail.image?.uri});
        // setImage({})
        setAccountName(consultantDetail.consultantInfoDTO.accountName);
        setAccountNumber(consultantDetail.consultantInfoDTO.accountNumber);

        setDistributionAmount(consultantDetail.consultantInfoDTO.distributionAmount)
        setIsVatInclude(consultantDetail.consultantInfoDTO.isVatInclude
            ? TaxSelectedValue.SELECT
            : TaxSelectedValue.UNSELECT
        );
        setIsTaxDeduction(consultantDetail.consultantInfoDTO.isTaxDeduction
            ? TaxSelectedValue.SELECT
            : TaxSelectedValue.UNSELECT);
        //eslint-disable-next-line
    }, [consultantDetail, id]);

    useEffect(() => {
        resetData();
    }, [resetData]);

    useEffect(() => {
        counselorInfoPutParam({
            name: name,
            codePhone: codePhone,
            phoneNumber: phoneNumber,
            briefHistory: briefHistory,
            counselingAffiliationIdx: consultantDetail.affiliationIdx,
            bankName: bankName,
            accountNumber: accountNumber,
            accountName: accountName,
            consultantDistributionAmount: distributionAmount,
            isVatInclude: isVatInclude === TaxSelectedValue.SELECT,
            isTaxDeduction: isTaxDeduction === TaxSelectedValue.SELECT,
            image: image,
            addedFile:addedFile
        })
        //eslint-disable-next-line
    }, [name, codePhone, phoneNumber, briefHistory, bankName,accountNumber,accountName,
        distributionAmount,isVatInclude, isTaxDeduction, image, addedFile]);

    const getCounselorInfo = (data: ConsultantDetailDTO): DescriptionValue[][] => [
        [
            {
                key: t("counselor.name"), value: data.name,
                editModeValue: (
                    <GridInputText
                        value={name}
                        required={true}
                        minLength={2}
                        maxLength={50}
                        onChange={(e) => setName(e.target.value)}
                    />
                ),
            },
            {key: t("counselor.registeredAt"), value: data?.createAt,},
        ],
        [
            {key: t("counselor.id"), value: data?.email,},
            {
                key: t("counselor.authStatus"), value:
                    <div style={{position:"relative"}}>
                        <span
                            className={
                                `${data.status === AdminStatus.BLOCKED
                                    ? styled.blocked : ''}`}
                            onClick={(e) => {
                                e.stopPropagation();
                                setShowBlockedReason(true);
                            }}
                        >
                            {getInfoSearchStatusConverter(data.status as AdminStatus)}
                        </span>
                        <BlockReasonModal
                            isShow={showBlockedReason}
                            callbackCloseModal={() => setShowBlockedReason(false)}
                            blockAt={data.blockAt}
                            reasonForBlocking={data.reasonForBlocking}
                        />
                    </div>,

            },
        ],
        [
            {
                key: t("counselor.cellPhone"),
                value: <>({data?.codePhone}) {data?.phoneNumber}</>,
                editModeValue: (
                    <InputPhoneNumber
                        codePhone={codePhone} onChangeCodePhone={(data) => setCodePhone(data)}
                        phoneNumber={phoneNumber} onChangePhoneNumber={(data) => setPhoneNumber(data)}
                        min={11} max={11}
                    />
                ),
            },
            {
                key: t("counselor.position"),
                value: getInfoSearchPositionConverter(data?.consultantRank as CounselorPosition)
            },
        ],
        [
            {
                key: t("counselor.profileImage"),
                value: (
                    <>{addedFile ? (<NormalImageButton imageSrc={addedFile?.uri}/>) : <></>}</>
                ),
                editModeValue: (
                    <ImageWrapperStyle>
                        <AnImageAddition
                            image={addedFile}
                            setImage={(v) => setAddedFile(v)}
                            addedImage={image}
                            setAddedImage={(v) => setImage(v)}
                        />
                        <span>* 프로필 이미지는 가로:세로 1:1 비율의 이미지를 등록해 주세요.<br/>
                              * 권장 이미지 : 1M 이하 / gif, png, jpg(jpeg)<br/>
                              * 이미지 크기 : 600px*600px</span>
                    </ImageWrapperStyle>
                ),
            },
        ],
        [
            {
                key: t("counselor.counselorProfile"), value: data?.consultantInfoDTO.briefHistory,
                editModeValue: (
                    <LargeInputWrapper>
                        <GridInputText
                            maxLength={400}
                            value={briefHistory}
                            onChange={(e) =>
                                setBriefHistory(e.target.value)
                            }
                        />
                    </LargeInputWrapper>
                ),
            },
        ],
        [
            {
                key: t("counselor.accountInfo"),
                value:
                    <>
                        환불은행
                        : {data?.consultantInfoDTO.bankName} / 환불계좌
                        : {data?.consultantInfoDTO.accountNumber} / 예금주
                        : {data?.consultantInfoDTO.accountName}
                    </>,
                editModeValue: (
                    <InputRefundAccount
                        bankName={bankName}
                        onChangeBankName={setBankName}
                        accountNumber={accountNumber}
                        onChangeAccountNumber={setAccountNumber}
                        accountName={accountName}
                        onChangeAccountName={setAccountName}
                        required={true}
                    />
                ),
            },
        ],
        [
            {key: t("counselor.lastHistoryDate"), value: data?.createAt,},
            {key: t("counselor.country"), value: getCountryNameByCodeISOAlpha2(data?.nationality),},
        ],
    ];

    // 소속이 있는 경우
    const getCounselorGroupAffiliated = (data: ConsultantDetailDTO): DescriptionValue[][] => [
        [
            {key: t("group.groupName"), value: data?.name,},
            {key: t("group.cellPhone"), value: data?.affiliationPhoneNumber,},
        ],
        [
            {
                key: t("group.address"),
                value: <>{data?.affiliationAddress.address1}</>
            },
            {key: '', value: <>{data?.affiliationAddress.address2}</>,},
        ],
    ];

    // 소속이 없는 경우
    const getCounselorGroupUnAffiliated = (data: ConsultantDetailDTO): DescriptionValue[][] => [
        [
            {key: t("group.groupName"), value: '무소속',},
            {
                key: t("group.counselorDistribution"),
                value: data?.consultantInfoDTO.distributionAmount.toString(),
                editModeValue:(
                    <GridInputNumber
                        value={distributionAmount}
                        required={true} minLength={0} maxLength={10}
                        onChange={(e) => setDistributionAmount(Number(e.target.value))}
                    />
                )
            },
        ],
        [
            {
                key: t("group.addedTax"),
                value:
                    <>
                        {data.consultantInfoDTO.isVatInclude ?
                            '10%' : '-'
                        }
                    </>,
                editModeValue: (
                    <RadioWrapperStyle>
                        <CounselorRadioField
                            selectedValue={isVatInclude} setSelectedValue={setIsVatInclude}
                            groupName={'testAdded'}
                        />
                    </RadioWrapperStyle>
                )
            },
            {
                key: t("group.taxDeduction"),
                value:
                    <>
                        {data.consultantInfoDTO.isTaxDeduction ?
                            '10%' : '-'
                        }
                    </>,
                editModeValue: (
                    <RadioWrapperStyle>
                        <CounselorRadioField
                            selectedValue={isTaxDeduction} setSelectedValue={setIsTaxDeduction}
                            groupName={'testDeducted'}
                        />
                    </RadioWrapperStyle>
                )
            },
        ]
    ];

    return {
        getCounselorInfo,
        getCounselorGroupAffiliated,
        getCounselorGroupUnAffiliated,
        resetData
    }
}