import {useCounselorInfoList} from "./hooks/useCounselorInfoList";
import React from "react";
import {useCounselorInfoFilters} from "./hooks/useCounselorInfoFilters";
import {ListTable} from "../../../layout/content/list/ListTable";
import {useCounselorColumns} from "./hooks/useCounselorColumns";
import {ListCount, WhichList} from "../../../components/ListCount";
import {DetailActionButton} from "../../../components/Buttons";
import organAddImg from "../../../assets/images/button_icons/add_button.svg"
import counselorAddImg from "../../../assets/images/button_icons/person-add-icon.png"
import {useNavigate} from "react-router-dom";
import {useMyInfoGetter} from "../../account/mypage/hooks/useMyInfoGetter";

export function CounselorInfoList() {
    const {
        counselorInfoList,
        totalCounselorInfoCount,
        pageCondition,
        searchCounselorInfoList
    } = useCounselorInfoList();

    const {filters} = useCounselorInfoFilters({search: searchCounselorInfoList({})})
    const {isConsultant} = useMyInfoGetter();
    const {columns} = useCounselorColumns();
    const navigate = useNavigate();
    const onClickAddOrgan = () => {
        navigate('/counselor/manager/post')
    }

    const onClickAddCounselor = () => {
        navigate('/counselor/manager/counselor_post')
    }
    return (
        <>
            <ListTable
                filters={filters}
                buttonsNode={
                    <div>
                        {!isConsultant() && (
                            <DetailActionButton onClick={() => onClickAddOrgan()}>
                                <img src={organAddImg} alt={"사용중지 아이콘"}/>
                                &nbsp;소속 등록
                            </DetailActionButton>
                        )}
                        <DetailActionButton onClick={() => onClickAddCounselor()}>
                            <img src={counselorAddImg} alt={"상담사 등록"}/>
                            &nbsp;상담사 등록
                        </DetailActionButton>
                    </div>
                }
                listData={counselorInfoList}
                getList={({page, sort, size}) => searchCounselorInfoList({page, sort, size})}
                columns={columns}
                totalRowsCnt={{
                    totalCount: <ListCount totalCount={totalCounselorInfoCount ?? 0} whichList={WhichList.COUNSELING}/>,
                    singleUnitKeyInCommonFile: "codeUnit.singleComponent",
                    multipleUnitKeyInCommonFile: "codeUnit.multipleComponent",
                }}
                pageOnFilter={pageCondition}
            />
        </>
    );
}