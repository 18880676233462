import {ListQueryType} from "./list";
import {SearchCondition} from "./page";
import {GrantProductVoucherRequestParam} from "./voucher";

export interface ExternalVoucherListDTO{
    idx: number;
    createAt: string;
    externalChannel:ExternalChannel;
    code: string;
    purchasedUserName: string;
    purchasedUserCellphone: string;
    externalVoucherStatus: ExternalVoucherStatus;
    productNames: string[];
    userId: string;
    userName: string;
}

export enum ExternalVoucherFilterWordType {
    CODE = "CODE",
    GRANTED_NAME = "GRANTED_NAME",
    USER_NAME = "USER_NAME",
    ID = "ID",
}

export enum ExternalChannel {
    NAVER = "NAVER",
    KAKAO = "KAKAO",
    ETC = "ETC",
}

export enum ExternalVoucherStatus{
    BEFORE_REGISTERED = "BEFORE_REGISTERED",
    REGISTERED = "REGISTERED",
    DELETED = "DELETED",
    ALARMED = "ALARMED",
}


// 검색 요청 DTO
export interface VoucherListQueryType extends ListQueryType {
    searchWordType?: string;
    searchWord?: string;
    createdFrom?: string;
    createdTo?: string;
    channel?: string;
    status?: string;
}

export interface VoucherListSearchCondition extends SearchCondition {
    query?: string;
    query_type?: ExternalVoucherFilterWordType;
    created_from?: Date;
    created_to?: Date;
    external_voucher_status?: ExternalVoucherStatus;
    external_channel?: ExternalChannel;
}

// 이용권 코드 생성 POST DTO
export interface ExternalVoucherPostDTO{
    grantProductVouchersRequest: GrantProductVoucherRequestParam[],
    channel: ExternalChannel,
    voucherQuantity: number;
}

// 이용권 안내 PUT DTO
export interface ExternalInformationPutDTO{
    idx: number,
    purchasedUserName: string,
    purchasedUserCellphone: string
}

// 이용권 삭제 DELETE DTO
export interface VoucherDeleteDTO{
    idx: number[]
}

// 이용권 등록 Post API
export interface AssignVoucherDTO{
    userIdx: number;
    voucherIdx: number;
}