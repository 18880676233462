import {useListFilterValues, useTimeConverter} from "../../../../hooks/hooks";
import {useTranslation} from "react-i18next";
import {useMemo} from "react";
import {FilterContentType, TableFilter} from "../../../../types/tableFilter";
import {CounselorFilterWordType, CounselorPosition} from "../../../../types/counselor";
import {useCounselorConverter} from "./useCounselorConverter";
import {AdminStatus} from "../../../../types/admin";

interface Args {
    search: () => void;
}

export function useCounselorInfoFilters({search}: Args) {
    const {t} = useTranslation(["filter", "common"]);
    const {filterValues} = useListFilterValues();
    const {timeConverter} = useTimeConverter();

    const {
        getInfoSearchWordConverter,
        getInfoSearchPositionConverter,
        getInfoSearchStatusConverter
    } = useCounselorConverter();


    const searchWordTypeFilter = useMemo(
        () => filterValues.searchWordTypeFilterValue,
        [filterValues.searchWordTypeFilterValue]
    );
    const searchWordFilter = useMemo(() => filterValues.searchWordFilterValue, [filterValues.searchWordFilterValue]);
    const registerStartAtFilter = useMemo(
        () => filterValues.registerStartAtFilterValue,
        [filterValues.registerStartAtFilterValue]
    );
    const registerEndAtFilter = useMemo(
        () => filterValues.registerEndAtFilterValue,
        [filterValues.registerEndAtFilterValue]
    );
    const positionFilter = useMemo(
        () => filterValues.positionFilterValue,
        [filterValues.positionFilterValue]
    );
    const statusFilter = useMemo(() => filterValues.statusFilterValue,
        [filterValues.statusFilterValue]);

    const filters: TableFilter[][] = [
        [
            {
                labelTitle: "검색어",
                content: {
                    type: FilterContentType.TEXT,
                    value: searchWordFilter?.value,
                    maxLength: 100,
                    placeholderVal: t("searchWord.placeholder"),
                    onChangeFunc: (v) => searchWordFilter.setter(v),
                    onEnterKeyUpFunc: search,
                    typeSelector: {
                        type: FilterContentType.SELECT,
                        value: searchWordTypeFilter?.value,
                        options: [
                            {label: "전체", value: ""},
                            {
                                value: CounselorFilterWordType.COUNSELING_AFFILIATION,
                                label: getInfoSearchWordConverter(CounselorFilterWordType.COUNSELING_AFFILIATION),
                            },
                            {
                                value: CounselorFilterWordType.NAME,
                                label: getInfoSearchWordConverter(CounselorFilterWordType.NAME),
                            },
                            {
                                value: CounselorFilterWordType.ID,
                                label: getInfoSearchWordConverter(CounselorFilterWordType.ID),
                            },
                            {
                                value: CounselorFilterWordType.CELLPHONE,
                                label: getInfoSearchWordConverter(CounselorFilterWordType.CELLPHONE),
                            },
                        ],
                        onChangeFunc: (v) => searchWordTypeFilter?.setter(v as CounselorFilterWordType),
                    },
                },
            },
        ],
        [
            {
                labelTitle: "등록일",
                content: {
                    type: FilterContentType.CALENDAR_RANGE,
                    from: {
                        type: FilterContentType.CALENDAR,
                        value: timeConverter.convertToFilterDateString(registerStartAtFilter?.value),
                        onChangeFunc: (v) =>
                            registerStartAtFilter?.setter(v ? timeConverter.convertToFilterStartOfDay(v) : undefined),
                        placeholderVal: t("inputDatePlaceholder"),
                    },
                    to: {
                        type: FilterContentType.CALENDAR,
                        value: timeConverter.convertToFilterDateString(registerEndAtFilter?.value),
                        onChangeFunc: (v) => registerEndAtFilter?.setter(
                            v ? timeConverter.convertToFilterEndOfDay(v) : undefined),
                        placeholderVal: t("inputDatePlaceholder"),
                    },
                },
            },
            {
                labelTitle: "직급",
                content: {
                    type: FilterContentType.SELECT,
                    value: positionFilter.value,
                    options: [
                        {label: "전체", value: ""},
                        {
                            value: CounselorPosition.CONSULTANT_MANAGER.toString(),
                            label: getInfoSearchPositionConverter(CounselorPosition.CONSULTANT_MANAGER),
                        },
                        {
                            value: CounselorPosition.CONSULTANT.toString(),
                            label: getInfoSearchPositionConverter(CounselorPosition.CONSULTANT),
                        },
                    ],
                    onChangeFunc: (v) => positionFilter.setter(v as unknown as CounselorPosition),
                },
            },
            {
                labelTitle: "상태",
                content: {
                    type: FilterContentType.SELECT,
                    value: statusFilter.value,
                    options: [
                        {label: "전체", value: ""},
                        {
                            value: AdminStatus.BEFORE_SELF_AUTHENTICATION.toString(),
                            label: getInfoSearchStatusConverter(AdminStatus.BEFORE_SELF_AUTHENTICATION),
                        },
                        {
                            value: AdminStatus.BEFORE_ADMIN_AUTHENTICATION.toString(),
                            label: getInfoSearchStatusConverter(AdminStatus.BEFORE_ADMIN_AUTHENTICATION),
                        },
                        {
                            value: AdminStatus.BLOCKED.toString(),
                            label: getInfoSearchStatusConverter(AdminStatus.BLOCKED),
                        },
                        {
                            value: AdminStatus.LEAVE.toString(),
                            label: getInfoSearchStatusConverter(AdminStatus.LEAVE),
                        }
                    ],
                    onChangeFunc: (v) => statusFilter.setter(v as unknown as AdminStatus),
                },
            },
        ],
    ];

    return {
        filters,
    };
}