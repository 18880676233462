import style from "./style/registration.module.scss";
import React, {useState} from "react";
import {CounselorGroupDTO, CounselorManagerDTO} from "../../types/counselor";
import {useTranslation} from "react-i18next";
import {Post} from "../../layout/content/post/Post";
import {ValidStatus} from "../../types/common";
import {useCounselorGroupValidator} from "./registration/hooks/useCounselorGroupValidator";
import {CounselorField} from "./registration/CounselorField";
import {CounselorGroupField} from "./registration/CounselorGroupField";
import {useCounselorCreator} from "./registration/hooks/useCounselorCreator";

export function CounselorGroupRegistration() {
    /**
     * 상담소속 & 상담소속 관리자 등록 페이지
     */
    const [groupDTO, setGroupDTO] = useState<CounselorGroupDTO>();
    const [counselorDTO, setCounselorDTO] = useState<CounselorManagerDTO>();
    const {createCounselorAffiliation} = useCounselorCreator();

    // 상담 소속 유효성 검사 상태
    const [groupNameValidate, setGroupNameValidate] =
        useState(ValidStatus.NONE);
    const [addressValidate, setAddressValidate] =
        useState(ValidStatus.NONE);
    const [affiliationDistributionValidate, setAffiliationDistributionValidate] =
        useState(ValidStatus.NONE);
    const [consultantDistributionValidate, setConsultantDistributionValidate] =
        useState(ValidStatus.NONE);

    // 상담관리자 유효성 검사 상태
    const [idValidate, setIdValidate] = useState(ValidStatus.NONE);
    const [cellPhoneValidate, setCellPhoneValidate] = useState(ValidStatus.NONE);
    const [nameValidate, setNameValidate] = useState(ValidStatus.NONE);
    const [passwordValidate, setPasswordValidate] = useState(ValidStatus.NONE);
    const [passwordConfirmValidate, setPasswordConfirmValidate] =
        useState(ValidStatus.NONE);


    const {t} = useTranslation("counselorGroup");

    const {validate} = useCounselorGroupValidator({
        groupDTO: groupDTO,
        counselorDTO: counselorDTO,
        groupNameValidate:groupNameValidate,
        setGroupNameValidate: setGroupNameValidate,
        addressValidate: addressValidate,
        setAddressValidate: setAddressValidate,
        affiliationDistributionValidate: affiliationDistributionValidate,
        setAffiliationDistributionValidate: setAffiliationDistributionValidate,
        consultantDistributionValidate: consultantDistributionValidate,
        setConsultantDistributionValidate: setConsultantDistributionValidate,

        idValidate, setIdValidate,
        cellPhoneValidate, setCellPhoneValidate,
        nameValidate,setNameValidate,
        passwordValidate, setPasswordValidate,
        passwordConfirmValidate, setPasswordConfirmValidate
    });


    const onClickCreateButton = () => {

        const result = validate();
        if(!result) return false;
        if(!groupDTO || !counselorDTO) return false;

        const isConfirm = window.confirm('소속을 등록하시겠습니까?');
        counselorDTO.consultantDistributionAmount = groupDTO.consultantDistributionAmount
        counselorDTO.affiliationDistributionAmount = groupDTO.affiliationDistributionAmount

        if (isConfirm) {
            createCounselorAffiliation({
                consultantManagerPostDTO: counselorDTO,
                counselingAffiliationPostDTO: groupDTO,
            }).then(() => {
                alert('소속이 등록되었습니다.')
                window.location.href = '/counselor/manager'
            });
        }

    }

    return (
        <>
            <div className={style.postWrapper}>
                <Post buttonTitle={t("counselorGroupRegistration")}
                      title={t("registration.group.title")}
                      buttonOnClickAction={onClickCreateButton} formClassName={style.postForm}>
                    <>
                        {/*  상담 소속정보  */}
                        <CounselorGroupField
                            groupDTO={groupDTO}
                            setGroupDTO={setGroupDTO}
                            groupNameValidate={groupNameValidate} setGroupNameValidate={setGroupNameValidate}
                            addressValidate={addressValidate} setAddressValidate={setAddressValidate}
                            affiliationDistributionValidate={affiliationDistributionValidate}
                            setAffiliationDistributionValidate={setAffiliationDistributionValidate}
                            consultantDistributionValidate={consultantDistributionValidate}
                            setConsultantDistributionValidate={setConsultantDistributionValidate}
                        />

                        {/*  상담관리자 정보  */}
                        <CounselorField title={t('registration.counselor.title')}
                                        counselorDTO={counselorDTO}
                                        setCounselorDTO={setCounselorDTO}
                                        idValidate={idValidate} setIdValidate={setIdValidate}
                                        cellPhoneValidate={cellPhoneValidate} setCellPhoneValidate={setCellPhoneValidate}
                                        nameValidate={nameValidate} setNameValidate={setNameValidate}
                                        passwordValidate={passwordValidate} setPasswordValidate={setPasswordValidate}
                                        passwordConfirmValidate={passwordConfirmValidate}
                                        setPasswordConfirmValidate={setPasswordConfirmValidate}
                        />
                    </>
                </Post>
            </div>
        </>
    );
}