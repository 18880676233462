import React from "react";
import {TestResultReportDTO} from "../../../types/testResult";
import {ContentMapDTO, useGuideFunctions} from "./hooks/useGuideFunctions";
import {LearningCardContent} from "./LearningCardContent";

interface Args{
    testResult: TestResultReportDTO
}
/* eslint-disable max-len */
export function LearningCardFragment({testResult}:Args) {
    const {contentMap} = useGuideFunctions();

    const leftScoreCode = testResult.testResultComputedDataVO.a2OfSuccessiveScoreCode;
    const rightScoreCode = testResult.testResultComputedDataVO.a2OfSimultaneousScoreCode;
    const planningScoreCode = testResult.testResultComputedDataVO.a2OfPlanningScoreCode;
    const testerName = testResult.testResultUserProfileVO.name

    const scoreKey = `${leftScoreCode}-${rightScoreCode}-${planningScoreCode}`;
    const content = contentMap[scoreKey as keyof typeof contentMap] as ContentMapDTO

    return (
        <>
            <LearningCardContent
                gender={testResult.testResultUserProfileVO.gender}
                testerName={testerName}
                brainType={testResult.testResultComputedDataVO.brainType}
                content={content}
            />
        </>
    );
}