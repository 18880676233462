import {useAppDispatch} from "../../../../hooks/hooks";
import {useCallback} from "react";
import {
    createCounselorAffiliationAsync,
    createCounselorAsync,
    createCounselorByManagerAsync
} from "../../../../store/counselorSlice";
import {CounselorGroupRegistrationDTO, CounselorManagerDTO} from "../../../../types/counselor";
import {FileClassification, FileResponse} from "../../../../types/file";
import {useFile} from "../../../../hooks/file/useFile";
import {useMyInfoGetter} from "../../../account/mypage/hooks/useMyInfoGetter";
import {AdminRoleCategory} from "../../../../types/adminRole";

export function useCounselorCreator() {

    // 이미지 저장용
    const {uploadImages} = useFile();
    const dispatch = useAppDispatch();
    const { myInfo } = useMyInfoGetter();
    const currentRole = myInfo && myInfo.role.category

    const createCounselorAffiliation = useCallback(
        async ({consultantManagerPostDTO, counselingAffiliationPostDTO}
                   : CounselorGroupRegistrationDTO) => {
            try {
                await dispatch(
                    createCounselorAffiliationAsync({
                        consultantManagerPostDTO: consultantManagerPostDTO,
                        counselingAffiliationPostDTO: counselingAffiliationPostDTO
                    })
                ).unwrap();
            } catch (e) {
                console.error(e);
                alert('일시적인 문제가 발생했습니다.\n다시 시도해주세요.');
                throw new Error("상담 관리자 등록 에러");
            }
        },
        [dispatch]
    );

    const createCounselor = useCallback(
        async ({
                   nationality, email, name, codePhone, phoneNumber, password, briefHistory,
                   bankName, accountNumber, accountName, affiliationDistributionAmount, consultantDistributionAmount,
                   isTaxDeduction, isVatInclude, counselingAffiliationIdx, addedImage
               }
                   : CounselorManagerDTO) => {
            try {
                const files: FileResponse[] = addedImage
                    ? await uploadImages([addedImage?.file], FileClassification.ADMIN_PROFILE_IMAGE)
                    : [];

                await dispatch(
                    compareCounselorCreator()({
                        nationality,
                        email,
                        name,
                        codePhone,
                        phoneNumber,
                        password,
                        profileImageUrl: {
                            uri: files[0]?.uri,
                            fileIdx: files[0]?.idx,
                        },
                        briefHistory,
                        bankName,
                        accountNumber,
                        accountName,
                        affiliationDistributionAmount,
                        consultantDistributionAmount,
                        isTaxDeduction,
                        isVatInclude, counselingAffiliationIdx
                    })
                ).unwrap();
            } catch (e) {
                console.error(e);
                alert('일시적인 문제가 발생했습니다.\n다시 시도해주세요.');
                throw new Error("상담사 등록 에러");
            }
        },
//eslint-disable-next-line
        [dispatch]
    );

    // 상담사 등록 시 필굿관리자, 상담관리자 API 구분처리
    const compareCounselorCreator = () =>{
        console.log(currentRole)
        switch (currentRole) {
            case AdminRoleCategory.CONSULTANT:
                return createCounselorByManagerAsync;
            case AdminRoleCategory.CONSULTANT_MANAGER:
                return createCounselorByManagerAsync;
            default:
                return createCounselorAsync
        }
    }

    return {
        createCounselor, createCounselorAffiliation
    }
}