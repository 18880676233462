import React from "react";
import {useHistoryList} from "./hooks/useHistoryList";
import {ListTable} from "../../../layout/content/list/ListTable";
import {ListCount, WhichList} from "../../../components/ListCount";
import {useHistoryFilters} from "./hooks/useHistoryFilters";
import {useHistoryColumn} from "./hooks/useHistoryColumn";

export function CounselorHistoryList() {

    const {
        counselorHistoryList,
        totalCounselorHistoryCount,
        pageCondition,
        searchCounselorHistoryList
    } = useHistoryList();

    const {filters} = useHistoryFilters({search: searchCounselorHistoryList({})});
    const {columns} = useHistoryColumn();

    return(
        <>
            {counselorHistoryList ? (
                <ListTable
                    filters={filters}
                    buttonsNode={<></>}
                    listData={counselorHistoryList}
                    getList={({page, sort, size}) => searchCounselorHistoryList({page, sort, size})}
                    columns={columns}
                    totalRowsCnt={{
                        totalCount: <ListCount totalCount={totalCounselorHistoryCount ?? 0} whichList={WhichList.COUNSELOR}/>,
                        singleUnitKeyInCommonFile: "counselorUnit.singleComponent",
                        multipleUnitKeyInCommonFile: "counselorUnit.multipleComponent",
                    }}
                    pageOnFilter={pageCondition}
                />
            ) : (
                <></>
            )}
        </>
    )
}