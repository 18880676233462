import {MainApi} from "./MainApi";
import {
    ConsultantAllocationPostDTO,
    ConsultantPasswordPutDTO,
    CounselingMemoPostDTO,
    CounselingMemoPutDTO,
    CounselingMemoSearchCondition,
    CounselorCellPhoneCheckDTO,
    CounselorEmailCheckDTO,
    CounselorGroupPutDTO,
    CounselorGroupRegistrationDTO,
    CounselorHistoryListQueryType,
    CounselorInfoPutDTO,
    CounselorInfoSearchCondition,
    CounselorManagerDTO,
    UpdateSettlementStatusDTO
} from "../types/counselor";
import {getTwoDigitsText} from "../util/etcUtil";
import {SearchCondition} from "../types/page";

export class CounselorApi{
    static baseUrl = `${MainApi.urlPrefix}/consultant`;
    static affiliateUrl = `${MainApi.urlPrefix}/counseling-affiliation`;
    static userUrl = `${MainApi.userUrlPrefix}/consultant`;
    static memoUrl = `${MainApi.urlPrefix}/counseling-memo`;
    static managerUrl = `${MainApi.userUrlPrefix}/consultant-manager`;
    static settlementUrl = `${MainApi.urlPrefix}/consultant-settlement`;

    // 이메일 중복체크
    static checkCounselorEmail = (idx: CounselorEmailCheckDTO) => () =>
        MainApi.api.post(`${CounselorApi.baseUrl}/check-duplicates-email`, idx);

    // 휴대전화 중복체크
    static checkCounselorCellPhone = (idx: CounselorCellPhoneCheckDTO) => () =>
        MainApi.api.post(`${CounselorApi.baseUrl}/check-duplicates-phone-number`, idx);

    // 소속이름 중복체크
    static checkCounselorAffiliation = (idx: string) => () =>
        MainApi.api.get(`${CounselorApi.affiliateUrl}/check-duplicates/${idx}`);

    // 소속 등록
    static createCounselorAffiliation = (body: CounselorGroupRegistrationDTO) => () =>
        MainApi.api.post(`${CounselorApi.affiliateUrl}`, body);

    // 필굿 관리자 상담사 등록
    static createCounselor = (body: CounselorManagerDTO) => () =>
        MainApi.api.post(`${CounselorApi.baseUrl}`, body);

    // 상담 관리자 상담사 등록
    static createCounselorByManager = (body: CounselorManagerDTO) => () =>
        MainApi.api.post(`${CounselorApi.managerUrl}/consultant`, body);

    // 필굿관리자 상담사 등록 인증
    static adminSignUpConfirm = (param: string) => () =>
        MainApi.api.post(`${CounselorApi.baseUrl}/sign-up/confirm/${param}`);

    // 필굿관리자 미인증 상담사 삭제
    static adminDeleteCounselor = (param: string) => () =>
        MainApi.api.delete(`${CounselorApi.baseUrl}/${param}`);

    // 상담관리자 미인증 상담사 삭제
    static consultantManagerDeleteCounselor = (param: string) => () =>
        MainApi.api.delete(`${CounselorApi.managerUrl}/consultant/${param}`);

    // 상담 관리자 소속 정보 조회
    static getAffiliationInfo = () => () =>
        MainApi.api.get(`${CounselorApi.managerUrl}/consultant/affiliation-info`);

    // 상담소속 목록 조회
    static getCounselingAffiliations = () => () =>
        MainApi.api.get(`${CounselorApi.affiliateUrl}`, );

    // 필굿 - 관리자 목록 조회
    static getConsultants = (idx: CounselorInfoSearchCondition) => () =>
        MainApi.api.get(`${CounselorApi.baseUrl}${MainApi.toParamStringFromObject(idx)}`);

    // 상담 관리자 목록 조회
    static getConsultantManagerConsultantsAsync = (idx: CounselorInfoSearchCondition) => () =>
        MainApi.api.get(`${CounselorApi.managerUrl}/consultant${MainApi.toParamStringFromObject(idx)}`);

    // 상담 소속 상세 조회
    static getGroupDetail = (idx: string) => () =>
        MainApi.api.get(`${CounselorApi.affiliateUrl}/${idx}`);

    // 상담 소속 상세 수정
    static putGroupDetail = ({idx, body}:{idx:string, body: CounselorGroupPutDTO}) => () =>
        MainApi.api.put(`${CounselorApi.affiliateUrl}/${idx}`, body);

    // 상담 소속별 상담사 조회
    static getAffiliationConsultants = ({idx, condition}:{idx:string, condition:SearchCondition}) => () =>
        MainApi.api.get(`${CounselorApi.affiliateUrl}/${idx}/manager${MainApi.toParamStringFromObject(condition)}`);

    // 상담 소속 관리자 변경
    static updateAffiliationsConsultantManager = ({idx, managerIdx}:{idx:string, managerIdx: string;}) => () =>
        MainApi.api.put(`${CounselorApi.affiliateUrl}/${idx}/update-manager/${managerIdx}`);

    // 상담 소속 삭제
    static deleteCounselingAffiliation = (idx: string) => () =>
        MainApi.api.delete(`${CounselorApi.affiliateUrl}/${idx}`);

    // 상담사 상세 조회
    static getConsultantDetail = (idx: string) => () =>
        MainApi.api.get(`${CounselorApi.baseUrl}/${idx}`);

    // 상담사 상세 수정
    static updateConsultant = ({idx, body}: {idx: string, body: CounselorInfoPutDTO}) => () =>
        MainApi.api.put(`${CounselorApi.baseUrl}/${idx}`, body);

    // 상담사 본인 비밀번호 변경
    static updateConsultantPassword = (body:ConsultantPasswordPutDTO) => () =>
        MainApi.api.put(`${CounselorApi.userUrl}/password`, body);

    // 필굿 관리자 상담사 회원 차단
    static adminConsultantBlock = ({ idx, reasonForBlocking }: { idx: number[]; reasonForBlocking: string }) => () =>
        MainApi.api.put(`${CounselorApi.baseUrl}/block`, {idx, reasonForBlocking});

    // 필굿 관리자 상담사 회원 차단해제
    static adminConsultantUnblock = ({ idx}: { idx: number[]; }) => () =>
        MainApi.api.put(`${CounselorApi.baseUrl}/unblock`, {idx});

    // 필굿 관리자 상담내역 조회
    static adminCounselingHistory = (query: CounselorHistoryListQueryType) => () =>
        MainApi.api.get(`${CounselorApi.baseUrl}/counseling-history${MainApi.toParamStringFromObject(query)}`);

    // 상담 관리자 상담내역 조회
    static consultantManagerCounselingHistory = (query: CounselorHistoryListQueryType) => () =>
        MainApi.api.get(`${CounselorApi.managerUrl}/consultant/counseling-history${MainApi.toParamStringFromObject(query)}`);

    // 상담사 상담내역 조회
    static consultantCounselingHistory = (query: CounselorHistoryListQueryType) => () =>
        MainApi.api.get(`${CounselorApi.userUrl}/counseling-history${MainApi.toParamStringFromObject(query)}`);

    // 필굿 관리자 상담사 배정
    static adminCounselorAllocation = (body: ConsultantAllocationPostDTO) => () =>
        MainApi.api.post(`${CounselorApi.baseUrl}/allocation`, body);

    // 상담 관리자 상담사 배정
    static consultantManagerCounselorAllocation = (body: ConsultantAllocationPostDTO) => () =>
        MainApi.api.post(`${CounselorApi.managerUrl}/consultant/allocation`, body);

    // 상담사 - 상담사 배정
    static counselorAllocation = (body: ConsultantAllocationPostDTO) => () =>
        MainApi.api.post(`${CounselorApi.userUrl}/allocation`, body);

    // 사전문의 정보 조회
    static getCounselingPreQuestion = (param: number) => () =>
        MainApi.api.get(`${CounselorApi.baseUrl}/pre-question/${param}`);

    // 상담 메모 조회
    static getCounselingMemo = (condition: CounselingMemoSearchCondition) => () =>
        MainApi.api.get(`${CounselorApi.memoUrl}${MainApi.toParamStringFromObject(condition)}`);

    // 상담 메모 등록
    static createCounselingMemo = (body: CounselingMemoPostDTO) => () =>
        MainApi.api.post(`${CounselorApi.memoUrl}`, body);

    // 상담 메모 수정
    static updateCounselingMemo = (body: CounselingMemoPutDTO) => () =>
        MainApi.api.put(`${CounselorApi.memoUrl}`, body);

    // 상담 메모 삭제
    static deleteCounselingMemo = (param: number) => () =>
        MainApi.api.delete(`${CounselorApi.memoUrl}/${param}`);

    // 상담 종료
    static finishCounseling = (param: number) => () =>
        MainApi.api.put(`${CounselorApi.baseUrl}/counseling-end/${param}`);

    // 필굿 관리자 - 월별 상담 내역 통계
    static adminMonthlyStatics = (year: number, month: number) => () =>
        MainApi.api.get(`${CounselorApi.settlementUrl}/statics?year_month=${year}-${getTwoDigitsText(month)}`);

    // 상담 관리자 - 월별 상담 내역 통계
    static consultantManagerMonthlyStatics = (year: number, month: number) => () =>
        MainApi.api.get(`${CounselorApi.managerUrl}/consultant-settlement/statics?year_month=${year}-${getTwoDigitsText(month)}`);

    // 상담사 - 월별 상담 내역 통계
    static consultantMonthlyStatics = (year: number, month: number) => () =>
        MainApi.api.get(`${CounselorApi.userUrl}/consultant-settlement/statics?year_month=${year}-${getTwoDigitsText(month)}`);

    // 필굿 관리자 - 월별 정산 상세 내역 통계
    static adminMonthlySettlements = (year: number, month: number) => () =>
        MainApi.api.get(`${CounselorApi.settlementUrl}?year_month=${year}-${getTwoDigitsText(month)}`);// 필굿 관리자 - 월별 정산 상세 내역 통계

    // 상담 관리자 - 월별 정산 상세 내역 통계
    static consultantManagerMonthlySettlements = (year: number, month: number) => () =>
        MainApi.api.get(`${CounselorApi.managerUrl}/consultant-settlement?year_month=${year}-${getTwoDigitsText(month)}`);

    // 상담사 - 월별 정산 상세 내역 통계
    static consultantMonthlySettlements = (year: number, month: number) => () =>
        MainApi.api.get(`${CounselorApi.userUrl}/consultant-settlement?year_month=${year}-${getTwoDigitsText(month)}`);

    // 정산 상태 수정
    static updateSettlementsStatus = (body: UpdateSettlementStatusDTO) => () =>
        MainApi.api.put(`${CounselorApi.settlementUrl}/status`, body);
}