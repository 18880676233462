import {useAppDispatch} from "../../../hooks/hooks";
import {useCallback} from "react";
import {
    AssignVoucherDTO,
    ExternalInformationPutDTO,
    ExternalVoucherPostDTO,
    VoucherDeleteDTO
} from "../../../types/externalVoucher";
import {
    assignVoucherAsync,
    createVouchersAsync,
    deleteVoucherAsync,
    informationVoucherAsync
} from "../../../store/externalVoucherSlice";

export function useExternalVoucherFunctions() {
    const dispatch = useAppDispatch();

    // 외부 이용권 등록 API
    const createVouchers = useCallback(
        async (dto: ExternalVoucherPostDTO) => {
            try {
                return await dispatch(createVouchersAsync(dto)).unwrap();
            } catch
                (e) {
                alert('일시적인 문제가 발생했습니다.\n다시 시도해주세요.')
            }
        }, [dispatch]);

    // 이용권 안내 API
    const informationVoucher = useCallback(
        async (dto: ExternalInformationPutDTO) => {
            try {
                return await dispatch(informationVoucherAsync(dto)).unwrap();
            } catch
                (e) {
                alert('일시적인 문제가 발생했습니다.\n다시 시도해주세요.')
            }
        }, [dispatch]);

    // 이용권 삭제 API
    const deleteVoucherCode = useCallback(
        async (dto: VoucherDeleteDTO) => {
            try {
                return await dispatch(deleteVoucherAsync(dto)).unwrap();
            } catch
                (e) {
                alert('일시적인 문제가 발생했습니다.\n다시 시도해주세요.')
            }
        }, [dispatch]);

    // 이용권 등록 API
    const assignVoucher = useCallback(
        async (dto: AssignVoucherDTO) => {
            try {
                return await dispatch(assignVoucherAsync(dto)).unwrap();
            } catch
                (e) {
                alert('일시적인 문제가 발생했습니다.\n다시 시도해주세요.')
            }
        }, [dispatch]);

    return {
        createVouchers, informationVoucher, deleteVoucherCode,
        assignVoucher
    }
}