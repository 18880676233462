import {useNewWindow, useTimeConverter} from "../../../../hooks/hooks";
import {TableColumnData} from "../../../../types/common";
import {CounselorHistoryListVO} from "../../../../types/counselor";
import {useMemo} from "react";
import style from "../../../../layout/content/list/list.module.scss";
import {CounselorHistoryStatusButtonField} from "../CounselorHistoryStatusButtonField";
import {CounselorHistoryAdditionalButtonField} from "../CounselorHistoryAdditionalButtonField";
import {AdminRoleCategory} from "../../../../types/adminRole";

export function useHistoryColumn() {
    const {timeConverter} = useTimeConverter();
    const isAdminRole = localStorage.getItem('role') === AdminRoleCategory.ADMIN_ROOT
    const {openNewWindowWithURL} = useNewWindow();

    const createAtColumn: TableColumnData<CounselorHistoryListVO> = useMemo(
        () => ({
            accessor: "createAt",
            header: "등록일",
            sortable: false,
            getNode: (vo: CounselorHistoryListVO) => <span>{timeConverter.convertToLocalTime(vo.createAt)}</span>,
        }), [timeConverter]);

    const desiredDateColumn: TableColumnData<CounselorHistoryListVO> = useMemo(
        () => ({
            accessor: "desiredDate",
            header: "상담요청일",
            sortable: false,
            getNode: (vo: CounselorHistoryListVO) => <>
                <span>{vo.desiredDate}</span>
            </>,
        }), []);

    const desiredTimeColumn: TableColumnData<CounselorHistoryListVO> = useMemo(
        () => ({
            accessor: "desiredTime",
            header: "희망시간",
            sortable: false,
            getNode: (vo: CounselorHistoryListVO) => <span>{vo.desiredTime}</span>,
        }), []);

    const applicantNameColumn: TableColumnData<CounselorHistoryListVO> = useMemo(
        () => ({
            accessor: "applicantName",
            header: "신청자 이름",
            sortable: false,
            getNode: (vo: CounselorHistoryListVO) =>
                <>
                    {isAdminRole ? (
                        <span className={style.underline}
                              onClick={() => openNewWindowWithURL(
                                  // eslint-disable-next-line max-len
                                  `/detail/member/user/${vo.applicantIdx}?category=USER&isLeaved=false&name=${vo.applicantName}&type=SELF_INFO`,
                                  {
                                      width: 1000,
                                      height: 800,
                                  }
                              )}>
                            {vo.applicantName}
                        </span>
                    ) : <>
                        {vo.applicantName}
                    </>
                    }
                </>,
            //eslint-disable-next-line
        }), []);

    const phoneNumberColumn: TableColumnData<CounselorHistoryListVO> = useMemo(
        () => ({
            accessor: "phoneNumber",
            header: "휴대전화",
            sortable: false,
            getNode: (vo: CounselorHistoryListVO) =>
                <span>
                    {`(${vo.codePhone}) ${vo.phoneNumber}`}
                </span>,
        }), []);

    const testerNameColumn: TableColumnData<CounselorHistoryListVO> = useMemo(
        () => ({
            accessor: "testerName",
            header: "피검사자 이름",
            sortable: false,
            getNode: (vo: CounselorHistoryListVO) =>
                <>
                    {isAdminRole ? (
                        <span className={style.underline} onClick={() => openNewWindowWithURL(
                            // eslint-disable-next-line max-len
                            `/detail/member/user/${vo.applicantIdx}?type=TESTERS&profileIdx=${vo.testerIdx}`,
                            {
                                width: 1000,
                                height: 800,
                            }
                        )}>{vo.testerName}</span>
                    ) : (
                        <>{vo.testerName}</>
                    )}
                </>,
            //eslint-disable-next-line
        }), []);

    const ageYearColumn: TableColumnData<CounselorHistoryListVO> = useMemo(
        () => ({
            accessor: "ageYear",
            header: "나이(개월)",
            sortable: false,
            getNode: (vo: CounselorHistoryListVO) => <span>{vo.ageYear + '(' + vo.ageMonth + ')'}</span>,
        }), []);

    const testTypeColumn: TableColumnData<CounselorHistoryListVO> = useMemo(
        () => ({
            accessor: "testType",
            header: "검사",
            sortable: false,
            getNode: (vo: CounselorHistoryListVO) => <span>{vo.testType + ' ' + vo.testRound + '회차'}</span>,
        }), []);

    const consultingStatusColumn: TableColumnData<CounselorHistoryListVO> = useMemo(
        () => ({
            accessor: "consultingStatus",
            header: "상태",
            sortable: false,
            getNode: (vo: CounselorHistoryListVO) =>
                <>
                    <CounselorHistoryStatusButtonField
                        counselingIdx={vo.idx}
                        status={vo.consultingStatus}
                        assignee={vo.consultantName}
                    />
                </>
            ,
            //eslint-disable-next-line
        }), []);

    const idxColumn: TableColumnData<CounselorHistoryListVO> = useMemo(
        () => ({
            accessor: "idx",
            header: "관련 기능",
            sortable: false,
            width: 170,
            getNode: (vo: CounselorHistoryListVO) => (
                <div>
                    <CounselorHistoryAdditionalButtonField
                        consultStatus={vo.consultingStatus}
                        contentExecutedInfoIdx={vo.contentExecutedInfoIdx}
                        testType={vo.testType}
                        counselingIdx={vo.idx}
                        isMemoWritten={vo.memoWritten}
                    />
                </div>
            ),
        }), []);


    const columns: TableColumnData<CounselorHistoryListVO>[] = useMemo(
        () => [
            createAtColumn,
            desiredDateColumn,
            desiredTimeColumn,
            applicantNameColumn,
            phoneNumberColumn,
            testerNameColumn,
            ageYearColumn,
            testTypeColumn,
            consultingStatusColumn,
            idxColumn
        ],
        [
            createAtColumn,
            desiredDateColumn,
            desiredTimeColumn,
            applicantNameColumn,
            phoneNumberColumn,
            testerNameColumn,
            ageYearColumn,
            testTypeColumn,
            consultingStatusColumn,
            idxColumn
        ]
    );

    return {
        columns,
    };

}