import {useAppDispatch} from "../../../../hooks/hooks";
import {useCallback, useState} from "react";
import {getKpassTestResultAsync} from "../../../../store/TestResultSlice";
import {TestResultReportDTO} from "../../../../types/testResult";

export function useKpassTestResultGetter() {
    const dispatch = useAppDispatch();
    const [kpassTestResult, setKpassTestResult] = useState<TestResultReportDTO>();

    const getKpassTestResult = useCallback(
        async (executedInfo: number, token: string) => {
            try {
                const result: TestResultReportDTO = await dispatch(getKpassTestResultAsync({executedInfo, token})).unwrap();
                setKpassTestResult(result);
                return result;
            } catch (e) {
                console.error(e);
                throw new Error("검사결과지 조회 오류");
            }
        },
        [dispatch]
    );
    return {
        kpassTestResult,
        getKpassTestResult
    };
}