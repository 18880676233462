import {useAppDispatch} from "../../../../hooks/hooks";
import {getCounselingMemoAsync} from "../../../../store/counselorSlice";
import {useCallback, useState} from "react";
import {PageResponse} from "../../../../types/page";
import {Memo} from "../../../../types/memo";
import {CounselingMemoSearchCondition} from "../../../../types/counselor";

export function useCounselorMemoGetter() {
    const dispatch = useAppDispatch();
    const [counselorMemo, setCounselorMemo] = useState<PageResponse<Memo>>()

    // 상담소속 상세 조회
    const getCounselorGroupDetail = useCallback(
        // async (param: number) => {
        async (condition: CounselingMemoSearchCondition) => {
            try {
                const result: PageResponse<Memo> = await dispatch(
                    getCounselingMemoAsync({
                        targetIdx:condition.targetIdx,
                        pageCondition:{
                            page: condition.pageCondition?.page,
                            size: condition.pageCondition?.size
                        }
                    })).unwrap();
                setCounselorMemo(result)
            } catch (e) {
                console.error(e);
                alert('일시적인 문제가 발생했습니다. \n다시 시도해주세요.');
            }
        },
        //eslint-disable-next-line
        [dispatch]
    );

    return{
        counselorMemo, getCounselorGroupDetail
    }
}