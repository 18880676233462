import {Gender} from "./common";

export enum TestResultPath {
    "TOTAL_SCALE" =  "TOTAL_SCALE", // 01 전체 척도
    "LOW_LEVEL_SCALE" = "LOW_LEVEL_SCALE", // 02 하위척도별 지수
    "AVERAGE_STRENGTH_AND_WEAKNESS" = "AVERAGE_STRENGTH_AND_WEAKNESS", // 03 규준적 강/약 분석
    "PERSONAL_STRENGTH_AND_WEAKNESS" = "PERSONAL_STRENGTH_AND_WEAKNESS", // 04 개인 내적 강/약 분석
    "PROFILE_ANALYSIS" = "PROFILE_ANALYSIS", // 05 학습유형 판단을 위한 순차처리-동시처리 프로파일 분석
    "INTRODUCTION_ABOUT_KPASS" = "INTRODUCTION_ABOUT_KPASS", // 06 K-PASS 검사 소개
}

export enum GuideResultPath {
    IMPROVE_WEAKNESS = "IMPROVE_WEAKNESS", // 상대적 약점 지능의 보완
    PERSONAL_CHARACTER_TYPE = "PERSONAL_CHARACTER_TYPE", // 나는 어떤 유형의 사람일까?
    LEARNING_STRATEGY = "LEARNING_STRATEGY", // ~~우세형 학습법
    RECOMMENDATION_JOB = "RECOMMENDATION_JOB", // ~~우세형 추천 직업군
}

export enum TestTypes {
    SUCCESSIVE = "SUCCESSIVE",
    SIMULTANEOUS = "SIMULTANEOUS",
    PLANNING = "PLANNING",
    ATTENTION = "ATTENTION",
}

export enum BrainType{
    UNKNOWN = "UNKNOWN",  // 모름
    BALANCED = "BALANCED", // 좌우밸런스형
    SUCCESSIVE = "SUCCESSIVE", // 좌뇌우세형
    SIMULTANEOUS = "SIMULTANEOUS", // 우뇌우세형
}

enum A2{
    N = "N",
    NS = "NS",
    NW = "NW",
}

enum UserProfileParentType{
    USER = "USER",
    ORGAN = "ORGAN",
}

interface SubMeasureVO{
    score: number;
    trustMin: number;
    trustMax: number;
    percent: number;
}

interface TestResultVO{
    idx: number;
    contentExecutedInfoIdx: number;
    score: number;
    trustMin: number;
    trustMax: number;
    percent: number;
    successiveScore: SubMeasureVO,
    simultaneousScore: SubMeasureVO,
    planningScore: SubMeasureVO,
    attentionScore: SubMeasureVO,
    yearOfAge: number;
    monthOfAge: number;
}

interface TestResultUserProfileVO{
    name: string;
    gender : Gender,
    genderOfEnum: Gender,
    birthday : string,
    parentType: UserProfileParentType,
}

interface TestResultComputedDataVO{
    a1: string;
    a2: string;
    r: number;
    a1OfSuccessiveScore: string;
    a1OfSimultaneousScore: string;
    a1OfPlanningScore: string;
    a1OfAttentionScore: string;

    a2OfSuccessiveScore: string;
    a2OfSimultaneousScore: string;
    a2OfPlanningScore: string;
    a2OfAttentionScore: string;

    a2OfSuccessiveScoreCode: A2,
    a2OfSimultaneousScoreCode: A2,
    a2OfPlanningScoreCode: A2,
    a2OfAttentionScoreCode: A2,

    average: number;
    differenceAvgOfSuccessiveScore: number;
    differenceAvgOfSimultaneousScore: number;
    differenceAvgOfPlanningScore: number;
    differenceAvgOfAttentionScore: number;
    psOrPwOfSuccessive: string;
    psOrPwOfSimultaneous: string;
    psOrPwOfPlanning: string;
    psOrPwOfAttention: string;
    brainType: BrainType,
    brainTypeText: string
}

interface MinimumScoreDifferenceVO{
    age: number;
    planningMinDifference: number;
    simultaneousMinDifference: number;
    successiveMinDifference: number;
    attentionMinDifference: number;
}

export interface TestResultReportDTO{
    testResultVO: TestResultVO,
    testResultUserProfileVO: TestResultUserProfileVO,
    testResultComputedDataVO: TestResultComputedDataVO,
    minimumScoreDifferenceVO: MinimumScoreDifferenceVO,
    testDate: string,
    guideUrl: string;
}

export interface TestPersonalExposureDTO{
    idx: number;
    type: string;
    testType:TestTypes
    pureScore: number;
    averageScore: number;
    scoreDifference: number;
    minimumDifference: number;
}

export enum ResultType {
    "TEST_RESULT" = "TEST_RESULT",
    "GUIDE" = "GUIDE",
}