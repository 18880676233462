import styled from "styled-components/macro";
import {SettlementYearAndMonthWrapper} from "../../affiliate_code/settlement/SettlementYearAndMonthWrapper";
import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {CounselorSettlementListFilterValues} from "./hooks/useCounselorSettlementFilterValues";
import {useListFilterValues} from "../../../hooks/hooks";
import {CounselorSettlementStatistics} from "./CounselorSettlementStatistics";
import {CounselorSettlementDetailList} from "./CounselorSettlementDetailList";
import {SettlementStatus} from "../../../types/affiliateCode";

const WrapperStyle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export function CounselorSettlementList() {

    const { filterValues, searchList } = useListFilterValues();
    const {yearFilterValue, monthFilterValue} = useMemo(
        () => filterValues as CounselorSettlementListFilterValues,
        [filterValues]);

    const [tmpYear, setTmpYear] = useState<string>(yearFilterValue.value);
    const [tmpMonth, setTmpMonth] = useState<string>(monthFilterValue.value);

    useEffect(() => {
        if (tmpYear && tmpMonth) {
            searchList?.(
                {},
                {
                    year: tmpYear,
                    month: tmpMonth,
                    status: SettlementStatus.ALL,
                }
            )();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tmpYear, tmpMonth]);

    const changeYearNMonth = useCallback((year: string, month: string) => {
        setTmpYear(year);
        setTmpMonth(month);
    }, []);

    const refreshStaticsRef = useRef<(y: string, m: string) => void>(() => {});
    const refreshStatics = useCallback(() => {
        refreshStaticsRef?.current(yearFilterValue.value, monthFilterValue.value);
    }, [yearFilterValue.value, monthFilterValue.value]);

    return(
        <WrapperStyle>
            <SettlementYearAndMonthWrapper
                setYearAndMonth={(y, m) => {
                    changeYearNMonth(y, m);
                }}
            />
            <CounselorSettlementStatistics refreshRef={refreshStaticsRef} />
            <CounselorSettlementDetailList refreshStatics={refreshStatics} />
        </WrapperStyle>
    )
}