import {RefObject} from "react";

export function useGraphFunctions() {

    //  두 값 차이 절대값 도출 함수
    const compareValue = (first: number, second: number) => Math.abs(second - first);

    // 04 그래프에 각 검사 유형별 중첩부분 해결 함수
    function adjustValues(first: number, second: number, third: number, fourth: number) {
        const MIN_GAP = 80;
        const GAP = 40

        let isSecondFixed = false;
        let isThirdFixed = false;

        // 유형별 점수 결과 오름차순 정렬
        const values = sortRank(first, second, third, fourth);

        // 겹치는 부분 위치 재정렬 할 변수 초기화
        let firstValue, secondValue, thirdValue, fourthValue;

        firstValue = values[0].value;
        secondValue = values[1].value;
        thirdValue = values[2].value;
        fourthValue = values[3].value;

        // 낮은 값 부터 상위로 비교
        const diff12 = compareValue(firstValue, secondValue);
        if (diff12 < MIN_GAP) {

            values[0].value -= GAP;
            values[1].value += GAP;

            isSecondFixed = true;
        }

        // 낮은 값 부터 상위로 비교
        const diff23 = compareValue(values[1].value, thirdValue);

        if (diff23 < MIN_GAP) {
            // first vs second 비교가 진행된 경우에는 Second 값이 Gap만큼 합산
            if (isSecondFixed) {
                values[2].value = values[1].value + GAP;
                // 아닌경우 양쪽에 +-
            } else {
                values[1].value -= GAP;
                values[2].value += GAP;
            }
            isThirdFixed = true;
        }

        const diff34 = compareValue(values[2].value, fourthValue);
        if (diff34 < MIN_GAP) {
            if (isThirdFixed) {
                fourthValue = thirdValue + GAP;
            } else {
                values[2].value -= GAP;
                values[3].value += GAP;
            }
        }
        // 오름차순 정렬내용들 다시 successive, simultaneous, planning, attention 순으로 정렬
        for (let i = 0; i < values.length; i++) {
            if (values[i].label === 'first') {
                firstValue = values[i].value;
            } else if (values[i].label === 'second') {
                secondValue = values[i].value;
            } else if (values[i].label === 'third') {
                thirdValue = values[i].value;
            } else if (values[i].label === 'fourth') {
                fourthValue = values[i].value;
            }
        }
        return {firstValue, secondValue, thirdValue, fourthValue};
    }

    // 변수 4개 오름차순 정렬 함수
    const sortRank = (a: number, b: number, c: number, d: number) => {
        let labeledNumbers = [
            {value: a, label: 'first'},
            {value: b, label: 'second'},
            {value: c, label: 'third'},
            {value: d, label: 'fourth'}
        ];

        labeledNumbers.sort((x, y) => x.value - y.value);

        return labeledNumbers;
    }

    // 픽셀단위 계산위한 점수 계산 함수
    const calculateScores = (value: number) => {
        const lineLeftPadding = 15;
        const numberForPointToPixel = 7;
        const calculatedValue = (value - 40) * numberForPointToPixel + lineLeftPadding
        return calculatedValue - 15 - Math.round(55 / 2);
    };
    
    // 04 그래프에 선 그리는 함수
    const drawLine = (elementXPosition: number, circleXPosition: number,
                      color: string, canvasRef: RefObject<HTMLCanvasElement>) => {
        const canvas = canvasRef.current;
        if (!canvas) return;

        const context = canvas.getContext('2d');
        if (!context) return;

        context.beginPath();
        context.moveTo(elementXPosition + 223, 141);
        context.lineTo(circleXPosition  + 223, 80);

        context.strokeStyle = color;  // 선 색깔
        context.lineWidth = 2; // 선 굵기

        context.stroke();
    };

    const drawCircle = (xPoint: number, color: string, canvasRef: RefObject<HTMLCanvasElement>) => {
        const canvas = canvasRef.current;
        if (!canvas) return;

        const context = canvas.getContext('2d');
        if (!context) return;
        
        context.beginPath();
        context.lineWidth = 4;
        context.fillStyle = "white";
        context.strokeStyle = color;
        context.arc(xPoint + 223, 140, 5, 0, 2 * Math.PI);
        context.fill();
        context.stroke();
    };


    const drawAverageLine = (position:number, canvasRef: RefObject<HTMLCanvasElement>,
                             avgRef: RefObject<HTMLDivElement>) => {

        const canvas = canvasRef.current;
        if (!canvas) return;

        const avg = avgRef.current?.getBoundingClientRect()
        if(!avg) return;

        const context = canvas.getContext('2d');
        if (!context) return;

        if(avg.x + Math.round(avg.width / 2) - canvas.getBoundingClientRect().x <200) return;

        console.log(position)
        context.beginPath();
        context.moveTo(position + 223 , 141);
        context.lineTo(
            avg.x + Math.round(avg.width / 2) - canvas.getBoundingClientRect().x + 15, 190
        );
        context.strokeStyle = "#E60063";
        context.lineWidth = 2;

        context.stroke();
    }

    const drawGraph = (planningPercentValue:number,
                       attentionPercentValue:number,
                       simultaneousPercentValue:number,
                       successivePercentValue:number,
                       canvasRef: RefObject<HTMLCanvasElement>)  => {

        const canvas = canvasRef.current;
        if (!canvas) return;

        const context = canvas.getContext('2d');
        if (!context) return;


        const planningPoint = [210, (planningPercentValue - 100) * -2 + 10];
        const attentionPoint = [(attentionPercentValue - 100) * -2 + 10, 210];
        const simultaneousPoint = [(simultaneousPercentValue + 100) * 2 + 10, 210];
        const successivePoint = [210, (successivePercentValue + 100) * 2 + 10];

        context.beginPath();
        context.moveTo(planningPoint[0], planningPoint[1]);
        context.lineTo(attentionPoint[0], attentionPoint[1]);
        context.lineTo(successivePoint[0], successivePoint[1]);
        context.lineTo(simultaneousPoint[0], simultaneousPoint[1]);
        context.lineTo(planningPoint[0], planningPoint[1]);
        context.fillStyle = "#FF5A811A";
        context.strokeStyle = "#E60063";
        context.lineWidth = 2;
        context.fill();
        context.stroke();

        const startAngle = 0;
        const endAngle = 2 * Math.PI;
        context.lineWidth = 4;
        context.fillStyle = "white";

        context.beginPath();
        context.arc(planningPoint[0], planningPoint[1], 5, startAngle, endAngle);
        context.fill();
        context.stroke();

        context.beginPath();
        context.arc(attentionPoint[0], attentionPoint[1], 5, startAngle, endAngle);
        context.fill();
        context.stroke();

        context.beginPath();
        context.arc(successivePoint[0], successivePoint[1], 5, startAngle, endAngle);
        context.fill();
        context.stroke();

        context.beginPath();
        context.arc(simultaneousPoint[0], simultaneousPoint[1], 5, startAngle, endAngle);
        context.fill();
        context.stroke();
    }


    return{
        drawLine,
        adjustValues,
        calculateScores,
        drawCircle,
        drawAverageLine,
        drawGraph,
    }
}