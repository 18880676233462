import style from "../../style/test_result/testGuideModal.module.scss";
import {TestResultPath} from "../../../types/testResult";
import React, {useEffect, useState} from "react";
import {Trans, useTranslation} from "react-i18next";
import {useLanguage} from "../../../hooks/hooks";

interface Args {
    testResultPath: TestResultPath
    setIsModalOpen: (_: boolean) => void
}

/* eslint-disable max-len */
export function TestGuideModal({testResultPath, setIsModalOpen}: Args) {

    const {t} = useTranslation("guideModal");
    const {isKorean} = useLanguage();
    const [title, setTitle] = useState('');
    const handleCloseBtn = () => {
        document.documentElement.style.overflow = 'auto';
        setIsModalOpen(false);
    }
    useEffect(() => {
        const value =
            testResultPath === TestResultPath.TOTAL_SCALE ? t('totalScale.title')
                : testResultPath === TestResultPath.LOW_LEVEL_SCALE ? t('lowLevelScale.title')
                    : testResultPath === TestResultPath.AVERAGE_STRENGTH_AND_WEAKNESS ? t('average.title')
                        : testResultPath === TestResultPath.PERSONAL_STRENGTH_AND_WEAKNESS ? t('personal.title')
                            : testResultPath === TestResultPath.PROFILE_ANALYSIS ? t('profile.title') : ''
        setTitle(value)
    //eslint-disable-next-line
    }, [testResultPath]);

    return (
        <>
            <div className={style.modal}>
                <div className={style.modalContent}>
                    <div className={style.modalTopDesc}>
                        <h3 className={style.modalContentTitle}>{title}</h3>
                        <span className={style.close} onClick={handleCloseBtn}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="51" viewBox="0 0 50 51"
                                 fill="none"
                                 className="close_button">
                              <g filter="url(#filter0_d_6724_119398)">
                                <path fillRule={"evenodd"} clipRule={"evenodd"}
                                      d="M7.89453 25.6573C7.89453 16.5737 15.2583 9.20996 24.3419 9.20996H25.6577C34.7413 9.20996 42.1051 16.5737 42.1051 25.6573C42.1051 34.741 34.7413 42.1047 25.6577 42.1047H24.3419C15.2583 42.1047 7.89453 34.741 7.89453 25.6573ZM23.9069 25.978L17.04 19.1111L18.4542 17.6969L25.3211 24.5638L32.1881 17.6969L33.6023 19.1111L26.7353 25.978L33.6022 32.8449L32.188 34.2591L25.3211 27.3922L18.4543 34.2591L17.0401 32.8449L23.9069 25.978Z"
                                      fill="white"/>
                              </g>
                              <defs>
                                <filter id="filter0_d_6724_119398" x="1.89453" y="5.20996" width="46.2109"
                                        height="44.8945"
                                        filterUnits="userSpaceOnUse" colorInterpolationFilters={"sRGB"}>
                                  <feFlood floodOpacity={0} result="BackgroundImageFix"/>
                                  <feColorMatrix in="SourceAlpha" type="matrix"
                                                 values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                                 result="hardAlpha"/>
                                  <feOffset dy="2"/>
                                  <feGaussianBlur stdDeviation="3"/>
                                  <feComposite in2="hardAlpha" operator="out"/>
                                  <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
                                  <feBlend mode="normal" in2="BackgroundImageFix"
                                           result="effect1_dropShadow_6724_119398"/>
                                  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_6724_119398"
                                           result="shape"/>
                                </filter>
                              </defs>
                            </svg>
                        </span>
                    </div>

                    {/*  01 전체 척도 해설  */}
                    {testResultPath === TestResultPath.TOTAL_SCALE && (
                        <div className={style.modalInnerContent}>
                            {/*  ※ 지능이란?  */}
                            <p className={style.innerContentTitle}>{t('totalScale.content1Title')}</p>
                            <ul className={style.innerContentList}>
                                <li className={style.strong}>{t('totalScale.content1Description1')}</li>
                                <li>{t('totalScale.content1Description2')}</li>
                                <li>
                                    <Trans t={t} i18nKey="totalScale.content1Description3"
                                           components={{strong: <span className={style.semiBold}/>}}
                                    />
                                </li>
                            </ul>
                            <p className={`${style.innerContentSubitem} ${style.firstSubitem}`}>{t('totalScale.content1SubDescription1')}</p>
                            <p className={style.innerContentSubitem}>{t('totalScale.content1SubDescription2')}</p>

                            {/*  ※ 전체 척도, 하위 척도 설명  */}
                            <p className={`${style.innerContentTitle} ${style.secondTitle}`}>{t('totalScale.content2Title')}</p>
                            <ul className={style.innerContentList}>
                                <li>{t('totalScale.content2Description1')}</li>
                                <li>
                                    <Trans t={t} i18nKey="totalScale.content2Description2"
                                           components={{strong: <span className={style.semiBold}/>}}
                                    />
                                </li>
                            </ul>
                            <p className={`${style.innerContentSubitem} ${style.firstSubitem}`}>{t('totalScale.content2SubDescription1')}</p>
                            <p className={style.innerContentSubitem}>{t('totalScale.content2SubDescription2')}</p>
                        </div>
                    )}

                    {/*  02 하위척도별 지수 해설  */}
                    {testResultPath === TestResultPath.LOW_LEVEL_SCALE && (
                        <div className={style.modalInnerContent}>
                            <p className={style.innerContentTitle}>{t('lowLevelScale.title')}</p>
                            <ul className={style.innerContentList}>
                                <li>{t('lowLevelScale.contentDescription')}
                                </li>
                            </ul>
                            <div className={style.problemSolveContainer}>
                                <img
                                    src={isKorean ? 'https://images.feel-good.io/guide/problem_solve.png' : 'https://images.feel-good.io/guide/problem_solve_en.png'}
                                    alt="Explanation Guide Button"
                                    className={style.problem_solving_image}/>
                            </div>

                            {/*  순차처리능력  */}
                            <p className={style.innerContentMainDesc}>{t('lowLevelScale.content1Title')}</p>
                            <ul className={`${style.innerContentList} ${style.innerContentBottomMargin1}`}>
                                <li>
                                    <Trans t={t} i18nKey="lowLevelScale.content1Description1"
                                           components={{strong: <span className={style.semiBold}/>}}/>
                                </li>
                                <li>{t('lowLevelScale.content1Description2')}</li>
                                <li>{t('lowLevelScale.content1Description3')}</li>
                            </ul>

                            {/*  동시처리능력  */}
                            <p className={style.innerContentMainDesc}>{t('lowLevelScale.content2Title')}</p>
                            <ul className={`${style.innerContentList} ${style.innerContentBottomMargin3}`}>
                                <li><Trans t={t} i18nKey="lowLevelScale.content2Description1"
                                           components={{strong: <span className={style.semiBold}/>}}/></li>
                                <li>{t('lowLevelScale.content2Description2')}</li>
                                <li>{t('lowLevelScale.content2Description3')}</li>
                            </ul>
                            <p className={style.innerContentSubitem}>{t('lowLevelScale.content2SubDescription1')}</p>
                            <p className={style.innerContentSubitem}>{t('lowLevelScale.content2SubDescription2')}</p>
                            <p className={`${style.innerContentSubitem} ${style.innerContentBottomMargin1}`}>{t('lowLevelScale.content2SubDescription3')}</p>

                            {/*  계획력  */}
                            <p className={style.innerContentMainDesc}>{t('lowLevelScale.content3Title')}</p>
                            <ul className={`${style.innerContentList} ${style.innerContentBottomMargin3}`}>
                                <li><Trans t={t} i18nKey="lowLevelScale.content3Description1"
                                           components={{strong: <span className={style.semiBold}/>}}/></li>
                                <li>{t('lowLevelScale.content3Description2')}</li>
                                <li>{t('lowLevelScale.content3Description3')}</li>
                            </ul>
                            <p className={`${style.innerContentSubitem} ${style.innerContentBottomMargin1}`}>
                                {t('lowLevelScale.content3SubDescription1')}</p>

                            {/*  주의력  */}
                            <p className={style.innerContentMainDesc}>{t('lowLevelScale.content4Title')}</p>
                            <ul className={`${style.innerContentList} ${style.innerContentBottomMargin2}`}>
                                <li><Trans t={t} i18nKey="lowLevelScale.content4Description1"
                                           components={{strong: <span className={style.semiBold}/>}}/></li>
                                <li>{t('lowLevelScale.content4Description2')}</li>
                                <li>{t('lowLevelScale.content4Description3')}</li>
                                <li>{t('lowLevelScale.content4Description4')}</li>
                            </ul>
                            <p className={style.innerContentSubitem}>
                                <li>{t('lowLevelScale.content4SubDescription1')}</li>
                            </p>
                        </div>
                    )}

                    {/*  03 규준적 강/약 분석 해설  */}
                    {testResultPath === TestResultPath.AVERAGE_STRENGTH_AND_WEAKNESS && (
                        <div className={style.modalInnerContent}>
                            <p className={style.innerContentTitle}>{t('average.title')}</p>
                            <ul className={style.innerContentList}>
                                <li><Trans t={t} i18nKey="average.contentDescription1"
                                           components={{strong: <span className={style.semiBold}/>}}/></li>
                                <li>{t('average.contentDescription2')}</li>
                                <li>{t('average.contentDescription3')}</li>
                            </ul>
                        </div>
                    )}

                    {/*  04 개인 내적 강/약 분석 해설  */}
                    {testResultPath === TestResultPath.PERSONAL_STRENGTH_AND_WEAKNESS && (
                        <div className={style.modalInnerContent}>
                            <p className={style.innerContentTitle}>{t('personal.title')}</p>
                            <ul className={style.innerContentList}>
                                <li><Trans t={t} i18nKey="personal.contentDescription1"
                                           components={{strong: <span className={style.semiBold}/>}}/></li>
                                <li>{t('personal.contentDescription2')}</li>
                                <li>{t('personal.contentDescription3')}</li>
                                <li>{t('personal.contentDescription4')}</li>
                            </ul>
                        </div>
                    )}

                    {/*  05 학습유형 판단을 위한 순차처리-동시처리 프로파일 분석 해설  */}
                    {testResultPath === TestResultPath.PROFILE_ANALYSIS && (
                        <div className={style.modalInnerContent}>
                            <p className={style.innerContentTitle}>{t('profile.contentTitle')}</p>
                            <ul className={`${style.innerContentList} ${style.innerContentBottomMargin3}`}>
                                <li>{t('profile.contentDescription1')}</li>
                                <li>{t('profile.contentDescription2')}</li>
                                <li>{t('profile.contentDescription3')}</li>
                            </ul>
                            <p className={style.innerContentSubitem}>{t('profile.contentSubDescription1')}</p>
                            <p className={`${style.innerContentSubitem} ${style.innerContentBottomMargin3}`}>{t('profile.contentSubDescription2')}</p>
                            {isKorean && (
                                <div className={style.brainContentContainer}
                                    // th:if="${#locale.getLanguage() eq 'ko'}"
                                >
                                    <div className={style.brainContentLeftContainer}>
                                        <Trans t={t} i18nKey="profile.brainLeftText"
                                               components={{p: <p/>, strong: <span className={style.semiBold}/>}}/>
                                    </div>
                                    <img src="https://images.feel-good.io/guide/leftbrain_with_text.png"
                                         className={style.leftbrainImg}
                                         alt="leftbrain-img"/>
                                    <img src="https://images.feel-good.io/guide/rightbrain_with_text.png"
                                         className={style.rightbrainImg}
                                         alt="rightbrain-img"/>
                                    <img src="https://images.feel-good.io/guide/brain_with_text.png"
                                         className={style.brainImg}
                                         alt="brain-img"/>
                                    <div className={style.brainContentRightContainer}>
                                        <Trans t={t} i18nKey="profile.brainRightText"
                                               components={{p: <p/>, strong: <span className={style.semiBold}/>}}/>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </>
    )
}