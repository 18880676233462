import style from "../../style/test_result/guide/guideContent.module.scss";
import {TestResultReportDTO} from "../../../types/testResult";
import {GuidePersonalTitle} from "./GuidePersonalTitle";
import {useGuideFunctions} from "./hooks/useGuideFunctions";
import {GuidePersonalDetails} from "./GuidePersonalDetails";

interface Args {
    testResult: TestResultReportDTO
}

/* eslint-disable max-len */
export function GuidePersonalCharacterType({testResult}: Args) {
    /**
     * 나는 어떤 유형의 사람일까?
     */
    const {contentMap} = useGuideFunctions();

    const leftScoreCode = testResult.testResultComputedDataVO.a2OfSuccessiveScoreCode;
    const rightScoreCode = testResult.testResultComputedDataVO.a2OfSimultaneousScoreCode;
    const planningScoreCode = testResult.testResultComputedDataVO.a2OfPlanningScoreCode;

    const currentResult = `${leftScoreCode}-${rightScoreCode}-${planningScoreCode}`;

    const content = contentMap[currentResult as keyof typeof contentMap]
    const rightImg = content.guideRightImg.replace('.png',`_${testResult.testResultUserProfileVO.gender}.png`)

    const characterBg = 'https://images.feel-good.io/guide/character-bg.svg';

    return (
        <>

            <div className={style.characterBg}>
                <img src={characterBg} alt="background"/>
            </div>
            <div className={`${style.pageBody} ${style.aboutCharacteristics}`}>
                <GuidePersonalTitle currentResult={currentResult} rightImg={rightImg}
                                    testerName={testResult.testResultUserProfileVO.name}
                />
                <hr className={style.underline}/>


                <GuidePersonalDetails brainType={testResult.testResultComputedDataVO.brainType}
                                      currentResult={currentResult}/>
            </div>
        </>
    );
}