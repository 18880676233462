import {TestPersonalExposureDTO} from "../../../types/testResult";
import React, {useCallback} from "react";
import style from "../../style/test_result/testResult.module.scss";
import {Trans, useTranslation} from "react-i18next";

interface Args{
    exposureDTO: TestPersonalExposureDTO;
    name?: string;
}
/* eslint-disable */
export function ScoreExposureCase({exposureDTO, name}: Args) {
    const {t} = useTranslation("personal");

    const exposureCase = [
        // case 1
        {
            title: t('personal.isLargerTitle'),
            description: t('personal.isLargerDescription'),
        },

        // case 2
        {
            title: t('personal.isLargerTitle'),
            description: t('personal.isAverageDescription'),
        },

        // case 3
        {
            title: t('personal.isLowerTitle'),
            description: t('personal.isLowerDescription'),
        },

        // case 4
        {
            title: t('personal.isLowerTitle'),
            description: t('personal.isLowerDescription'),
        }
    ];
    const exposureCaseNumber = useCallback(
        (D: number, A3: number) => {
            switch (true) {
                case D > 0 && D >= A3:
                    return 0;
                case D > 0 && D < A3:
                    return 1;
                case D < 0 && Math.abs(D) >= A3:
                    return 2;
                case D < 0 && Math.abs(D) < A3:
                    return 3;
                default:
                    return 0;
            }
        },
        []
    );


    return (
        <div className={style.result}>
            {exposureCase.map((data, index) => (
                index === exposureCaseNumber(exposureDTO.scoreDifference, exposureDTO.minimumDifference) && (
                    <div className={style.title} key={index}>
                        <h4>
                            <Trans t={t} i18nKey="personal.abilityTitle"
                                   values={{
                                       idx: exposureDTO.idx,
                                       type: exposureDTO.type,
                                       pureScore: exposureDTO.pureScore,
                                       averageScore: exposureDTO.averageScore,
                                       scoreDifference: Math.abs(exposureDTO.scoreDifference),
                                       title: data.title,
                                       blank: '\u00A0'
                                   }}
                                   components={{
                                       numbering: <span className={style.numbering}/>,
                                       blue: <span className={style.blue}/>,
                                       span: <span/>,
                                       br: <br/>,
                                   }}
                            />
                        </h4>
                        <p className={style.paragraph}>
                            <Trans t={t} i18nKey="personal.abilityDescription"
                                   values={{
                                       type: exposureDTO.type,
                                       name: name,
                                       minimumDifference: exposureDTO.minimumDifference,
                                       scoreDifference: exposureDTO.scoreDifference,
                                       description: data.description,
                                       blank: '\u00A0'
                                   }}
                                   components={{
                                       strong: <span className={style.strong}/>,
                                       blue: <span className={style.blue}/>,
                                   }}
                            />
                        </p>
                    </div>
                )
            ))}
        </div>
    );
}