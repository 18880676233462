import {ListFilterValuesContext} from "../../../hooks/list/ListFilterValuesContext";
import {useCounselorFilterValues} from "./hooks/useCounselorFilterValues";
import {CounselorInfoList} from "./CounselorInfoList";

export function CounselorInfoListWrapper() {
    const {pageInfoOnFilter, filterValues, searchList, listQueryType} = useCounselorFilterValues();

    return (
        <ListFilterValuesContext.Provider
            value={{
                filterValues,
                pageInfoOnFilter,
                searchList,
                listQueryType,
            }}
        >
            <CounselorInfoList />
        </ListFilterValuesContext.Provider>
    );
}