import {
    useAffiliateCodeSettlementFilterValues
} from "../../affiliate_code/settlement/hooks/useAffiliateCodeSettlementFilterValues";
import {ListFilterValuesContext} from "../../../hooks/list/ListFilterValuesContext";
import React from "react";
import {CounselorSettlementList} from "./CounselorSettlementList";

export function CounselorSettlementListWrapper() {
    const { filterValues, pageInfoOnFilter, searchList } = useAffiliateCodeSettlementFilterValues();

    return (
        <ListFilterValuesContext.Provider
            value={{
                pageInfoOnFilter,
                filterValues,
                searchList,
            }}
        >
            <CounselorSettlementList />
        </ListFilterValuesContext.Provider>
    );
}