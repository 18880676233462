import React, {useEffect, useMemo} from "react";
import {Panel} from "../../../../../layout/content/detail/Panel";
import style from "../../memberUserCoupons.module.scss";
import {DescriptionList, DescriptionValue} from "../../../../../layout/content/detail/DescriptionList";
import {useTranslation} from "react-i18next";
import {useLanguage} from "../../../../../hooks/hooks";
import {useCounselorVoucherGetter} from "../hooks/useCounselorVoucherGetter";

interface Props {
  userIdx: number;
}

export function CounselorVoucherInfoStatus({ userIdx }: Props) {
  const { t } = useTranslation("user");
  const {isKorean} = useLanguage();
  const {getCounselorVoucherStatus, counselorVoucherStatus} = useCounselorVoucherGetter();

  useEffect(() => {
    getCounselorVoucherStatus(userIdx).then();
    //eslint-disable-next-line
  }, [userIdx]);


  const counselorCntStatus: DescriptionValue[][] = useMemo(() => {
    return [
      [
        {
          key: t("detail.counselorVoucher.statusPanel.purchasedVoucher"),
          value: t("detail.counselorVoucher.statusPanel.unit", { count: counselorVoucherStatus?.purchasedVoucher ?? 0 }),
        },
        {
          key: t("detail.counselorVoucher.statusPanel.receivedVoucher"),
          value: t("detail.counselorVoucher.statusPanel.unit", { count: counselorVoucherStatus?.freeVoucher ?? 0 }),
        },
        {
          key: t("detail.counselorVoucher.statusPanel.availableVoucher"),
          value: t("detail.counselorVoucher.statusPanel.unit", { count: counselorVoucherStatus?.availableVoucher ?? 0 }),
        },
        {
          key: t("detail.counselorVoucher.statusPanel.collectedVoucher"),
          value: t("detail.counselorVoucher.statusPanel.unit", { count: counselorVoucherStatus?.collectedVoucher ?? 0 }),
        },
      ],
    ];
    //eslint-disable-next-line
  }, [counselorVoucherStatus, t]);


  return (
      <Panel
          title={t("detail.counselorVoucher.statusPanel.title")}
          additionalButtons={<></>}>
        <DescriptionList
            data={counselorCntStatus}
            labelWidth={isKorean ? 130 : [132, 147, 163]}
            valueWidth={isKorean ? 100 : 80}
            valueClassName={style.statusCell}/>
      </Panel>
  );
}
