import {CounselorAffiliationDTO, TaxSelectedValue} from "../../../../types/counselor";
import {useTranslation} from "react-i18next";
import style from "../../style/registration.module.scss";
import {Label104x46} from "../../../../components/Label";
import {CounselorRadioField} from "../CounselorRadioField";
import React from "react";

interface TaxSettingsProps {
    affiliationInfo: CounselorAffiliationDTO | null;
    isVatInclude: TaxSelectedValue;
    setIsVatInclude: (value: TaxSelectedValue) => void;
    isTaxDeducted: TaxSelectedValue;
    setIsTaxDeducted: (value: TaxSelectedValue) => void;
}

export function TaxSettings(
    {
        affiliationInfo,
        isVatInclude,
        setIsVatInclude,
        isTaxDeducted,
        setIsTaxDeducted
    }: TaxSettingsProps) {
    const {t} = useTranslation("counselorGroup");

    return (
        <div className={style.taxInputWrapper}>
            <div className={style.taxArea}>
                <Label104x46 text={t("registration.group.addedTax")} isRequired={false}/>
                {affiliationInfo?.isVatInclude !== undefined ? (
                    <span className={style.text}>{affiliationInfo.isVatInclude ? '10%' : '-'}</span>
                ) : (
                    <CounselorRadioField
                        selectedValue={isVatInclude}
                        setSelectedValue={setIsVatInclude}
                        groupName="taxAdded"
                    />
                )}
            </div>
            <div className={style.taxArea}>
                <Label104x46 text={t("registration.group.taxDeduction")} isRequired={false}/>
                {affiliationInfo?.isTaxDeduction !== undefined ? (
                    <span className={style.text}>{affiliationInfo.isTaxDeduction ? '10%' : '-'}</span>
                ) : (
                    <CounselorRadioField
                        selectedValue={isTaxDeducted}
                        setSelectedValue={setIsTaxDeducted}
                        groupName="taxDeduction"
                    />
                )}
            </div>
        </div>
    );
};