import React, {useEffect, useMemo, useState} from "react";
import style from "../style/settlement.module.scss"
import deleteIcon from "../../../assets/images/button_icons/delete_icon.svg";
import {DetailActionButton} from "../../../components/Buttons";
import confirmIcon from "../../../assets/images/button_icons/confirm_icon.svg";
import {AffiliationSettlementDTO} from "../../../types/counselor";
import {CounselorSettlementField} from "./CounselorSettlementField";
import {useCounselorSettlementGetter} from "./hooks/useCounselorSettlementGetter";
import {useListFilterValues} from "../../../hooks/hooks";
import {SettlementStatus} from "../../../types/affiliateCode";

interface Props {
    refreshStatics: () => void;
}

export function CounselorSettlementDetailList({refreshStatics}: Props) {

    const {getAdminMonthlySettlement, updateSettlementsStatus} = useCounselorSettlementGetter();
    const [monthlySettlement, setMonthlySettlement] = useState<AffiliationSettlementDTO[]>([])
    const [selectedId, setSelectedId] = useState<number[]>([]);
    const [isAllSelected, setIsAllSelected] = useState(false);

    const {filterValues} = useListFilterValues();
    const yearFilterValue = useMemo(() => filterValues.yearFilterValue, [filterValues.yearFilterValue]);
    const monthFilterValue = useMemo(() => filterValues.monthFilterValue, [filterValues.monthFilterValue]);
    const yearMonth = yearFilterValue.value + '-' + String(monthFilterValue.value).padStart(2,'0');

    useEffect(() => {
        if (yearFilterValue.value === "" && monthFilterValue.value === "") {
            return;
        }

        getAdminMonthlySettlement(
            yearFilterValue.value,
            monthFilterValue.value
        ).then((result) => setMonthlySettlement(result));
        //eslint-disable-next-line
    }, [refreshStatics]);

    // 체크버튼을 통한 정산 상태 처리 함수
    const onClickSettlementStatusButton = (status: SettlementStatus) => {
        if (selectedId.length < 1) {
            alert('처리할 정산 내역을 선택해주세요.');
            return false
        }

        const currentStatus = status === SettlementStatus.COMPLETE ? '정산완료' : '정산취소';
        const isConfirm = window.confirm(`선택한 정산내역을 모두 ${currentStatus} 처리 하시겠습니까?`);

        if (isConfirm) {
            updateSettlementsStatus({settlementIdx: selectedId, status: status, yearMonth: yearMonth})
                .then(() => {
                    alert(`${currentStatus} 처리 되었습니다.`);
                    window.location.reload();
                });
        }
    }

    // 상담사 + 소속 로우 체크박스 전체 선택
    const onClickSelectAllCheckBox = () => {
        const allSettlementIdx = monthlySettlement.flatMap((data) => [
            ...data.settlementCounselorDTO.map((dto) => dto.settlementIdx),
            data.affiliationSettlement.counselor?.settlementIdx ?? 0
        ]).filter((idx) => idx !== 0);

        // 전체 선택된 상태
        if (selectedId.length === allSettlementIdx.length) {
            setSelectedId([]);
            setIsAllSelected(false)
        } else {
            setIsAllSelected(true)
            setSelectedId(allSettlementIdx);
        }
    }

    return (
        <>
            <div className={style.detailWrapper}>
                {/*  제목  */}
                <span className={style.title}>월별 정산 상세 내역</span>
                {/*  정산 건 수  */}
                <span className={style.description}>
                    총 정산 건&nbsp;
                    <span className={style.count}>11</span>건
                </span>

                {/*  테이블  */}
                <div className={style.body}>
                    {/*  정산 취소/완료 버튼  */}
                    <div className={style.buttonsArea}>
                        <div className={style.buttonsWrapper}>
                            <DetailActionButton
                                onClick={() => onClickSettlementStatusButton(SettlementStatus.INCOMPLETE)}>
                                <img src={deleteIcon} alt={"delete icon"}/>
                                정산취소
                            </DetailActionButton>
                            <DetailActionButton
                                onClick={() => onClickSettlementStatusButton(SettlementStatus.COMPLETE)}>
                                <img src={confirmIcon} alt={"confirm icon"}/>
                                정산완료
                            </DetailActionButton>
                        </div>
                    </div>

                    {/*  표 영역  */}
                    <table className={style.table}>
                        <thead>
                        <tr>
                            <th className={style.th}>
                                <input
                                    className={style.checkbox}
                                    type={"checkbox"}
                                    checked={isAllSelected || false}
                                    onChange={() => {
                                        onClickSelectAllCheckBox()
                                    }}
                                />
                            </th>
                            <th className={style.th}>상담소속</th>
                            <th className={style.th}>직급</th>
                            <th className={style.th}>이름</th>
                            <th className={style.th}>월 상담횟수</th>
                            <th className={style.th}>분배금</th>
                            <th className={style.th}>부가세</th>
                            <th className={style.th}>세금공제</th>
                            <th className={style.th}>정산금액</th>
                            <th className={style.th}>정산여부</th>
                            <th className={style.th}>관련 기능</th>
                        </tr>
                        </thead>

                        <tbody>
                        {monthlySettlement.length > 0 ? (
                            <>
                                {monthlySettlement.map((row: AffiliationSettlementDTO, rowIdx) => (
                                    // 임시 추가(key)
                                    <CounselorSettlementField key={row.totalSettlement.totalSettlementValue}
                                                              row={row}
                                                              selectedId={selectedId}
                                                              setSelectedId={setSelectedId}
                                                              yearMonth={yearMonth}
                                                              setIsAllSelected={setIsAllSelected}
                                    />
                                ))}
                            </>
                        ) : (
                            <tr>
                                <td className={style.td} colSpan={11} style={{padding: "89px 0"}}>
                                    정산 상세 내역이 없습니다.
                                </td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );

}