import style from "../../style/test_result/testResult.module.scss";
import {TestSummaryHeader} from "../common/TestSummaryHeader";
import {TestResultPath} from "../../../types/testResult";
import {Trans, useTranslation} from "react-i18next";
import React from "react";

/* eslint-disable max-len */
export function KpassTestResultIntroduction() {

    const {t} = useTranslation("introduction");
    return(
        <>
            <article className={style.summaryWrapper}>
                <TestSummaryHeader testResultPath={TestResultPath.INTRODUCTION_ABOUT_KPASS}/>

                <div className={style.underline}/>
            </article>

            {/*  K-PASS 이론적 배경 ~~  */}
            <article className={style.mainWrapper}>
                <h3>{t('introduction.background.backgroundTitle')}</h3>
                <p className={style.paragraph}>
                    <Trans t={t} i18nKey="introduction.background.backgroundDescription"
                           values={{
                               blank: '\u00A0'
                           }}
                           components={{
                               deepblue: <span className={style.deepblue}/>,
                               br: <br/>,
                           }}
                    />
                </p>

                {/*  순차처리~~  */}
                <div className={style.result}>
                    <div className={style.title}>
                        <h4>
                            <span className={style.numbering}>1</span>
                            <span>{t('introduction.background.successiveTitle')}</span>
                        </h4>
                        <p className={style.paragraph}>
                            <Trans t={t} i18nKey="introduction.background.successiveDescription"
                                   values={{
                                       blank: '\u00A0'
                                   }}
                                   components={{
                                       br: <br/>,
                                   }}
                            />
                        </p>
                    </div>
                </div>

                {/*  동시처리~~  */}
                <div className={style.result}>
                    <div className={style.title}>
                        <h4>
                            <span className={style.numbering}>2</span>
                            <span>{t('introduction.background.simultaneousTitle')}</span>
                        </h4>
                        <p className={style.paragraph}>
                            <Trans t={t} i18nKey="introduction.background.simultaneousDescription"
                                   values={{
                                       blank: '\u00A0'
                                   }}
                                   components={{
                                       br: <br/>,
                                   }}
                            />
                        </p>
                    </div>
                </div>

                {/*  계획력~~  */}
                <div className={style.result}>
                    <div className={style.title}>
                        <h4>
                            <span className={style.numbering}>3</span>
                            <span>{t('introduction.background.planningTitle')}</span>
                        </h4>
                        <p className={style.paragraph}>
                            <Trans t={t} i18nKey="introduction.background.planningDescription"
                                   values={{
                                       blank: '\u00A0'
                                   }}
                                   components={{
                                       br: <br/>,
                                   }}
                            />
                        </p>
                    </div>
                </div>

                {/*  주의력~~  */}
                <div className={style.result}>
                    <div className={style.title}>
                        <h4>
                            <span className={style.numbering}>4</span>
                            <span>{t('introduction.background.attentionTitle')}</span>
                        </h4>
                        <p className={style.paragraph}>
                            <Trans t={t} i18nKey="introduction.background.attentionDescription"
                                   values={{
                                       blank: '\u00A0'
                                   }}
                                   components={{
                                       br: <br/>,
                                   }}
                            />
                        </p>
                    </div>
                </div>
            </article>

            {/*  K-PASS의 조직  */}
            <article className={style.mainWrapper}>
                <h3>{t('introduction.scale.scaleTitle')}</h3>
                <p className={style.paragraph}>
                    <Trans t={t} i18nKey="introduction.scale.scaleDescription"
                           values={{
                               blank: '\u00A0'
                           }}
                           components={{
                               strong: <span className={style.strong}/>,
                               deepblue: <span className={style.deepblue}/>,
                               br: <br/>,
                           }}
                    />
                </p>

                <div className={style.result}>
                    <div className={style.title}>
                        <h4>
                            <span className={style.numbering}>1</span>
                            <span>{t('introduction.scale.fullScaleTitle')}</span>
                        </h4>
                        <p className={style.paragraph}>
                            <Trans t={t} i18nKey="introduction.scale.fullScaleDescription"
                                   values={{ blank: '\u00A0'}}
                                   components={{br: <br/>,}}/>
                        </p>
                    </div>
                </div>

                <div className={style.result}>
                    <div className={style.title}>
                        <h4>
                            <span className={style.numbering}>2</span>
                            <span>{t('introduction.scale.passScaleTitle')}</span>
                        </h4>
                        <p className={style.paragraph}>
                            <Trans t={t} i18nKey="introduction.scale.passScaleDescription"
                                   values={{ blank: '\u00A0'}}
                                   components={{br: <br/>,}}/>
                        </p>
                    </div>
                </div>
            </article>

            <article className={style.mainWrapper}>
                <h3>{t('introduction.applicant.title')}</h3>
                <p className={style.paragraph}>
                    <Trans t={t} i18nKey="introduction.applicant.description"
                           values={{ blank: '\u00A0'}}
                           components={{br: <br/>,}}/>
                </p>

                <div className={style.result}>
                    <div className={style.title}>
                        <h4>
                            <span className={style.numbering}>1</span>
                            <span>{t('introduction.applicant.item1')}</span>
                        </h4>
                        <p className={style.paragraph}>
                            <Trans t={t} i18nKey="introduction.applicant.item1Description"
                                   values={{ blank: '\u00A0'}}
                                   components={{br: <br/>,}}/>
                        </p>
                    </div>
                </div>

                <div className={style.result}>
                    <div className={style.title}>
                        <h4>
                            <span className={style.numbering}>2</span>
                            <span>{t('introduction.applicant.item2')}</span>
                        </h4>
                        <p className={style.paragraph}>
                            <Trans t={t} i18nKey="introduction.applicant.item2Description"
                                   values={{ blank: '\u00A0'}}
                                   components={{br: <br/>,}}/>
                        </p>
                    </div>
                </div>

                <div className={style.result}>
                    <div className={style.title}>
                        <h4>
                            <span className={style.numbering}>3</span>
                            <span>{t('introduction.applicant.item3')}</span>
                        </h4>
                        <p className={style.paragraph}>
                            <Trans t={t} i18nKey="introduction.applicant.item3Description"
                                   values={{ blank: '\u00A0'}}
                                   components={{br: <br/>,}}/>
                        </p>
                    </div>
                </div>

                <div className={style.result}>
                    <div className={style.title}>
                        <h4>
                            <span className={style.numbering}>4</span>
                            <span>{t('introduction.applicant.item4')}</span>
                        </h4>
                        <p className={style.paragraph}>
                            <Trans t={t} i18nKey="introduction.applicant.item4Description"
                                   values={{ blank: '\u00A0'}}
                                   components={{br: <br/>,}}/>
                        </p>
                    </div>
                </div>

                <div className={style.result}>
                    <div className={style.title}>
                        <h4>
                            <span className={style.numbering}>5</span>
                            <span>{t('introduction.applicant.item5')}</span>
                        </h4>
                        <p className={style.paragraph}>
                            <Trans t={t} i18nKey="introduction.applicant.item5Description"
                                   values={{ blank: '\u00A0'}}
                                   components={{br: <br/>,}}/>
                        </p>
                    </div>
                </div>

                <div className={style.result}>
                    <div className={style.title}>
                        <h4>
                            <span className={style.numbering}>6</span>
                            <span>{t('introduction.applicant.item6')}</span>
                        </h4>
                        <p className={style.paragraph}>
                            <Trans t={t} i18nKey="introduction.applicant.item6Description"
                                   values={{ blank: '\u00A0'}}
                                   components={{br: <br/>,}}/>
                        </p>
                    </div>
                </div>

                <div className={style.result}>
                    <div className={style.title}>
                        <h4>
                            <span className={style.numbering}>7</span>
                            <span>{t('introduction.applicant.item7')}</span>
                        </h4>
                        <p className={style.paragraph}>
                            <Trans t={t} i18nKey="introduction.applicant.item7Description"
                                   values={{ blank: '\u00A0'}}
                                   components={{br: <br/>,}}/>
                        </p>
                    </div>
                </div>

                <div className={style.result}>
                    <div className={style.title}>
                        <h4>
                            <span className={style.numbering}>8</span>
                            <span>{t('introduction.applicant.item8')}</span>
                        </h4>
                        <p className={style.paragraph}>
                            <Trans t={t} i18nKey="introduction.applicant.item8Description"
                                   values={{ blank: '\u00A0'}}
                                   components={{br: <br/>,}}/>
                        </p>
                    </div>
                </div>
            </article>
        </>
    )
}