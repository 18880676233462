import {createAsyncThunk} from "@reduxjs/toolkit";
import {executePromise} from "../util/sliceUtil";
import {CounselorApi} from "../api/CounselorApi";
import {
    ConsultantAllocationPostDTO,
    ConsultantPasswordPutDTO,
    CounselingMemoPostDTO,
    CounselingMemoPutDTO,
    CounselingMemoSearchCondition,
    CounselorCellPhoneCheckDTO,
    CounselorEmailCheckDTO,
    CounselorGroupPutDTO,
    CounselorGroupRegistrationDTO,
    CounselorHistoryListQueryType,
    CounselorInfoPutDTO,
    CounselorInfoSearchCondition,
    CounselorManagerDTO,
    UpdateSettlementStatusDTO
} from "../types/counselor";
import {SearchCondition} from "../types/page";

// 이메일 중복체크
export const checkCounselorEmailAsync = createAsyncThunk("counselor/validateEmail", (idx: CounselorEmailCheckDTO) =>
    executePromise(CounselorApi.checkCounselorEmail(idx))
);

// 휴대전화 중복체크
export const checkCounselorCellPhoneAsync = createAsyncThunk("counselor/validateCellPhone", (idx: CounselorCellPhoneCheckDTO) =>
    executePromise(CounselorApi.checkCounselorCellPhone(idx))
);

// 소속이름 중복체크
export const checkCounselorAffiliationAsync = createAsyncThunk("counselor/validateAffiliation", (idx: string) =>
    executePromise(CounselorApi.checkCounselorAffiliation(idx))
);

// 소속 등록
export const createCounselorAffiliationAsync =
    createAsyncThunk("counselor/createCounselorAffiliation", (body: CounselorGroupRegistrationDTO) =>
    executePromise(CounselorApi.createCounselorAffiliation(body))
);

// 필굿 관리자 상담사 등록
export const createCounselorAsync = createAsyncThunk("counselor/createCounselor", (body: CounselorManagerDTO) =>
    executePromise(CounselorApi.createCounselor(body))
);

// 상담 관리자 상담사 등록
export const createCounselorByManagerAsync =
    createAsyncThunk("counselor/createCounselorByManager", (body: CounselorManagerDTO) =>
    executePromise(CounselorApi.createCounselorByManager(body))
);

// 필굿 관리자 상담사 등록 인증
export const adminSignUpConfirmAsync =
    createAsyncThunk("counselor/adminSignUpConfirm", (param: string) =>
    executePromise(CounselorApi.adminSignUpConfirm(param))
);

// 필굿 관리자 미인증 상담사 삭제
export const adminDeleteCounselorAsync =
    createAsyncThunk("counselor/adminDeleteCounselor", (param: string) =>
    executePromise(CounselorApi.adminDeleteCounselor(param))
);

// 상담 관리자 미인증 상담사 삭제
export const consultantManagerDeleteCounselorAsync =
    createAsyncThunk("counselor/consultantManagerDeleteCounselor", (param: string) =>
    executePromise(CounselorApi.consultantManagerDeleteCounselor(param))
);

// 상담 관리자 소속 정보 조회
export const getAffiliationInfoAsync = createAsyncThunk("counselor/getAffiliationInfo", () =>
    executePromise(CounselorApi.getAffiliationInfo())
);

// 상담 소속 목록 조회
export const getCounselingAffiliationsAsync = createAsyncThunk("counselor/getCounselingAffiliations", () =>
    executePromise(CounselorApi.getCounselingAffiliations())
);

// 필굿 관리자 목록 조회
export const getConsultantsAsync = createAsyncThunk("counselor/createCounselor", (idx: CounselorInfoSearchCondition) =>
    executePromise(CounselorApi.getConsultants(idx))
);

// 상담 관리자 목록 조회
export const getConsultantManagerConsultantsAsync = createAsyncThunk("counselor/createCounselor",
    (idx: CounselorInfoSearchCondition) =>
    executePromise(CounselorApi.getConsultantManagerConsultantsAsync(idx))
);

// 상담 소속 상세 조회
export const getGroupDetailAsync = createAsyncThunk("counselor/getGroupDetail", (idx: string) =>
    executePromise(CounselorApi.getGroupDetail(idx))
);

// 상담 소속 상세 수정
export const putGroupDetailAsync = createAsyncThunk("counselor/putGroupDetail",
    ({idx, body}:{idx:string, body: CounselorGroupPutDTO}) => executePromise(CounselorApi.putGroupDetail({idx, body}))
);

// 상담 소속별 상담사 조회
export const getAffiliationConsultantsAsync = createAsyncThunk("counselor/getAffiliationConsultants",
    ({idx, condition}:{idx:string, condition:SearchCondition}) =>
        executePromise(CounselorApi.getAffiliationConsultants({idx, condition}))
);

// 상담 소속 관리자 변경
export const updateAffiliationsConsultantManagerAsync = createAsyncThunk("counselor/updateAffiliationsConsultantManager",
    ({idx, managerIdx}:{idx:string, managerIdx: string;}) =>
        executePromise(CounselorApi.updateAffiliationsConsultantManager({idx, managerIdx}))
);

// 상담 소속 삭제
export const deleteCounselingAffiliationAsync = createAsyncThunk("counselor/deleteCounselingAffiliation",
    (idx: string) =>
        executePromise(CounselorApi.deleteCounselingAffiliation(idx))
);

// 상담사 상세 조회
export const getConsultantDetailAsync = createAsyncThunk("counselor/getConsultantDetail", (idx: string) =>
    executePromise(CounselorApi.getConsultantDetail(idx))
);

// 상담사 상세 수정
export const updateConsultantAsync =
    createAsyncThunk("counselor/updateConsultant", ({idx, body}: {idx: string, body: CounselorInfoPutDTO}) =>
    executePromise(CounselorApi.updateConsultant({idx, body}))
);

// 상담사 상세 조회
export const updateConsultantPasswordAsync =
    createAsyncThunk("counselor/updateConsultantPassword", (body: ConsultantPasswordPutDTO) =>
    executePromise(CounselorApi.updateConsultantPassword(body))
);

// 필굿 관리자 상담사 회원 차단
export const adminConsultantBlockAsync = createAsyncThunk("counselor/adminConsultantBlock",
    ({ idx, blockReason }: { idx: number[]; blockReason: string }) =>
    executePromise(CounselorApi.adminConsultantBlock({idx, reasonForBlocking:blockReason}))
);

// 필굿 관리자 상담사 회원 차단
export const adminConsultantUnblockAsync = createAsyncThunk("counselor/adminConsultantUnblock",
    ({ idx}: { idx: number[] }) =>
    executePromise(CounselorApi.adminConsultantUnblock({idx}))
);

// 필굿 관리자 상담내역 조회
export const adminCounselingHistoryAsync = createAsyncThunk("counselor/adminCounselingHistory",
    (query: CounselorHistoryListQueryType) =>
    executePromise(CounselorApi.adminCounselingHistory(query))
);

// 상담 관리자 상담내역 조회
export const consultantManagerCounselingHistoryAsync = createAsyncThunk("counselor/consultantManagerCounselingHistory",
    (query: CounselorHistoryListQueryType) =>
    executePromise(CounselorApi.consultantManagerCounselingHistory(query))
);

// 상담사 상담내역 조회
export const consultantCounselingHistoryAsync = createAsyncThunk("counselor/consultantCounselingHistory",
    (query: CounselorHistoryListQueryType) =>
    executePromise(CounselorApi.consultantCounselingHistory(query))
);

// 필굿 관리자 상담사 배정
export const adminCounselorAllocationAsync = createAsyncThunk("counselor/adminCounselingAllocation",
    (body: ConsultantAllocationPostDTO) =>
    executePromise(CounselorApi.adminCounselorAllocation(body))
);

// 상담 관리자 상담사 배정
export const consultantManagerCounselorAllocationAsync = createAsyncThunk("counselor/consultantManagerCounselorAllocation",
    (body: ConsultantAllocationPostDTO) =>
    executePromise(CounselorApi.consultantManagerCounselorAllocation(body))
);

// 상담 관리자 상담사 배정
export const counselorAllocationAsync = createAsyncThunk("counselor/counselorAllocation",
    (body: ConsultantAllocationPostDTO) =>
    executePromise(CounselorApi.counselorAllocation(body))
);

// 사전문의 정보 조회
export const getCounselingPreQuestionAsync = createAsyncThunk("counselor/getCounselingPreQuestion",
    (param: number) =>
    executePromise(CounselorApi.getCounselingPreQuestion(param))
);

// 상담 메모 조회
export const getCounselingMemoAsync = createAsyncThunk("counselor/getCounselingMemo",
    (condition: CounselingMemoSearchCondition) =>
    executePromise(CounselorApi.getCounselingMemo(condition))
);

// 상담 메모 등록
export const createCounselingMemoAsync = createAsyncThunk("counselor/createCounselingMemo",
    (body: CounselingMemoPostDTO) =>
    executePromise(CounselorApi.createCounselingMemo(body))
);

// 상담 메모 수정
export const updateCounselingMemoAsync = createAsyncThunk("counselor/updateCounselingMemo",
    (body: CounselingMemoPutDTO) =>
    executePromise(CounselorApi.updateCounselingMemo(body))
);

// 상담 메모 삭제
export const deleteCounselingMemoAsync = createAsyncThunk("counselor/deleteCounselingMemo",
    (param: number) =>
    executePromise(CounselorApi.deleteCounselingMemo(param))
);

// 상담 종료
export const finishCounselingAsync = createAsyncThunk("counselor/finishCounseling",
    (param: number) =>
    executePromise(CounselorApi.finishCounseling(param))
);

// 필굿 관리자 - 월별 상담 내역 통계
export const adminMonthlyStaticsAsync = createAsyncThunk("counselor/adminMonthlySettlement",
    ({ year, month }: { year: number; month: number }) =>
    executePromise(CounselorApi.adminMonthlyStatics(year,month))
);

// 상담 관리자 - 월별 상담 내역 통계
export const consultantManagerMonthlyStaticsAsync = createAsyncThunk("counselor/consultantManagerMonthlyStatics",
    ({ year, month }: { year: number; month: number }) =>
    executePromise(CounselorApi.consultantManagerMonthlyStatics(year,month))
);

// 상담사 - 월별 상담 내역 통계
export const consultantMonthlyStaticsAsync = createAsyncThunk("counselor/consultantManagerMonthlyStatics",
    ({ year, month }: { year: number; month: number }) =>
        executePromise(CounselorApi.consultantMonthlyStatics(year,month))
);

// 필굿 관리자 - 월별 정산 상세 내역 통계
export const adminMonthlySettlementsAsync = createAsyncThunk("counselor/adminMonthlySettlements",
    ({ year, month }: { year: number; month: number }) =>
    executePromise(CounselorApi.adminMonthlySettlements(year,month))
);

// 상담 관리자 - 월별 정산 상세 내역 통계
export const consultantManagerMonthlySettlementsAsync = createAsyncThunk("counselor/consultantManagerMonthlySettlements",
    ({ year, month }: { year: number; month: number }) =>
    executePromise(CounselorApi.consultantManagerMonthlySettlements(year,month))
);

// 상담사 - 월별 정산 상세 내역 통계
export const consultantMonthlySettlementsAsync = createAsyncThunk("counselor/consultantMonthlySettlements",
    ({ year, month }: { year: number; month: number }) =>
    executePromise(CounselorApi.consultantMonthlySettlements(year,month))
);

// 정산 상태 수정
export const updateSettlementsStatusAsync = createAsyncThunk("counselor/updateSettlementsStatus",
    (body: UpdateSettlementStatusDTO) =>
    executePromise(CounselorApi.updateSettlementsStatus(body))
);
