import {useTranslation} from "react-i18next";

/* eslint-disable max-len */
export function useGuidePersonalDetailFunctions() {
    const {t} = useTranslation("guidePersonalDetail");


    const createTitleAndDesc = (prefix: string, count: number) => Array.from({length: count}, (_, i) => ({
        title: t(`${prefix}.title${i + 1}`),
        description: t(`${prefix}.desc${i + 1}`)
    }));

    const createDescriptionList = (prefix: string, count: number) => Array.from({length: count}, (_, i) => ({
        title: t(`${prefix}.title${i + 1}`),
        description: t(`${prefix}.desc${i + 1}`)
    }));

    const abilityData = {
        // left - right - planning
        // balance ~
        'NS-NS-NS': [
            {
                title: t('thinking'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/thinking_power_icon.png",
                advantage: createTitleAndDesc('NS_NS_NS.thinking', 3),
            },

            {
                title: t('interests'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/interests_icon.png",
                advantage: createTitleAndDesc('NS_NS_NS.interest', 12),
            },
            {
                title: t('planning'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/planning_execution_icon.png",
                advantage: createTitleAndDesc('NS_NS_NS.planning', 2),
            },
            {
                title: t('socialAbility'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/sociability_icon.png",
                advantage: createTitleAndDesc('NS_NS_NS.socializing', 2),
            },
            {
                title: t('problemSolving'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/problem_solving_skills_icon.png",
                advantage: createTitleAndDesc('NS_NS_NS.problemSolving', 10),
            },
            {
                title: t('leadership'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/leadership_icon.png",
                advantage: createTitleAndDesc('NS_NS_NS.leadership', 2),
            },
            {
                title: t('learning'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/learning_icon.png",
                advantage: [
                    {title: t('NS_NS_NS.studying.title1'), description: t('NS_NS_NS.studying.desc1'),},
                    {title: t('NS_NS_NS.studying.title2'), descriptionList: createDescriptionList('NS_NS_NS.studying.descriptionList2', 2),},
                    {title: t('NS_NS_NS.studying.title3'), descriptionList: createDescriptionList('NS_NS_NS.studying.descriptionList3', 2),},
                    {title: t('NS_NS_NS.studying.title4'), descriptionList: createDescriptionList('NS_NS_NS.studying.descriptionList4', 2),},
                    {title: t('NS_NS_NS.studying.title5'), descriptionList: createDescriptionList('NS_NS_NS.studying.descriptionList5', 2),},
                    {title: t('NS_NS_NS.studying.title6'), descriptionList: createDescriptionList('NS_NS_NS.studying.descriptionList6', 2),},
                    {title: t('NS_NS_NS.studying.title7'), descriptionList: createDescriptionList('NS_NS_NS.studying.descriptionList7', 2),},
                    {title: t('NS_NS_NS.studying.title8'), descriptionList: createDescriptionList('NS_NS_NS.studying.descriptionList8', 2),},
                    {title: t('NS_NS_NS.studying.title9'), descriptionList: createDescriptionList('NS_NS_NS.studying.descriptionList9', 2),},
                ],
            },
        ],
        'NS-NS-N': [
            {
                title: t('thinking'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/thinking_power_icon.png",
                advantage: createTitleAndDesc('NS_NS_N.thinking', 2),
            },

            {
                title: t('interests'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/interests_icon.png",
                advantage: createTitleAndDesc('NS_NS_N.interest', 12),
            },
            {
                title: t('planning'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/planning_execution_icon.png",
                advantage: createTitleAndDesc('NS_NS_N.planning', 2),
            },
            {
                title: t('socialAbility'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/sociability_icon.png",
                advantage: createTitleAndDesc('NS_NS_N.socializing', 2),
            },
            {
                title: t('problemSolving'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/problem_solving_skills_icon.png",
                advantage: createTitleAndDesc('NS_NS_N.problemSolving', 10),
            },
            {
                title: t('learning'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/learning_icon.png",
                advantage: [
                    {title: t('NS_NS_N.studying.title1'), descriptionList: createDescriptionList('NS_NS_N.studying.descriptionList1', 2),},
                ],
            },
        ],
        'NS-NS-NW': [
            {
                title: t('thinking'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/thinking_power_icon.png",
                advantage: createTitleAndDesc('NS_NS_NW.thinking', 2),
            },

            {
                title: t('interests'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/interests_icon.png",
                advantage: createTitleAndDesc('NS_NS_NW.interest', 2),
            },
            {
                title: t('planning'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/planning_execution_icon.png",
                advantage: createTitleAndDesc('NS_NS_NW.planning', 2),
            },
            {
                title: t('socialAbility'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/sociability_icon.png",
                advantage: createTitleAndDesc('NS_NS_NW.socializing', 2),
            },
            {
                title: t('problemSolving'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/problem_solving_skills_icon.png",
                advantage: createTitleAndDesc('NS_NS_NW.problemSolving', 8),
            },
            {
                title: t('learning'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/learning_icon.png",
                advantage: [
                    {title: t('NS_NS_NW.studying.title1'), description: t('NS_NS_NS.studying.desc1'),},
                    {title: t('NS_NS_NW.studying.title2'), descriptionList: createDescriptionList('NS_NS_NW.studying.descriptionList2', 2),},
                    {title: t('NS_NS_NW.studying.title3'), descriptionList: createDescriptionList('NS_NS_NW.studying.descriptionList3', 2),},
                    {title: t('NS_NS_NW.studying.title4'), descriptionList: createDescriptionList('NS_NS_NW.studying.descriptionList4', 2),},
                    {title: t('NS_NS_NW.studying.title5'), descriptionList: createDescriptionList('NS_NS_NW.studying.descriptionList5', 2),},
                    {title: t('NS_NS_NW.studying.title6'), descriptionList: createDescriptionList('NS_NS_NW.studying.descriptionList6', 2),},
                    {title: t('NS_NS_NW.studying.title7'), descriptionList: createDescriptionList('NS_NS_NW.studying.descriptionList7', 2),},
                    {title: t('NS_NS_NW.studying.title8'), descriptionList: createDescriptionList('NS_NS_NW.studying.descriptionList8', 2),},
                    {title: t('NS_NS_NW.studying.title9'), descriptionList: createDescriptionList('NS_NS_NW.studying.descriptionList9', 2),},
                ],
            },
        ],
        'N-N-NS': [
            {
                title: t('thinking'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/thinking_power_icon.png",
                advantage: createTitleAndDesc('N_N_NS.thinking', 2),
            },

            {
                title: t('interests'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/interests_icon.png",
                advantage: createTitleAndDesc('N_N_NS.interest', 10),
            },
            {
                title: t('planning'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/planning_execution_icon.png",
                advantage: createTitleAndDesc('N_N_NS.planning', 2),
            },
            {
                title: t('socialAbility'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/sociability_icon.png",
                advantage: createTitleAndDesc('N_N_NS.socializing', 2),
            },
            {
                title: t('problemSolving'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/problem_solving_skills_icon.png",
                advantage: createTitleAndDesc('N_N_NS.problemSolving', 6),
            },
            {
                title: t('learning'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/learning_icon.png",
                advantage: createTitleAndDesc('N_N_NS.studying', 10),
            },
        ],
        'N-N-N': [
            {
                title: t('thinking'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/thinking_power_icon.png",
                advantage: createTitleAndDesc('N_N_N.thinking', 2),
            },

            {
                title: t('interests'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/interests_icon.png",
                advantage: createTitleAndDesc('N_N_N.interest', 10),
            },
            {
                title: t('planning'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/planning_execution_icon.png",
                advantage: createTitleAndDesc('N_N_N.planning', 2),
            },
            {
                title: t('socialAbility'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/sociability_icon.png",
                advantage: createTitleAndDesc('N_N_N.socializing', 2),
            },
            {
                title: t('problemSolving'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/problem_solving_skills_icon.png",
                advantage: createTitleAndDesc('N_N_N.problemSolving', 2),
            },
            {
                title: t('learning'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/learning_icon.png",
                advantage: [
                    {title: t('N_N_N.studying.title1'), description: t('N_N_N.studying.desc1'),},
                    {title: t('N_N_N.studying.title2'), description: t('N_N_N.studying.desc2'),},
                    {title: t('N_N_N.studying.title3'), description: t('N_N_N.studying.desc3'),},
                    {title: t('N_N_N.studying.title4'), description: t('N_N_N.studying.desc4'),},
                    {title: t('N_N_N.studying.title5'), descriptionList: createDescriptionList('N_N_N.studying.descriptionList5', 2),},
                    {title: t('N_N_N.studying.title6'), descriptionList: createDescriptionList('N_N_N.studying.descriptionList6', 2),},
                    {title: t('N_N_N.studying.title7'), descriptionList: createDescriptionList('N_N_N.studying.descriptionList7', 2),},
                    {title: t('N_N_N.studying.title8'), descriptionList: createDescriptionList('N_N_N.studying.descriptionList8', 2),},
                    {title: t('N_N_N.studying.title9'), descriptionList: createDescriptionList('N_N_N.studying.descriptionList9', 2),},
                ],
            },
        ],
        'N-N-NW': [
            {
                title: t('thinking'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/thinking_power_icon.png",
                advantage: createTitleAndDesc('N_N_NW.thinking', 2),
            },

            {
                title: t('interests'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/interests_icon.png",
                advantage: [
                    {title: t('N_N_NW.interest.title1'), descriptionList: createDescriptionList('N_N_NW.interest.descriptionList1', 4),},
                    {title: t('N_N_NW.interest.title2'), descriptionList: createDescriptionList('N_N_NW.interest.descriptionList2', 2),},
                    {title: t('N_N_NW.interest.title3'), descriptionList: createDescriptionList('N_N_NW.interest.descriptionList3', 2),},
                    {title: t('N_N_NW.interest.title4'), descriptionList: createDescriptionList('N_N_NW.interest.descriptionList4', 2),},
                    {title: t('N_N_NW.interest.title5'), descriptionList: createDescriptionList('N_N_NW.interest.descriptionList5', 2),},
                    {title: t('N_N_NW.interest.title6'), descriptionList: createDescriptionList('N_N_NW.interest.descriptionList6', 2),},
                ],
            },
            {
                title: t('planning'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/planning_execution_icon.png",
                advantage: createTitleAndDesc('N_N_NW.planning', 2),
            },
            {
                title: t('socialAbility'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/sociability_icon.png",
                advantage: createTitleAndDesc('N_N_NW.socializing', 2),
            },
            {
                title: t('problemSolving'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/problem_solving_skills_icon.png",
                advantage: createTitleAndDesc('N_N_NW.problemSolving', 2),
            },
            {
                title: t('learning'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/learning_icon.png",
                advantage: [
                    {title: t('N_N_NW.studying.title1'), descriptionList: createDescriptionList('N_N_NW.studying.descriptionList1', 2),},
                    {title: t('N_N_NW.studying.title2'), descriptionList: createDescriptionList('N_N_NW.studying.descriptionList2', 2),},
                    {title: t('N_N_NW.studying.title3'), descriptionList: createDescriptionList('N_N_NW.studying.descriptionList3', 2),},
                    {title: t('N_N_NW.studying.title4'), descriptionList: createDescriptionList('N_N_NW.studying.descriptionList4', 2),},
                    {title: t('N_N_NW.studying.title5'), descriptionList: createDescriptionList('N_N_NW.studying.descriptionList5', 2),},
                    {title: t('N_N_NW.studying.title6'), descriptionList: createDescriptionList('N_N_NW.studying.descriptionList6', 2),},
                    {title: t('N_N_NW.studying.title7'), descriptionList: createDescriptionList('N_N_NW.studying.descriptionList7', 2),},
                ],
            },
        ],
        'NW-NW-NS': [
            {
                title: t('thinking'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/thinking_power_icon.png",
                advantage: createTitleAndDesc('NW_NW_NS.thinking', 6),
            },

            {
                title: t('interests'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/interests_icon.png",
                advantage: [
                    {title: t('NW_NW_NS.interest.title1'), descriptionList: createDescriptionList('NW_NW_NS.interest.descriptionList1', 2),},
                    {title: t('NW_NW_NS.interest.title2'), descriptionList: createDescriptionList('NW_NW_NS.interest.descriptionList2', 2),},
                    {title: t('NW_NW_NS.interest.title3'), descriptionList: createDescriptionList('NW_NW_NS.interest.descriptionList3', 2),},
                    {title: t('NW_NW_NS.interest.title4'), descriptionList: createDescriptionList('NW_NW_NS.interest.descriptionList4', 2),},
                    {title: t('NW_NW_NS.interest.title5'), descriptionList: createDescriptionList('NW_NW_NS.interest.descriptionList5', 2),},
                    {title: t('NW_NW_NS.interest.title6'), descriptionList: createDescriptionList('NW_NW_NS.interest.descriptionList6', 2),},
                    {title: t('NW_NW_NS.interest.title7'), description: t('NW_NW_NS.interest.desc7'),},
                ],
            },
            {
                title: t('planning'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/planning_execution_icon.png",
                advantage: createTitleAndDesc('NW_NW_NS.planning', 2),
            },
            {
                title: t('socialAbility'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/sociability_icon.png",
                advantage: createTitleAndDesc('NW_NW_NS.socializing', 2),
            },
            {
                title: t('problemSolving'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/problem_solving_skills_icon.png",
                advantage: createTitleAndDesc('NW_NW_NS.problemSolving', 10),
            },
            {
                title: t('learning'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/learning_icon.png",
                advantage: [
                    {title: t('NW_NW_NS.studying.title1'), descriptionList: createDescriptionList('NW_NW_NS.studying.descriptionList1', 2),},
                    {title: t('NW_NW_NS.studying.title2'), descriptionList: createDescriptionList('NW_NW_NS.studying.descriptionList2', 2),},
                    {title: t('NW_NW_NS.studying.title3'), descriptionList: createDescriptionList('NW_NW_NS.studying.descriptionList3', 2),},
                    {title: t('NW_NW_NS.studying.title4'), descriptionList: createDescriptionList('NW_NW_NS.studying.descriptionList4', 2),},
                    {title: t('NW_NW_NS.studying.title5'), descriptionList: createDescriptionList('NW_NW_NS.studying.descriptionList5', 2),},
                    {title: t('NW_NW_NS.studying.title6'), descriptionList: createDescriptionList('NW_NW_NS.studying.descriptionList6', 2),},
                    {title: t('NW_NW_NS.studying.title7'), description: t('NW_NW_NS.studying.desc7'),},
                ],
            },
        ],
        'NW-NW-N': [
            {
                title: t('thinking'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/thinking_power_icon.png",
                advantage: createTitleAndDesc('NW_NW_N.thinking', 2),
            },

            {
                title: t('interests'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/interests_icon.png",
                advantage: [
                    {title: t('NW_NW_N.interest.title1'), descriptionList: createDescriptionList('NW_NW_N.interest.descriptionList1', 2),},
                    {title: t('NW_NW_N.interest.title2'), descriptionList: createDescriptionList('NW_NW_N.interest.descriptionList2', 2),},
                    {title: t('NW_NW_N.interest.title3'), descriptionList: createDescriptionList('NW_NW_N.interest.descriptionList3', 2),},
                    {title: t('NW_NW_N.interest.title4'), descriptionList: createDescriptionList('NW_NW_N.interest.descriptionList4', 2),},
                ],
            },
            {
                title: t('planning'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/planning_execution_icon.png",
                advantage: createTitleAndDesc('NW_NW_N.planning', 2),
            },
            {
                title: t('socialAbility'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/sociability_icon.png",
                advantage: createTitleAndDesc('NW_NW_N.socializing', 2),
            },
            {
                title: t('problemSolving'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/problem_solving_skills_icon.png",
                advantage: [
                    {title: t('NW_NW_N.problemSolving.title1'), descriptionList: createDescriptionList('NW_NW_N.problemSolving.descriptionList1', 2),},
                    {title: t('NW_NW_N.problemSolving.title2'), descriptionList: createDescriptionList('NW_NW_N.problemSolving.descriptionList2', 2),},
                    {title: t('NW_NW_N.problemSolving.title3'), descriptionList: createDescriptionList('NW_NW_N.problemSolving.descriptionList3', 2),},
                    {title: t('NW_NW_N.problemSolving.title4'), descriptionList: createDescriptionList('NW_NW_N.problemSolving.descriptionList4', 2),},
                    {title: t('NW_NW_N.problemSolving.title5'), descriptionList: createDescriptionList('NW_NW_N.problemSolving.descriptionList5', 2),},
                    {title: t('NW_NW_N.problemSolving.title6'), descriptionList: createDescriptionList('NW_NW_N.problemSolving.descriptionList6', 2),},
                ],
            },
            {
                title: t('learning'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/learning_icon.png",
                advantage: [
                    {title: t('NW_NW_N.studying.title1'), descriptionList: createDescriptionList('NW_NW_N.studying.descriptionList1', 2),},
                    {title: t('NW_NW_N.studying.title2'), descriptionList: createDescriptionList('NW_NW_N.studying.descriptionList2', 2),},
                    {title: t('NW_NW_N.studying.title3'), descriptionList: createDescriptionList('NW_NW_N.studying.descriptionList3', 2),},
                    {title: t('NW_NW_N.studying.title4'), descriptionList: createDescriptionList('NW_NW_N.studying.descriptionList4', 2),},
                    {title: t('NW_NW_N.studying.title5'), descriptionList: createDescriptionList('NW_NW_N.studying.descriptionList5', 2),},
                    {title: t('NW_NW_N.studying.title6'), descriptionList: createDescriptionList('NW_NW_N.studying.descriptionList6', 2),},
                    {title: t('NW_NW_N.studying.title7'), descriptionList: createDescriptionList('NW_NW_N.studying.descriptionList7', 2),},
                ],
            },
        ],
        'NW-NW-NW': [
            {
                title: t('thinking'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/thinking_power_icon.png",
                advantage: createTitleAndDesc('NW_NW_NW.thinking', 2),
            },

            {
                title: t('interests'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/interests_icon.png",
                advantage: [
                    {title: t('NW_NW_NW.interest.title1'), descriptionList: createDescriptionList('NW_NW_NW.interest.descriptionList1', 2),},
                    {title: t('NW_NW_NW.interest.title2'), descriptionList: createDescriptionList('NW_NW_NW.interest.descriptionList2', 2),},
                    {title: t('NW_NW_NW.interest.title3'), descriptionList: createDescriptionList('NW_NW_NW.interest.descriptionList3', 2),},
                    {title: t('NW_NW_NW.interest.title4'), descriptionList: createDescriptionList('NW_NW_NW.interest.descriptionList4', 2),},
                ],
            },
            {
                title: t('planning'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/planning_execution_icon.png",
                advantage: createTitleAndDesc('NW_NW_NW.planning', 2),
            },
            {
                title: t('socialAbility'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/sociability_icon.png",
                advantage: createTitleAndDesc('NW_NW_NW.socializing', 2),
            },
            {
                title: t('problemSolving'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/problem_solving_skills_icon.png",
                advantage: [
                    {title: t('NW_NW_NW.problemSolving.title1'), descriptionList: createDescriptionList('NW_NW_NW.problemSolving.descriptionList1', 2),},
                    {title: t('NW_NW_NW.problemSolving.title2'), descriptionList: createDescriptionList('NW_NW_NW.problemSolving.descriptionList2', 2),},
                    {title: t('NW_NW_NW.problemSolving.title3'), descriptionList: createDescriptionList('NW_NW_NW.problemSolving.descriptionList3', 2),},
                    {title: t('NW_NW_NW.problemSolving.title4'), descriptionList: createDescriptionList('NW_NW_NW.problemSolving.descriptionList4', 2),},
                    {title: t('NW_NW_NW.problemSolving.title5'), descriptionList: createDescriptionList('NW_NW_NW.problemSolving.descriptionList5', 2),},
                    {title: t('NW_NW_NW.problemSolving.title6'), descriptionList: createDescriptionList('NW_NW_NW.problemSolving.descriptionList6', 2),},
                ],
            },
            {
                title: t('learning'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/learning_icon.png",
                advantage: [
                    {title: t('NW_NW_NW.studying.title1'), descriptionList: createDescriptionList('NW_NW_NW.studying.descriptionList1', 2),},
                    {title: t('NW_NW_NW.studying.title2'), descriptionList: createDescriptionList('NW_NW_NW.studying.descriptionList2', 2),},
                    {title: t('NW_NW_NW.studying.title3'), descriptionList: createDescriptionList('NW_NW_NW.studying.descriptionList3', 2),},
                    {title: t('NW_NW_NW.studying.title4'), descriptionList: createDescriptionList('NW_NW_NW.studying.descriptionList4', 2),},
                    {title: t('NW_NW_NW.studying.title5'), descriptionList: createDescriptionList('NW_NW_NW.studying.descriptionList5', 2),},
                    {title: t('NW_NW_NW.studying.title6'), descriptionList: createDescriptionList('NW_NW_NW.studying.descriptionList6', 2),},
                    {title: t('NW_NW_NW.studying.title7'), descriptionList: createDescriptionList('NW_NW_NW.studying.descriptionList7', 2),},
                ],
            },
        ],

        // SUCCESSIVE ~
        'NS-N-NS': [
            {
                title: t('thinking'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/thinking_power_icon.png",
                advantage: [
                    {title: t('NS_N_NS.thinking.title1'), description: t('NS_N_NS.thinking.desc1')},
                    {title: t('NS_N_NS.thinking.title2'), description: t('NS_N_NS.thinking.desc2')},
                ]
            },
            {
                title: t('interests'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/interests_icon.png",
                advantage: [
                    {
                        title: t('NS_N_NS.interest.title1'),
                        descriptionList: [
                            {
                                title: t('NS_N_NS.interest.descriptionList1.title1'),
                                description: t('NS_N_NS.interest.descriptionList1.desc1')
                            },
                            {
                                title: t('NS_N_NS.interest.descriptionList1.title2'),
                                description: t('NS_N_NS.interest.descriptionList1.desc2')
                            },
                        ]
                    },
                    {
                        title: t('NS_N_NS.interest.title2'),
                        descriptionList: [
                            {
                                title: t('NS_N_NS.interest.descriptionList2.title1'),
                                description: t('NS_N_NS.interest.descriptionList2.desc1')
                            },
                            {
                                title: t('NS_N_NS.interest.descriptionList2.title2'),
                                description: t('NS_N_NS.interest.descriptionList2.desc2')
                            },
                        ]
                    },
                    {
                        title: t('NS_N_NS.interest.title3'),
                        descriptionList: [
                            {
                                title: t('NS_N_NS.interest.descriptionList3.title1'),
                                description: t('NS_N_NS.interest.descriptionList3.desc1')
                            },
                            {
                                title: t('NS_N_NS.interest.descriptionList3.title2'),
                                description: t('NS_N_NS.interest.descriptionList3.desc2')
                            },
                        ]
                    },
                    {
                        title: t('NS_N_NS.interest.title4'),
                        descriptionList: [
                            {
                                title: t('NS_N_NS.interest.descriptionList4.title1'),
                                description: t('NS_N_NS.interest.descriptionList4.desc1')
                            },
                            {
                                title: t('NS_N_NS.interest.descriptionList4.title2'),
                                description: t('NS_N_NS.interest.descriptionList4.desc2')
                            },
                        ]
                    },
                    {
                        title: t('NS_N_NS.interest.title5'),
                        descriptionList: [
                            {
                                title: t('NS_N_NS.interest.descriptionList5.title1'),
                                description: t('NS_N_NS.interest.descriptionList5.desc1')
                            },
                            {
                                title: t('NS_N_NS.interest.descriptionList5.title2'),
                                description: t('NS_N_NS.interest.descriptionList5.desc2')
                            },
                        ]
                    },
                    {
                        title: t('NS_N_NS.interest.title6'),
                        descriptionList: [
                            {
                                title: t('NS_N_NS.interest.descriptionList6.title1'),
                                description: t('NS_N_NS.interest.descriptionList6.desc1')
                            },
                            {
                                title: t('NS_N_NS.interest.descriptionList6.title2'),
                                description: t('NS_N_NS.interest.descriptionList6.desc2')
                            },
                        ]
                    },
                ]
            },
            {
                title: t('planning'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/planning_execution_icon.png",
                advantage: [
                    {title: t('NS_N_NS.planning.title1'), description: t('NS_N_NS.planning.desc1')},
                    {title: t('NS_N_NS.planning.title2'), description: t('NS_N_NS.planning.desc2')},
                ]
            },
            {
                title: t('socialAbility'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/sociability_icon.png",
                advantage: [
                    {title: t('NS_N_NS.socializing.title1'), description: t('NS_N_NS.socializing.desc1')},
                    {title: t('NS_N_NS.socializing.title2'), description: t('NS_N_NS.socializing.desc2')},
                ]
            },
            {
                title: t('problemSolving'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/problem_solving_skills_icon.png",
                advantage: [
                    {title: t('NS_N_NS.problemSolving.title1'), description: t('NS_N_NS.problemSolving.desc1')},
                    {title: t('NS_N_NS.problemSolving.title2'), description: t('NS_N_NS.problemSolving.desc2')},
                ]
            },
            {
                title: t('learning'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/learning_icon.png",
                advantage: [
                    {
                        title: t('NS_N_NS.studying.title1'),
                        description: "",
                        descriptionList: [
                            {
                                title: t('NS_N_NS.studying.descriptionList1.title1'),
                                description: t('NS_N_NS.studying.descriptionList1.desc1')
                            },
                            {
                                title: t('NS_N_NS.studying.descriptionList1.title2'),
                                description: t('NS_N_NS.studying.descriptionList1.desc2')
                            },
                        ]
                    },
                    {
                        title: t('NS_N_NS.studying.title2'),
                        description: "",
                        descriptionList: [
                            {
                                title: t('NS_N_NS.studying.descriptionList2.title1'),
                                description: t('NS_N_NS.studying.descriptionList2.desc1')
                            },
                            {
                                title: t('NS_N_NS.studying.descriptionList2.title2'),
                                description: t('NS_N_NS.studying.descriptionList2.desc2')
                            },
                        ]
                    },
                    {
                        title: t('NS_N_NS.studying.title3'),
                        description: "",
                        descriptionList: [
                            {
                                title: t('NS_N_NS.studying.descriptionList3.title1'),
                                description: t('NS_N_NS.studying.descriptionList3.desc1')
                            },
                            {
                                title: t('NS_N_NS.studying.descriptionList3.title2'),
                                description: t('NS_N_NS.studying.descriptionList3.desc2')
                            },
                        ]
                    },
                    {
                        title: t('NS_N_NS.studying.title4'),
                        description: "",
                        descriptionList: [
                            {
                                title: t('NS_N_NS.studying.descriptionList4.title1'),
                                description: t('NS_N_NS.studying.descriptionList4.desc1')
                            },
                            {
                                title: t('NS_N_NS.studying.descriptionList4.title2'),
                                description: t('NS_N_NS.studying.descriptionList4.desc2')
                            },
                        ]
                    },
                    {
                        title: t('NS_N_NS.studying.title5'),
                        description: "",
                        descriptionList: [
                            {
                                title: t('NS_N_NS.studying.descriptionList5.title1'),
                                description: t('NS_N_NS.studying.descriptionList5.desc1')
                            },
                            {
                                title: t('NS_N_NS.studying.descriptionList5.title2'),
                                description: t('NS_N_NS.studying.descriptionList5.desc2')
                            },
                        ]
                    },
                    {
                        title: t('NS_N_NS.studying.title6'),
                        description: "",
                        descriptionList: [
                            {
                                title: t('NS_N_NS.studying.descriptionList6.title1'),
                                description: t('NS_N_NS.studying.descriptionList6.desc1')
                            },
                            {
                                title: t('NS_N_NS.studying.descriptionList6.title2'),
                                description: t('NS_N_NS.studying.descriptionList6.desc2')
                            },
                        ]
                    },
                    {
                        title: t('NS_N_NS.studying.title7'),
                        description: "",
                        descriptionList: [
                            {
                                title: t('NS_N_NS.studying.descriptionList7.title1'),
                                description: t('NS_N_NS.studying.descriptionList7.desc1')
                            },
                            {
                                title: t('NS_N_NS.studying.descriptionList7.title2'),
                                description: t('NS_N_NS.studying.descriptionList7.desc2')
                            },
                        ]
                    },
                ]
            },
        ],
        'NS-N-N': [
            {
                title: t('thinking'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/thinking_power_icon.png",
                advantage:
                    createTitleAndDesc('NS_N_N.thinking', 2),
                // [
                // {title: t('NS_N_N.thinking.title1'), description: t('NS_N_N.thinking.desc1')},
                // {title: t('NS_N_N.thinking.title2'), description: t('NS_N_N.thinking.desc2')},
                // ]
            },
            {
                title: t('interests'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/interests_icon.png",
                advantage: [
                    {
                        title: t('NS_N_N.interest.title1'),
                        descriptionList: createDescriptionList('NS_N_N.interest.descriptionList1', 2),
                    },
                    {
                        title: t('NS_N_N.interest.title2'),
                        descriptionList: createDescriptionList('NS_N_N.interest.descriptionList2', 2),
                    },
                    {
                        title: t('NS_N_N.interest.title3'),
                        descriptionList: createDescriptionList('NS_N_N.interest.descriptionList3', 2),
                    },
                    {
                        title: t('NS_N_N.interest.title4'),
                        descriptionList: createDescriptionList('NS_N_N.interest.descriptionList4', 2),
                    },
                    {
                        title: t('NS_N_N.interest.title5'),
                        descriptionList: createDescriptionList('NS_N_N.interest.descriptionList5', 2),
                    },
                    {
                        title: t('NS_N_N.interest.title6'),
                        descriptionList: createDescriptionList('NS_N_N.interest.descriptionList6', 2),
                    },
                ]
            },
            {
                title: t('planning'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/planning_execution_icon.png",
                advantage: createTitleAndDesc('NS_N_N.planning', 2),
            },
            {
                title: t('socialAbility'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/sociability_icon.png",
                advantage: createTitleAndDesc('NS_N_N.socializing', 2),
            },
            {
                title: t('problemSolving'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/problem_solving_skills_icon.png",
                advantage: createTitleAndDesc('NS_N_N.problemSolving', 2),
            },
            {
                title: t('learning'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/learning_icon.png",
                advantage: createTitleAndDesc('NS_N_N.studying', 7),
            },
        ],
        'NS-N-NW': [
            {
                title: t('thinking'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/thinking_power_icon.png",
                advantage:
                    createTitleAndDesc('NS_N_NW.thinking', 3),
            },
            {
                title: t('interests'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/interests_icon.png",
                advantage: [
                    {
                        title: t('NS_N_NW.interest.title1'),
                        descriptionList: createDescriptionList('NS_N_NW.interest.descriptionList1', 3),
                    },
                    {
                        title: t('NS_N_NW.interest.title2'),
                        descriptionList: createDescriptionList('NS_N_NW.interest.descriptionList2', 2),
                    },
                    {
                        title: t('NS_N_NW.interest.title3'),
                        descriptionList: createDescriptionList('NS_N_NW.interest.descriptionList3', 2),
                    },
                    {
                        title: t('NS_N_NW.interest.title4'),
                        descriptionList: createDescriptionList('NS_N_NW.interest.descriptionList4', 2),
                    },
                    {
                        title: t('NS_N_NW.interest.title5'),
                        descriptionList: createDescriptionList('NS_N_NW.interest.descriptionList5', 2),
                    },
                    {
                        title: t('NS_N_N.interest.title6'),
                        descriptionList: createDescriptionList('NS_N_N.interest.descriptionList6', 2),
                    },
                ]
            },
            {
                title: t('planning'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/planning_execution_icon.png",
                advantage: createTitleAndDesc('NS_N_NW.planning', 3),
            },
            {
                title: t('socialAbility'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/sociability_icon.png",
                advantage: createTitleAndDesc('NS_N_NW.socializing', 3),
            },
            {
                title: t('problemSolving'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/problem_solving_skills_icon.png",
                advantage: createTitleAndDesc('NS_N_NW.problemSolving', 3),
            },
            {
                title: t('learning'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/learning_icon.png",
                advantage: [
                    {title: t('NS_N_NW.studying.title1'), descriptionList: createDescriptionList('NS_N_NW.studying.descriptionList1', 2),},
                    {title: t('NS_N_NW.studying.title2'), descriptionList: createDescriptionList('NS_N_NW.studying.descriptionList2', 2),},
                    {title: t('NS_N_NW.studying.title3'), descriptionList: createDescriptionList('NS_N_NW.studying.descriptionList3', 2),},
                    {title: t('NS_N_NW.studying.title4'), descriptionList: createDescriptionList('NS_N_NW.studying.descriptionList4', 2),},
                    {title: t('NS_N_NW.studying.title5'), descriptionList: createDescriptionList('NS_N_NW.studying.descriptionList5', 2),},
                    {title: t('NS_N_NW.studying.title6'), descriptionList: createDescriptionList('NS_N_NW.studying.descriptionList6', 2),},
                    {title: t('NS_N_NW.studying.title7'), descriptionList: createDescriptionList('NS_N_NW.studying.descriptionList7', 2),},
                ]
            },
        ],
        'N-NW-NS': [
            {
                title: t('thinking'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/thinking_power_icon.png",
                advantage: createTitleAndDesc('N_NW_NS.thinking', 2),
            },
            {
                title: t('interests'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/interests_icon.png",
                advantage: createTitleAndDesc('N_NW_NS.interest', 7),
            },
            {
                title: t('planning'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/planning_execution_icon.png",
                advantage: createTitleAndDesc('N_NW_NS.planning', 2),
            },
            {
                title: t('socialAbility'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/sociability_icon.png",
                advantage: createTitleAndDesc('N_NW_NS.socializing', 2),
            },
            {
                title: t('problemSolving'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/problem_solving_skills_icon.png",
                advantage: createTitleAndDesc('N_NW_NS.problemSolving', 2),
            },
            {
                title: t('learning'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/learning_icon.png",
                advantage: [
                    {title: t('N_NW_NS.studying.title1'), descriptionList: createDescriptionList('N_NW_NS.studying.descriptionList1', 2),},
                    {title: t('N_NW_NS.studying.title2'), descriptionList: createDescriptionList('N_NW_NS.studying.descriptionList2', 2),},
                    {title: t('N_NW_NS.studying.title3'), descriptionList: createDescriptionList('N_NW_NS.studying.descriptionList3', 2),},
                    {title: t('N_NW_NS.studying.title4'), descriptionList: createDescriptionList('N_NW_NS.studying.descriptionList4', 2),},
                    {title: t('N_NW_NS.studying.title5'), descriptionList: createDescriptionList('N_NW_NS.studying.descriptionList5', 2),},
                    {title: t('N_NW_NS.studying.title6'), descriptionList: createDescriptionList('N_NW_NS.studying.descriptionList6', 2),},
                ]
            },
        ],
        'N-NW-N': [
            {
                title: t('thinking'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/thinking_power_icon.png",
                advantage: createTitleAndDesc('N_NW_N.thinking', 2),
            },
            {
                title: t('interests'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/interests_icon.png",
                advantage: createTitleAndDesc('N_NW_N.interest', 6),
            },
            {
                title: t('planning'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/planning_execution_icon.png",
                advantage: createTitleAndDesc('N_NW_N.planning', 2),
            },
            {
                title: t('socialAbility'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/sociability_icon.png",
                advantage: createTitleAndDesc('N_NW_N.socializing', 2),
            },
            {
                title: t('problemSolving'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/problem_solving_skills_icon.png",
                advantage: createTitleAndDesc('N_NW_N.problemSolving', 2),
            },
            {
                title: t('learning'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/learning_icon.png",
                advantage: [
                    {title: t('N_NW_N.studying.title1'), descriptionList: createDescriptionList('N_NW_N.studying.descriptionList1', 2),},
                    {title: t('N_NW_N.studying.title2'), descriptionList: createDescriptionList('N_NW_N.studying.descriptionList2', 2),},
                    {title: t('N_NW_N.studying.title3'), descriptionList: createDescriptionList('N_NW_N.studying.descriptionList3', 2),},
                    {title: t('N_NW_N.studying.title4'), descriptionList: createDescriptionList('N_NW_N.studying.descriptionList4', 2),},
                    {title: t('N_NW_N.studying.title5'), descriptionList: createDescriptionList('N_NW_N.studying.descriptionList5', 2),},
                    {title: t('N_NW_N.studying.title6'), descriptionList: createDescriptionList('N_NW_N.studying.descriptionList6', 1),},
                    {title: t('N_NW_N.studying.title7'), descriptionList: createDescriptionList('N_NW_N.studying.descriptionList7', 2),},
                ]
            },
        ],
        'N-NW-NW': [
            {
                title: t('thinking'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/thinking_power_icon.png",
                advantage: createTitleAndDesc('N_NW_NW.thinking', 2),
            },
            {
                title: t('interests'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/interests_icon.png",
                advantage: createTitleAndDesc('N_NW_NW.interest', 5),
            },
            {
                title: t('planning'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/planning_execution_icon.png",
                advantage: createTitleAndDesc('N_NW_NW.planning', 2),
            },
            {
                title: t('socialAbility'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/sociability_icon.png",
                advantage: createTitleAndDesc('N_NW_NW.socializing', 2),
            },
            {
                title: t('problemSolving'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/problem_solving_skills_icon.png",
                advantage: createTitleAndDesc('N_NW_NW.problemSolving', 2),
            },
            {
                title: t('learning'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/learning_icon.png",
                advantage: createTitleAndDesc('N_NW_NW.studying', 7),
            },
        ],
        'NS-NW-NS': [
            {
                title: t('thinking'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/thinking_power_icon.png",
                advantage: createTitleAndDesc('NS_NW_NS.thinking', 2),
            },
            {
                title: t('interests'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/interests_icon.png",
                advantage: [
                    {title: t('NS_NW_NS.interest.title1'), descriptionList: createDescriptionList('NS_NW_NS.interest.descriptionList1', 2),},
                    {title: t('NS_NW_NS.interest.title2'), descriptionList: createDescriptionList('NS_NW_NS.interest.descriptionList2', 2),},
                    {title: t('NS_NW_NS.interest.title3'), descriptionList: createDescriptionList('NS_NW_NS.interest.descriptionList3', 2),},
                    {title: t('NS_NW_NS.interest.title4'), descriptionList: createDescriptionList('NS_NW_NS.interest.descriptionList4', 2),},
                    {title: t('NS_NW_NS.interest.title5'), descriptionList: createDescriptionList('NS_NW_NS.interest.descriptionList5', 2),},
                ],
            },
            {
                title: t('planning'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/planning_execution_icon.png",
                advantage: createTitleAndDesc('NS_NW_NS.planning', 2),
            },
            {
                title: t('socialAbility'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/sociability_icon.png",
                advantage: createTitleAndDesc('NS_NW_NS.socializing', 2),
            },
            {
                title: t('problemSolving'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/problem_solving_skills_icon.png",
                advantage: createTitleAndDesc('NS_NW_NS.problemSolving', 2),
            },
            {
                title: t('learning'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/learning_icon.png",
                advantage: [
                    {title: t('NS_NW_NS.studying.title1'), descriptionList: createDescriptionList('NS_NW_NS.studying.descriptionList1', 2),},
                    {title: t('NS_NW_NS.studying.title2'), descriptionList: createDescriptionList('NS_NW_NS.studying.descriptionList2', 2),},
                    {title: t('NS_NW_NS.studying.title3'), descriptionList: createDescriptionList('NS_NW_NS.studying.descriptionList3', 2),},
                    {title: t('NS_NW_NS.studying.title4'), descriptionList: createDescriptionList('NS_NW_NS.studying.descriptionList4', 2),},
                    {title: t('NS_NW_NS.studying.title5'), descriptionList: createDescriptionList('NS_NW_NS.studying.descriptionList5', 2),},
                    {title: t('NS_NW_NS.studying.title6'), descriptionList: createDescriptionList('NS_NW_NS.studying.descriptionList6', 2),},
                ],
            },
        ],
        'NS-NW-N': [
            {
                title: t('thinking'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/thinking_power_icon.png",
                advantage: createTitleAndDesc('NS_NW_N.thinking', 2),
            },

            {
                title: t('interests'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/interests_icon.png",
                advantage: [
                    {title: t('NS_NW_N.interest.title1'), descriptionList: createDescriptionList('NS_NW_N.interest.descriptionList1', 2),},
                    {title: t('NS_NW_N.interest.title2'), descriptionList: createDescriptionList('NS_NW_N.interest.descriptionList2', 2),},
                    {title: t('NS_NW_N.interest.title3'), descriptionList: createDescriptionList('NS_NW_N.interest.descriptionList3', 2),},
                    {title: t('NS_NW_N.interest.title4'), descriptionList: createDescriptionList('NS_NW_N.interest.descriptionList4', 2),},
                    {title: t('NS_NW_N.interest.title5'), descriptionList: createDescriptionList('NS_NW_N.interest.descriptionList5', 2),},
                ],
            },
            {
                title: t('planning'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/planning_execution_icon.png",
                advantage: createTitleAndDesc('NS_NW_N.planning', 2),
            },
            {
                title: t('socialAbility'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/sociability_icon.png",
                advantage: createTitleAndDesc('NS_NW_N.socializing', 2),
            },
            {
                title: t('problemSolving'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/problem_solving_skills_icon.png",
                advantage: createTitleAndDesc('NS_NW_N.problemSolving', 2),
            },
            {
                title: t('learning'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/learning_icon.png",
                advantage: [
                    {title: t('NS_NW_N.studying.title1'), descriptionList: createDescriptionList('NS_NW_N.studying.descriptionList1', 2),},
                    {title: t('NS_NW_N.studying.title2'), descriptionList: createDescriptionList('NS_NW_N.studying.descriptionList2', 2),},
                    {title: t('NS_NW_N.studying.title3'), descriptionList: createDescriptionList('NS_NW_N.studying.descriptionList3', 2),},
                    {title: t('NS_NW_N.studying.title4'), descriptionList: createDescriptionList('NS_NW_N.studying.descriptionList4', 2),},
                    {title: t('NS_NW_N.studying.title5'), descriptionList: createDescriptionList('NS_NW_N.studying.descriptionList5', 2),},
                    {title: t('NS_NW_N.studying.title6'), descriptionList: createDescriptionList('NS_NW_N.studying.descriptionList6', 2),},
                ],
            },
        ],
        'NS-NW-NW': [
            {
                title: t('thinking'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/thinking_power_icon.png",
                advantage: createTitleAndDesc('NS_NW_NW.thinking', 2),
            },

            {
                title: t('interests'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/interests_icon.png",
                advantage: [
                    {title: t('NS_NW_NW.interest.title1'), descriptionList: createDescriptionList('NS_NW_NW.interest.descriptionList1', 2),},
                    {title: t('NS_NW_NW.interest.title2'), descriptionList: createDescriptionList('NS_NW_NW.interest.descriptionList2', 2),},
                    {title: t('NS_NW_NW.interest.title3'), descriptionList: createDescriptionList('NS_NW_NW.interest.descriptionList3', 2),},
                    {title: t('NS_NW_NW.interest.title4'), descriptionList: createDescriptionList('NS_NW_NW.interest.descriptionList4', 2),},
                    {title: t('NS_NW_NW.interest.title5'), descriptionList: createDescriptionList('NS_NW_NW.interest.descriptionList5', 2),},
                ],
            },
            {
                title: t('planning'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/planning_execution_icon.png",
                advantage: createTitleAndDesc('NS_NW_NW.planning', 2),
            },
            {
                title: t('socialAbility'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/sociability_icon.png",
                advantage: createTitleAndDesc('NS_NW_NW.socializing', 2),
            },
            {
                title: t('problemSolving'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/problem_solving_skills_icon.png",
                advantage: createTitleAndDesc('NS_NW_NW.problemSolving', 2),
            },
            {
                title: t('learning'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/learning_icon.png",
                advantage: [
                    {title: t('NS_NW_NW.studying.title1'), descriptionList: createDescriptionList('NS_NW_NW.studying.descriptionList1', 2),},
                    {title: t('NS_NW_NW.studying.title2'), descriptionList: createDescriptionList('NS_NW_NW.studying.descriptionList2', 2),},
                    {title: t('NS_NW_NW.studying.title3'), descriptionList: createDescriptionList('NS_NW_NW.studying.descriptionList3', 2),},
                    {title: t('NS_NW_NW.studying.title4'), descriptionList: createDescriptionList('NS_NW_NW.studying.descriptionList4', 2),},
                    {title: t('NS_NW_NW.studying.title5'), descriptionList: createDescriptionList('NS_NW_NW.studying.descriptionList5', 2),},
                    {title: t('NS_NW_NW.studying.title6'), descriptionList: createDescriptionList('NS_NW_NW.studying.descriptionList6', 2),},
                ],
            },
        ],

        // SIMULTANEOUS ~
        'N-NS-NS': [
            {
                title: t('thinking'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/thinking_power_icon.png",
                advantage: createTitleAndDesc('N_NS_NS.thinking', 4),
            },

            {
                title: "자기 관리 능력",
                imgSrc: "https://images.feel-good.io/guide/characteristics/self_management_skills_icon.png",
                advantage: createTitleAndDesc('N_NS_NS.selfControl', 2),
            },
            {
                title: t('interests'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/interests_icon.png",
                advantage: [
                    {title: t('N_NS_NS.interest.title1'), descriptionList: createDescriptionList('N_NS_NS.interest.descriptionList1', 2),},
                    {title: t('N_NS_NS.interest.title2'), descriptionList: createDescriptionList('N_NS_NS.interest.descriptionList2', 2),},
                    {title: t('N_NS_NS.interest.title3'), descriptionList: createDescriptionList('N_NS_NS.interest.descriptionList3', 2),},
                    {title: t('N_NS_NS.interest.title4'), descriptionList: createDescriptionList('N_NS_NS.interest.descriptionList4', 2),},
                    {title: t('N_NS_NS.interest.title5'), descriptionList: createDescriptionList('N_NS_NS.interest.descriptionList5', 2),},
                    {title: t('N_NS_NS.interest.title6'), descriptionList: createDescriptionList('N_NS_NS.interest.descriptionList6', 2),},
                    {title: t('N_NS_NS.interest.title7'), descriptionList: createDescriptionList('N_NS_NS.interest.descriptionList7', 2),},
                ],
            },
            {
                title: t('planning'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/planning_execution_icon.png",
                advantage: createTitleAndDesc('N_NS_NS.planning', 6),
            },
            {
                title: t('socialAbility'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/sociability_icon.png",
                advantage: createTitleAndDesc('N_NS_NS.socializing', 2),
            },
            {
                title: t('problemSolving'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/problem_solving_skills_icon.png",
                advantage: [
                    {title: t('N_NS_NS.problemSolving.title1'), descriptionList: createDescriptionList('N_NS_NS.problemSolving.descriptionList1', 2),},
                    {title: t('N_NS_NS.problemSolving.title2'), descriptionList: createDescriptionList('N_NS_NS.problemSolving.descriptionList2', 2),},
                ],
            },
            {
                title: t('learning'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/learning_icon.png",
                advantage: [
                    {title: t('N_NS_NS.studying.title1'), descriptionList: createDescriptionList('N_NS_NS.studying.descriptionList1', 2),},
                    {title: t('N_NS_NS.studying.title2'), descriptionList: createDescriptionList('N_NS_NS.studying.descriptionList2', 2),},
                    {title: t('N_NS_NS.studying.title3'), descriptionList: createDescriptionList('N_NS_NS.studying.descriptionList3', 2),},
                    {title: t('N_NS_NS.studying.title4'), descriptionList: createDescriptionList('N_NS_NS.studying.descriptionList4', 2),},
                    {title: t('N_NS_NS.studying.title5'), descriptionList: createDescriptionList('N_NS_NS.studying.descriptionList5', 2),},
                ],
            },
        ],
        'N-NS-N': [
            {
                title: t('thinking'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/thinking_power_icon.png",
                advantage: createTitleAndDesc('N_NS_N.thinking', 6),
            },

            {
                title: t('interests'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/interests_icon.png",
                advantage: [
                    {title: t('N_NS_N.interest.title1'), descriptionList: createDescriptionList('N_NS_N.interest.descriptionList1', 2),},
                    {title: t('N_NS_N.interest.title2'), descriptionList: createDescriptionList('N_NS_N.interest.descriptionList2', 2),},
                    {title: t('N_NS_N.interest.title3'), descriptionList: createDescriptionList('N_NS_N.interest.descriptionList3', 2),},
                    {title: t('N_NS_N.interest.title4'), descriptionList: createDescriptionList('N_NS_N.interest.descriptionList4', 2),},
                    {title: t('N_NS_N.interest.title5'), descriptionList: createDescriptionList('N_NS_N.interest.descriptionList5', 2),},
                    {title: t('N_NS_N.interest.title6'), descriptionList: createDescriptionList('N_NS_N.interest.descriptionList6', 2),},
                ],
            },
            {
                title: t('planning'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/planning_execution_icon.png",
                advantage: createTitleAndDesc('N_NS_N.planning', 2),
            },
            {
                title: t('socialAbility'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/sociability_icon.png",
                advantage: createTitleAndDesc('N_NS_N.socializing', 2),
            },
            {
                title: t('problemSolving'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/problem_solving_skills_icon.png",
                advantage: createTitleAndDesc('N_NS_N.problemSolving', 2),
            },
            {
                title: t('learning'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/learning_icon.png",
                advantage: [
                    {title: t('N_NS_N.studying.title1'), descriptionList: createDescriptionList('N_NS_N.studying.descriptionList1', 2),},
                    {title: t('N_NS_N.studying.title2'), descriptionList: createDescriptionList('N_NS_N.studying.descriptionList2', 2),},
                    {title: t('N_NS_N.studying.title3'), descriptionList: createDescriptionList('N_NS_N.studying.descriptionList3', 2),},
                    {title: t('N_NS_N.studying.title4'), descriptionList: createDescriptionList('N_NS_N.studying.descriptionList4', 2),},
                    {title: t('N_NS_N.studying.title5'), descriptionList: createDescriptionList('N_NS_N.studying.descriptionList5', 2),},
                    {title: t('N_NS_N.studying.title6'), descriptionList: createDescriptionList('N_NS_N.studying.descriptionList6', 2),},
                    {title: t('N_NS_N.studying.title7'), descriptionList: createDescriptionList('N_NS_N.studying.descriptionList7', 2),},
                    {title: t('N_NS_N.studying.title8'), descriptionList: createDescriptionList('N_NS_N.studying.descriptionList8', 2),},
                ],
            },
        ],
        'N-NS-NW': [
            {
                title: t('thinking'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/thinking_power_icon.png",
                advantage: createTitleAndDesc('N_NS_NW.thinking', 4),
            },

            {
                title: t('interests'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/interests_icon.png",
                advantage: [
                    {title: t('N_NS_NW.interest.title1'), descriptionList: createDescriptionList('N_NS_NW.interest.descriptionList1', 2),},
                    {title: t('N_NS_NW.interest.title2'), descriptionList: createDescriptionList('N_NS_NW.interest.descriptionList2', 2),},
                    {title: t('N_NS_NW.interest.title3'), descriptionList: createDescriptionList('N_NS_NW.interest.descriptionList3', 2),},
                    {title: t('N_NS_NW.interest.title4'), descriptionList: createDescriptionList('N_NS_NW.interest.descriptionList4', 2),},
                    {title: t('N_NS_NW.interest.title5'), descriptionList: createDescriptionList('N_NS_NW.interest.descriptionList5', 2),},
                    {title: t('N_NS_NW.interest.title6'), descriptionList: createDescriptionList('N_NS_NW.interest.descriptionList6', 2),},
                    {title: t('N_NS_NW.interest.title7'), descriptionList: createDescriptionList('N_NS_NW.interest.descriptionList7', 2),},
                ],
            },
            {
                title: t('planning'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/planning_execution_icon.png",
                advantage: createTitleAndDesc('N_NS_NW.planning', 2),
            },
            {
                title: t('socialAbility'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/sociability_icon.png",
                advantage: createTitleAndDesc('N_NS_NW.socializing', 2),
            },
            {
                title: t('problemSolving'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/problem_solving_skills_icon.png",
                advantage: createTitleAndDesc('N_NS_NW.problemSolving', 2),
            },
            {
                title: t('learning'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/learning_icon.png",
                advantage: [
                    {title: t('N_NS_NW.studying.title1'), descriptionList: createDescriptionList('N_NS_NW.studying.descriptionList1', 2),},
                    {title: t('N_NS_NW.studying.title2'), descriptionList: createDescriptionList('N_NS_NW.studying.descriptionList2', 2),},
                    {title: t('N_NS_NW.studying.title3'), descriptionList: createDescriptionList('N_NS_NW.studying.descriptionList3', 2),},
                    {title: t('N_NS_NW.studying.title4'), descriptionList: createDescriptionList('N_NS_NW.studying.descriptionList4', 2),},
                    {title: t('N_NS_NW.studying.title5'), descriptionList: createDescriptionList('N_NS_NW.studying.descriptionList5', 2),},
                    {title: t('N_NS_NW.studying.title6'), descriptionList: createDescriptionList('N_NS_NW.studying.descriptionList6', 2),},
                    {title: t('N_NS_NW.studying.title7'), descriptionList: createDescriptionList('N_NS_NW.studying.descriptionList7', 2),},
                    {title: t('N_NS_NW.studying.title8'), descriptionList: createDescriptionList('N_NS_NW.studying.descriptionList8', 2),},
                ],
            },
        ],
        'NW-N-NS': [
            {
                title: t('thinking'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/thinking_power_icon.png",
                advantage: createTitleAndDesc('NW_N_NS.thinking', 2),
            },

            {
                title: t('interests'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/interests_icon.png",
                advantage: [
                    {title: t('NW_N_NS.interest.title1'), descriptionList: createDescriptionList('NW_N_NS.interest.descriptionList1', 2),},
                    {title: t('NW_N_NS.interest.title2'), descriptionList: createDescriptionList('NW_N_NS.interest.descriptionList2', 2),},
                    {title: t('NW_N_NS.interest.title3'), descriptionList: createDescriptionList('NW_N_NS.interest.descriptionList3', 2),},
                    {title: t('NW_N_NS.interest.title4'), descriptionList: createDescriptionList('NW_N_NS.interest.descriptionList4', 2),},
                    {title: t('NW_N_NS.interest.title5'), descriptionList: createDescriptionList('NW_N_NS.interest.descriptionList5', 2),},
                    {title: t('NW_N_NS.interest.title6'), descriptionList: createDescriptionList('NW_N_NS.interest.descriptionList6', 2),},
                    {title: t('NW_N_NS.interest.title7'), descriptionList: createDescriptionList('NW_N_NS.interest.descriptionList7', 2),},
                ],
            },
            {
                title: t('planning'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/planning_execution_icon.png",
                advantage: createTitleAndDesc('NW_N_NS.planning', 4),
            },
            {
                title: t('socialAbility'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/sociability_icon.png",
                advantage: createTitleAndDesc('NW_N_NS.socializing', 2),
            },
            {
                title: t('problemSolving'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/problem_solving_skills_icon.png",
                advantage: createTitleAndDesc('NW_N_NS.problemSolving', 3),
            },
            {
                title: t('learning'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/learning_icon.png",
                advantage: [
                    {title: t('NW_N_NS.studying.title1'), descriptionList: createDescriptionList('NW_N_NS.studying.descriptionList1', 2),},
                    {title: t('NW_N_NS.studying.title2'), descriptionList: createDescriptionList('NW_N_NS.studying.descriptionList2', 2),},
                    {title: t('NW_N_NS.studying.title3'), descriptionList: createDescriptionList('NW_N_NS.studying.descriptionList3', 2),},
                    {title: t('NW_N_NS.studying.title4'), descriptionList: createDescriptionList('NW_N_NS.studying.descriptionList4', 2),},
                    {title: t('NW_N_NS.studying.title5'), descriptionList: createDescriptionList('NW_N_NS.studying.descriptionList5', 2),},
                    {title: t('NW_N_NS.studying.title6'), descriptionList: createDescriptionList('NW_N_NS.studying.descriptionList6', 2),},
                    {title: t('NW_N_NS.studying.title7'), descriptionList: createDescriptionList('NW_N_NS.studying.descriptionList7', 2),}
                ],
            },
        ],
        'NW-N-N': [
            {
                title: t('thinking'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/thinking_power_icon.png",
                advantage: createTitleAndDesc('NW_N_N.thinking', 6),
            },

            {
                title: t('interests'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/interests_icon.png",
                advantage: [
                    {title: t('NW_N_N.interest.title1'), descriptionList: createDescriptionList('NW_N_N.interest.descriptionList1', 3),},
                    {title: t('NW_N_N.interest.title2'), descriptionList: createDescriptionList('NW_N_N.interest.descriptionList2', 2),},
                    {title: t('NW_N_N.interest.title3'), descriptionList: createDescriptionList('NW_N_N.interest.descriptionList3', 2),},
                    {title: t('NW_N_N.interest.title4'), descriptionList: createDescriptionList('NW_N_N.interest.descriptionList4', 2),},
                    {title: t('NW_N_N.interest.title5'), descriptionList: createDescriptionList('NW_N_N.interest.descriptionList5', 2),},
                    {title: t('NW_N_N.interest.title6'), descriptionList: createDescriptionList('NW_N_N.interest.descriptionList6', 2),},
                    {title: t('NW_N_N.interest.title7'), descriptionList: createDescriptionList('NW_N_N.interest.descriptionList7', 2),},
                ],
            },
            {
                title: t('planning'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/planning_execution_icon.png",
                advantage: createTitleAndDesc('NW_N_N.planning', 2),
            },
            {
                title: t('socialAbility'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/sociability_icon.png",
                advantage: createTitleAndDesc('NW_N_N.socializing', 1),
            },
            {
                title: t('problemSolving'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/problem_solving_skills_icon.png",
                advantage: createTitleAndDesc('NW_N_N.problemSolving', 3),
            },
            {
                title: t('learning'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/learning_icon.png",
                advantage: [
                    {title: t('NW_N_N.studying.title1'), descriptionList: createDescriptionList('NW_N_N.studying.descriptionList1', 2),},
                    {title: t('NW_N_N.studying.title2'), descriptionList: createDescriptionList('NW_N_N.studying.descriptionList2', 2),},
                    {title: t('NW_N_N.studying.title3'), descriptionList: createDescriptionList('NW_N_N.studying.descriptionList3', 2),},
                    {title: t('NW_N_N.studying.title4'), descriptionList: createDescriptionList('NW_N_N.studying.descriptionList4', 2),},
                    {title: t('NW_N_N.studying.title5'), descriptionList: createDescriptionList('NW_N_N.studying.descriptionList5', 2),},
                    {title: t('NW_N_N.studying.title6'), descriptionList: createDescriptionList('NW_N_N.studying.descriptionList6', 2),},
                    {title: t('NW_N_N.studying.title7'), descriptionList: createDescriptionList('NW_N_N.studying.descriptionList7', 2),}
                ],
            },
        ],
        'NW-N-NW': [
            {
                title: t('thinking'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/thinking_power_icon.png",
                advantage: createTitleAndDesc('NW_N_NW.thinking', 2),
            },

            {
                title: t('interests'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/interests_icon.png",
                advantage: [
                    {title: t('NW_N_NW.interest.title1'), descriptionList: createDescriptionList('NW_N_NW.interest.descriptionList1', 3),},
                    {title: t('NW_N_NW.interest.title2'), descriptionList: createDescriptionList('NW_N_NW.interest.descriptionList2', 2),},
                    {title: t('NW_N_NW.interest.title3'), descriptionList: createDescriptionList('NW_N_NW.interest.descriptionList3', 2),},
                    {title: t('NW_N_NW.interest.title4'), descriptionList: createDescriptionList('NW_N_NW.interest.descriptionList4', 2),},
                    {title: t('NW_N_NW.interest.title5'), descriptionList: createDescriptionList('NW_N_NW.interest.descriptionList5', 2),},
                    {title: t('NW_N_NW.interest.title6'), descriptionList: createDescriptionList('NW_N_NW.interest.descriptionList6', 2),},
                ],
            },
            {
                title: t('planning'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/planning_execution_icon.png",
                advantage: createTitleAndDesc('NW_N_NW.planning', 2),
            },
            {
                title: t('socialAbility'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/sociability_icon.png",
                advantage: createTitleAndDesc('NW_N_NW.socializing', 2),
            },
            {
                title: t('problemSolving'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/problem_solving_skills_icon.png",
                advantage: createTitleAndDesc('NW_N_NW.problemSolving', 2),
            },
            {
                title: t('learning'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/learning_icon.png",
                advantage: [
                    {title: t('NW_N_NW.studying.title1'), descriptionList: createDescriptionList('NW_N_NW.studying.descriptionList1', 2),},
                    {title: t('NW_N_NW.studying.title2'), descriptionList: createDescriptionList('NW_N_NW.studying.descriptionList2', 2),},
                    {title: t('NW_N_NW.studying.title3'), descriptionList: createDescriptionList('NW_N_NW.studying.descriptionList3', 2),},
                    {title: t('NW_N_NW.studying.title4'), descriptionList: createDescriptionList('NW_N_NW.studying.descriptionList4', 2),},
                    {title: t('NW_N_NW.studying.title5'), descriptionList: createDescriptionList('NW_N_NW.studying.descriptionList5', 2),},
                    {title: t('NW_N_NW.studying.title6'), descriptionList: createDescriptionList('NW_N_NW.studying.descriptionList6', 2),},
                ],
            },
        ],
        'NW-NS-NS': [
            {
                title: t('thinking'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/thinking_power_icon.png",
                advantage: createTitleAndDesc('NW_NS_NS.thinking', 2),
            },

            {
                title: t('interests'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/interests_icon.png",
                advantage: createTitleAndDesc('NW_NS_NS.interest', 6),
            },
            {
                title: t('planning'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/planning_execution_icon.png",
                advantage: createTitleAndDesc('NW_NS_NS.planning', 2),
            },
            {
                title: t('socialAbility'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/sociability_icon.png",
                advantage: createTitleAndDesc('NW_NS_NS.socializing', 2),
            },
            {
                title: t('problemSolving'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/problem_solving_skills_icon.png",
                advantage: createTitleAndDesc('NW_NS_NS.problemSolving', 2),
            },
            {
                title: t('learning'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/learning_icon.png",
                advantage: [
                    {title: t('NW_NS_NS.studying.title1'), descriptionList: createDescriptionList('NW_NS_NS.studying.descriptionList1', 2),},
                    {title: t('NW_NS_NS.studying.title2'), descriptionList: createDescriptionList('NW_NS_NS.studying.descriptionList2', 2),},
                    {title: t('NW_NS_NS.studying.title3'), descriptionList: createDescriptionList('NW_NS_NS.studying.descriptionList3', 2),},
                    {title: t('NW_NS_NS.studying.title4'), descriptionList: createDescriptionList('NW_NS_NS.studying.descriptionList4', 2),},
                    {title: t('NW_NS_NS.studying.title5'), descriptionList: createDescriptionList('NW_NS_NS.studying.descriptionList5', 2),},
                    {title: t('NW_NS_NS.studying.title6'), descriptionList: createDescriptionList('NW_NS_NS.studying.descriptionList6', 2),},
                ],
            },
        ],
        'NW-NS-N': [
            {
                title: t('thinking'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/thinking_power_icon.png",
                advantage: createTitleAndDesc('NW_NS_N.thinking', 2),
            },

            {
                title: t('interests'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/interests_icon.png",
                advantage: createTitleAndDesc('NW_NS_N.interest', 6),
            },
            {
                title: t('planning'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/planning_execution_icon.png",
                advantage: createTitleAndDesc('NW_NS_N.planning', 2),
            },
            {
                title: t('socialAbility'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/sociability_icon.png",
                advantage: createTitleAndDesc('NW_NS_N.socializing', 2),
            },
            {
                title: t('problemSolving'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/problem_solving_skills_icon.png",
                advantage: createTitleAndDesc('NW_NS_N.problemSolving', 2),
            },
            {
                title: t('learning'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/learning_icon.png",
                advantage: [
                    {title: t('NW_NS_N.studying.title1'), descriptionList: createDescriptionList('NW_NS_N.studying.descriptionList1', 2),},
                    {title: t('NW_NS_N.studying.title2'), descriptionList: createDescriptionList('NW_NS_N.studying.descriptionList2', 2),},
                    {title: t('NW_NS_N.studying.title3'), descriptionList: createDescriptionList('NW_NS_N.studying.descriptionList3', 2),},
                    {title: t('NW_NS_N.studying.title4'), descriptionList: createDescriptionList('NW_NS_N.studying.descriptionList4', 2),},
                    {title: t('NW_NS_N.studying.title5'), descriptionList: createDescriptionList('NW_NS_N.studying.descriptionList5', 2),},
                    {title: t('NW_NS_N.studying.title6'), descriptionList: createDescriptionList('NW_NS_N.studying.descriptionList6', 2),},
                ],
            },
        ],
        'NW-NS-NW': [
            {
                title: t('thinking'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/thinking_power_icon.png",
                advantage: createTitleAndDesc('NW_NS_NW.thinking', 2),
            },

            {
                title: t('interests'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/interests_icon.png",
                advantage: [
                    {title: t('NW_NS_NW.interest.title1'), descriptionList: createDescriptionList('NW_NS_NW.interest.descriptionList1', 3),},
                    {title: t('NW_NS_NW.interest.title2'), descriptionList: createDescriptionList('NW_NS_NW.interest.descriptionList2', 2),},
                    {title: t('NW_NS_NW.interest.title3'), descriptionList: createDescriptionList('NW_NS_NW.interest.descriptionList3', 2),},
                    {title: t('NW_NS_NW.interest.title4'), descriptionList: createDescriptionList('NW_NS_NW.interest.descriptionList4', 2),},
                    {title: t('NW_NS_NW.interest.title5'), descriptionList: createDescriptionList('NW_NS_NW.interest.descriptionList5', 2),},
                ],
            },
            {
                title: t('planning'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/planning_execution_icon.png",
                advantage: createTitleAndDesc('NW_NS_NW.planning', 2),
            },
            {
                title: t('socialAbility'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/sociability_icon.png",
                advantage: createTitleAndDesc('NW_NS_NW.socializing', 2),
            },
            {
                title: t('problemSolving'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/problem_solving_skills_icon.png",
                advantage: createTitleAndDesc('NW_NS_NW.problemSolving', 2),
            },
            {
                title: t('learning'),
                imgSrc: "https://images.feel-good.io/guide/characteristics/learning_icon.png",
                advantage: [
                    {title: t('NW_NS_NW.studying.title1'), descriptionList: createDescriptionList('NW_NS_NW.studying.descriptionList1', 2),},
                    {title: t('NW_NS_NW.studying.title2'), descriptionList: createDescriptionList('NW_NS_NW.studying.descriptionList2', 2),},
                    {title: t('NW_NS_NW.studying.title3'), descriptionList: createDescriptionList('NW_NS_NW.studying.descriptionList3', 2),},
                    {title: t('NW_NS_NW.studying.title4'), descriptionList: createDescriptionList('NW_NS_NW.studying.descriptionList4', 2),},
                    {title: t('NW_NS_NW.studying.title5'), descriptionList: createDescriptionList('NW_NS_NW.studying.descriptionList5', 2),},
                    {title: t('NW_NS_NW.studying.title6'), descriptionList: createDescriptionList('NW_NS_NW.studying.descriptionList6', 2),},
                ],
            },
        ],
    }


    return {
        abilityData
    }
}