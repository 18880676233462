import {ConsultStatus} from "../../../types/counselor";
import style from "../style/button.module.scss";
import {useNewWindow} from "../../../hooks/hooks";
import {useCounselorAllocator} from "../info/hooks/useCounselorAllocator";
import {AdminRoleCategory} from "../../../types/adminRole";
import {useMyInfoGetter} from "../../account/mypage/hooks/useMyInfoGetter";

interface Args {
    counselingIdx: number;
    status: ConsultStatus;
    assignee?: string;
}

export function CounselorHistoryStatusButtonField({counselingIdx, status, assignee}: Args) {
    const {openNewWindowWithURL} = useNewWindow();
    const {allocateCounselor} = useCounselorAllocator();
    const { myInfo} = useMyInfoGetter();

    const TextBlock = ({text, additionalClass}: { text?: string, additionalClass: string }) => (
        <p className={`${style.text} ${additionalClass}`}>
            {text}
        </p>
    );

    // 배정하기 버튼 클릭 이벤트 함수
    const handleAssignButton = () => {
        const role = localStorage.getItem('role');

        if (role === AdminRoleCategory.CONSULTANT) {
            handleConsultantAssignment();
        } else {
            handleManagerAssignment();
        }
    };

    // 상담사 - 상담사 배정
    const handleConsultantAssignment = () => {
        // detail
        if (!myInfo) {
            alert('유효한 상담사가 없습니다.');
            return;
        }

        const isConfirm = window.confirm('정말 상담사 배정을 하시겠습니까?')
        if (isConfirm) {
            allocateCounselor({consultantIdx: myInfo.idx, counselingIdx})
                .then(() => {
                    alert('상담사가 배정되었습니다.');
                    window.location.reload();
                })
                .catch((error) => {
                    console.error('Assignment failed:', error);
                    alert('상담사 배정 중 문제가 발생했습니다.');
                });
        }
    };

    // 필굿관리자 OR 상담관리자의 배정
    const handleManagerAssignment = () => {
        openNewWindowWithURL(`/detail/counselor/assign/${counselingIdx}`, {
            width: 1000,
            height: 800,
        });
    };


    const buttonResult = () => {
        const renderAssigneeBlock = (statusText: string, textStyle: string) => (
            <>
                <TextBlock text={assignee} additionalClass={style.underLineText}/>
                <TextBlock text={statusText} additionalClass={textStyle}/>
            </>
        );
        switch (status) {
            case ConsultStatus.NOT_ALLOTTED:
                return (
                    <div className={style.assignButtonWrapper} onClick={handleAssignButton}>
                        <TextBlock text={'배정하기'} additionalClass={''}/>
                    </div>
                );
            case ConsultStatus.ALLOTTED:
                return renderAssigneeBlock('상담사 배정완료', style.blueText);
            case ConsultStatus.FINISHED:
                return renderAssigneeBlock('상담종료', style.redText);
            case ConsultStatus.CANCELED:
                return <TextBlock text={'취소처리'} additionalClass={style.normalText}/>;
            case ConsultStatus.REFUNDED:
                return renderAssigneeBlock('환불처리', style.redText);
            default:
                return <></>;
        }
    };
    return (
        <>
            {buttonResult()}
        </>
    )

}