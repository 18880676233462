import style from "./style.module.scss";
import feelgoodLogo from "../../assets/images/feelgood_logo.svg";
import { useMemo, useRef, useState } from "react";
import { AdminType } from "../../types/account";
import { useNavigate } from "react-router-dom";
import { useAppSelector, useAuth } from "../../hooks/hooks";
import { accountStatus } from "../../store/accountSlice";
import { useLoader } from "../../hooks/common/useLoader";
import { useTranslation } from "react-i18next";
import { useBlockEnter } from "../../hooks/useBlockEnter";
import { PasswordInputUnderline } from "../../components/PasswordInputUnderline";

enum SignInErrorCode {
  CODE_460 = "CODE_460",
  CODE_401 = "CODE_401",
  CODE_403 = "CODE_403",
  CODE_469 = "CODE_469",
  CODE_404 = "CODE_404",
  CODE_472 = "CODE_472",
  CODE_477 = "CODE_477",
}

export function Login() {
  const { t } = useTranslation("account");
  const status = useAppSelector(accountStatus);
  useLoader({ status });
  const navigate = useNavigate();
  const loginFormRef = useRef<HTMLFormElement>(null);
  const auth = useAuth();
  const { blockEnterKeyEvent } = useBlockEnter();

  const adminType = useMemo(() => AdminType.GENERAL, []);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordIncorrectCount, setPasswordIncorrectCount] = useState(0);
  const [idErrorMessage, setIdErrorMessage] = useState("");

  const errorTable = useMemo(
    () => ({
      [SignInErrorCode.CODE_460.toString()]: () => navigate(`/pending-admin-approval`),
      [SignInErrorCode.CODE_401.toString()]: (errorData: any) => setPasswordIncorrectCount(errorData.errCnt),
      [SignInErrorCode.CODE_403.toString()]: () =>
        navigate(`/lock-account?email=${encodeURIComponent(email)}&adminType=${adminType}`),
      [SignInErrorCode.CODE_469.toString()]: () => navigate(`/no-authorization?email=${encodeURIComponent(email)}`),
      [SignInErrorCode.CODE_404.toString()]: () => setIdErrorMessage(t("login.error.notSignedUpID")),
      [SignInErrorCode.CODE_472.toString()]: () => setIdErrorMessage(t("login.error.blockedAccount")),
      [SignInErrorCode.CODE_477.toString()]: () => setIdErrorMessage(t("login.error.leavedAccount")),
    }),
    [t, email, adminType, navigate]
  );

  const onClickSignUpLink = () => navigate(`/sign-up`);
  const onClickFindAccountLink = () => navigate(`/find-account`);
  const onClickLoginBtn = () => {
    if (loginFormRef.current?.reportValidity()) {
      auth.signIn(
        {
          email: email.trim(),
          password,
        },
        () => {
          navigate("/");
        },
        (e) => {
          const errorCaseFunc = errorTable[`CODE_${e.errorCode.httpCode.toString()}`];
          if (errorCaseFunc) {
            errorCaseFunc(e.data);
          } else {
            alert(t("login.error.serverError"));
          }
        }
      );
    }
  };

  return (
    <div className={style.loginWrapper}>
      <img src={feelgoodLogo} alt={t("login.feelgoodLogo")} />
      <div className={style.adminBadge}>Admin</div>
      <form className={style.form} onSubmit={(e) => e.preventDefault()} ref={loginFormRef}>
        <div className={style.field}>
          <label className={style.label}>{t("login.id.label")}</label>
          <div className={style.line}>
            <input
              type={"email"}
              className={style.input}
              value={email}
              minLength={6}
              maxLength={60}
              onChange={(e) => {
                setEmail(e.target.value);
                setIdErrorMessage("");
              }}
              required={true}
              placeholder={t("login.id.placeholder")}
              onKeyDown={blockEnterKeyEvent}
            />
          </div>
          {idErrorMessage ? <div className={style.errorMessage}>{idErrorMessage}</div> : <></>}
        </div>
        <div className={style.field}>
          <label className={style.label}>{t("login.password.label")}</label>
          <div className={style.line}>
            <PasswordInputUnderline
              className={style.input}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required={true}
              placeholder={t("login.password.placeholder")}
              onKeyDown={blockEnterKeyEvent}
            />
          </div>

          {passwordIncorrectCount > 0 && (
            <div className={style.errorMessage}>
              - {t("login.error.wrongPassword")}({passwordIncorrectCount}/5)
            </div>
          )}
        </div>
        <div className={`${style.field} ${style.loginBtnField}`}>
          <button className={style.loginBtn} onClick={onClickLoginBtn}>
            {t("login.loginButton")}
          </button>
        </div>
        <div className={`${style.field} ${style.linkField}`}>
          <span className={style.link} onClick={onClickSignUpLink}>
            {t("login.signUpButton")}
          </span>
          <span className={`${style.link} ${style.underline}`} onClick={onClickFindAccountLink}>
            {t("login.findAccountButton")}
          </span>
        </div>
      </form>
    </div>
  );
}
