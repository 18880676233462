import { useCallback, useMemo } from "react";
import { DisplayPosition } from "../../../types/product";

export function useProductDisplayPosition() {
  const statusNeededMobileImage = useMemo(
    () => [
      DisplayPosition.USER_WEB_KPASS,
      DisplayPosition.USER_WEB_DCAS,
      DisplayPosition.USER_APP_KPASS_IOS,
      DisplayPosition.USER_APP_KPASS_ANDROID,
      DisplayPosition.USER_APP_WITH_IOS,
      DisplayPosition.USER_APP,
      DisplayPosition.COUNSELOR_VOUCHER,
    ],
    []
  );

  const isDesktopImagesNeeded = useCallback(
    (displayPosition: DisplayPosition) =>
      displayPosition === DisplayPosition.USER_WEB_KPASS || displayPosition === DisplayPosition.USER_WEB_DCAS  ||
        displayPosition === DisplayPosition.ORGAN_WEB_KPASS || displayPosition === DisplayPosition.ORGAN_WEB_DCAS ||
        displayPosition === DisplayPosition.COUNSELOR_VOUCHER,
    []
  );
  const isMobileImagesNeeded = useCallback(
    (displayPosition: DisplayPosition) => statusNeededMobileImage.includes(displayPosition),
    [statusNeededMobileImage]
  );

  return {
    isDesktopImagesNeeded,
    isMobileImagesNeeded,
  };
}
