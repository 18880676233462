import {BrainType} from "../../../types/testResult";
import {BalancedStrategy} from "./BalancedStrategy";
import {useCallback} from "react";
import {LeftStrategy} from "./LeftStrategy";
import {RightStrategy} from "./RightStrategy";

interface Args{
    brainType:BrainType
}
export function GuideLearningStrategy({brainType}:Args) {
    /**
     * 두뇌유형별 학습법
     */
    const StrategyByBrainType = useCallback(() => {
        switch (brainType) {
            case BrainType.BALANCED:
                return <BalancedStrategy/>
            case BrainType.SIMULTANEOUS:
                return <RightStrategy />;
            case BrainType.SUCCESSIVE:
                return <LeftStrategy />
            default:
                return <></>;
        }
    }, [brainType]);

    return(
        <>
            <StrategyByBrainType  />
        </>
    )
}