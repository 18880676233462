import style from "../../style/test_result/attentionTip.module.scss";
import {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";

export function AttentionTip() {
    /**
     * 다크몽 이미지가 포함된
     */
    const {t} = useTranslation("lowLevelScale");

    const [isExpanded, setIsExpanded] = useState(false);
    const handleExpanded = () =>{
        //eslint-disable-next-line
        !isExpanded ? setIsExpanded(true) : null;
    }

    const darkmongSpeechWrapperRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    setIsExpanded(true);  // 요소가 관측되면 isExpanded를 true로 설정
                } else {
                    setIsExpanded(false); // 요소가 보이지 않으면 isExpanded를 false로 설정
                }
            });
        });

        if (darkmongSpeechWrapperRef.current) {
            observer.observe(darkmongSpeechWrapperRef.current);
        }

        return () => {
            if (darkmongSpeechWrapperRef.current) {
                //eslint-disable-next-line
                observer.unobserve(darkmongSpeechWrapperRef.current);
            }
        };
    }, []);
    return (
        <>
            <div className={`${style.darkmongTip} ${style.noPrint}`}>
                <div className={`${style.darkmongSpeechWrapper} ${isExpanded ? style.expanded : ''}`}
                     onClick={handleExpanded}
                     ref={darkmongSpeechWrapperRef}>
                    <div id="tip-image-and-text-wrapper" className={style.darkmongImageAndTextWrapper}>
                        <div className={style.darkmongImgBox}>
                            <img src="https://images.feel-good.io/report/attention/darkmong_on_chair.png"
                                 alt="darkmong"/>
                        </div>
                        <div className={style.textBoxWrapper}>
                            <div className={style.topSpace}></div>
                            <div className={`${style.textBox} ${style.closeButton}`}>
                                <div className={style.text} id="tip-text-wrapper">
                                    <div className={style.question}>
                                        <span className={style.yellow}>Tip</span>
                                        &nbsp;&nbsp;<span>{t('lowLevelScale.darkmongTip.attentionTipTitle')}</span>
                                    </div>
                                    <div className={style.dividerAndAnswer}>
                                        <hr className={style.divider}/>
                                        <div className={style.answer}>
                                            <span className={style.strong}>
                                              {t('lowLevelScale.darkmongTip.attentionTipContent1')}
                                            </span><br/>
                                            <span>
                                              {t('lowLevelScale.darkmongTip.attentionTipContent2')}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <button className={style.nextButton}
                                        onClick={() => setIsExpanded(true)}>
                                    <img src="https://images.feel-good.io/report/attention/arrow_on_circle_icon.svg"
                                         alt="오른쪽 화살표"/>
                                </button>
                                <button id="darkmong-attention-tip-close-button" className={style.closeButton}
                                        onClick={() => setIsExpanded(false)}>
                                    <img
                                        src="https://images.feel-good.io/report/attention/close_on_circle_icon.svg"
                                         alt="닫기"/>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}