import deleteIcon from "../../../../assets/images/button_icons/delete_icon.svg";
import {Panel} from "../../../../layout/content/detail/Panel";
import {useCallback, useEffect, useState} from "react";
import {DescriptionList} from "../../../../layout/content/detail/DescriptionList";
import {DetailSubMenuCategories} from "../../../../layout/content/detail/DetailSubMenuCategories";
import {ProfileTestHistory} from "./ProfileTestHistory";
import {ProfileOrganVoucher} from "./ProfileOrganVoucher";
import {DetailActionButton} from "../../../../components/Buttons";
import {useTesterDeleter} from "./hooks/useTesterDeleter";
import {useTesterDetailGetter} from "./hooks/useTesterDetailGetter";
import {useTranslation} from "react-i18next";
import {ProfileCounselorVoucher} from "./ProfileCounselorVoucher";
import {useTesterDetailFunctions} from "./hooks/useTesterDetailFunctions";
import {MenuCategory} from "../../../../types/test";

interface Props {
  profileIdx: number;
  onDeletedCallback?: () => void;
}

// todo 전체 수정 필요
export function ProfileDetail({ profileIdx, onDeletedCallback }: Props) {
  const { t } = useTranslation(["user", "common"]);
  const [selectedTab, setSelectedTab] = useState<MenuCategory>(MenuCategory.TEST_HISTORY);

  const { profile, getTesterDetail } = useTesterDetailGetter();
  const {categoryMenus, descriptionValue} =
      useTesterDetailFunctions({profileIdx,profile});

  const { deleteUserProfiles } = useTesterDeleter({
    onCompleteCallback: () => {
      onDeletedCallback?.();
    },
  });

  useEffect(() => {
    if (profileIdx) {
      getTesterDetail(profileIdx);
      setSelectedTab(MenuCategory.TEST_HISTORY);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileIdx]);

  // 피검사자 삭제
  const onClickDeleteProfileButton = useCallback(() => {
    if (profile?.organVoucher) {
      alert(t("detail.profile.delete.cannotDeleteAlertMessage"));
    } else {
      const isConfirmed = window.confirm(t("detail.profile.delete.confirmMessage", { name: profile?.name ?? "" }));
      if (isConfirmed) {
        deleteUserProfiles([profileIdx]);
      }
    }
  }, [t, profile?.organVoucher, profile?.name, profileIdx, deleteUserProfiles]);


  const profileTab = (currentTab:MenuCategory) => {
    if (!profile) return null;

    switch (currentTab) {
      case MenuCategory.TEST_HISTORY:
        return <ProfileTestHistory profileIdx={profileIdx}
                                   profile={profile} />
      case MenuCategory.ORGAN_VOUCHER:
        return <ProfileOrganVoucher profileIdx={profileIdx} />
      case MenuCategory.COUNSELOR_VOUCHER:
        return <ProfileCounselorVoucher profileIdx={profileIdx}/>
      default:
        return null;
    }
  };

  return (
      <>
        {profile ? (
            <>
              <Panel
                  title={t("detail.profile.detail.title")}
                  subtitle={`(${profile.name})`}
                  additionalButtons={
                    <>
                      {profile.isOwnerProfile ? (
                          <></>
                      ) : (
                          <DetailActionButton onClick={onClickDeleteProfileButton}>
                            <img src={deleteIcon} alt={t("detail.profile.delete.iconAlt")} />
                            {t("detail.profile.delete.buttonLabel")}
                          </DetailActionButton>
                      )}
                    </>
                  }
              >
                <DescriptionList data={descriptionValue} labelWidth={134} />
              </Panel>



              <DetailSubMenuCategories tabs={categoryMenus} selectedTab={selectedTab} onClickTab={setSelectedTab} />
              {profileTab(selectedTab)}
            </>
        ) : (
            <></>
        )}
      </>
  );
}
