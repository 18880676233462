import style from "../../style/test_result/guide/learningCard.module.scss";
import React from "react";
import {BrainType} from "../../../types/testResult";
import {Gender} from "../../../types/common";
import {ContentMapDTO, useGuideFunctions} from "./hooks/useGuideFunctions";
import {useRefContext} from "./hooks/GuideRefProvider";

interface Args{
    brainType: BrainType;
    testerName: string;
    gender: Gender;
    content: ContentMapDTO
}

export function LearningCardContent({
                                        brainType, testerName, gender, content}:Args) {
    const {convertBrainType} = useGuideFunctions();
    const planeImg = 'https://images.feel-good.io/guide/plane_icon.svg';
    const penImg = 'https://images.feel-good.io/guide/pen_icon.svg';

    const {mainRef, leftRef, rightRef}
        = useRefContext();

    return (
        <>
            <div id={"png-content"} className={style.content}>
                <section className={style.section} ref={mainRef}>
                    <div className={style.imageWrapper} ref={leftRef}>
                        <img className={style.cards}
                             src={content.leftCardImgUrl + `_${gender}.png`} alt={content.leftCardImgUrl}/>
                        <span className={`${style.cardDesc} ${style.desc1}`}>{content.subtitle}</span>
                        <span className={`${style.cardDesc} ${style.desc2}`}>{content.title}</span>
                        <span
                            className={style.cardDesc3Blue}>{content.title}</span>
                        <p className={style.cardHashtag}>
                            {/*  해시태그 영역  */}
                            {content.hashTags.map((data, idx) =>
                                <span key={idx} className={style.hashTagDesc}>{data}</span>
                            )}
                        </p>
                    </div>
                    <div style={{width: '30px'}}></div>
                    <div className={style.imageWrapper} ref={rightRef}>
                        <img className={style.cards} src={content.rightCardImgUrl} alt={"right_cards"}/>
                        <span className={style.card2Title}>
                                {/* 좌뇌우세형(순차처리우세형) */}
                            {convertBrainType(brainType)}
                            </span>
                        <p className={style.card2NameWrapper}>
                            <span className={style.name}>{testerName}</span>
                            <span className={style.subName}>님</span>
                        </p>
                        <p className={style.card2BrainDesc}>
                            <span className={`${style.brain} ${style.left}`}>좌뇌</span>
                            <span className={`${style.brain} ${style.right}`}>우뇌</span>
                            <span className={`${style.brain} ${style.plan}`}>계획력</span>
                        </p>
                        <div className={style.card2TextWrapper}>
                            <div className={style.card2LearningWrapper}>
                                <img src={penImg} className={style.card2Image} alt={"pen"}/>
                                <div className={style.card2DescWrapper}>
                                    <p className={style.title}>나의 효율적인 학습법</p>
                                    <p className={style.desc}>{content.studyStrategy}</p>
                                </div>
                            </div>
                            <div className={`${style.card2LearningWrapper} ${style.secondContent}`}>
                                <img src={planeImg} className={style.card2Image} alt={"plane"}/>
                                <div className={style.card2DescWrapper}>
                                    <p className={style.title}>나의 미래 직업</p>
                                    <p className={style.desc}>{content.recommendJob}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}