import styled from "styled-components";
import {Postcode} from "../../../components/Postcode";
import variables from "../../../assets/scss/variable.module.scss";
import {UserDetailInnerWrapperStyle} from "../../member/user/components/UserSelfInfoDataStyle";

export const CounselorInputWrapperStyle = styled(UserDetailInnerWrapperStyle)`
  padding-right: 20px;
`;

export const ChangeAddressButton = styled(Postcode)`
  font-size: 14px;
  line-height: 1.6;
  padding: 0 6px;
  color: ${variables.colorFGDarkBlue};
  border: 1px solid ${variables.colorFGDarkBlue};
  border-radius: 2px;
  flex-shrink: 0;
  height: 24px;
`;

export const LargeInputWrapper = styled.div`
    display: flex;
    align-items: center;
    height: 66px;
    //padding: 0 20px;
    gap: 10px;

`
export const NumberInputStyle = styled.input`
    height: 46px;
    border: 1px solid #DEDEDE;
    background: #FFF;
    width: 100px;
    padding: 12px;
    
    color: #757575;
    text-align: right;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 22.4px */
    letter-spacing: -0.42px;
`

export const RadioWrapperStyle = styled.div`
    display: flex;
    padding: 0 20px;
    align-items: center;
    gap: 10px;
`

export const RadioWrapper = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 10px;
`

export const ImageWrapperStyle = styled.div`
    padding: 0 20px;
    height: 166px;
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 10px;
`
