import {ConsultantDTO} from "../../../../types/counselor";
import styled from "styled-components/macro";
import React from "react";
import {AuthorizeAction} from "./AuthorizeAction";
import {DeleteAction} from "./DeleteAction";

const ButtonWrapperStyle = styled.div`
    display: flex;
    gap: 10px;
    justify-content: center;
`;

interface Args {
    dto: ConsultantDTO
}

export function CounselorAdditionalFunction({dto}: Args) {
    const role = localStorage.getItem('role');

    return (
        <ButtonWrapperStyle>
            <DeleteAction dto={dto} role={role} />
            <AuthorizeAction dto={dto} role={role} />
        </ButtonWrapperStyle>
    );
}