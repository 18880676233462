import {ListQueryType} from "./list";
import {PageResponse, SearchCondition} from "./page";
import {FilterValue} from "./filter";
import {TestType} from "./common";
import {Address} from "./user";
import {AddedFile, FileType} from "./file";
import {AdminStatus} from "./admin";
import {VoucherType} from "./voucher";
import {MemoType} from "./memo";
import {SettlementStatus} from "./affiliateCode";
import {ActionUser} from "./dataCommon";

// 상담사 정보 영역
export enum CounselorFilterWordType {
    COUNSELING_AFFILIATION = "COUNSELING_AFFILIATION", // 상담소속
    NAME = "NAME", // 이름
    ID = "ID", // ID
    CELLPHONE = "CELLPHONE", // 휴대전화
}

export enum CounselorHistoryFunctions {
    TEST_RESULT = "TEST_RESULT",
    PRE_QUESTION = "PRE_QUESTION",
    MEMO = "MEMO",
    REVIEW = "REVIEW",
    CONSULTING_END = "CONSULTING_END"
}

export enum CounselorPosition {
    "CONSULTANT_MANAGER" = "CONSULTANT_MANAGER", // 상담관리자
    "CONSULTANT" = "CONSULTANT", // 상담사
}

export enum CounselorCheckOptions {
    ACCOUNT = "ACCOUNT", // 계좌
    COUNTRY = "COUNTRY", // 국적
    NATIONALITY = "NATIONALITY", // 휴대폰번호 국가
    COUNSELOR_GROUP = "COUNSELOR_GROUP" // 상담소속
}

export enum CounselorMenus {
    COUNSELOR_GROUP_INFO = "COUNSELOR_GROUP_INFO", // 상담 소속정보
    COUNSELOR_INFO = "COUNSELOR_INFO", // 상담사 정보
}

export enum CounselorHistoryMenus {
    CONSULTANT_INFO = "CONSULTANT_INFO", // 상담 정보
}

export enum TaxSelectedValue {
    SELECT = "SELECT",
    UNSELECT = "UNSELECT",
}

// 이메일 체크 DTO
export interface CounselorEmailCheckDTO {
    email: string;
}

// 휴대전화번호 체크 DTO
export interface CounselorCellPhoneCheckDTO {
    codePhone: string;
    phoneNumber: string;
}

// 상담사 정보 영역 끝 ㅡㅡㅡㅡㅡㅡ

// 상담 내역 영역 ㅡㅡㅡㅡㅡㅡㅡ
export enum ConsultStatus {
    NOT_ALLOTTED = "NOT_ALLOTTED",
    ALLOTTED = "ALLOTTED",
    FINISHED = "FINISHED",
    CANCELED = "CANCELED",
    REFUNDED = "REFUNDED",
}

export enum CounselorHistoryFilterWordType {
    APPLICANT_NAME = "APPLICANT_NAME", // 신청자 이름
    TESTER_NAME = "TESTER_NAME", // 피검사자 이름
    PHONE_NUMBER = "PHONE_NUMBER", // 휴대전화
}

export enum CounselorDuplicateType {
    CELL_PHONE = "휴대폰번호",
    EMAIL = "이메일",
    GROUP_NAME = "소속이름",
}

export interface FileResponse {
    idx: number;
    uri: string;
}

// 상담사 정보 검색 DTO
export interface CounselorInfoListQueryType extends ListQueryType {
    searchWordType?: string;
    searchWord?: string;
    registerStartAt?: string;
    registerEndAt?: string;
    position?: string;
    status?: string;
}

export interface CounselorInfoSearchCondition extends SearchCondition {
    query?: string;
    query_type?: CounselorFilterWordType;
    created_at_from?: Date;
    created_at_to?: Date;
    status?: AdminStatus;
    consultantRank?: CounselorPosition;
}

// 상담사 정보 목록 조회 API 응답 VO
export interface ConsultantDTO {
    idx: number;
    email: string;
    name: string;
    status: AdminStatus;
    codePhone: string;
    phoneNumber: string;
    roleIdx: number;
    roleName: string;
    consultantRank: CounselorPosition;
    createAt: string;
    leaveAt: string;
    blockAt: string;
    reasonForBlocking: string;
    affiliationIdx: number;
    affiliationName: string;
    group: string;
    totalCounselorCount: number;
    consultantInfoDTO: ConsultantInfoDTO;
    testRound: number;
}

export interface ConsultantInfoDTO{
    briefHistory: string;
    bankName: string;
    accountNumber: string;
    accountName: string;
    counselingAffiliationIdx: number;
    counselingAffiliationName: string;
    distributionAmount: number;
    isVatInclude: boolean;
    isTaxDeduction: boolean;
}

// 상담사 상세 정보
export interface ConsultantDetailDTO {
    idx: number;
    email: string;
    name: string;
    status: AdminStatus;
    codePhone: string;
    phoneNumber: string;
    roleIdx: number;
    roleName: string;
    consultantRank: CounselorPosition;
    consultantInfoDTO: ConsultantInfoDTO;
    createAt: string;
    nationality: string;
    blockAt: string;
    reasonForBlocking: string;
    profileImageURL: string;
    affiliationIdx: number;
    affiliationName: string;
    affiliationPhoneNumber: string;
    affiliationAddress: Address;
    image?:FileResponse
}

// 상담소속 등록 DTO
export interface CounselorGroupRegistrationDTO {
    counselingAffiliationPostDTO: CounselorGroupDTO;
    consultantManagerPostDTO: CounselorManagerDTO;
}

// 상담 소속정보 DTO
export interface CounselorGroupDTO {
    name: string;
    address: Address
    codePhone: string;
    phoneNumber: string;
    briefHistory: string;
    bankName: string;
    accountNumber: string;
    accountName: string;
    affiliationDistributionAmount: number;
    consultantDistributionAmount: number;
    isVatInclude: boolean;
    isTaxDeduction: boolean;
}

// // 상담 소속정보 유효성 검사 DTO
// export interface CounselorGroupValidateDTO {
//     groupNameValidate: ValidStatus;
//     addressValidate: ValidStatus;
//     affiliationDistributionValidate: ValidStatus;
//     consultantDistributionValidate: ValidStatus;
// }

/**
 상담소속 중 상담 관리자 DTO
 요청 보내는 정보
 */
export interface CounselorManagerDTO {
    nationality: string;
    email: string;
    name: string;
    codePhone: string;
    phoneNumber: string;
    password: string;
    passwordConfirm?: string;
    profileImageUrl?: AddedFile;
    addedImage?: FileType;
    briefHistory: string; // counselorProfile
    bankName: string;
    accountNumber: string | null;
    accountName: string | null;
    affiliationDistributionAmount: number;
    consultantDistributionAmount: number;
    isVatInclude: boolean
    isTaxDeduction: boolean;
    counselingAffiliationIdx?: string;
}

// 상담사 등록 중 상담 소속 조회 정보 DTO
export interface AffiliationConsultantsDTO{
    consultants: PageResponse<ConsultantDTO>;
}

export interface CounselorAffiliationDTO{
    idx: number,
    name: string,
    affiliationDistributionAmount: number,
    consultantDistributionAmount: number,
    isVatInclude: boolean,
    isTaxDeduction: boolean,
}

// 상담소속 중 상담소속 상세 페이지 DTO
export interface CounselorGroupInfoDTO {
    name: string;
    address: Address
    codePhone: string;
    phoneNumber: string;
    briefHistory: string;
    bankName: string;
    accountNumber: string;
    accountName: string;
    affiliationDistributionAmount: number;
    consultantDistributionAmount: number;
    isVatInclude: boolean;
    isTaxDeduction: boolean;
    affiliationManagerIdx: number;
}


// 상담사 등록 요청 DTO
export interface CounselorRegisterDTO {
    name: string;
    address: Address
    codePhone: string;
    phoneNumber: string;
    briefHistory: string;
    bankName: string;
    accountNumber: string;
    accountName: string;
    affiliationDistributionAmount: number;
    consultantDistributionAmount: number;
    isVatInclude: boolean;
    isTaxDeduction: boolean;
    affiliationManagerIdx: number;
}

// 상담소속 상세 상담소속 정보 변경 DTO
export interface CounselorGroupPutDTO{
    address: Address,
    codePhone: string;
    phoneNumber: string,
    briefHistory: string,
    bankName: string,
    accountNumber: string,
    accountName: string,
    affiliationDistributionAmount: number;
    consultantDistributionAmount: number;
    isVatInclude: boolean;
    isTaxDeduction: boolean
}

// 상담소속 중 상담사 DTO
export interface CounselorDetailDTO {
    nation: string;
    id: string;
    authStatus: AdminStatus;
    name: string;
    nationality: string;
    cellPhone: string;
    position: CounselorPosition;
    password: string;
    profileImage: string;
    counselorProfile: string;
    bankName: string;
    accountNumber: string;
    accountName: string;
    registeredAt: string;
    lastLoginTime: string;
}

// 상담소속 중 상담사 정보 수정 DTO
export interface CounselorInfoPutDTO {
    name: string,
    codePhone: string,
    phoneNumber: string,
    briefHistory: string,
    counselingAffiliationIdx: number,
    bankName: string,
    accountNumber: string,
    accountName: string,
    consultantDistributionAmount: number,
    isVatInclude: boolean,
    isTaxDeduction: boolean,
    addedFile?: AddedFile
    image?: FileType;
}



// 상담 내역 검색 DTO
export interface CounselorHistoryListQueryType extends ListQueryType {
    query?: string;
    queryType?: CounselorHistoryFilterWordType;
    createdAtFrom?: string;
    createdAtTo?: string;
    desiredAtFrom?: string;
    desiredAtTo?: string;
    testType?: string;
    status?: string;
}

// 상담 내역 테이블 컬럼 응답 내용
export interface CounselorHistoryListVO {
    idx: number;
    createAt: string;
    memoWritten: boolean;
    // preQuestioning: string;
    // status: ConsultStatus;
    desiredDate: string;
    desiredTime: string;
    applicantIdx: number;
    testerIdx: number;
    applicantName: string;
    codePhone: string;
    phoneNumber: string;
    testerName: string;
    contentExecutedInfoIdx: number;
    ageYear: number;
    ageMonth: number;
    testType: VoucherType;
    testRound: number;
    consultantName: string;
    consultingStatus: ConsultStatus;
}

export interface CounselorHistoryFilterValues {
    searchWordTypeFilterValue: FilterValue<CounselorFilterWordType>;
    searchWordFilterValue: FilterValue<string>;
    registerStartAtFilterValue: FilterValue<Date | undefined>;
    registerEndAtFilterValue: FilterValue<Date | undefined>;
    consultationStartAtFilterValue: FilterValue<Date | undefined>;
    consultationEndAtFilterValue: FilterValue<Date | undefined>;
    testTypeFilterValue: FilterValue<TestType>;
    statusFilterValue: FilterValue<AdminStatus>;
}

// 상담 배정 요청 DTO
export interface ConsultantAllocationPostDTO{
    counselingIdx: number,
    consultantIdx: number
}

export interface CounselingHistoryDetailDTO{
    idx: number;
    createAt: string;
    preQuestioning: string;
    review: string;
    reviewStar: number;
    desiredDate: string;
    desiredTime: string;
    applicantName: string;
    codePhone: string;
    phoneNumber: string;
    testerName: string;
    ageYear: number;
    ageMonth: number;
    testType: VoucherType;
    testRound: number;
    status: ConsultStatus;
}

// 상담 메모 조회 DTO
export interface CounselingMemoSearchCondition extends SearchCondition {
    targetIdx: number;
}

// 상담 메모 POST DTO
export interface CounselingMemoPostDTO{
    memo: String,
    type: MemoType,
    targetIdx: number,
}

// 상담 메모 PUT DTO
export interface CounselingMemoPutDTO {
    memo: String,
    type: MemoType,
    memoIdx: number,
}

// 상담내역 영역 종료 ㅡㅡㅡㅡㅡㅡㅡㅡ


// 상담내역 정산 영역 ㅡㅡㅡㅡㅡㅡㅡㅡ
// 상담내역 리스트 조회 타입
export interface CounselorSettlementListQueryType extends ListQueryType {
    status?: string;
    year: string;
    month: string;
}

// 상담 정산 내역 중 상담사 정보 DTO
export interface SettlementCounselorDTO{
    consultantIdx: number;
    consultantStatus: CounselorPosition;
    consultantName: string;

    affiliationIdx: number;
    affiliationName: string;
    affiliationDistribution: number;

    monthlyCounselingCount: number;
    consultantDistributionAmount: number;
    vatValue: number;
    taxValue: number;
    settlementValue: number;
    settlementStatus: SettlementStatus;
    settlementIdx: number;
}

// 월별 상담 통계 응답 DTO
export interface MonthlySettlementDTO{
    completeCount: number;
    incompleteCount: number;
    totalCounselingCount: number;
    totalSettlementValue: number;
}

export interface SettlementAmountVO{
    totalMonthlyCounselingCount: number;
    totalDistributionAmount: number;
    totalVatValue: number;
    totalTaxValue: number;
    totalSettlementValue: number;
    counselor?: SettlementCounselorDTO;
}

// 월별 상담 정산 내역 응답 DTO
export interface AffiliationSettlementDTO{
    isAffiliation: boolean,
    settlementCounselorDTO: SettlementCounselorDTO[],
    affiliationSettlement: SettlementAmountVO;
    totalSettlement: SettlementAmountVO;
}

// 정산상태 업데이트 요청 DTO
export interface UpdateSettlementStatusDTO{
    settlementIdx: number[],
    yearMonth: string,
    status: SettlementStatus;
}

// 상담내역 정산 영역 종료 ㅡㅡㅡㅡㅡㅡㅡㅡ


// 일반회원 상세 영역 ㅡㅡㅡㅡㅡㅡㅡㅡ
export interface CounselorStatusInfo {
    purchasedVoucher: number; //구매한 이용권
    receivedVoucher: number; // 부여받은 이용권
    availableVoucher: number; // 사용가능 이용권
    collectedVoucher: number; // 회수된 이용권
}

export enum CounselorVoucherStatus {
    USABLE = "USABLE", // 사용가능
    COLLECTED = "COLLECTED", // 회수됨
    EXPIRED = "EXPIRED", // 기한만료
    USED = "USED" // 사용완료
}

// 일반회원 개별창 - 쿠폰/이용권 내 상담이용권 탭
export interface CounselorCouponVoucherListVO {
    idx: number;
    createAt: string; //발급일
    availableUntil: string; //유효기간
    issuerOrganName: ActionUser; //발급(회수자)
    unitVoucherStatus: CounselorVoucherStatus;
    collectReason: string;
}

// 일반회원 개별창 - 피검사자 내 상담이용권 탭
export interface CounselorProfileVoucherListVO {
    idx: number;
    createdAt: string; //등록일
    consultingRequestAt: string; //상담요청일
    wishTime: string; //희망시간
    testType: TestType;
    testCount: number;
    status: ConsultStatus;
    assignee: string; // 배정받은 상담사
}

// 상담사 본인 비밀번호 변경 요청 DTO
export interface ConsultantPasswordPutDTO{
    oldPwd: string;
    newPwd: string;
}