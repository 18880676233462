import {AffiliationSettlementDTO, SettlementAmountVO, SettlementCounselorDTO} from "../../../types/counselor";
import style from "../style/settlement.module.scss";
import React, {Fragment} from "react";
import {SettlementStatus} from "../../../types/affiliateCode";
import {useCounselorSettlementGetter} from "./hooks/useCounselorSettlementGetter";
import {useCounselorConverter} from "../info/hooks/useCounselorConverter";
import {numberWithCommas} from "../../../util/etcUtil";

interface Args {
    row: AffiliationSettlementDTO;
    setSelectedId: (_: number[]) => void;
    selectedId: number[];
    yearMonth: string;
    setIsAllSelected: (_: boolean) => void
}

export function CounselorSettlementField({row, selectedId, setSelectedId, yearMonth, setIsAllSelected}: Args) {

    const {updateSettlementsStatus} = useCounselorSettlementGetter();
    const {getInfoSearchPositionConverter} = useCounselorConverter()
    const isSettlementCompleted = (status: SettlementStatus) => {
        return status === SettlementStatus.COMPLETE
    }

    const handleClickButton = (idx: number) => {
        const updatedIds = selectedId.includes(idx)
            ? selectedId.filter((id) => id !== idx)
            : [...selectedId, idx];

        setIsAllSelected(false);
        setSelectedId(updatedIds);
    };

    // 관련기능 버튼을 통한 정산 상태 처리 함수
    const onClickSettlementStatusButton = (status: SettlementStatus, idx: number) => {
        // 정산완료 / 정산취소
        const currentStatus = status === SettlementStatus.COMPLETE ? '정산완료' : '정산취소';
        const isConfirm = window.confirm(`선택한 정산내역을 ${currentStatus} 처리 하시겠습니까?`);

        if (isConfirm) {
            updateSettlementsStatus({settlementIdx: [idx], status: status, yearMonth: yearMonth})
                .then(() => {
                    alert(`${currentStatus} 처리 되었습니다.`);
                    window.location.reload();
                });
        }
    }

    // 소속 정산 내역
    const AffiliationRow = (
        {settlement, name}: { settlement: SettlementAmountVO, name: string }
    ) => {
        if (!settlement.counselor) return <></>;
        const counselor = settlement.counselor;

        return (
            <tr key="summary">
                <td className={style.td}>
                    <input type={"checkbox"}
                           checked={selectedId.includes(counselor.settlementIdx)}
                           onChange={() => handleClickButton(counselor.settlementIdx)}
                    />
                </td>
                <td className={style.td} colSpan={3}>{name}</td>
                <td className={style.td}>{settlement.totalMonthlyCounselingCount}</td>
                <td className={style.td}>{settlement.totalDistributionAmount}</td>
                <td className={style.td}>{settlement.totalVatValue}</td>
                <td className={style.td}>{settlement.totalTaxValue}</td>
                <td className={`${style.td} ${style.bold}`}>
                    {settlement.totalSettlementValue}
                </td>
                <td className={`${style.td} 
                       ${isSettlementCompleted(counselor.settlementStatus) ? style.blueFont : style.redFont}`}>
                    {isSettlementCompleted(counselor.settlementStatus) ? '정산완료' : '미완료'}
                </td>
                <td className={style.td}>
                    {/* 정산 상태에 따른 버튼 분기처리 */}
                    <button
                        className={
                            isSettlementCompleted(counselor.settlementStatus)
                                ? style.settlementCancelButton
                                : style.settlementCompleteButton
                        }
                        onClick={() =>
                            onClickSettlementStatusButton(
                                isSettlementCompleted(counselor.settlementStatus)
                                    ? SettlementStatus.INCOMPLETE
                                    : SettlementStatus.COMPLETE,
                                counselor.settlementIdx
                            )
                        }
                    >
                        {isSettlementCompleted(counselor.settlementStatus) ? "정산취소" : "정산완료"}
                    </button>
                </td>
            </tr>
        );
    };

    return (
        <>
            {row.settlementCounselorDTO.map((settlement: SettlementCounselorDTO, idx, arr) => (
                <Fragment key={idx}>
                    <tr key={settlement.consultantIdx}>
                        <td className={style.td}>
                            <input type={"checkbox"}
                                   checked={selectedId.includes(settlement.settlementIdx)}
                                   onChange={() => handleClickButton(settlement.settlementIdx)}
                            />
                        </td>
                        <td className={style.td}>{settlement.affiliationName ?? '무소속'}</td>
                        <td className={style.td}>{getInfoSearchPositionConverter(settlement.consultantStatus)}</td>
                        <td className={style.td}>{settlement.consultantName}</td>
                        <td className={style.td}>{settlement.monthlyCounselingCount}</td>
                        <td className={style.td}>{numberWithCommas(settlement.consultantDistributionAmount)}</td>
                        <td className={style.td}>{numberWithCommas(settlement.vatValue)}</td>
                        <td className={style.td}>{numberWithCommas(settlement.taxValue)}</td>
                        <td className={style.td}>{numberWithCommas(settlement.settlementValue)}</td>
                        <td className={`${style.td} 
                       ${isSettlementCompleted(settlement.settlementStatus) ? style.blueFont : style.redFont}`}>
                            {isSettlementCompleted(settlement.settlementStatus) ? '정산완료' : '미완료'}
                        </td>
                        <td className={style.td}>
                            {/* 정산 상태에 따른 버튼 분기처리 */}
                            <button
                                className={
                                    isSettlementCompleted(settlement.settlementStatus)
                                        ? style.settlementCancelButton
                                        : style.settlementCompleteButton
                                }
                                onClick={() =>
                                    onClickSettlementStatusButton(
                                        isSettlementCompleted(settlement.settlementStatus)
                                            ? SettlementStatus.INCOMPLETE
                                            : SettlementStatus.COMPLETE,
                                        settlement.settlementIdx
                                    )
                                }
                            >
                                {isSettlementCompleted(settlement.settlementStatus) ? "정산취소" : "정산완료"}
                            </button>
                        </td>
                    </tr>
                    {idx === arr.length - 1 && row.isAffiliation && ( // 마지막 반복일 경우에만 추가
                        <AffiliationRow settlement={row.affiliationSettlement} name={settlement.affiliationName}/>
                    )}
                </Fragment>
            ))}
            <tr className={style.summary}>
                <td className={`${style.td} ${style.bold}`} colSpan={4}>계</td>
                <td className={style.td}>{numberWithCommas(row.totalSettlement.totalMonthlyCounselingCount)}</td>
                <td className={style.td}>{numberWithCommas(row.totalSettlement.totalDistributionAmount)}</td>
                <td className={style.td}>{numberWithCommas(row.totalSettlement.totalVatValue)}</td>
                <td className={style.td}>{numberWithCommas(row.totalSettlement.totalTaxValue)}</td>
                <td className={`${style.td} ${style.bold}`}>
                    {numberWithCommas(row.totalSettlement.totalSettlementValue)}
                </td>
                <td className={style.td}/>
                <td className={style.td}/>
            </tr>
        </>
    )
}