import {FilterType, FilterValue} from "../../../../types/filter";
import {useCallback, useMemo, useState} from "react";
import {
    CounselorFilterWordType,
    CounselorInfoListQueryType,
    CounselorPosition
} from "../../../../types/counselor";
import {useFilterValues} from "../../../../hooks/list/useFilterValues";
import {useListSearchParams} from "../../../../hooks/list/useListSearchParams";
import {PageSearchCondition} from "../../../../types/page";
import {AdminStatus} from "../../../../types/admin";

export interface CounselorInfoFilterValues {
    searchWordTypeFilterValue: FilterValue<CounselorFilterWordType>;
    searchWordFilterValue: FilterValue<string>;
    registerStartAtFilterValue: FilterValue<Date | undefined>;
    registerEndAtFilterValue: FilterValue<Date | undefined>;
    positionFilterValue: FilterValue<CounselorPosition>;
    statusFilterValue: FilterValue<AdminStatus>;
}

export function useCounselorFilterValues() {

    const [searchWordType, setSearchWordType] = useState('');
    const [searchWord, setSearchWord] = useState("");
    const [registerStartAt, setRegisterStartAt] = useState<Date | undefined>();
    const [registerEndAt, setRegisterEndAt] = useState<Date | undefined>();
    const [position, setPosition] = useState(''); // 직급
    const [status, setStatus] = useState(''); // 직급
    const [pageInfoOnFilter, setPageInfoOnFilter] = useState<PageSearchCondition>({});

    const { listQueryType, setUrlSearchParams } = useListSearchParams<CounselorInfoListQueryType>();

    // 필터 영역
    const filterValueArray: FilterValue<any>[] = useMemo(
        () => [
            {
                key: "searchWordType",
                value: searchWordType,
                setter: setSearchWordType,
                // defaultValue: CounselorFilterWordType.ALL,
                defaultValue: '',
                type: FilterType.OTHER,
            },
            { key: "searchWord", value: searchWord, setter: setSearchWord, defaultValue: "", type: FilterType.STRING },
            {
                key: "registerStartAt",
                value: registerStartAt,
                setter: setRegisterStartAt,
                defaultValue: undefined,
                type: FilterType.DATE,
            },
            {
                key: "registerEndAt",
                value: registerEndAt,
                setter: setRegisterEndAt,
                defaultValue: undefined,
                type: FilterType.DATE,
            },
            {
                key: "position",
                value: position,
                setter: setPosition,
                defaultValue: '',
                type: FilterType.OTHER,
            },
            {
                key: "status",
                value: status,
                setter: setStatus,
                defaultValue: '',
                type: FilterType.OTHER,
            },
        ],
        [searchWordType, searchWord, registerStartAt, registerEndAt, position, status]
    );

    const { getFilterValueByKey } = useFilterValues({
        filterValueArray,
        listQueryType: listQueryType,
        setPageInfoOnFilter,
    });

    const filterValues = useMemo(
        () =>
            ({
                searchWordTypeFilterValue: getFilterValueByKey("searchWordType") as FilterValue<CounselorFilterWordType>,
                searchWordFilterValue: getFilterValueByKey("searchWord") as FilterValue<string>,
                registerStartAtFilterValue: getFilterValueByKey("registerStartAt") as FilterValue<Date | undefined>,
                registerEndAtFilterValue: getFilterValueByKey("registerEndAt") as FilterValue<Date | undefined>,
                positionFilterValue: getFilterValueByKey("position") as FilterValue<CounselorPosition>,
                statusFilterValue: getFilterValueByKey("status") as FilterValue<AdminStatus>,
            } as CounselorInfoFilterValues),
        [getFilterValueByKey]
    );
    // 필터 영역 종료

    // searchList 영역
    const searchList = useCallback(
        (pageSearchCondition: PageSearchCondition, listQueryTypeParam?: CounselorInfoListQueryType) => {
            const param: CounselorInfoListQueryType = {
                searchWordType: listQueryTypeParam?.searchWordType,
                searchWord: listQueryTypeParam?.searchWord,
                registerStartAt: listQueryTypeParam?.registerStartAt,
                registerEndAt: listQueryTypeParam?.registerEndAt,
                position: listQueryTypeParam?.position,
                status: listQueryTypeParam?.status,
            };

            return () => setUrlSearchParams(param, pageSearchCondition);
        },
        [setUrlSearchParams]
    );

    return{
        pageInfoOnFilter,
        filterValues, searchList,
        listQueryType
    }
}