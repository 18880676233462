import style from "./memo.module.scss";
import {Panel} from "../../../layout/content/detail/Panel";
import {MemoTargetType, MemoType} from "../../../types/memo";
import {MemoTitle} from "./MemoComponent";
import {useCallback, useRef, useState} from "react";
import {callAsync} from "../../../util/sliceUtil";
import {createMemoAsync} from "../../../store/memoSlice";
import {useAppDispatch} from "../../../hooks/hooks";
import {MemoTypeSelector} from "./MemoTypeSelector";
import {MemoTextArea} from "./MemoTextArea";
import {useTranslation} from "react-i18next";
import {useUtil} from "../../../util/hooks/useUtil";

interface Props {
  targetIdx: number;
  isWritable?: boolean;
  memoTitle: MemoTitle;
  targetType: MemoTargetType;
  onRefresh: () => void;
}
export function MemoWritingPanel({ targetIdx, isWritable, memoTitle, targetType, onRefresh }: Props) {
  const { t } = useTranslation("memo");
  const dispatch = useAppDispatch();
  const { defaultErrorMessage } = useUtil();

  const formRef = useRef<HTMLFormElement>(null);
  const memoRef = useRef<HTMLTextAreaElement>(null);
  const [memoType, setMemoType] = useState<MemoType>(MemoType.GENERAL);
  const [memo, setMemo] = useState("");

  const resetMemoData = useCallback(() => {
    setMemoType(MemoType.GENERAL);
    setMemo("");
    (memoRef.current as HTMLTextAreaElement).value = "";
  }, [memoRef]);

  const postMemo = useCallback(() => {
    callAsync(
      dispatch(
        createMemoAsync({
          param: {
            memo: memo.trim(),
            type: memoType,
            targetIdx,
            targetType,
          },
        })
      ).unwrap(),
      () => {
        onRefresh();
        alert(t("memoWritingPanel.result"));
        resetMemoData();
      },
      () => {
        alert(defaultErrorMessage);
      }
    ).then();
  }, [defaultErrorMessage, dispatch, targetType, t, targetIdx, memo, memoType, resetMemoData, onRefresh]);

  const onClickSaveMemoBtn = () => {
    if (!formRef.current?.reportValidity()) return;

    if (window.confirm(t("memoWritingPanel.confirm"))) {
      postMemo();
    }
  };

  return (
    <>
      {isWritable && (
        <div className={style.panelWrap}>
          <Panel title={memoTitle.memoWriteTitle} panelClassName={style.memoInputField}>
            <div className={style.inputArea}>
              <MemoTypeSelector targetType={targetType} memoType={memoType} setMemoType={setMemoType} />
              <form className={style.textArea} ref={formRef}>
                <MemoTextArea
                  memoListTitle={memoTitle.memoListTitle}
                  defaultValue={""}
                  setMemo={setMemo}
                  memoRef={memoRef}
                />
              </form>
              <button className={style.saveBtn} onClick={onClickSaveMemoBtn}>
                {t("memoWritingPanel.button")}
              </button>
            </div>
          </Panel>
        </div>
      )}
    </>
  );
}
