import {useAppDispatch} from "../../../../hooks/hooks";
import {CounselorCellPhoneCheckDTO, CounselorEmailCheckDTO} from "../../../../types/counselor";
import {
    checkCounselorAffiliationAsync,
    checkCounselorCellPhoneAsync,
    checkCounselorEmailAsync
} from "../../../../store/counselorSlice";

export function useDuplicateChecker() {

    const dispatch = useAppDispatch();

    const checkEmail = async (email: string) => {
        const param: CounselorEmailCheckDTO = {
            email: email,
        };
        try {
            return await dispatch(checkCounselorEmailAsync(param)).unwrap();
        } catch (e) {
            console.error(e);
            throw new Error("입력 오류");
        }
    };

    const checkCellPhone = async ({ codePhone, phoneNumber }: { codePhone: string; phoneNumber: string }) => {
        const param: CounselorCellPhoneCheckDTO = {
            codePhone: codePhone,
            phoneNumber: phoneNumber,
        };
        try {
            return await dispatch(checkCounselorCellPhoneAsync(param)).unwrap();
        } catch (e) {
            console.error(e);
            throw new Error("입력 오류");
        }
    };

    const checkAffiliation = async (affiliation:string) => {
        try {
            return await dispatch(checkCounselorAffiliationAsync(affiliation)).unwrap();
        } catch (e) {
            console.error(e);
            throw new Error("입력 오류");
        }
    };



    return {
        checkEmail, checkCellPhone, checkAffiliation
    }
}