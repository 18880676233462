import style from "../../style/test_result/testResult.module.scss";
import {TestSummaryHeader} from "../common/TestSummaryHeader";
import {TestResultPath, TestResultReportDTO} from "../../../types/testResult";
import React, {useEffect, useRef, useState} from "react";
import {AttentionTip} from "../common/AttentionTip";
import {Trans, useTranslation} from "react-i18next";
import {useLanguage} from "../../../hooks/hooks";

interface Args {
    testResult?: TestResultReportDTO
}

interface LowLevelScaleDTO {
    title: JSX.Element,
    testType: string,
    abilityName: string,
    testTypeEnglish: string,
    score: number | undefined,
    trustMin: number | undefined,
    trustMax: number | undefined,
    percent: number | undefined,
    computedData: string | undefined,
    description: JSX.Element;
}

/* eslint-disable max-len */
export function KpassTestResultLowLevelScale({testResult}: Args) {
    /**
     * 검사 결과 중 02 하위척도별 지수
     * todo 텍스트 통일 필요
     */
    const {t} = useTranslation("lowLevelScale");
    const {isKorean} = useLanguage()
    const name = testResult?.testResultUserProfileVO.name;
    const [successiveSummaryHide, setSuccessiveSummaryHide] = useState(false);
    const [simultaneousSummaryHide, setSimultaneousSummaryHide] = useState(false);
    const [planningSummaryHide, setPlanningSummaryHide] = useState(false);
    const [attentionSummaryHide, setAttentionSummaryHide] = useState(false);

    const [summaryHide,] = useState(false);

    // eslint-disable-next-line
    const toggleContent = (type: string) => {
        if (type === t('table.successive')) {
            setSuccessiveSummaryHide(!successiveSummaryHide)
        }
        if (type === t('table.simultaneous')) {
            setSimultaneousSummaryHide(!simultaneousSummaryHide)
        }
        if (type === t('table.planning')) {
            setPlanningSummaryHide(!planningSummaryHide)
        }
        if (type === t('table.attention')) {
            setAttentionSummaryHide(!attentionSummaryHide)
        }
    };

    const contentRef = useRef<HTMLDivElement>(null);
    const [height, setHeight] = useState('0px');

    useEffect(() => {
        const content = contentRef.current;

        if (content) {
            if (summaryHide) {
                setHeight('0px');
            } else {
                setHeight(`${content.scrollHeight + 20}px`);
            }
        }
    }, [summaryHide, testResult, toggleContent]);

    // 하위척도 별 표준점수, 신뢰구간, 백분위, 서술적 범주 표 내 노출되는 데이터
    const tableScores: LowLevelScaleDTO[] = [
        {
            testType: t('table.successive'),
            abilityName: t('table.successiveAbility'),
            testTypeEnglish: 'Successive Processing',
            score: testResult?.testResultVO.successiveScore.score,
            trustMin: testResult?.testResultVO.successiveScore.trustMin,
            trustMax: testResult?.testResultVO.successiveScore.trustMax,
            percent: testResult?.testResultVO.successiveScore.percent,
            computedData: testResult?.testResultComputedDataVO.a1OfSuccessiveScore,
            title: (
                <Trans t={t} i18nKey="lowLevelScale.successiveTitle"
                       components={{
                           light: <span className={style.light}/>,
                       }}
                />
            ),
            description:
            // 순차처리는 문제 해결을 위해 주어진~~
                (<>
                        <Trans t={t} i18nKey="lowLevelScale.successiveContent"
                               values={{
                                   blank: '\u00A0'
                               }}
                               components={{
                                   br: <br/>,
                               }}
                        />
                    </>
                )
        },
        {
            testType: t('table.simultaneous'),
            abilityName: t('table.simultaneousAbility'),
            testTypeEnglish: 'Simultaneous Processing',
            score: testResult?.testResultVO.simultaneousScore.score,
            trustMin: testResult?.testResultVO.simultaneousScore.trustMin,
            trustMax: testResult?.testResultVO.simultaneousScore.trustMax,
            percent: testResult?.testResultVO.simultaneousScore.percent,
            computedData: testResult?.testResultComputedDataVO.a1OfSimultaneousScore,
            title: (
                <Trans t={t} i18nKey="lowLevelScale.simultaneousTitle"
                       components={{
                           light: <span className={style.light}/>,
                       }}
                />
            ),
            description:
            // 동시처리는 주어진 여러 가지 정보들을 ~~
                (
                    <Trans t={t} i18nKey="lowLevelScale.simultaneousContent"
                           values={{
                               blank: '\u00A0'
                           }}
                           components={{
                               br: <br/>,
                           }}
                    />
                )
        },
        {
            testType: t('table.planning'),
            abilityName: t('table.planning'),
            testTypeEnglish: 'Planning',
            score: testResult?.testResultVO.planningScore.score,
            trustMin: testResult?.testResultVO.planningScore.trustMin,
            trustMax: testResult?.testResultVO.planningScore.trustMax,
            percent: testResult?.testResultVO.planningScore.percent,
            computedData: testResult?.testResultComputedDataVO.a1OfPlanningScore,
            title: (
                <Trans t={t} i18nKey="lowLevelScale.planningTitle"
                       components={{
                           light: <span className={style.light}/>,
                       }}
                />
            ),
            description:
            // K-PASS를 통해 측정된 계획력은 ~~
                (
                    <Trans t={t} i18nKey="lowLevelScale.planningContent"
                           values={{
                               blank: '\u00A0'
                           }}
                           components={{
                               br: <br/>,
                           }}
                    />
                )
        },
        {
            testType: t('table.attention'),
            abilityName: t('table.attention'),
            testTypeEnglish: 'Attention',
            score: testResult?.testResultVO.attentionScore.score,
            trustMin: testResult?.testResultVO.attentionScore.trustMin,
            trustMax: testResult?.testResultVO.attentionScore.trustMax,
            percent: testResult?.testResultVO.attentionScore.percent,
            computedData: testResult?.testResultComputedDataVO.a1OfAttentionScore,
            title: (
                <Trans t={t} i18nKey="lowLevelScale.attentionTitle"
                       components={{
                           light: <span className={style.light}/>,
                       }}
                />
            ),
            description:
            // 주의력은 아동이 문제해결에~~
                (
                    <Trans t={t} i18nKey="lowLevelScale.attentionContent"
                           values={{
                               blank: '\u00A0'
                           }}
                           components={{
                               br: <br/>,
                           }}
                    />
                )
        }
    ];


    // 처리 측정 결과에 따른 요약 & 풀이
    const explanationLoop = (data: LowLevelScaleDTO, summaryType: boolean) => {
        return (
            <>
                <button
                    className={style.summaryAndExplanationHeader}
                    onClick={() => toggleContent(data.testType)}
                >
                    <h4 className={style.title}>{t('summary')}</h4>
                    <span
                        className={`${style.spreadButton} ${!summaryType ? '' : style.hide}`}>
                                    <span>{t('fold')}</span>
                                    <img src="https://images.feel-good.io/report/minus_icon.svg" alt="접기"/>
                                </span>
                    <span
                        className={`${style.spreadButton} ${!summaryType ? style.hide : ''}`}>
                                    <span>{t('open')}</span>
                                    <img src="https://images.feel-good.io/report/plus_icon.svg" alt="자세히보기"/>
                                </span>
                </button>

                <div style={{height: height}}
                     className={`${style.contentParagraphWrapper} ${!summaryType ? style.hide : ''}`}>
                    <p className={style.contentParagraph} ref={contentRef}>
                        {/*  K-PASS를 통해 ~~의 ~~을 측정한 결과~~  */}
                        <Trans t={t} i18nKey="lowLevelScale.resultSummary"
                               values={{
                                   name: name,
                                   abilityName: data.abilityName,
                                   // percent: data.percent,
                                   // @ts-ignore
                                   percent: data.percent.startsWith('<')
                                       // @ts-ignore
                                       ? `${data.percent.toString().replace('<', '')}\u003E`
                                       : `${data.percent}`,
                                   blank: '\u00A0'
                               }}
                               components={{
                                   strong: <span className={style.strong}/>,
                                   blue: <span className={style.blue}/>,
                               }}
                        />
                    </p>
                </div>
            </>
        )
    }

    // 주의력 상세 내용
    const attentionDetails = [
        <p className={`${style.paragraph} ${style.special}`}>
            <Trans t={t} i18nKey="lowLevelScale.attentionDetail"
                   values={{
                       name: name,
                       score: testResult?.testResultVO.attentionScore.score,
                       blank: '\u00A0'
                   }}
                   components={{
                       strong: <span className={style.strong}/>,
                       blue: <span className={style.blue}/>,
                       underLine: <span className={style.underLine}/>,
                   }}
            />
        </p>
    ]

    return (
        <>
            <article className={style.summaryWrapper}>

                {/*  02 하위척도별 지수  */}
                <TestSummaryHeader testResultPath={TestResultPath.LOW_LEVEL_SCALE}
                                   testerName={testResult?.testResultUserProfileVO.name}/>
                <div className={style.underline}></div>

                {/*  하위척도 표준점수 표  */}
                <table className={`${style.table} ${style.subscale}`}>
                    <thead>
                    <tr>
                        <th rowSpan={2}>{t('table.lowLevel')}</th>
                        <th>{t('table.standardScore')}</th>
                        <th rowSpan={2}>{t('table.confidenceInterval')}</th>
                        <th rowSpan={2} className={style.percentile}>{t('table.percentile')}</th>
                        <th rowSpan={2} className={style.descriptiveCategory}>{t('table.descriptiveCategory')}</th>
                    </tr>
                    <tr>
                        <th>
                            <span className={style.light}>M=100, SD=15</span>
                        </th>
                    </tr>
                    </thead>
                    <tbody>

                    {/*  척도별 점수 표   */}
                    {tableScores.map((data, index) => (
                        <tr key={index}>
                            <th>{data.testType}</th>
                            <td><span className={style.red}>{data.score}</span></td>
                            <td><span className={style.red}>{data.trustMin} ~ {data.trustMax}</span></td>
                            <td><span className={style.red}>{data.percent}</span></td>
                            <td><span className={style.red}>{data.computedData}</span></td>
                        </tr>
                    ))}
                    </tbody>
                </table>
                {tableScores.map((data, index) => (
                    <article className={style.mainWrapper} key={index}>
                        <>
                            {/*  다크몽 가이드 버튼  */}
                            {data.testType === t('table.attention') && (
                                <AttentionTip/>
                            )}

                            <h3>
                                {data.title}
                            </h3>
                            <p className={style.paragraph}>
                                {data.description}
                            </p>
                            <hr className={style.divider}/>
                            <p className={style.paragraph}>
                                <Trans t={t} i18nKey="lowLevelScale.percentSummary"
                                       values={{
                                           name: name,
                                           score: data.score,
                                           trustMin: data.trustMin,
                                           trustMax: data.trustMax,
                                           testType: data.testType,
                                           // @ts-ignore
                                           percent: data.percent.startsWith('<')
                                           // @ts-ignore
                                               ? `${data.percent.toString().replace('<', '')}\u003E`
                                               : `${data.percent}`,
                                           blank: '\u00A0',
                                       }}
                                       components={{
                                           strong: <span className={style.strong}/>,
                                           blue: <span className={style.blue}/>,
                                           br: <br/>,
                                       }}
                                />
                            </p>

                            {data.testType === t('table.attention') && data.score && data.score < 70 && (
                                attentionDetails
                            )}


                            {/*  측정결과에 따른 요약 & 풀이  */}
                            {isKorean && (
                                <div className={`${style.summaryAndExplanation} ${style.noPrint}`}>
                                    {data.testType === t('table.successive') && explanationLoop(data, successiveSummaryHide)}
                                    {data.testType === t('table.simultaneous') && explanationLoop(data, simultaneousSummaryHide)}
                                    {data.testType === t('table.planning') && explanationLoop(data, planningSummaryHide)}
                                    {data.testType === t('table.attention') && explanationLoop(data, attentionSummaryHide)}
                                </div>
                            )}
                        </>
                    </article>
                ))}
            </article>
        </>
    );
}