import {useTranslation} from "react-i18next";
import {useTimeConverter} from "../../../../hooks/hooks";
import {TableColumnData} from "../../../../types/common";
import {ActionButtonWrapper} from "../../../../layout/content/ActionButtonWrapper";
import {Panel} from "../../../../layout/content/detail/Panel";
import {Table} from "../../../../layout/content/list/table/Table";
import {PaginationWrapper} from "../../../../layout/content/list/PaginationWrapper";
import {Pagination} from "../../../../layout/content/list/Pagination";
import {CounselorHistoryStatusButtonField} from "../../../counselor/history/CounselorHistoryStatusButtonField";
import {useTesterCounselorGetter} from "./hooks/useTesterCounselorGetter";
import React, {useEffect} from "react";
import {AdminTesterCounselingHistoryDTO} from "../../../../types/voucher";
import {CounselorHistoryAdditionalButtonField} from "../../../counselor/history/CounselorHistoryAdditionalButtonField";

interface Args {
    profileIdx:number
}

export function ProfileCounselorVoucher({profileIdx}: Args) {
    const {t} = useTranslation(["user", "common"]);
    const {timeConverter} = useTimeConverter();

    const {getCounselorVoucherHistory, testerCounselingList} = useTesterCounselorGetter()

    useEffect(() => {
        const counselorVoucherHistory = getCounselorVoucherHistory({profileIdx: profileIdx});
        counselorVoucherHistory();

        //eslint-disable-next-line
    }, [profileIdx]);

    const columns: TableColumnData<AdminTesterCounselingHistoryDTO>[] = [
        {
            accessor: "createAt",
            header: t("detail.profile.counselorHistory.createdAt"),
            sortable: false,
            getNode: (it) => <span>{timeConverter.convertToLocalTime(it.createAt)}</span>,
        },
        {
            accessor: "desiredDate",
            header: t("detail.profile.counselorHistory.consultingRequestAt"),
            sortable: true,
            getNode: (it) => <span>{timeConverter.convertToLocalTime(it.desiredDate) ?? ""}</span>,
        },
        {
            accessor: "desiredTime",
            header: t("detail.profile.counselorHistory.wishTime"),
            sortable: false,
            getNode: (it) => <span>{it.desiredTime}</span>,
        },
        {
            accessor: "contentExecutedInfoIdx",
            header: t("detail.profile.counselorHistory.testType"),
            sortable: false,
            getNode: (it) => (
                <span>{it.testType + ' ' + it.testRound + '회차'}</span>
            ),
        },
        {
            accessor: "testType",
            header: t("detail.profile.counselorHistory.status"),
            sortable: false,
            getNode: (it) => (
                <CounselorHistoryStatusButtonField
                    status={it.consultingStatus} assignee={it.consultantName}
                    counselingIdx={it.consultantIdx}
                />
            ),
        },
        {
            accessor: "idx",
            header: t("detail.profile.counselorHistory.additionalFunctions"),
            sortable: false,
            getNode: (it) => (
                <ActionButtonWrapper>
                    <CounselorHistoryAdditionalButtonField consultStatus={it.consultingStatus}
                                                           counselingIdx={it.idx}
                                                           isTestResultRemoved={true}/>
                </ActionButtonWrapper>
            ),
        },
    ];

    return (
        <Panel title={t("detail.profile.testHistory.title")}>
            {testerCounselingList ? (
                <>
                    <Table
                        listData={testerCounselingList}
                        columns={columns}
                        getList={() => getCounselorVoucherHistory({profileIdx:profileIdx})}
                        noDataText={t("detail.profile.testHistory.noData")}
                    />
                    {testerCounselingList.content.length === 0 ? (
                        <></>
                    ) : (
                        <PaginationWrapper>
                            <Pagination page={testerCounselingList}
                                        goSpecificPage={(pageNum) => {
                                            getCounselorVoucherHistory(
                                                { page: pageNum, profileIdx: profileIdx }
                                            )();
                                        }}
                            />
                        </PaginationWrapper>
                    )}
                </>
            ) : (
                <></>
            )}
        </Panel>
    );
}