import {SelectorOption} from "../../../../types/selector";
import {useCallback, useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {CounselorAffiliationDTO} from "../../../../types/counselor";
import {getAffiliationInfoAsync, getCounselingAffiliationsAsync} from "../../../../store/counselorSlice";
import {useAppDispatch} from "../../../../hooks/hooks";

export function useCounselorGroup() {
    const { t } = useTranslation("counselorGroup");
    const dispatch = useAppDispatch();
    const [affiliationList, setAffiliationList] = useState<CounselorAffiliationDTO[]>([]);

    const getAffiliationList = useCallback(
        async () => {
            try {
                const result: CounselorAffiliationDTO[] = await dispatch(getCounselingAffiliationsAsync()).unwrap();
                setAffiliationList(result);
                return result;
            } catch (e) {
                console.error(e);
                alert('일시적인 문제가 발생했습니다. \n다시 시도해주세요.');
            }
        },
        //eslint-disable-next-line
        [dispatch]
    );

    // 소속 정보 조회 API
    const getAffiliationInfo = useCallback(
        async () => {
            try {
                const result:CounselorAffiliationDTO = await dispatch(getAffiliationInfoAsync()).unwrap();
                return result;
            } catch (e) {
                console.error(e);
                alert('일시적인 문제가 발생했습니다. \n다시 시도해주세요.');
            }
        },
        //eslint-disable-next-line
        [dispatch]
    );

    useEffect(() => {
        if (affiliationList.length >= 0) {
            getAffiliationList().then();
        }
    //eslint-disable-next-line
    }, [getAffiliationList]);


    const toStringCounselorGroup: SelectorOption[] = useMemo(() => {
        const defaultOptions = [
            {
                value: "independent",
                label: t("registration.counselor.independent")
            },
        ];


        const affiliationOptions = affiliationList
            ? affiliationList.map((affiliation) => ({
                value: affiliation.idx.toString(),
                label: affiliation.name
            }))
            : [];

        return [...defaultOptions, ...affiliationOptions];
        //eslint-disable-next-line
    }, [t, affiliationList]);

    const counselorGroupOptions: SelectorOption[] = useMemo(() => {
        return [
            { label: t("registration.counselorGroupPlaceHolder"), value: "" },
            ...toStringCounselorGroup.filter((o) => o.value !== "")
        ];
        //eslint-disable-next-line
    }, [t, toStringCounselorGroup]);

    return {
        counselorGroupOptions, getAffiliationList, affiliationList,
        getAffiliationInfo,
    };
}