import React, { createContext, useRef, useContext } from "react";
import JSZip from "jszip";
import html2canvas from "html2canvas";

// Context 생성
const RefContext = createContext({
    mainRef: { current: null } as React.RefObject<HTMLDivElement>,
    leftRef: { current: null } as React.RefObject<HTMLDivElement>,
    rightRef: { current: null } as React.RefObject<HTMLDivElement>,
    handleDownloadButton: (
        mainRef: React.RefObject<HTMLDivElement>,
        leftRef: React.RefObject<HTMLDivElement>,
        rightRef: React.RefObject<HTMLDivElement>
    ) => {},
});

export const GuideRefProvider = ({ children }: { children: React.ReactNode }) => {
    const mainRef = useRef<HTMLDivElement>(null);
    const leftRef = useRef<HTMLDivElement>(null);
    const rightRef = useRef<HTMLDivElement>(null);

    const handleDownloadButton = (
        mainRef: React.RefObject<HTMLDivElement>,
        leftRef: React.RefObject<HTMLDivElement>,
        rightRef: React.RefObject<HTMLDivElement>
    ) => {
        const classNames = [mainRef.current, leftRef.current, rightRef.current];
        const fileNames = ["Education Card Total", "Education Card1", "Education Card2"];
        const zip = new JSZip(); // JSZip 인스턴스 생성

        const promises = classNames.map(async (element) => {
            if (!element) return Promise.resolve();
            const canvas = await html2canvas(element, { useCORS: true });
            return canvas.toDataURL("image/png").split(";base64,")[1];
        });

        Promise.all(promises)
            .then((results) => {
                results.forEach((dataURL, index) => {
                    if (dataURL) {
                        zip.file(`${fileNames[index]}.png`, dataURL, { base64: true });
                    }
                });

                // ZIP 파일 생성 및 다운로드
                zip.generateAsync({ type: "blob" }).then((content) => {
                    const link = document.createElement("a");
                    link.href = URL.createObjectURL(content);
                    link.download = "Education Card.zip";
                    link.click();
                });
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    return (
        <RefContext.Provider value={{ mainRef, leftRef, rightRef, handleDownloadButton }}>
            {children}
        </RefContext.Provider>
    );
};

export const useRefContext = () => useContext(RefContext);
