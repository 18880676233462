import style from "../../style/topbuttons.module.scss";
import React, {useCallback, useEffect, useState} from "react";
import {throttle} from "lodash";
import {Trans, useTranslation} from "react-i18next";

interface Args{
    isLinkeyInvisible?: boolean;
}
export function TestTopButton({isLinkeyInvisible}:Args) {
    const {t} = useTranslation("testResult");

    const [scrollY, setScrollY] = useState(window.scrollY);
    const onClickTopButton = useCallback(() => {
        window.scrollBy({
            left: 0,
            top: -window.scrollY,
            behavior: "smooth",
        });
    }, []);

    useEffect(() => {
        const defaultScrollListener = throttle(() => {
            setScrollY(scrollY);
            setScrollY(window.scrollY);
        }, 20);

        window.addEventListener("scroll", defaultScrollListener);
        return () => {
            window.removeEventListener("scroll", defaultScrollListener);
        };
    }, [scrollY]);

    return(
        <>
            <div id="topButtonWrapper" className={`${style.topButtonWrapper} ${scrollY>0 ? style.show : ''}`}>
                <button className={style.topButton} id="topButton" onClick={onClickTopButton}>
                    <img src="https://images.feel-good.io/icon/top_arrow_only.svg" alt="top arrow"/>
                </button>
                {/*  학습진로가이드에서는 안보이게  */}
                {!isLinkeyInvisible && (
                    <div id="guideLinkOnTopButton" className={style.guideLink}>
                        <img className={style.linkey} src="https://images.feel-good.io/report/linkey.png" alt="링키"/>
                        <a className={style.guideLinkButton}
                           target="_blank">
                            <div className={style.hoverBg}></div>
                            <div className={style.text}>
                                <Trans t={t} i18nKey="floatingText"
                                       components={{
                                           strong: <span className={style.strong}/>,
                                           br: <br />
                                       }}
                                />
                            </div>
                        </a>
                    </div>
                )}
            </div>
        </>
    )
}