interface AddedFile {
  uri: string;
  fileIdx: number;
}

enum FileClassification {
  PRODUCT_IMAGE = "PRODUCT_IMAGE",
  ADMIN_PROFILE_IMAGE = "ADMIN_PROFILE_IMAGE",
  PRODUCT_VIDEO = "PRODUCT_VIDEO",
  PRODUCT_THUMBNAIL_IMAGE = "PRODUCT_THUMBNAIL_IMAGE",
  PARTNER_COMPANY_LOGO = "PARTNER_COMPANY_LOGO",
  POP_UP_IMAGE = "POP_UP_IMAGE",
  BANNER_IMAGE = "BANNER_IMAGE",
}

interface FileResponse {
  idx: number;
  uri: string;
}

interface FileType {
  src: string;
  file: File;
}

export type { AddedFile, FileResponse, FileType };
export { FileClassification };
