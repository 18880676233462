import {useTranslation} from "react-i18next";
import {useListFilterValues, useTimeConverter} from "../../../../hooks/hooks";
import {useMemo} from "react";
import {FilterContentType, TableFilter} from "../../../../types/tableFilter";
import {
    ConsultStatus,
    CounselorFilterWordType,
    CounselorHistoryFilterWordType
} from "../../../../types/counselor";
import {TestType} from "../../../../types/common";
import {useHistoryConverter} from "./useHistoryConverter";
import {AdminStatus} from "../../../../types/admin";

// 엔터 기능을 위함
interface Args {
    search: () => void;
}

export function useHistoryFilters({search}: Args) {
    const {t} = useTranslation(["filter", "common"]);
    const {filterValues} = useListFilterValues();
    const {timeConverter} = useTimeConverter();

    const {getHistorySearchWordConverter, getHistoryTestTypeWordConverter,getHistoryStatusWordConverter } = useHistoryConverter();


    const searchWordTypeFilter = useMemo(
        () => filterValues.searchWordTypeFilterValue,
        [filterValues.searchWordTypeFilterValue]
    );
    const searchWordFilter = useMemo(() => filterValues.searchWordFilterValue, [filterValues.searchWordFilterValue]);
    const registerStartAtFilter = useMemo(
        () => filterValues.registerStartAtFilterValue,
        [filterValues.registerStartAtFilterValue]
    );
    const registerEndAtFilter = useMemo(
        () => filterValues.registerEndAtFilterValue,
        [filterValues.registerEndAtFilterValue]
    );
    const consultationStartAtFilter = useMemo(
        () => filterValues.consultationStartAtFilterValue,
        [filterValues.consultationStartAtFilterValue]
    );
    const consultationEndAtFilter = useMemo(
        () => filterValues.consultationEndAtFilterValue,
        [filterValues.consultationEndAtFilterValue]
    );
    const testTypeFilter = useMemo(
        () => filterValues.testTypeFilterValue,
        [filterValues.testTypeFilterValue]
    );
    const statusFilter = useMemo(() => filterValues.statusFilterValue,
        [filterValues.statusFilterValue]);

    const filters: TableFilter[][] = [
        [
            {
                labelTitle: "검색어",
                content: {
                    type: FilterContentType.TEXT,
                    value: searchWordFilter?.value,
                    maxLength: 100,
                    placeholderVal: t("searchWord.placeholder"),
                    onChangeFunc: (v) => searchWordFilter.setter(v),
                    onEnterKeyUpFunc: search,
                    typeSelector: {
                        type: FilterContentType.SELECT,
                        value: searchWordTypeFilter?.value,
                        options: [
                            {label: "전체", value: ""},
                            {
                                value: CounselorHistoryFilterWordType.APPLICANT_NAME,
                                label: getHistorySearchWordConverter(CounselorHistoryFilterWordType.APPLICANT_NAME),
                            },
                            {
                                value: CounselorHistoryFilterWordType.TESTER_NAME,
                                label: getHistorySearchWordConverter(CounselorHistoryFilterWordType.TESTER_NAME),
                            },
                            {
                                value: CounselorHistoryFilterWordType.PHONE_NUMBER,
                                label: getHistorySearchWordConverter(CounselorHistoryFilterWordType.PHONE_NUMBER),
                            },
                        ],
                        onChangeFunc: (v) => searchWordTypeFilter?.setter(v as CounselorFilterWordType),
                    },
                },
            },
        ],
        [
            {
                labelTitle: "등록일",
                content: {
                    type: FilterContentType.CALENDAR_RANGE,
                    from: {
                        type: FilterContentType.CALENDAR,
                        value: timeConverter.convertToFilterDateString(registerStartAtFilter?.value),
                        onChangeFunc: (v) =>
                            registerStartAtFilter?.setter(v ? timeConverter.convertToFilterStartOfDay(v) : undefined),
                        placeholderVal: t("inputDatePlaceholder"),
                    },
                    to: {
                        type: FilterContentType.CALENDAR,
                        value: timeConverter.convertToFilterDateString(registerEndAtFilter?.value),
                        onChangeFunc: (v) => registerEndAtFilter?.setter(
                            v ? timeConverter.convertToFilterEndOfDay(v) : undefined),
                        placeholderVal: t("inputDatePlaceholder"),
                    },
                }
            },
            {
                labelTitle: "상담요청일",
                content: {
                    type: FilterContentType.CALENDAR_RANGE,
                    from: {
                        type: FilterContentType.CALENDAR,
                        value: timeConverter.convertToFilterDateString(consultationStartAtFilter?.value),
                        onChangeFunc: (v) =>
                            registerStartAtFilter?.setter(v ? timeConverter.convertToFilterStartOfDay(v) : undefined),
                        placeholderVal: t("inputDatePlaceholder"),
                    },
                    to: {
                        type: FilterContentType.CALENDAR,
                        value: timeConverter.convertToFilterDateString(consultationEndAtFilter?.value),
                        onChangeFunc: (v) => registerEndAtFilter?.setter(
                            v ? timeConverter.convertToFilterEndOfDay(v) : undefined),
                        placeholderVal: t("inputDatePlaceholder"),
                    },
                },
            },
        ], [
            {
                labelTitle: "검사",
                content: {
                    type: FilterContentType.SELECT,
                    value: testTypeFilter.value,
                    options: [
                        {label: "전체", value: ""},
                        {
                            value: TestType.KPASS.toString(),
                            label: getHistoryTestTypeWordConverter(TestType.KPASS),
                        },
                        {
                            value: TestType.DCAS.toString(),
                            label: getHistoryTestTypeWordConverter(TestType.DCAS),
                        },
                    ],
                    onChangeFunc: (v) => testTypeFilter.setter(v as unknown as TestType),
                },
            },
            {
                labelTitle: "상태",
                content: {
                    type: FilterContentType.SELECT,
                    value: statusFilter.value,
                    options: [
                        {label: "전체", value: ""},
                        {
                            value: ConsultStatus.NOT_ALLOTTED.toString(),
                            label: getHistoryStatusWordConverter(ConsultStatus.NOT_ALLOTTED),
                        },
                        {
                            value: ConsultStatus.ALLOTTED.toString(),
                            label: getHistoryStatusWordConverter(ConsultStatus.ALLOTTED),
                        },
                        {
                            value: ConsultStatus.FINISHED.toString(),
                            label: getHistoryStatusWordConverter(ConsultStatus.FINISHED),
                        },
                        {
                            value: ConsultStatus.CANCELED.toString(),
                            label: getHistoryStatusWordConverter(ConsultStatus.CANCELED),
                        },
                        {
                            value: ConsultStatus.REFUNDED.toString(),
                            label: getHistoryStatusWordConverter(ConsultStatus.REFUNDED),
                        },
                        {
                            value: ConsultStatus.CANCELED.toString(),
                            label: getHistoryStatusWordConverter(ConsultStatus.CANCELED),
                        }
                    ],
                    onChangeFunc: (v) => statusFilter.setter(v as unknown as AdminStatus),
                },
            },
        ],
    ];

    return {
        filters,
    };
}