import style from "../../style/test_result/testResultCover.module.scss";
import {TestHeaderWithLine} from "./TestHeaderWithLine";
import {TestFooterWithLine} from "./TestFooterWithLine";
import {ResultType, TestResultReportDTO} from "../../../types/testResult";
import {useTimeConverter} from "../../../hooks/hooks";
import {Trans, useTranslation} from "react-i18next";
import React from "react";

interface Args{
    testResult: TestResultReportDTO
}

export function TestResultCover({ testResult }:Args) {
    /**
     * 검사결과보고서 표지
     */
    const {t} = useTranslation("testResultCover");
    const { timeConverter } = useTimeConverter();

    return (
        <>
            <div className={style.page}>
                <TestHeaderWithLine resultType={ResultType.TEST_RESULT}/>

                <img className={style.background_1}
                     src="https://images.feel-good.io/report/background1.svg"
                     alt="background1"/>
                <img className={style.background_2}
                     src="https://images.feel-good.io/report/background2.svg"
                     alt="background2"/>

                <div className={style.content}>

                    <div className={style.titleWrapper}>
                        <img className={style.logo} src={
                             true ? 'https://images.feel-good.io/report/kpass-logo-with-text.svg'
                                 : 'https://images.feel-good.io/report/kpass-logo.svg'}
                             alt="kpass-logo"/>
                        <div className={style.title}>
                            <h3 className={style.description}>
                                {/*웹 기반 게임을 통한 아동용 지능검사*/}
                                {t('testResultCover.subTitle')}
                            </h3>
                            <br/>
                            <h1 className={style.title}>
                                {/*검사 결과 보고서*/}
                                {t('testResultCover.testResultReport')}
                            </h1>
                        </div>
                        <div className={style.writerWrapper}>
                            <span className={style.title}>
                                {/*저자*/}
                                {t('testResultCover.writerLabel')}
                            </span>
                            <span className={style.name}>
                                {/*문수백 Ph.D 연구 (주)필굿 한국 심리검사 표준화 연구소 소장*/}
                                <Trans t={t} i18nKey="testResultCover.writer"
                                       components={{
                                           br: <br />
                                       }}
                                />
                            </span>
                        </div>
                    </div>

                    <dl className={style.testerProfile}>
                        <dt>{t('testResultCover.table.checkerLabel')}</dt>
                        <dd>{t('testResultCover.table.checker')}</dd>
                        <dt>{t('testResultCover.table.nameLabel')}</dt>
                        <dd>{testResult.testResultUserProfileVO.name}</dd>
                        <dt>{t('testResultCover.table.associationLabel')}</dt>
                        <dd>{t('testResultCover.table.association')}</dd>
                        <dt>{t('testResultCover.table.genderLabel')}</dt>
                        <dd>{testResult.testResultUserProfileVO.gender === 'MALE' ? '남' : '여'}</dd>
                        <dt>{t('testResultCover.table.testDateLabel')}</dt>
                        <dd>{timeConverter.convertDateWithWords(testResult.testDate)}</dd>
                        <dt>{t('testResultCover.table.birthdayLabel')}</dt>
                        <dd>{timeConverter.convertDateWithWords(testResult.testResultUserProfileVO.birthday)}</dd>
                    </dl>
                </div>

                <TestFooterWithLine/>
            </div>

        </>
    )
}