import style from "../../style/test_result/guide/personalDetail.module.scss"
import {BrainType} from "../../../types/testResult";
import {useGuidePersonalDetailFunctions} from "./hooks/useGuidePersonalDetailFunctions";

interface Args {
    brainType: BrainType
    currentResult: string;
}

/* eslint-disable max-len */
export function GuidePersonalDetails({brainType, currentResult}: Args) {
    const {abilityData} = useGuidePersonalDetailFunctions();

    const content = abilityData[currentResult as keyof typeof abilityData]

    // 선형적 학습 스타일 내 (순차적 접근, 디테일 중심..)
    const DescriptionList = ({descriptionList}: { descriptionList: any[] }) => (
        <ul className={style.descriptionList}>
            {descriptionList.map((listData, idx) => (
                <li key={idx}>
                    <span className={listData.title !== '' ? style.subTitle : ''}>{listData.title}</span>
                    <span>{listData.description}</span>
                </li>
            ))}
        </ul>
    );

    // 사고력, 관심분야 ..
    const Ability = (
        {title, imgSrc, advantage}:
            { title: string; imgSrc: string; advantage: any; }) => (
        <li>
            <h3 className={style.title}>
                <span>{title}</span>
                <img className={style.symbol} src={imgSrc} alt={'title_img'}/>
            </h3>
            <ul className={style.advantages}>
                {advantage.map((data: any, index: number) => (
                    <li key={index}>
                        <h4 className={style.title}>{data.title}</h4>
                        <p className={style.description}>{data.description}</p>
                        {data.descriptionList && <DescriptionList descriptionList={data.descriptionList}/>}
                    </li>
                ))}
            </ul>
        </li>
    )


    return (
        <>
            <ul className={style.abilities}>
                {content.map((data, index) => (
                    <Ability title={data.title} imgSrc={data.imgSrc} advantage={data.advantage} key={index}/>
                ))}
            </ul>
        </>
    )
}