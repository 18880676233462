import style from "../../style/test_result/testResult.module.scss";
import {BrainType} from "../../../types/testResult";
import React, {useCallback} from "react";
import {Trans, useTranslation} from "react-i18next";

interface Args {
    name: string;
    difference: number;
    brainType: BrainType;
}

/* eslint-disable max-len */
export function ProfileTestResultCaseField({name, brainType, difference}: Args) {
    const {t} = useTranslation("profile");

    const brainTypeText = useCallback(
        (type: BrainType) => {
            switch (type) {
                case BrainType.SUCCESSIVE:
                    return (
                        <>
                            {/*  순차처리 우세형 학습자일 경우 ~~  */}
                            <article className={style.mainWrapper}>
                                <h3>{t('profile.successiveCaseTitle')}</h3>
                                <p className={style.paragraph}>&nbsp;
                                    <Trans t={t} i18nKey="profile.successiveCaseDetail"
                                           values={{
                                               name: name,
                                               difference: Math.abs(difference),
                                               blank: '\u00A0'
                                           }}
                                           components={{
                                               strong: <span className={style.strong} />,
                                               blue: <span className={style.blue}/>,
                                               br: <br/>,
                                           }}
                                    />
                                </p>
                            </article>
                        </>
                    );
                case BrainType.SIMULTANEOUS:
                    return (
                        <>
                            {/*  동시처리 우세형 학습자일 경우  */}
                            <article className={style.mainWrapper}>
                                <h3>{t('profile.simultaneousCaseTitle')}</h3>
                                <p className={style.paragraph}>
                                    <Trans t={t} i18nKey="profile.simultaneousCaseDetail"
                                           values={{
                                               name: name,
                                               difference: Math.abs(difference),
                                               blank: '\u00A0'
                                           }}
                                           components={{
                                               strong: <span className={style.strong} />,
                                               blue: <span className={style.blue}/>,
                                               br: <br/>,
                                           }}
                                    />
                                </p>
                            </article>
                        </>
                    );
                case BrainType.BALANCED:
                    return (
                        <>
                        {/*  순차처리 = 동시처리형 학습자일 경우  */}
                        <article className={style.mainWrapper}>
                            <h3>{t('profile.balancedCaseTitle')}</h3>
                            <p className={style.paragraph}>
                                <Trans t={t} i18nKey="profile.balancedCaseDetail"
                                       values={{
                                           name: name,
                                           difference: Math.abs(difference),
                                           blank: '\u00A0'
                                       }}
                                       components={{
                                           strong: <span className={style.strong} />,
                                           blue: <span className={style.blue}/>,
                                           br: <br/>,
                                       }}
                                />
                            </p>
                        </article>
                        </>
                    )
            }
        },
        // eslint-disable-next-line
        []
    );

    return (
        <>
            {brainTypeText(brainType)}

        </>
    )
}