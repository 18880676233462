import { MainApi } from "./MainApi";
import { UserPutParam, UserSearchCondition } from "../types/user";
import { RefundAccountPutDTO } from "../features/order/detail/component/refund/hooks/useRefundAccountModifier";
import { MarketingTermsCategory } from "../types/terms";
import {SearchByKeyword} from "../types/common";

export class UserApi {
  static baseUrl = `${MainApi.urlPrefix}/user`;

  static getUserList = (condition: UserSearchCondition) => () =>
    MainApi.api.get(`${UserApi.baseUrl}${MainApi.toParamStringFromObject(condition)}`);

  static getUserDetail = (idx: number) => () => MainApi.api.get(`${UserApi.baseUrl}/${idx}`);

  static updateUser = (idx: number, userPutParam: UserPutParam) => () =>
    MainApi.api.put(
      `${UserApi.baseUrl}/${idx}`,
      Object.assign(userPutParam, {
        birthday: userPutParam.birthday?.replace(/(\d{4})(\d{2})(\d{2})/, "$1-$2-$3"),
      })
    );

  static blockUsers = (idxArr: number[], blockReason: string) => () =>
    MainApi.api.put(`${UserApi.baseUrl}/block`, {
      idx: idxArr,
      reasonForBlocking: blockReason,
    });

  static unblockUsers = (idxArr: number[]) => () => MainApi.api.put(`${UserApi.baseUrl}/unblock`, { idx: idxArr });

  static disagreeMarketing = (userIdx: number, category: MarketingTermsCategory) => () =>
    MainApi.api.put(`${UserApi.baseUrl}/${userIdx}/disagree-terms?termsCategoryEnum=${category}`);

  static updateUserRefundAccount = (userIdx: number, refundAccountPutDTO: RefundAccountPutDTO) => () =>
    MainApi.api.put(`${UserApi.baseUrl}/${userIdx}/refund-account`, refundAccountPutDTO);

  static downloadUsers = (userIdxes: number[]) => () =>
    MainApi.api.get(`${UserApi.baseUrl}/info/download?${userIdxes.map((idx) => `userIdxes=${idx}`).join("&")}`, {
      responseType: "blob",
    });

  // 이용권 내 사용자 조회
  static getNormalUserList = (query:SearchByKeyword) => () =>
    MainApi.api.get(`${UserApi.baseUrl}/voucher-info${MainApi.toParamStringFromObject(query)}`);
}
