import style from "../../product/edit/productEditForm.module.scss";
import {Label104x46} from "../../../components/Label";
import {AnImageAddition} from "../../../components/AnImageAddition";
import React from "react";
import {AddedFile, FileType} from "../../../types/file";
import {useTranslation} from "react-i18next";

interface Args {
    thumbnail?: AddedFile;
    setThumbnail: (_: AddedFile | undefined) => void;
    addedProfileImage?: FileType;
    setAddedProfileImage: (_: FileType | undefined) => void;
}

export function CounselorImageField(
    {
        thumbnail, setThumbnail, addedProfileImage, setAddedProfileImage
    }: Args) {
    const {t} = useTranslation("counselorGroup");
    return (
        <>
            <div className={style.fieldLine}>
                <div className={style.field}>
                    <Label104x46 className={style.label} text={t("registration.counselor.profileImage")}/>
                    <AnImageAddition
                        image={thumbnail}
                        setImage={setThumbnail}
                        addedImage={addedProfileImage}
                        setAddedImage={setAddedProfileImage}
                        additionalText={<span className={style.text}>
                            * 프로필 이미지는 가로:세로 1:1 비율의 이미지를 등록해 주세요.<br/>
                             * 권장 이미지 : 1M 이하 / gif, png, jpg(jpeg)<br/>
                             * 이미지 크기 : 600px*600px
                        </span>}
                    />
                </div>
            </div>
        </>
    )
}