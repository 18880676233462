import style from "../features/product/edit/productEditForm.module.scss";
import React from "react";

interface Args {
    imageSrc: string
}

export function NormalImageButton({imageSrc}: Args) {
    /**
     * 단일 이미지 노출만을 위한 컴포넌트
     */

    return (
        <button type={"button"}
                className={style.imageButton}
                style={{pointerEvents: "none"}}>
            <div className={style.addedImage}>
                <img src={imageSrc} alt={'normal_image'}/>
            </div>
        </button>
    )
}