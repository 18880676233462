import style from "../../style/test_result/highGraph.module.scss";
import {TestResultReportDTO} from "../../../types/testResult";
import {useGraphFunctions} from "./hooks/useGraphFunctions";
import {useEffect, useRef} from "react";
import {useTranslation} from "react-i18next";
import {useLanguage} from "../../../hooks/hooks";

interface Args{
    testResult:TestResultReportDTO
}
export function HighGraph({testResult}:Args) {
    const {t} = useTranslation("personal");
    const {isKorean} = useLanguage()

    const {drawGraph} = useGraphFunctions();
    const canvasRef = useRef<HTMLCanvasElement>(null);

    useEffect(() => {
        // 캔버스 크기 설정
        const canvas = canvasRef.current;
        if (canvas) {
            const scale = window.devicePixelRatio;
            canvas.width = canvas.clientWidth * scale;
            canvas.height = canvas.clientHeight * scale;

            const context = canvas.getContext('2d');
            if (context) {
                context.scale(scale, scale); // 스케일링 설정
            }
        }

        // 머리 이미지에 그래프 추가
        drawGraph(
            calculatePercentage(testResult?.testResultVO.planningScore.score),
            calculatePercentage(testResult?.testResultVO.attentionScore.score),
            calculatePercentage(testResult?.testResultVO.simultaneousScore.score),
            calculatePercentage(testResult?.testResultVO.successiveScore.score),
            canvasRef)
        //eslint-disable-next-line
    }, []);

    // 만점 대비 점수 계산 함수
    const calculatePercentage = (value: number) => {
        const perfectScore = 160.0;
        return value / perfectScore * 100;

    };

    const labels = [
        {
            labelClass: style.planning,
            ballText: 'P',
            strongText: t('table.planning'),
            lightText: 'Planning',
        },
        {
            labelClass: style.attention,
            ballText: 'A',
            strongText: t('table.attention'),
            lightText: 'Attention',
        },
        {
            labelClass: style.successive,
            ballText: 'S',
            strongText: t('table.successive'),
            lightText: 'Successive\nProcessing',
        },
        {
            labelClass: style.simultaneous,
            ballText: 'S',
            strongText: t('table.simultaneous'),
            lightText: 'Simultaneous\nProcessing',
        },
    ];

    useEffect(() => {

    }, [testResult]);

    return (
        <>
            <div className={style.headGraphWrapper}>
                <img className={style.headBg}
                     src="https://images.feel-good.io/report/head_graph_bg_only_head.svg" alt="head"/>
                <img className={style.headGraphLine}
                     src="https://images.feel-good.io/report/head_graph_line.svg" alt="head graph"/>

                {labels.map((item, index) => (
                    <div key={index} className={`${style.labelWrapper} ${item.labelClass}`}>
                        <span className={style.ball}>{item.ballText}</span>
                        <label className={style.label}>
                            <span className={style.strong}>{item.strongText}</span>
                            {isKorean && (
                                <span className={style.lightEn}>{item.lightText}</span>
                            )}
                        </label>
                    </div>
                ))}
                <div id="canvas-wrapper" className={style.lines}>
                    <canvas id="canvas" className={style.canvas} ref={canvasRef}></canvas>
                    <img className={style.brain}
                         src="https://images.feel-good.io/report/head_graph_brain.svg" alt="brain"/>
                </div>
            </div>
        </>
    )
}