import {Panel} from "../../layout/content/detail/Panel";
import {PanelWrapper} from "../../layout/content/detail/PanelWrapper";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Table} from "../../layout/content/list/table/Table";
import {PageResponse} from "../../types/page";
import {ConsultantDTO} from "../../types/counselor";
import {PaginationWrapper} from "../../layout/content/list/PaginationWrapper";
import {Pagination} from "../../layout/content/list/Pagination";
import {useCounselorManagerColumns} from "./info/hooks/useCounselorManagerColumns";
import {ButtonWithBlueColor} from "../../components/Buttons";
import {useCounselorInfoGetter} from "./info/hooks/useCounselorInfoGetter";
import {useParams} from "react-router-dom";
import {useCounselorAllocator} from "./info/hooks/useCounselorAllocator";

export function CounselorAssignField() {
    const {t} = useTranslation("counselorInfo");
    const { id } = useParams<"id">();
    const consultantId = Number(id)
    const {allocateCounselor} = useCounselorAllocator();
    const {getCounselorInfoLists, counselorInfoList, getCounselorInfoList}
        = useCounselorInfoGetter();

    const [selectedId, setSelectedId] = useState(0);
    const {columns} = useCounselorManagerColumns({selectedId, setSelectedId});

    useEffect(() => {
        getCounselorInfoList( {size: 4}).then();
        //eslint-disable-next-line
    }, []);

    const handleAssignment = () => {
        if(selectedId === 0) {
            alert('배정할 상담사를 선택해주세요.');
            return false;
        }

        const isConfirm = window.confirm('정말 상담사 배정을 하시겠습니까?')
        if (isConfirm) {
            allocateCounselor({consultantIdx: selectedId, counselingIdx:consultantId}).then(() =>{
                alert('상담사가 배정되었습니다.');

                // 이전 페이지 새로고침
                if (window.opener) {
                    window.opener.location.reload();
                }

                window.close();
            });
        }
    }

    return (
        <PanelWrapper>
            {counselorInfoList && (

                <Panel title={t("manager.groupManagerTitle")}
                       additionalButtons={
                           <>
                               <ButtonWithBlueColor onClick={() => handleAssignment()}>
                                   {t("button.assignment")}
                               </ButtonWithBlueColor>
                           </>
                       }>
                    <Table
                        listData={counselorInfoList as
                            PageResponse<ConsultantDTO>}
                        columns={columns}
                        getList={getCounselorInfoLists}
                        noDataText={'잠시만 기다려주세요.'}
                    />
                    <PaginationWrapper>
                        <Pagination page={counselorInfoList} goSpecificPage={(pageNum: number) =>
                            getCounselorInfoList({page:pageNum, size:4})}/>
                    </PaginationWrapper>
                </Panel>
            )}

        </PanelWrapper>
    )
}