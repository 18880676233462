import {useTranslation} from "react-i18next";
import {TableColumnData} from "../../../../../types/common";
import React from "react";
import {useTimeConverter} from "../../../../../hooks/hooks";
import {CounselorCouponVoucherListVO, CounselorVoucherStatus} from "../../../../../types/counselor";
import {RedActionButton} from "../../../../../components/Buttons";
import {UserCounselorStatusConverter} from "../counselor_voucher/UserCounselorStatusConverter";

export function useCounselorVoucherInfoColumns() {
    const { t } = useTranslation("user");
    const { timeConverter } = useTimeConverter();

    const handleRevokeVoucher = () =>{
        alert('회수 기능 개발중입니다.')
    }

    const columns: TableColumnData<CounselorCouponVoucherListVO>[] = [
        {
            accessor: "createAt",
            header: t("detail.counselorVoucher.infoListPanel.createAt"),
            sortable: true,
            getNode: (a) => <span>{timeConverter.convertToLocalTime(a.createAt)}</span>,
        },
        {
            accessor: "availableUntil",
            header: t("detail.counselorVoucher.infoListPanel.availableUntil"),
            sortable: true,
            getNode: (a) => <span>{timeConverter.convertToLocalTime(a.availableUntil)}</span>,
        },
        {
            accessor: "issuerOrganName",
            header: t("detail.counselorVoucher.infoListPanel.issuer"),
            sortable: false,
            getNode: (a) => <span>{a.issuerOrganName?.name}</span>,
        },
        {
            accessor: "unitVoucherStatus",
            header: t("detail.counselorVoucher.infoListPanel.status"),
            sortable: false,
            getNode: (a) =>(
                <>
                    <UserCounselorStatusConverter status={a.unitVoucherStatus}/>
                </>
            )
            // <span>
            //     {a.status}
            // </span>,
        },
        {
            accessor: "collectReason",
            header: t("detail.counselorVoucher.infoListPanel.reason"),
            sortable: false,
            getNode: (a) => <span>{a.collectReason}</span>,
        },
        {
            accessor: "idx",
            header: "",
            sortable: false,
            getNode: (a) => {
                // todo 회수 할수있는 조건으로 수정해야함
                if (a.unitVoucherStatus === CounselorVoucherStatus.USABLE) {
                    return (
                        <RedActionButton onClick={handleRevokeVoucher}>
                            {t("detail.counselorVoucher.infoListPanel.revoke")}
                        </RedActionButton>
                    );
                } else {
                    return <></>;
                }
            },
            width: 65,
        },
    ];

    return{
        columns
    }
}