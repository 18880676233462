import style from "../../memberUserCoupons.module.scss";
import React, {useEffect, useState} from "react";
import {Panel} from "../../../../../layout/content/detail/Panel";
import {Table} from "../../../../../layout/content/list/table/Table";
import {PaginationWrapper} from "../../../../../layout/content/list/PaginationWrapper";
import {Pagination} from "../../../../../layout/content/list/Pagination";
import {useTranslation} from "react-i18next";
import {useCounselorVoucherInfoColumns} from "../hooks/useCounselorVoucherInfoColumns";
import {useCounselorVoucherGetter} from "../hooks/useCounselorVoucherGetter";

interface Props {
    userIdx: number;
    name: string;
}

export function CounselorVoucherInfoList({userIdx, name}: Props) {
    const {t} = useTranslation("user");
    const [isAvailable, setIsAvailable] = useState<boolean>(false);

    const {getCounselorVoucherHistory, counselorVoucherHistory} = useCounselorVoucherGetter();
    const {columns} = useCounselorVoucherInfoColumns();
    useEffect(() => {
        getCounselorVoucherHistory({userIdx:userIdx})();
        //eslint-disable-next-line
    }, [userIdx]);


    return (
        <Panel
            title={t("detail.counselorVoucher.infoListPanel.title")}
            additionalButtons={
                <label className={style.headerCheckboxLabel}>
                    <input type={"checkbox"} readOnly={true} checked={isAvailable}
                           onClick={() => setIsAvailable(!isAvailable)}/>
                    {t("detail.organVoucherInfo.list.viewOnlyAvailableItems")}
                </label>
            }
        >
            <>
                {counselorVoucherHistory ? (
                    <>
                        <Table
                            listData={counselorVoucherHistory}
                            columns={columns}
                            getList={() => getCounselorVoucherHistory({userIdx:userIdx})}
                            noDataText={t("detail.organVoucherInfo.list.noData")}
                        />
                        {counselorVoucherHistory.content.length === 0 ? (
                            <></>
                        ) : (
                            <PaginationWrapper>
                                <Pagination
                                    page={counselorVoucherHistory}
                                    goSpecificPage={(page: number) =>
                                        getCounselorVoucherHistory({page, userIdx:userIdx})()}
                                />
                            </PaginationWrapper>
                        )}
                    </>
                ) : (
                    <></>
                )}
            </>
        </Panel>
    );
}
