import { SearchCondition } from "./page";
import { Gender, ListObject } from "./common";

enum UserQueryType {
  NAME = "NAME",
  ID = "ID",
  PHONE_NUMBER = "PHONE_NUMBER",
  REGION = "REGION",
}

interface UserSearchCondition extends SearchCondition {
  queryType?: UserQueryType;
  q?: string;
  ageFrom?: number;
  ageTo?: number;
  createdAtFrom?: Date;
  createdAtTo?: Date;
  country?: string;
  nationality?: string;
  totalTestCntFrom?: number;
  totalTestCntTo?: number;
  status?: UserStatus;
}

interface UserListObj extends ListObject {
  email: string;
  name: string;
  countryPhoneCode: string;
  phoneNumber: string;
  age: number;
  country: string;
  region: string;
  totalTestCnt: number;
  status: UserStatus;
  hasMemo: boolean;
  createAt: string;
  blockAt: string;
  reasonForBlocking: string;
  leaveAt: string;
  nationality: string;
}

interface Address {
  address1: string;
  address2?: string;
  address3?: string;
  city: string;
  state?: string;
  country: string;
  postalCode?: string;
}

export const toAddressLine1 = (address: Address): string =>
  `${address.postalCode ? `[${address.postalCode}]` : ""} ${address.state ?? ""} ${address.city ?? "-"} ${
    address.address1 ?? ""
  }`;

interface UserProfile {
  idx: number;
  userIdx: number;
  currentTestType: string;
  name: string;
  isDeleted: boolean;
  isOwnerProfile: boolean;
  address: Address;
  birthday: string;
  createAt: string;
  userCode: string;
  gender: Gender;
  testCnt: number;
  testHistoryCheck: boolean;
  voucherCheck: boolean;
  isPassedPretest: boolean;
  needPeriodCheck: boolean;
}

enum UserStatus {
  DEFAULT = "DEFAULT",
  DORMANT = "DORMANT",
  BLOCKED = "BLOCKED",
  LEAVE = "LEAVE",
}

interface OrganListByProfile {
  idx: number;
  organName: string;
  signInCode: string;
  organManagerIdx: number;
}

interface VoucherFromOrgan {
  productVoucherIdx: number;
  organName: string;
  organIdx: number;
}

interface MarketingAgreementDTO {
  isCollectingPrivateInfo: boolean;
  isReceivingViaEmail: boolean;
  isReceivingViaSMS: boolean;
}

interface MarketingAgreementVO {
  isCollectingPrivateInfo: boolean;
  isReceivingViaEmail: boolean;
  isReceivingViaSMS: boolean;
}

interface UserDTO {
  idx: number;
  email: string;
  name: string;
  phoneNumber: string;
  codePhone: string;
  status: UserStatus;
  profile: UserProfile;
  createAt: string;
  lastSignInIp: string;
  lastSignInAt: string;
  age: number;
  reasonForBlocking: string;
  reasonForLeave: string;
  blockAt: string;
  leaveAt: string;
  organList: OrganListByProfile[];
  voucherCountFromOrganList: VoucherFromOrgan[];
  refundBankName: string;
  refundAccountNumber: string;
  refundAccountName: string;
  marketingAgreement: MarketingAgreementDTO;
}

interface UserVO {
  idx: number;
  email: string;
  name: string;
  phoneNumber: string;
  codePhone: string;
  status: UserStatus;
  profile: UserProfile;
  createAt: string;
  lastSignInIp: string;
  lastSignInAt: string;
  age: number;
  reasonForBlocking: string;
  reasonForLeave: string;
  blockAt: string;
  leaveAt: string;
  organList: OrganListByProfile[];
  voucherCountFromOrganList: VoucherFromOrgan[];
  refundBankName: string;
  refundAccountNumber: string;
  refundAccountName: string;
  marketingAgreement: MarketingAgreementVO;
  nationality: string;
}

enum MemberUserDetailTabType {
  SELF_INFO = "SELF_INFO",
  PAYMENT_HISTORY = "PAYMENT_HISTORY",
  COUPON = "COUPON",
  TESTERS = "TESTERS",
}

interface UserPutParam {
  name: string;
  codePhone: string;
  phoneNumber: string;
  gender?: Gender;
  birthday?: string;
  address: Address;
  refundBankName?: string;
  refundAccountNumber?: string;
  refundAccountName?: string;
}

interface NormalUser extends ListObject{
  createAt: string;
  name: string;
  userEmail: string;
  age: number;
  nationality: string;
}

export type {
  UserSearchCondition,
  UserListObj,
  UserDTO,
  UserVO,
  UserProfile,
  Address,
  VoucherFromOrgan,
  OrganListByProfile,
  UserPutParam,
  MarketingAgreementDTO,
  MarketingAgreementVO, 
  NormalUser,
};
export { UserQueryType, UserStatus, MemberUserDetailTabType };
