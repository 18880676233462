import {useCallback} from "react";
import {AdminStatus} from "../../../../types/admin";

export function useCounselorStatus() {
    const isBlocked = useCallback((status: AdminStatus) => status === AdminStatus.BLOCKED, []);

    const isLeave = useCallback((status: AdminStatus) => status === AdminStatus.LEAVE, []);

    return{
        isBlocked, isLeave
    }
}