import style from "../../style/test_result/testResultReport.module.scss";
import React from "react";

export function KpassPrintAssistant() {
    return(
        <>
            <thead className={style.printHeader}>
            <tr>
                <td>
                    <div className={style.printHeaderSpace}></div>
                </td>
            </tr>
            </thead>
            <tfoot className={style.printFooterSpace}>
            <tr>
                <td>
                    <div className={style.printFooter}></div>
                </td>
            </tr>
            </tfoot>
        </>
    )
}