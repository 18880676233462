import {useAppDispatch} from "../../../../hooks/hooks";
import {useCallback, useState} from "react";
import {getCounselingPreQuestionAsync} from "../../../../store/counselorSlice";
import {CounselingHistoryDetailDTO} from "../../../../types/counselor";

export function useHistoryPreQuestionGetter() {

    const dispatch = useAppDispatch();
    const [counselorHistory, setCounselorHistory] = useState<CounselingHistoryDetailDTO>()

    // 상담소속 상세 조회
    const getCounselorHistory = useCallback(
        async (param: number) => {
            try {
                const result: CounselingHistoryDetailDTO = await dispatch(getCounselingPreQuestionAsync(param)).unwrap();
                setCounselorHistory(result);
            } catch (e) {
                console.error(e);
                alert('일시적인 문제가 발생했습니다. \n다시 시도해주세요.');
            }
        },
        //eslint-disable-next-line
        [dispatch]
    );

    return{
        getCounselorHistory, counselorHistory
    }
}