import style from "../style/test_result/testResult.module.scss";
import {TestHeaderWithInfo} from "./common/TestHeaderWithInfo";
import React, {useCallback} from "react";
import {KpassTestResultLowLevelScale} from "./kpass/KpassTestResultLowLevelScale";
import {KpassTestResultTotalScale} from "./kpass/KpassTestResultTotalScale";
import {KpassTestResultAverage} from "./kpass/KpassTestResultAverage";
import {KpassTestResultPersonal} from "./kpass/KpassTestResultPersonal";
import {KpassTestResultProfile} from "./kpass/KpassTestResultProfile";
import {KpassTestResultIntroduction} from "./kpass/KpassTestResultIntroduction";
import {TestResultPath, TestResultReportDTO} from "../../types/testResult";

interface Args {
    resultStep: TestResultPath
    testResult?:TestResultReportDTO
}

export function KpassTestResult({resultStep, testResult}: Args) {

    const CurrentResultStepDetails = useCallback(() => {
        switch (resultStep) {
            case TestResultPath.TOTAL_SCALE:
                return <KpassTestResultTotalScale testResult={testResult} />
            case TestResultPath.LOW_LEVEL_SCALE:
                return <KpassTestResultLowLevelScale testResult={testResult} />
            case TestResultPath.AVERAGE_STRENGTH_AND_WEAKNESS:
                return <KpassTestResultAverage testResult={testResult} />
            case TestResultPath.PERSONAL_STRENGTH_AND_WEAKNESS:
                return <KpassTestResultPersonal testResult={testResult} />
            case TestResultPath.PROFILE_ANALYSIS:
                return <KpassTestResultProfile testResult={testResult} />
            case TestResultPath.INTRODUCTION_ABOUT_KPASS:
                return <KpassTestResultIntroduction />
            default:
                return (
                    <>
                    </>
                );
        }
    //eslint-disable-next-line
    }, [resultStep]);

    return (
        <>
            <div className={style.page}>
                {/*  헤더 부분
                ex)2023-12-16 K-PASS 만 8세 | 7개월
                */}
                {testResult && (
                    <TestHeaderWithInfo testResult={testResult}/>
                )}
                <div className={style.content}>
                    <section className={style.section}>
                        <article className={style.summaryWrapper}>
                            <CurrentResultStepDetails/>
                        </article>
                    </section>
                </div>

                <hr className={style.contentFooter}></hr>
            </div>
        </>
    )
}