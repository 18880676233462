import {useAppDispatch} from "../../../../hooks/hooks";
import {useCallback} from "react";
import {finishCounselingAsync} from "../../../../store/counselorSlice";
import {ErrorType} from "../../../../types/error";
import {getErrorType} from "../../../../util/sliceUtil";

export function useHistoryFunctions() {
    /**
     * 상담내역 내 별도 API 처리 컴포넌트
     */

    const dispatch = useAppDispatch();

    // 상담 종료 API
    const finishCounseling = useCallback(
        async (param: number) => {
            try {
                await dispatch(finishCounselingAsync(param)).unwrap();
                alert('상담이 종료되었습니다.')
            } catch (e) {
                const errors: ErrorType = getErrorType(e as Error);
                console.error(errors)
                alert(`일시적인 문제가 발생했습니다.\n다시 시도해주세요.`);
                return false;
            }
            //eslint-disable-next-line
        }, [dispatch]);

    return{
        finishCounseling,
    }
}