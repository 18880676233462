import {DetailSubMenus, DetailTab} from "../../../layout/content/detail/DetailSubMenus";
import {PanelWrapper} from "../../../layout/content/detail/PanelWrapper";
import {useCallback, useEffect, useState} from "react";
import {CounselorHistoryMenus} from "../../../types/counselor";
import {useTranslation} from "react-i18next";
import {HistoryInfoDetail} from "./HistoryInfoDetail";

export function CounselorHistoryDetail() {
    const [selectedTab, ] = useState<CounselorHistoryMenus>(CounselorHistoryMenus.CONSULTANT_INFO);
    const {t} = useTranslation("counselorInfo");

    // 해당 탭이 닫힐때 부모 탭 새로고침
    useEffect(() => {
        const handleBeforeUnload = () => {
            if (window.opener) {
                window.opener.location.reload();
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    const onClickTabMenu = (type: CounselorHistoryMenus) => {
        alert('기능 개발중입니다.')
        // navigate(`/detail/member/user/${id}?${queryString.stringify({ type })}`);
    };

    const getContentDetail = useCallback((
        tab: CounselorHistoryMenus
    ) =>{
        switch (tab) {
            case CounselorHistoryMenus.CONSULTANT_INFO:
                return <HistoryInfoDetail />
        }
    },[])

    const tabMenus: DetailTab<CounselorHistoryMenus>[] = [
        {
            tabValue: CounselorHistoryMenus.CONSULTANT_INFO,
            tabName: t('history.title'),
        },
    ];

    return(
        <>
            <DetailSubMenus
                tabs={tabMenus}
                onClickTab={onClickTabMenu}
                selectedTab={selectedTab}
            />
            <PanelWrapper>{getContentDetail(selectedTab)}</PanelWrapper>
        </>
    )
}