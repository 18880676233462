import React from "react";
import {callAsync} from "../../../../util/sliceUtil";
import {adminSignUpConfirmAsync} from "../../../../store/counselorSlice";
import {ActiveActionButton} from "../../../../components/Buttons";
import {useAppDispatch} from "../../../../hooks/hooks";
import {useTranslation} from "react-i18next";
import {ConsultantDTO} from "../../../../types/counselor";
import {AdminStatus} from "../../../../types/admin";
import {AdminRoleCategory} from "../../../../types/adminRole";

export const AuthorizeAction = ({ dto, role }: { dto: ConsultantDTO; role: string | null }) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation("admin");

    if (dto.status !== AdminStatus.BEFORE_SELF_AUTHENTICATION &&
        dto.status !== AdminStatus.BEFORE_ADMIN_AUTHENTICATION) return null;
    if (role !== AdminRoleCategory.ADMIN_ROOT) return null;

    const handleAuthorize = async () => {
        if (!role) {
            console.warn("권한이 없습니다. 다시 시도해주세요.");
            return;
        }

        if (window.confirm("해당 상담사를 인증처리 하시겠습니까?")) {
            await callAsync(
                dispatch(adminSignUpConfirmAsync(String(dto.idx))).unwrap(),
                () => {
                    alert("인증이 완료되었습니다.")
                    window.location.reload()
                },
                () => alert('일시적인 문제가 발생했습니다.\n다시 시도해주세요.')
            );
        }
    };

    return (
        <ActiveActionButton onClick={handleAuthorize}>
            {t("buttons.authorize.label")}
        </ActiveActionButton>
    );
};