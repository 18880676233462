import style from "../../style/test_result/commonStyle.module.scss";
import {ResultType} from "../../../types/testResult";
import {useLanguage} from "../../../hooks/hooks";

interface Args {
    resultType: ResultType
    brainType?: string
}

export function FixedHeaderWithLine({resultType, brainType}: Args) {
    /**
     * 하단에 줄이 있는 헤더
     */

    const {isKorean} = useLanguage()

    // 검사결과지, 학습진로 가이드 별 html 분리
    const LeftDescription = (): JSX.Element => {
        switch (resultType) {
            case ResultType.TEST_RESULT:
                return <span className={style.url}>kpass.feel-good.io</span>
            case ResultType.GUIDE:
                return (
                    <span className={style.textWrapper}>
                        {brainType && (
                            <span className={style.url}>{brainType}</span>
                        )}&nbsp;{isKorean ? '학습·진로 가이드' : 'education map'}
                    </span>
                )
        }
    }

    return (
        <>
            <div className={style.fixedHeaderWithLine}>
                <LeftDescription/>
                <img className={style.secondaryLogo}
                     src="https://images.feel-good.io/logo/kpass_logo_secondary.svg"
                     alt="K-PASS secondary logo"/>
                <div className={style.headerAfter}></div>
            </div>
        </>
    )
}