import {Panel} from "../../../layout/content/detail/Panel";
import {DetailActionButton} from "../../../components/Buttons";
import confirmIcon from "../../../assets/images/button_icons/confirm_icon.svg";
import editIcon from "../../../assets/images/button_icons/person_icon.svg";
import deleteIcon from "../../../assets/images/button_icons/delete_icon.svg";
import temporaryPassword from "../../../assets/images/button_icons/password_icon.svg";
import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {useCounselorInfoDetailUtil} from "../info/hooks/useCounselorInfoDetailUtil";
import {DescriptionList} from "../../../layout/content/detail/DescriptionList";
import {AdminMenuCode} from "../../../types/adminRole";
import style from "../../member/user/memberUserDetail.module.scss";
import {RoleChecker} from "../../role/RoleChecker";
import {BlockMemberParam} from "../../../types/newWindow";
import {ActionUserType} from "../../../types/dataCommon";
import {useAppDispatch, useNewWindow} from "../../../hooks/hooks";
import {useCounselorDetailGetter} from "./hooks/useCounselorDetailGetter";
import {useNavigate, useParams} from "react-router-dom";
import {CounselorInfoPutDTO} from "../../../types/counselor";
import {useCounselorDetailFunctions} from "./hooks/useCounselorDetailFunctions";
import {useCounselorStatus} from "./hooks/useCounselorStatus";
import checkIcon from "../../../assets/images/button_icons/check_icon.svg";
import {isAdmin} from "../../../util/etcUtil";
import {callAsync} from "../../../util/sliceUtil";
import {findPwdAsync} from "../../../store/accountSlice";
import {AdminType} from "../../../types/account";

export function CounselorInfoDetail() {

    // 상담사 정보 파라미터 취득
    const {id} = useParams<'id'>() as { id: string };

    const {t} = useTranslation("counselorInfo");
    const formRef = useRef<HTMLFormElement>(null);
    const [counselorInfoPutParam, setCounselorInfoPutParam] = useState<CounselorInfoPutDTO | null>(null);
    const isCounselorEditable = id && counselorInfoPutParam
    const [isEditMode, setIsEditMode] = useState(false);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [adminType] = useState(AdminType.CONSULTANT);

    const {isBlocked, isLeave} = useCounselorStatus();
    const {onClickUpdateUserButton, onClickChangePassword, onClickUnblockBtn} = useCounselorDetailFunctions();
    const {getConsultantDetail, consultantDetail} = useCounselorDetailGetter(id);
    const {getCounselorInfo, getCounselorGroupAffiliated, getCounselorGroupUnAffiliated, resetData}
        = useCounselorInfoDetailUtil({counselorInfoPutParam: setCounselorInfoPutParam});

    const {openBlockMemberPopup} = useNewWindow();
    const isCounselorAffiliated = consultantDetail.affiliationIdx !== null;

    useEffect(() => {
        getConsultantDetail().then()
    }, [id, getConsultantDetail]);


    const onClickTemporaryPassword = async () => {
        if (formRef.current?.reportValidity()) {
            const email = consultantDetail.email;
            const codePhone = consultantDetail.codePhone;
            const phoneNumber = consultantDetail.phoneNumber;

            callAsync(
                dispatch(
                    findPwdAsync({
                        codePhone: codePhone,
                        email: email,
                        adminType,
                        phoneNumber: phoneNumber,
                    })
                ).unwrap(),
                () => {
                    navigate(
                        `/find-account/pw-success?` +
                        `email=${encodeURIComponent(email)}&phoneNumber=${phoneNumber}&adminType=${adminType}&` +
                        `phoneCountryCode=${encodeURIComponent(codePhone)}`
                    );
                },
                (error) => {
                    console.log(error)
                    alert(error)
                }
            ).then();
        }
    };

    // 필굿관리자 회원 차단
    const onClickBlockBtn = () => {
        if (!id) return;
        const idx = Number(id);

        const param: BlockMemberParam = {
            memberIdxArr: [idx],
            userType: ActionUserType.CONSULTANT,
        };
        openBlockMemberPopup(param, () => getConsultantDetail());
    };



    // 정보수정, 비밀번호 변경 등 버튼 상태 처리
    const buttonStatus = () => {
        const renderBlockedButton = () => (
            <DetailActionButton onClick={() => onClickUnblockBtn(id)}>
                <img src={checkIcon} alt={t("unblock.voucherIcon")}/>
                {t("button.unblock")}
            </DetailActionButton>
        );

        const renderEditButtons = () => (
            <>
                <DetailActionButton
                    onClick={() => {
                        setIsEditMode(false);
                        resetData();
                    }}
                >
                    {t("button.cancel")}
                </DetailActionButton>
                <DetailActionButton
                    onClick={() => isCounselorEditable && onClickUpdateUserButton(id, counselorInfoPutParam)}
                >
                    <img src={confirmIcon} alt={'confirm'}/>
                    {t("button.save")}
                </DetailActionButton>
            </>
        );

        const renderDefaultButtons = () => (
            <>
                <DetailActionButton onClick={() => setIsEditMode(true)}>
                    <img src={editIcon} alt={'edit'}/>
                    {t('button.edit')}
                </DetailActionButton>
                {isAdmin ? (
                    <DetailActionButton onClick={onClickTemporaryPassword}>
                        <img src={temporaryPassword} alt={'temporaryPassword'}/>
                        {t('button.temporaryPassword')}
                    </DetailActionButton>
                ) : (
                    <DetailActionButton onClick={onClickChangePassword}>
                        <img src={deleteIcon} alt={'passwordChange'}/>
                        {t("button.passwordChange")}
                    </DetailActionButton>
                )}
            </>
        );

        if (isBlocked(consultantDetail.status)) return renderBlockedButton();
        if (isLeave(consultantDetail.status)) return <></>;
        return isEditMode ? renderEditButtons() : renderDefaultButtons();
    };

    return (
        <>
            {/*  상담사정보 (/detail/counselor/1)  */}
            <Panel title={t('menus.counselorInfo')}
                   additionalButtons={
                       buttonStatus()
                   }
            >
                <form ref={formRef} onSubmit={(e) => e.preventDefault()}>
                    {consultantDetail && (
                        <DescriptionList
                            data={getCounselorInfo(consultantDetail)}
                            isEditMode={isEditMode}
                            labelWidth={132}
                        />
                    )}
                </form>
            </Panel>

            {/*  상담사정보 (/detail/counselor/1)  */}
            <Panel title={t('menus.groupInfo')}>
                <form ref={formRef} onSubmit={(e) => e.preventDefault()}>
                    <DescriptionList
                        data={isCounselorAffiliated
                            ? getCounselorGroupAffiliated(consultantDetail)
                            : getCounselorGroupUnAffiliated(consultantDetail)}
                        isEditMode={isEditMode}
                        labelWidth={132}
                    />
                </form>
            </Panel>

            {/*  회원 차단 버튼  */}
            {!isBlocked(consultantDetail.status) && (
                <div className={style.buttonArea}>
                    <RoleChecker menuCode={AdminMenuCode.USER_DETAIL_BLOCK}>
                        <button className={style.blockBtn} onClick={onClickBlockBtn}>
                            <img src={deleteIcon} alt={'block'}/>
                            {t("button.counselorBlock")}
                        </button>
                    </RoleChecker>
                </div>
            )}
        </>
    )
}