import {
    ConsultantDetailDTO, ConsultantInfoDTO,
    CounselorGroupInfoDTO,
    CounselorPosition
} from "../../../../types/counselor";
import {AdminStatus} from "../../../../types/admin";

export function useCounselorDefaultValues() {
    /**
     * 인터페이스 기본값 정의 함수
     */
    const COUNSELOR_GROUP_DETAIL = (): CounselorGroupInfoDTO => (
        {
            name: '',
            address: {address1: '', country: '', city: ''},
            codePhone: '',
            phoneNumber: '',
            briefHistory: '',
            bankName: '',
            accountNumber: '',
            accountName: '',
            affiliationDistributionAmount: 0,
            consultantDistributionAmount: 0,
            isVatInclude: false,
            isTaxDeduction: false,
            affiliationManagerIdx: 0
        }
    );

    const COUNSELOR_DETAIL = (): ConsultantDetailDTO =>(
        {
            idx: 0,
            email: '',
            name: '',
            status: AdminStatus.DEFAULT,
            codePhone: '',
            phoneNumber: '',
            roleIdx: 0,
            roleName: '',
            consultantRank: CounselorPosition.CONSULTANT_MANAGER,
            consultantInfoDTO: consultantInfoDTO,
            createAt: '',
            blockAt: '',
            reasonForBlocking: '',
            nationality: '',
            profileImageURL: '',
            affiliationIdx: 0,
            affiliationName: '',
            affiliationPhoneNumber: '',
            affiliationAddress:{city:'', country:'', address1: ''}
        }
    )

    const consultantInfoDTO:ConsultantInfoDTO ={
        briefHistory: '',
        bankName: '',
        accountNumber:'',
        accountName: '',
        counselingAffiliationIdx: 0,
        counselingAffiliationName: '',
        distributionAmount: 0,
        isVatInclude: false,
        isTaxDeduction: false,
    }

    return{
        COUNSELOR_GROUP_DETAIL, COUNSELOR_DETAIL
    }
}