import style from "../../style/test_result/guide/learningStrategy.module.scss";
import {BrainType} from "../../../types/testResult";
import {Trans, useTranslation} from "react-i18next";
import React from "react";

interface Args {
    brainType: BrainType
}

/* eslint-disable max-len */
export function RightLearningStrategyGuide({brainType}: Args) {
    /**
     * 두뇌유형별 학습법 중 특징
     */

    const {t} = useTranslation("guideLearningStrategy");
    const brainValue = brainType === BrainType.BALANCED ? `${t('summary.right.title')} - ` : '';
    const subject = 'strategy.right.recommendation';

    return (
        <>
            {/*  우뇌우세형 특장  */}
            {brainValue && (
                <article className={style.article}>
                    <h2 className={style.title}>{t("strategy.right.characteristics.title")}</h2>
                    <p className={style.description}>
                        <Trans t={t} i18nKey="strategy.right.characteristics.description"
                               values={{blank: '\u00A0'}} components={{br: <br/>,}}/>
                    </p>
                </article>
            )}

            {/*  우뇌우세형 - 효율적인 학습법  */}
            <article className={style.article}>
                <h2 className={style.title}>
                    <Trans t={t} i18nKey="strategy.right.learningStrategy.title"
                           values={{name: brainValue, blank: '\u00A0'}} components={{br: <br/>,}}/>
                </h2>
                <img src="https://images.feel-good.io/guide/right/right_01.png" alt={'1'}/>

                <p className={style.description}>
                    <Trans t={t} i18nKey="strategy.right.learningStrategy.description"
                           values={{name: brainValue, blank: '\u00A0'}} components={{br: <br/>,}}/>
                </p>

                <article className={style.subArticle}>
                    <section>
                        <h3 className={style.title}>
                            <span className={style.number}>1</span>
                            <span>{t('strategy.right.learningStrategy.subTitle1')}</span>
                        </h3>
                        <p className={style.description}>
                            <Trans t={t} i18nKey="strategy.right.learningStrategy.subDesc1"
                                   values={{blank: '\u00A0'}}/>
                        </p>
                    </section>
                    <section>
                        <h3 className={style.title}>
                            <span className={style.number}>2</span>
                            <span>{t('strategy.right.learningStrategy.subTitle2')}</span>
                        </h3>
                        <p className={style.description}>
                            <Trans t={t} i18nKey="strategy.right.learningStrategy.subDesc2"
                                   values={{blank: '\u00A0'}}/>
                        </p>
                    </section>
                    <section>
                        <h3 className={style.title}>
                            <span className={style.number}>3</span>
                            <span>{t('strategy.right.learningStrategy.subTitle3')}</span>
                        </h3>
                        <p className={style.description}>
                            <Trans t={t} i18nKey="strategy.right.learningStrategy.subDesc3"
                                   values={{blank: '\u00A0'}}/>
                        </p>
                    </section>
                    <section>
                        <h3 className={style.title}>
                            <span className={style.number}>4</span>
                            <span>{t('strategy.right.learningStrategy.subTitle4')}</span>
                        </h3>
                        <p className={style.description}>
                            <Trans t={t} i18nKey="strategy.right.learningStrategy.subDesc4"
                                   values={{blank: '\u00A0'}}/>
                        </p>
                    </section>
                </article>
            </article>

            {/*  우뇌우세형 - 부족한 영역 보충 학습법  */}
            <article className={style.article}>
                <h2 className={style.title}>
                    <Trans t={t} i18nKey="strategy.right.weakness.title"
                           values={{name: brainValue, blank: '\u00A0'}} components={{br: <br/>,}}/>
                </h2>
                <img src="https://images.feel-good.io/guide/right/right_02.png" alt={'2'}/>

                <article className={style.subArticle}>
                    <section>
                        <h3 className={style.title}>
                            <span className={style.number}>1</span>
                            <span>{t('strategy.right.weakness.subTitle1')}</span>
                        </h3>
                        <p className={style.description}>
                            <Trans t={t} i18nKey="strategy.right.weakness.subDesc1" values={{blank: '\u00A0'}}/>
                        </p>
                    </section>

                    <section>
                        <h3 className={style.title}>
                            <span className={style.number}>2</span>
                            <span>{t('strategy.right.weakness.subTitle2')}</span>
                        </h3>
                        <p className={style.description}>
                            <Trans t={t} i18nKey="strategy.right.weakness.subDesc2" values={{blank: '\u00A0'}}/>
                        </p>
                    </section>

                    <section>
                        <h3 className={style.title}>
                            <span className={style.number}>3</span>
                            <span>{t('strategy.right.weakness.subTitle3')}</span>
                        </h3>
                        <p className={style.description}>
                            <Trans t={t} i18nKey="strategy.right.weakness.subDesc3" values={{blank: '\u00A0'}}/>
                        </p>
                    </section>

                    <section>
                        <h3 className={style.title}>
                            <span className={style.number}>4</span>
                            <span>{t('strategy.right.weakness.subTitle4')}</span>
                        </h3>
                        <p className={style.description}>
                            <Trans t={t} i18nKey="strategy.right.weakness.subDesc4" values={{blank: '\u00A0'}}/>
                        </p>
                    </section>

                    <section>
                        <h3 className={style.title}>
                            <span className={style.number}>5</span>
                            <span>{t('strategy.right.weakness.subTitle5')}</span>
                        </h3>
                        <p className={style.description}>
                            <Trans t={t} i18nKey="strategy.right.weakness.subDesc5" values={{blank: '\u00A0'}}/>
                        </p>
                    </section>
                </article>
            </article>

            {/*  우뇌우세형 - 과목별 추천 학습법  */}
            <article className={style.article}>
                <h2 className={style.title}>
                    <Trans t={t} i18nKey="strategy.right.recommendation.title"
                           values={{name: brainValue, blank: '\u00A0'}} components={{br: <br/>,}}/>
                </h2>
                <img src="https://images.feel-good.io/guide/right/right_03.png" alt={'3'}/>

                <p className={style.description}>
                    <Trans t={t} i18nKey="strategy.right.recommendation.description"
                           values={{blank: '\u00A0'}}
                           components={{
                               br: <br/>,
                               bold: <span className={style.bold}/>,
                               underlineText: <span className={style.underlineText}/>,
                           }}/>
                </p>

                <article className={style.subArticle}>
                    <section>
                        <h3 className={style.title}>
                            <span className={style.number}>1</span>
                            <span>{t(subject + '.subject1.title')}</span>
                        </h3>


                        <div className={style.description}>

                            <dl>{t(subject + '.subject1.group1')}</dl>

                            <dt>{t(subject + '.subject1.group1Title1')}</dt>
                            <dd>
                                <Trans t={t} i18nKey={`${subject}.subject1.group1Desc1`}
                                       values={{blank: '\u00A0'}} components={{br: <br/>}}/>
                            </dd>
                            <dt>{t(subject + '.subject1.group1Title2')}</dt>
                            <dd>
                                <Trans t={t} i18nKey={`${subject}.subject1.group1Desc2`}
                                       values={{blank: '\u00A0'}} components={{br: <br/>}}/>
                            </dd>
                            <dt>{t(subject + '.subject1.group1Title3')}</dt>
                            <dd>
                                <Trans t={t} i18nKey={`${subject}.subject1.group1Desc3`}
                                       values={{blank: '\u00A0'}} components={{br: <br/>}}/>
                            </dd>
                            <dt>{t(subject + '.subject1.group1Title4')}</dt>
                            <dd>
                                <Trans t={t} i18nKey={`${subject}.subject1.group1Desc4`}
                                       values={{blank: '\u00A0'}} components={{br: <br/>}}/>
                            </dd>
                            <dt>{t(subject + '.subject1.group1Title5')}</dt>
                            <dd>
                                <Trans t={t} i18nKey={`${subject}.subject1.group1Desc5`}
                                       values={{blank: '\u00A0'}} components={{br: <br/>}}/>
                            </dd>
                        </div>
                    </section>
                </article>

                <article className={style.subArticle}>
                    <section>
                        <h3 className={style.title}>
                            <span className={style.number}>2</span>
                            <span>{t(subject + '.subject2.title')}</span>
                        </h3>
                        <ul className={style.description}>
                            <li>
                                <h4 className={style.title}>{t(subject + '.subject2.group1Title1')}</h4>
                                <Trans t={t} i18nKey={`${subject}.subject2.group1Desc1`}
                                       values={{blank: '\u00A0'}} components={{br: <br/>}}/>
                            </li>

                            <li>
                                <h4 className={style.title}>{t(subject + '.subject2.group1Title2')}</h4>
                                <Trans t={t} i18nKey={`${subject}.subject2.group1Desc2`}
                                       values={{blank: '\u00A0'}} components={{br: <br/>}}/>
                            </li>

                            <li>
                                <h4 className={style.title}>{t(subject + '.subject2.group1Title3')}</h4>
                                <Trans t={t} i18nKey={`${subject}.subject2.group1Desc3`}
                                       values={{blank: '\u00A0'}} components={{br: <br/>}}/>
                            </li>

                            <li>
                                <h4 className={style.title}>{t(subject + '.subject2.group1Title4')}</h4>
                                <Trans t={t} i18nKey={`${subject}.subject2.group1Desc4`}
                                       values={{blank: '\u00A0'}} components={{br: <br/>}}/>
                            </li>

                            <li>
                                <h4 className={style.title}>{t(subject + '.subject2.group1Title5')}</h4>
                                <Trans t={t} i18nKey={`${subject}.subject2.group1Desc5`}
                                       values={{blank: '\u00A0'}} components={{br: <br/>}}/>
                            </li>
                        </ul>
                    </section>
                </article>

                <article className={style.subArticle}>
                    <section>
                        <h3 className={style.title}>
                            <span className={style.number}>3</span>
                            <span>{t(subject + '.subject3.title')}</span>
                        </h3>
                        <ul className={style.description}>
                            <li>
                                <h4 className={style.title}>{t(subject + '.subject3.group1Title1')}</h4>
                                <Trans t={t} i18nKey={`${subject}.subject3.group1Desc1`}
                                       values={{blank: '\u00A0'}} components={{br: <br/>}}/>
                            </li>
                            <li>
                                <h4 className={style.title}>{t(subject + '.subject3.group1Title2')}</h4>
                                <Trans t={t} i18nKey={`${subject}.subject3.group1Desc2`}
                                       values={{blank: '\u00A0'}} components={{br: <br/>}}/>
                            </li>
                            <li>
                                <h4 className={style.title}>{t(subject + '.subject3.group1Title3')}</h4>
                                <Trans t={t} i18nKey={`${subject}.subject3.group1Desc3`}
                                       values={{blank: '\u00A0'}} components={{br: <br/>}}/>
                            </li>
                            <li>
                                <h4 className={style.title}>{t(subject + '.subject3.group1Title4')}</h4>
                                <Trans t={t} i18nKey={`${subject}.subject3.group1Desc4`}
                                       values={{blank: '\u00A0'}} components={{br: <br/>}}/>
                            </li>
                            <li>
                                <h4 className={style.title}>{t(subject + '.subject3.group1Title5')}</h4>
                                <Trans t={t} i18nKey={`${subject}.subject3.group1Desc5`}
                                       values={{blank: '\u00A0'}} components={{br: <br/>}}/>
                            </li>
                        </ul>
                    </section>
                </article>

                <article className={style.subArticle}>
                    <section>
                        <h3 className={style.title}>
                            <span className={style.number}>4</span>
                            <span>{t(subject + '.subject4.title')}</span>
                        </h3>
                        <ul className={style.description}>
                            <li>
                                <h4 className={style.title}>{t(subject + '.subject4.group1Title1')}</h4>
                                <Trans t={t} i18nKey={`${subject}.subject4.group1Desc1`}
                                       values={{blank: '\u00A0'}} components={{br: <br/>}}/>
                            </li>
                            <li>
                                <h4 className={style.title}>{t(subject + '.subject4.group1Title2')}</h4>
                                <Trans t={t} i18nKey={`${subject}.subject4.group1Desc2`}
                                       values={{blank: '\u00A0'}} components={{br: <br/>}}/>
                            </li>
                            <li>
                                <h4 className={style.title}>{t(subject + '.subject4.group1Title3')}</h4>
                                <Trans t={t} i18nKey={`${subject}.subject4.group1Desc3`}
                                       values={{blank: '\u00A0'}} components={{br: <br/>}}/>
                            </li>
                            <li>
                                <h4 className={style.title}>{t(subject + '.subject4.group1Title4')}</h4>
                                <Trans t={t} i18nKey={`${subject}.subject4.group1Desc4`}
                                       values={{blank: '\u00A0'}} components={{br: <br/>}}/>
                            </li>
                            <li>
                                <h4 className={style.title}>{t(subject + '.subject4.group1Title5')}</h4>
                                <Trans t={t} i18nKey={`${subject}.subject4.group1Desc5`}
                                       values={{blank: '\u00A0'}} components={{br: <br/>}}/>
                            </li>
                        </ul>
                    </section>
                </article>

                <article className={style.subArticle}>
                    <section>
                        <h3 className={style.title}>
                            <span className={style.number}>5</span>
                            <span>{t(subject + '.subject5.title')}</span>
                        </h3>
                        <ul className={style.description}>
                            <li>
                                <h4 className={style.title}>{t(subject + '.subject5.group1Title1')}</h4>
                                <Trans t={t} i18nKey={`${subject}.subject5.group1Desc1`}
                                       values={{blank: '\u00A0'}} components={{br: <br/>}}/>
                            </li>
                            <li>
                                <h4 className={style.title}>{t(subject + '.subject5.group1Title2')}</h4>
                                <Trans t={t} i18nKey={`${subject}.subject5.group1Desc2`}
                                       values={{blank: '\u00A0'}} components={{br: <br/>}}/>
                            </li>
                            <li>
                                <h4 className={style.title}>{t(subject + '.subject5.group1Title3')}</h4>
                                <Trans t={t} i18nKey={`${subject}.subject5.group1Desc3`}
                                       values={{blank: '\u00A0'}} components={{br: <br/>}}/>
                            </li>
                        </ul>
                    </section>
                </article>
            </article>

            {/*  우뇌우세형 - 두뇌 훈련을 위한 놀이법  */}
            <article className={style.article}>
                <h2 className={style.title}>
                    <Trans t={t} i18nKey="strategy.right.brainTraining.title"
                           values={{name: brainValue, blank: '\u00A0'}} components={{br: <br/>,}}/>
                </h2>
                <img src="https://images.feel-good.io/guide/right/right_04.png" alt={'4'}/>

                <p className={style.description}>
                    <Trans t={t} i18nKey="strategy.right.brainTraining.description" />
                </p>

                <article className={style.subArticle}>
                    <section>
                        <h3 className={style.title}>
                            <span className={style.number}>1</span>
                            <span>{t('strategy.right.brainTraining.subTitle1')}</span>
                        </h3>
                        <p className={style.description}>
                            <Trans t={t} i18nKey="strategy.right.brainTraining.subDesc1" values={{blank: '\u00A0'}}/>
                        </p>
                    </section>

                    <section>
                        <h3 className={style.title}>
                            <span className={style.number}>2</span>
                            <span>{t('strategy.right.brainTraining.subTitle2')}</span>
                        </h3>
                        <p className={style.description}>
                            <Trans t={t} i18nKey="strategy.right.brainTraining.subDesc2" values={{blank: '\u00A0'}}/>
                        </p>
                    </section>

                    <section>
                        <h3 className={style.title}>
                            <span className={style.number}>3</span>
                            <span>{t('strategy.right.brainTraining.subTitle3')}</span>
                        </h3>
                        <p className={style.description}>
                            <Trans t={t} i18nKey="strategy.right.brainTraining.subDesc3" values={{blank: '\u00A0'}}/>
                        </p>
                    </section>

                    <section>
                        <h3 className={style.title}>
                            <span className={style.number}>4</span>
                            <span>{t('strategy.right.brainTraining.subTitle4')}</span>
                        </h3>
                        <p className={style.description}>
                            <Trans t={t} i18nKey="strategy.right.brainTraining.subDesc4" values={{blank: '\u00A0'}}/>
                        </p>
                    </section>
                </article>

                <p className={style.description}>
                    <Trans t={t} i18nKey="strategy.right.brainTraining.subDesc5"
                           values={{blank: '\u00A0'}}
                           components={{bold:<span className={style.bold} />}}/>
                </p>
            </article>

            {/*  우뇌우세형 - 부족한 영역 보완 놀이법  */}
            <article className={style.article}>
                <h2 className={style.title}>
                    <Trans t={t} i18nKey="strategy.right.supplement.title"
                           values={{name: brainValue, blank: '\u00A0'}} components={{br: <br/>,}}/>
                </h2>
                <img src="https://images.feel-good.io/guide/right/right_05.png" alt={'5'}/>

                <article className={style.subArticle}>
                    <section>
                        <h3 className={style.title}>
                            <span className={style.number}>1</span>
                            <span>{t('strategy.right.supplement.subTitle1')}</span>
                        </h3>
                        <p className={style.description}>
                            <Trans t={t} i18nKey="strategy.right.supplement.subDesc1" values={{blank: '\u00A0'}}/>
                        </p>
                    </section>
                    <section>
                        <h3 className={style.title}>
                            <span className={style.number}>2</span>
                            <span>{t('strategy.right.supplement.subTitle2')}</span>
                        </h3>
                        <p className={style.description}>
                            <Trans t={t} i18nKey="strategy.right.supplement.subDesc2" values={{blank: '\u00A0'}}/>
                        </p>
                    </section>
                    <section>
                        <h3 className={style.title}>
                            <span className={style.number}>3</span>
                            <span>{t('strategy.right.supplement.subTitle3')}</span>
                        </h3>
                        <p className={style.description}>
                            <Trans t={t} i18nKey="strategy.right.supplement.subDesc3" values={{blank: '\u00A0'}}/>
                        </p>

                        <div className={style.description}>
                            <h4 className={style.title}>
                                {t('strategy.recommendationPuzzle')}
                            </h4>
                            <ul className={style.tagWrap}>
                                <Trans t={t} i18nKey="strategy.right.supplement.recommendationPuzzle"
                                       values={{blank: '\u00A0'}}
                                       components={{li:<li className={style.tag}/>, span:<span className={style.eng} />}}/>

                            </ul>
                        </div>
                    </section>
                    <section>
                        <h3 className={style.title}>
                            <span className={style.number}>4</span>
                            <span>{t('strategy.right.supplement.subTitle4')}</span>
                        </h3>
                        <p className={style.description}>
                            <Trans t={t} i18nKey="strategy.right.supplement.subDesc4" values={{blank: '\u00A0'}}/>
                        </p>
                    </section>
                    <section>
                        <h3 className={style.title}>
                            <span className={style.number}>5</span>
                            <span>{t('strategy.right.supplement.subTitle5')}</span>
                        </h3>
                        <p className={style.description}>
                            <Trans t={t} i18nKey="strategy.right.supplement.subDesc5" values={{blank: '\u00A0'}}/>
                        </p>

                        <div className={style.description}>
                            <h4 className={style.title}>
                                {t('strategy.recommendationGame')}
                            </h4>
                            <ul className={style.tagWrap}>
                                <Trans t={t} i18nKey="strategy.right.supplement.recommendationGame1"
                                       values={{blank: '\u00A0'}}
                                       components={{li:<li className={style.tag}/>, span:<span className={style.eng} />}}/>

                            </ul>
                        </div>
                    </section>

                    <section>
                        <h3 className={style.title}>
                            <span className={style.number}>6</span>
                            <span>{t('strategy.right.supplement.subTitle6')}</span>
                        </h3>
                        <p className={style.description}>
                            <Trans t={t} i18nKey="strategy.right.supplement.subDesc6" values={{blank: '\u00A0'}}/>
                        </p>
                        <div className={style.description}>
                            <h4 className={style.title}>
                                {t('strategy.recommendationGame')}
                            </h4>
                            <ul className={style.tagWrap}>
                                <Trans t={t} i18nKey="strategy.right.supplement.recommendationGame2"
                                       values={{blank: '\u00A0'}}
                                       components={{li:<li className={style.tag}/>, span:<span className={style.eng} />}}/>
                            </ul>
                        </div>
                    </section>
                </article>
            </article>
        </>
    )
}