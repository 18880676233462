import React from "react";
import style from "../../style/test_result/commonStyle.module.scss";

export function TestFooterWithLine() {
    /**
     * 상단에 줄이 있는 푸터
     */

    //  kpass / dcas 분기처리 필요
    return(
        <>
            <div className={style.footerWithLine}>
                <div className={style.footerContent}>
                        {/*<span className={style.copyright}>Copyright 2023. FEELGOOD. All rights reserved.</span>*/}
                    <span className={style.copyright}>Copyright 2023. FEELGOOD. All rights reserved.</span>
                    <img className={style.logo} src="https://images.feel-good.io/logo/feelgood_logo_white_background.svg"
                         alt="FeelGood Logo"/>
                </div>
            </div>
        </>
    )
}