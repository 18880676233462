import style from "../style/test_result/testResultReport.module.scss";
import {TestResultHeader} from "./common/TestResultHeader";
import {TestResultGuideBanner} from "./common/TestResultGuideBanner";
import {TestResultInfo} from "./common/TestResultInfo";
import {TestTopButton} from "./common/TestTopButton";
import React, {useEffect} from "react";
import {TestResultCover} from "./common/TestResultCover";
import {KpassTestResult} from "./KpassTestResult";
import {ResultType, TestResultPath} from "../../types/testResult";
import {useKpassTestResultGetter} from "./kpass/hooks/useKpassTestResultGetter";
import {useParams} from "react-router-dom";
import {useLanguage} from "../../hooks/hooks";
import {Language} from "../../types/language";
import {KpassPrintAssistant} from "./kpass/KpassPrintAssistant";

export function TestResultReport() {
    /**
     * 검사 결과지 메인
     */
    const {getKpassTestResult, kpassTestResult} = useKpassTestResultGetter();
    const {id} = useParams<"id">();
    const {token} = useParams<"token">();
    const testIdx = Number(id);
    const {setLang} = useLanguage()

    // 브라우저 언어 변경 시 언어 수정
    useEffect(() => {
        const browserLanguage = navigator.language.slice(0, 2);
        const language = browserLanguage === 'en' ? Language.EN : Language.KO
        setLang(language)

        // eslint-disable-next-line
    }, [navigator]);

    useEffect(() => {
        token && getKpassTestResult(testIdx, token).then()
        //eslint-disable-next-line
    }, [getKpassTestResult]);

    useEffect(() => {
        const metaViewport = document.querySelector('meta[name="viewport"]');
        if (metaViewport) {
            metaViewport.setAttribute('content', 'width=1200px');
        }

    }, []);

    return (
        <>
            {/*  최상단 헤던  */}
            <TestResultHeader/>

            {/*  플로팅 탑 버튼  */}
            <TestTopButton/>

            {kpassTestResult && (
                <div className={style.pageWrapper}>
                    {/*  학습진로가이드 배너  */}
                    <TestResultGuideBanner testerName={kpassTestResult.testResultUserProfileVO.name}
                                           bannerType={ResultType.TEST_RESULT}/>

                    {/*  검사결과 안내페이지  */}
                    <TestResultInfo testerName={kpassTestResult.testResultUserProfileVO.name}/>

                    {/*  검사결과보고서 표지  */}
                    <TestResultCover testResult={kpassTestResult}/>
                    <table>
                        {/*  프린트시 여백 처리  */}
                        <KpassPrintAssistant/>

                        {/*  01 전체 척도 결과  */}
                        <KpassTestResult resultStep={TestResultPath.TOTAL_SCALE}
                                         testResult={kpassTestResult}/>

                        {/*  02 하위척도별 지수  */}
                        <KpassTestResult resultStep={TestResultPath.LOW_LEVEL_SCALE}
                                         testResult={kpassTestResult}/>

                        {/*  03 규준적 강/약 분석 지수  */}
                        <KpassTestResult resultStep={TestResultPath.AVERAGE_STRENGTH_AND_WEAKNESS}
                                         testResult={kpassTestResult}/>

                        {/*  04 개인 내적 강/약 분석  */}
                        <KpassTestResult resultStep={TestResultPath.PERSONAL_STRENGTH_AND_WEAKNESS}
                                         testResult={kpassTestResult}/>

                        {/*  05 학습유형 판단을 위한 ~~  */}
                        <KpassTestResult resultStep={TestResultPath.PROFILE_ANALYSIS}
                                         testResult={kpassTestResult}/>

                        {/*  06 K-PASS 검사 소개  */}
                        <KpassTestResult resultStep={TestResultPath.INTRODUCTION_ABOUT_KPASS}
                                         testResult={kpassTestResult}/>

                        {/* todo 수정예정*/}
                        <div style={{height: '6900px'}} className={style.emptySpace}/>
                    </table>
                </div>
            )}
        </>
    );
}