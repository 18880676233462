import {ResultType} from "../../../types/testResult";
import style from "../../style/test_result/testResultGuideBanner.module.scss";
import {Trans, useTranslation} from "react-i18next";
import React from "react";
import {useRefContext} from "../guide/hooks/GuideRefProvider";
import {useNavigate, useParams} from "react-router-dom";

interface Args {
    testerName: string,
    type: string,
    description: string,
    bannerName: string,
    buttonText: string,
    iconSrc: string
}

export function BannerContent(
    {testerName, type, description, bannerName, buttonText, iconSrc}
        : Args) {

    const {t} = useTranslation("testResult");
    const {id} = useParams<"id">();
    const testIdx = Number(id);
    const navigate = useNavigate();
    const {mainRef, leftRef, rightRef, handleDownloadButton} = useRefContext();

    const handleTestGuide = () =>{
        navigate(`/test-result/guide/${testIdx}`)
    }

    const bannerButton = type === ResultType.TEST_RESULT
        // 검사결과지인 경우 학습진로가이드 버튼
        ? <>
            <img className={style.linkey} src="https://images.feel-good.io/report/linkey.png" alt="링키"/>
            <div className={`${style.linkButton} ${style.hover}`} onClick={handleTestGuide}>
                <div className={style.hoverBg}></div>
                <div className={style.buttonLabel}>
                    <Trans t={t} i18nKey={buttonText}
                           components={{strong: <span className={style.strong}/>, br: <br/>}}/>
                </div>
                <img className={style.icon} src={iconSrc} alt="right arrow"/>
            </div>
        </>

        // 학습진로가이드인 경우 카드 다운로드
        : <>
            <div className={`${style.linkButton} ${style.learningCardButton} ${style.hover}`}
                 onClick={() => handleDownloadButton(mainRef, leftRef, rightRef)}>
                <div className={style.hoverBg}></div>
                <div className={style.buttonLabel}>
                    <Trans t={t} i18nKey={buttonText}
                           components={{strong: <span className={style.strong}/>, br: <br/>}}/>
                </div>
                <img className={style.icon} src={iconSrc} alt="right arrow"/>
            </div>
        </>


    return (

        <>
            <div className={`${style.guideBannerWrapper} 
            ${type === ResultType.TEST_RESULT ? '' : style.learningCard}`}>
                <div className={style.guideBanner}>
                    <div className={style.bannerTexts}>
                        <div className={style.bannerTitle}>
                            <span className={style.strong}>{testerName}</span>
                            {t('sir')}
                            <div className={style.pointWrapper}>
                                {/*  검사결과지인 경우에만 new 뱃지 추가  */}
                                {type === ResultType.TEST_RESULT && (
                                    <span className={style.newBadge}>New!
                                    <img className={style.tail}
                                         src="https://images.feel-good.io/report/new_badge_tail.svg"
                                         alt="new badge tail"/>
                                  </span>
                                )}
                                <span className={style.point}>{t(`${bannerName}`)}</span>
                            </div>
                            <span>{t('testResultGuideBanner.isReady')}</span>
                        </div>
                        <div className={style.bannerDescription}>
                            <Trans t={t} i18nKey={t(`${description}`)}
                                   components={{strong: <span className={style.strong}/>, br: <br/>}}/>
                        </div>
                    </div>
                    <div className={`${style.guideLinkButtonWrapper} ${type === ResultType.TEST_RESULT
                        ? '' : style.learningCardWrapper}`}
                    >
                        {bannerButton}
                    </div>
                </div>
            </div>
        </>
    )
}