import {useCallback, useMemo, useState} from "react";
import {ordersToSortArr, PageResponse, PageSearchCondition} from "../../../../../types/page";
import {AdminTestHistoryListObjByProfile} from "../../../../../types/userProfile";
import {ProfileVoucherHistorySearchCondition} from "../../../../../types/voucher";
import {callAsync} from "../../../../../util/sliceUtil";
import {getTestHistoriesByUserProfileAsync} from "../../../../../store/userProfileSlice";
import {useAppDispatch} from "../../../../../hooks/hooks";
const LIST_SIZE = 5;

export function useTesterHistoryGetter() {

    const dispatch = useAppDispatch();
    const [totalTesting, setTotalTesting] = useState(0)
    const [testHistoryList, setTestHistoryList] =
        useState<PageResponse<AdminTestHistoryListObjByProfile>>();

    const testHistoryPageCondition = useMemo(
        () => ({
            page: testHistoryList?.number ?? 0,
            size: testHistoryList?.size ?? 5,
            sort: ordersToSortArr(testHistoryList?.orders),
        }),
        [testHistoryList]
    );

    const getHistoryList = useCallback((
        {page, sort, profileIdx}: PageSearchCondition & { usable?: boolean, profileIdx: number }) => {
        const param: ProfileVoucherHistorySearchCondition = {
            pageCondition: {
                page: page ?? 0,
                size: LIST_SIZE,
                sort: sort ?? testHistoryPageCondition.sort,
            },
            profileIdx: profileIdx,
        };
        callAsync(
            dispatch(getTestHistoriesByUserProfileAsync({userProfileIdx: profileIdx, condition: param})).unwrap(),
            (result) => {
                setTestHistoryList(result);
            },
            () => alert('일시적인 문제가 발생했습니다.\n다시 시도해주세요.')
        ).then();
        //eslint-disable-next-line
    }, [dispatch, testHistoryList]);


    const getTestHistoryList = (
        {page, sort, profileIdx}:
            PageSearchCondition & { profileIdx: number }) => {
        console.log(profileIdx)
        const param: ProfileVoucherHistorySearchCondition = {
            pageCondition: {
                page: page ?? 0,
                size: LIST_SIZE,
                sort: sort ?? testHistoryPageCondition.sort,
            },
            profileIdx: profileIdx,
        };

        getHistoryList(param)
        return () => {
            callAsync(
                dispatch(getTestHistoriesByUserProfileAsync({userProfileIdx: profileIdx, condition: param})).unwrap(),
                (result) => {
                    setTestHistoryList(result)
                    setTotalTesting(result.totalElements)
                },
                () => alert('일시적인 문제가 발생했습니다.\n다시 시도해주세요.')
            ).then();
        };
    };

    return {
        getTestHistoryList, getHistoryList, testHistoryList, testHistoryPageCondition, totalTesting
    }
}