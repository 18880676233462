import { DetailSubMenus, DetailTab } from "../../../../layout/content/detail/DetailSubMenus";
import { MemberOrganManagerDetailTabType, OrganManagerStatus } from "../../../../types/organManager";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import queryString from "query-string";
import { useNavigate, useParams } from "react-router-dom";
import { OrganManagerInfo } from "./info/OrganManagerInfo";
import { PanelWrapper } from "../../../../layout/content/detail/PanelWrapper";
import { OrganManagerOrgans } from "./organ/OrganManagerOrgans";
import { OrganManagerCouponAndVoucher } from "./coupon_and_voucher/OrganManagerCouponAndVoucher";
import { useOrganManagerStatus } from "../hooks/useOrganManagerStatus";
import { useOrganManagerGetter } from "./info/hooks/useOrganManagerGetter";
import { MemberOrderList } from "../../components/order/MemberOrderList";
import { useTranslation } from "react-i18next";
import { OrganManagerAffiliateCode } from "./affiliate_code/OrganManagerAffiliateCode";
import {useLanguage} from "../../../../hooks/hooks";

export function MemberOrganManagerDetail() {
  const { t } = useTranslation("organManager");
  const navigate = useNavigate();

  const { id } = useParams<"id">();
  const organManagerIdx = useMemo(() => Number(id), [id]);
  const { tab } = queryString.parse(window.location.search);
  const [selectedTab, setSelectedTab] = useState<MemberOrganManagerDetailTabType | undefined>();
  const { isLeave } = useOrganManagerStatus();
  const { organManagerVO, getOrganManager } = useOrganManagerGetter();
  const {isKorean} = useLanguage();

  useEffect(() => {
    if (organManagerVO?.idx !== organManagerIdx) {
      getOrganManager(organManagerIdx).then();
    }
  }, [organManagerVO?.idx, getOrganManager, organManagerIdx]);

  const getTabMenus = useCallback(
    (status: OrganManagerStatus): DetailTab<MemberOrganManagerDetailTabType>[] => {
      if (isLeave(status)) {
        return [
          {
            tabValue: MemberOrganManagerDetailTabType.SELF_INFO,
            tabName: t("detail.submenus.memberInfo"),
          },
        ];
      } else {
        return [
          {
            tabValue: MemberOrganManagerDetailTabType.SELF_INFO,
            tabName: t("detail.submenus.memberInfo"),
          },
          {
            tabValue: MemberOrganManagerDetailTabType.ORDER,
            tabName: t("detail.submenus.payments"),
          },
          {
            tabValue: MemberOrganManagerDetailTabType.COUPON_VOUCHER,
            tabName: t("detail.submenus.couponAndVoucher"),
          },
          {
            tabValue: MemberOrganManagerDetailTabType.ORGAN,
            tabName: t("detail.submenus.organManagement"),
          },
          ...(isKorean ? [{
            tabValue: MemberOrganManagerDetailTabType.AFFILIATE_CODE,
            tabName: "제휴코드",
          }] : [])];
      }
    },
      // eslint-disable-next-line
    [isLeave, t]
  );

  const onClickTabMenu = (tab: MemberOrganManagerDetailTabType) => {
    navigate(`/detail/member/organ-manager/${organManagerIdx}?${queryString.stringify({ tab })}`);
  };

  useEffect(() => {
    setSelectedTab(
      typeof tab === "undefined" ? MemberOrganManagerDetailTabType.SELF_INFO : (tab as MemberOrganManagerDetailTabType)
    );
  }, [tab]);

  const getDetailContent = useCallback(
    (tab: MemberOrganManagerDetailTabType) => {
      switch (tab) {
        case MemberOrganManagerDetailTabType.SELF_INFO:
          return <OrganManagerInfo idx={organManagerIdx} />;
        case MemberOrganManagerDetailTabType.ORDER:
          return <MemberOrderList organManagerIdx={organManagerIdx} />;
        case MemberOrganManagerDetailTabType.COUPON_VOUCHER:
          return <OrganManagerCouponAndVoucher organManagerIdx={organManagerIdx} />;
        case MemberOrganManagerDetailTabType.ORGAN:
          return <OrganManagerOrgans organManagerIdx={organManagerIdx} />;
        case MemberOrganManagerDetailTabType.AFFILIATE_CODE:
          return <OrganManagerAffiliateCode organManagerIdx={organManagerIdx} />;
      }
    },
    [organManagerIdx]
  );

  return selectedTab && organManagerVO ? (
    <>
      <DetailSubMenus tabs={getTabMenus(organManagerVO.status)} onClickTab={onClickTabMenu} selectedTab={selectedTab} />
      <PanelWrapper>{getDetailContent(selectedTab)}</PanelWrapper>
    </>
  ) : (
    <></>
  );
}
