import style from "../../style/test_result/testResult.module.scss";
import React, {useEffect, useRef, useState} from "react";
import {TestResultPath, TestResultReportDTO, TestTypes} from "../../../types/testResult";
import {TestSummaryHeader} from "../common/TestSummaryHeader";
import {Trans, useTranslation} from "react-i18next";


const createTestCase = (
    result: string,
    number: string,
    type: TestTypes,
    typeKorean: string,
    compareValue: string,
    width: any,
    score: any,
    imgSrc: string
) => ({
    result,
    number,
    type,
    typeKorean,
    compareValue,
    width,
    score,
    imgSrc,
});

interface Args {
    testResult?: TestResultReportDTO
}

/* eslint-disable max-len */
export function KpassTestResultAverage({testResult}: Args) {
    /**
     * 검사 결과 중 03 규준적 강/약 분석
     */
    const name = testResult?.testResultUserProfileVO.name;
    const {t} = useTranslation("average");

    const [successiveScore, setSuccessiveScore] = useState('0');
    const [simultaneousScore, setSimultaneousScore] = useState('0');
    const [planningScore, setPlaningScore] = useState('0');
    const [attentionScore, setAttentionScore] = useState('0');

    const highGraph = 'https://images.feel-good.io/report/ns_graph.svg'
    const avgGraph = 'https://images.feel-good.io/report/avg_graph.svg'
    const lowGraph = 'https://images.feel-good.io/report/nw_graph.svg'

    const ref = useRef(null);
    const DEFAULT_X_POSITION = 260;

    // 각 처리 능력 별 점수 계산 함수
    const calculateScores = (value: number) => {
        if (ref.current) {
            const computedStyle = window.getComputedStyle(ref.current);
            const width = parseInt(computedStyle.width)
            const leftDefault = DEFAULT_X_POSITION - Math.round(width / 2);
            return `${leftDefault + (400 * (value - 40)) / 120}px`;
        }
        return '0px';
    };

    useEffect(() => {
        if (testResult) {
            setSuccessiveScore(calculateScores(testResult?.testResultVO.successiveScore.score));
            setSimultaneousScore(calculateScores(testResult?.testResultVO.simultaneousScore.score));
            setPlaningScore(calculateScores(testResult?.testResultVO.planningScore.score));
            setAttentionScore(calculateScores(testResult?.testResultVO.attentionScore.score));
        }
        //eslint-disable-next-line
    }, []);


    // 검사결과 그래프 호출 함수
    const testCaseBox = (testType: TestTypes, strength: string) => {

        const testCases = [
            // 각 유형별 검사 결과 케이스 추가
            createTestCase('NS', '1', TestTypes.SUCCESSIVE, t('table.successiveAbility'), t('average.relativeHighLevel'), successiveScore, testResult?.testResultVO.successiveScore.score, highGraph),
            createTestCase('-', '1', TestTypes.SUCCESSIVE, t('table.successiveAbility'), t('average.relativeAverageLevel'), successiveScore, testResult?.testResultVO.successiveScore.score, avgGraph),
            createTestCase('NW', '1', TestTypes.SUCCESSIVE, t('table.successiveAbility'), t('average.relativeLowLevel'), successiveScore, testResult?.testResultVO.successiveScore.score, lowGraph),

            createTestCase('NS', '2', TestTypes.SIMULTANEOUS, t('table.simultaneousAbility'), t('average.relativeHighLevel'), simultaneousScore, testResult?.testResultVO.simultaneousScore.score, highGraph),
            createTestCase('-', '2', TestTypes.SIMULTANEOUS, t('table.simultaneousAbility'), t('average.relativeAverageLevel'), simultaneousScore, testResult?.testResultVO.simultaneousScore.score, avgGraph),
            createTestCase('NW', '2', TestTypes.SIMULTANEOUS, t('table.simultaneousAbility'), t('average.relativeLowLevel'), simultaneousScore, testResult?.testResultVO.simultaneousScore.score, lowGraph),

            createTestCase('NS', '3', TestTypes.PLANNING, t('table.planningAbility'), t('average.relativeHighLevel'), planningScore, testResult?.testResultVO.planningScore.score, highGraph),
            createTestCase('-', '3', TestTypes.PLANNING, t('table.planningAbility'), t('average.relativeAverageLevel'), planningScore, testResult?.testResultVO.planningScore.score, avgGraph),
            createTestCase('NW', '3', TestTypes.PLANNING, t('table.planningAbility'), t('average.relativeLowLevel'), planningScore, testResult?.testResultVO.planningScore.score, lowGraph),

            createTestCase('NS', '4', TestTypes.ATTENTION, t('table.attentionAbility'), t('average.relativeHighLevel'), attentionScore, testResult?.testResultVO.attentionScore.score, highGraph),
            createTestCase('-', '4', TestTypes.ATTENTION, t('table.attentionAbility'), t('average.relativeAverageLevel'), attentionScore, testResult?.testResultVO.attentionScore.score, avgGraph),
            createTestCase('NW', '4', TestTypes.ATTENTION, t('table.attentionAbility'), t('average.relativeLowLevel'), attentionScore, testResult?.testResultVO.attentionScore.score, lowGraph),
        ]
        return (
            testCases.map((data, index) => (
                // testCases 중 검사타입(순차처리, 동시처리 등등)과 검사결과(상대적 상, 평균, 하) 체크하여 결과 값 반환
                data.type === testType && strength === data.result && (
                    <div className={style.result} key={index}>
                        <div className={style.title}>
                            <h4>
                                {/*  1 순차처리 능력의 규준적 강/약 판정결과  */}
                                <Trans t={t} i18nKey="average.contentTitle"
                                       values={{
                                           number: data.number,
                                           testType: data.typeKorean,
                                       }}
                                       components={{
                                           numbering: <span className={style.numbering}/>,
                                           span: <span/>,
                                       }}
                                />
                            </h4>
                            <p className={style.paragraph}>
                                <Trans t={t} i18nKey="average.contentDescription"
                                       values={{
                                           name: name,
                                           testType: data.typeKorean,
                                           compareValue: data.compareValue,
                                           blank: '\u00A0',
                                       }}
                                       components={{
                                           strong: <span className={style.strong}/>,
                                           br: <br/>,
                                       }}
                                />
                            </p>
                        </div>
                        <div className={`${style.content} ${style.judgementResult}`}>
                            <img src={data.imgSrc} alt="graph"/>
                            <div className={style.point} style={{left: data.width}} ref={ref}>
                                <span className={style.score}>{data.score}</span>
                                <img className={style.tail} src="https://images.feel-good.io/report/bubble_tail.svg"
                                     alt="bubble tail"/>
                                <div className={style.circle}></div>
                            </div>
                        </div>
                    </div>
                )))

        )
    }
    return (
        <>
            {/*  03 하위척도별 지수 {name}의 전반적인 지능의 수준은 어느 정도인가?  */}
            <TestSummaryHeader testResultPath={TestResultPath.AVERAGE_STRENGTH_AND_WEAKNESS}
                               testerName={testResult?.testResultUserProfileVO.name}/>
            <div className={style.underline}></div>

            <article className={style.mainWrapper}>

                <table className={`${style.table} ${style.subscale}`}>
                    <thead>
                    <tr>
                        <th rowSpan={2}>{t('table.lowLevel')}</th>
                        <th>{t('table.standardScore')}</th>
                        <th>{t('average.averageTitle')}</th>
                    </tr>
                    <tr>
                        <th><span className={style.light}>M=100, SD=15</span></th>
                        <th><span className={style.light}>{t('average.judgementLabel')}</span>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <th>{t('table.successive')}</th>
                        <td><span className={style.red}>{testResult?.testResultVO.successiveScore.score}</span></td>
                        <td><span
                            className={style.red}>{testResult?.testResultComputedDataVO.a2OfSuccessiveScore}</span></td>
                    </tr>
                    <tr>
                        <th>{t('table.simultaneous')}</th>
                        <td><span className={style.red}>{testResult?.testResultVO.simultaneousScore.score}</span></td>
                        <td><span
                            className={style.red}>{testResult?.testResultComputedDataVO.a2OfSimultaneousScore}</span>
                        </td>
                    </tr>
                    <tr>
                        <th>{t('table.planning')}</th>
                        <td><span className={style.red}>{testResult?.testResultVO.planningScore.score}</span></td>
                        <td><span className={style.red}>{testResult?.testResultComputedDataVO.a2OfPlanningScore}</span>
                        </td>
                    </tr>
                    <tr>
                        <th>{t('table.attention')}</th>
                        <td><span className={style.red}>{testResult?.testResultVO.attentionScore.score}</span></td>
                        <td><span className={style.red}>{testResult?.testResultComputedDataVO.a2OfAttentionScore}</span>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <p className={style.paragraph}>
                    <Trans t={t} i18nKey="average.mainSummary"
                           values={{
                               name: name,
                               blank: '\u00A0'
                           }}
                           components={{
                               strong: <span className={style.strong}/>,
                           }}
                    />

                </p>

                {testResult && (
                    <>
                        {testCaseBox(TestTypes.SUCCESSIVE, testResult?.testResultComputedDataVO.a2OfSuccessiveScoreCode)}
                        {testCaseBox(TestTypes.SIMULTANEOUS, testResult?.testResultComputedDataVO.a2OfSimultaneousScoreCode)}
                        {testCaseBox(TestTypes.PLANNING, testResult?.testResultComputedDataVO.a2OfPlanningScoreCode)}
                        {testCaseBox(TestTypes.ATTENTION, testResult?.testResultComputedDataVO.a2OfAttentionScoreCode)}
                    </>
                )}

            </article>
        </>
    )
}