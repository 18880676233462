import {ValidStatus} from "../../../../types/common";
import style from "../../style/registration.module.scss";
import React from "react";

export function useErrorMessageHandler() {

    const passwordErrorHandler = (validate:ValidStatus) =>{
        if (validate === ValidStatus.WRONG_TYPE) {
            return (
                <div className={style.inValidateText}>입력조건에 맞는 비밀번호를 입력해주세요.</div>
            )
        }

        if (validate === ValidStatus.MISMATCH) {
            return (
                <div className={style.inValidateText}>입력하신 비밀번호와 다릅니다.</div>
            )
        }


    }

    return{
        passwordErrorHandler
    }
}