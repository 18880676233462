import {MainApi} from "./MainApi";
import {
    AssignVoucherDTO,
    ExternalInformationPutDTO,
    ExternalVoucherPostDTO,
    VoucherDeleteDTO,
    VoucherListSearchCondition
} from "../types/externalVoucher";

export class ExternalVoucherApi {
    static baseUrl = `${MainApi.urlPrefix}/external-voucher`;

    // 이용권 목록 조회
    static getExternalVoucherList = (condition: VoucherListSearchCondition) => () =>
        MainApi.api.get(`${ExternalVoucherApi.baseUrl}${MainApi.toParamStringFromObject(condition)}`);

    // 이용권 상세 조회
    static getExternalVoucherDetail = (idx: number) => () =>
        MainApi.api.get(`${ExternalVoucherApi.baseUrl}/${idx}`);

    // 이용권 등록
    static createVouchers = (dto: ExternalVoucherPostDTO) => () =>
        MainApi.api.post(`${ExternalVoucherApi.baseUrl}`,dto);

    // 이용권 안내 등록
    static informationVoucher = (dto: ExternalInformationPutDTO) => () =>
        MainApi.api.put(`${ExternalVoucherApi.baseUrl}`,dto);

    // 이용권 삭제
    static deleteVoucher = (dto: VoucherDeleteDTO) => () =>
        MainApi.api.post(`${ExternalVoucherApi.baseUrl}/delete`,dto);

    // 이용권 등록
    static assignVoucher = (dto: AssignVoucherDTO) => () =>
        MainApi.api.post(`${ExternalVoucherApi.baseUrl}/assign`,dto);
}