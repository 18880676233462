import style from "../style/registration.module.scss";
import {CounselorDuplicateCheckButtonFiled} from "./CounselorDuplicateCheckButtonFiled";
import {CounselorInfoAddressField} from "./CounselorInfoAddressField";
import {CounselorShortInputField} from "./CounselorShortInputField";
import {CounselorCheckboxField} from "./CounselorCheckboxField";
import {CounselorPriceField} from "../CounselorPriceField";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {
    CounselorCheckOptions,
    CounselorDuplicateType,
    CounselorGroupDTO,
    TaxSelectedValue,
} from "../../../types/counselor";
import {CounselorRadioField} from "./CounselorRadioField";
import {Label104x46} from "../../../components/Label";
import {Address} from "../../../types/user";
import {CounselorLongInputField} from "./CounselorLongInputField";
import {ValidStatus} from "../../../types/common";

interface Args {
    groupDTO?: CounselorGroupDTO;
    setGroupDTO?: (_: CounselorGroupDTO) => void;
    groupNameValidate: ValidStatus;
    setGroupNameValidate: (_: ValidStatus) => void;
    addressValidate: ValidStatus;
    setAddressValidate: (_: ValidStatus) => void;
    affiliationDistributionValidate: ValidStatus;
    setAffiliationDistributionValidate: (_: ValidStatus) => void;
    consultantDistributionValidate: ValidStatus;
    setConsultantDistributionValidate: (_: ValidStatus) => void;
}

export function CounselorGroupField(
    {
        groupDTO, setGroupDTO,
        groupNameValidate, setGroupNameValidate,
        addressValidate, setAddressValidate,
        affiliationDistributionValidate, setAffiliationDistributionValidate,
        consultantDistributionValidate, setConsultantDistributionValidate
    }
        : Args) {
    /**
     * 상담 소속정보 영역
     */
    const {t} = useTranslation("counselorGroup");

    // 상담 소속정보
    const [groupName, setGroupName] = useState('')
    const [groupAddress, setGroupAddress] = useState<Address>({
        address1: '',
        address2: '',
        address3: "",
        country: "",
        city: ""
    })
    const [cellPhone, setCellPhone] = useState('');
    const [briefHistory, setBriefHistory] = useState('');
    const [bankName, setBankName] = useState('');
    const [accountNumber, setAccountNumber] = useState('');
    const [accountHolder, setAccountHolder] = useState('');
    const [affiliationDistributionAmount, setAffiliationDistributionAmount] = useState(0);
    const [consultantDistributionAmount, setConsultantDistributionAmount] = useState(0);
    const [isVatInclude, setIsVatInclude] = useState(TaxSelectedValue.UNSELECT)
    const [isTaxDeducted, setIsTaxDeducted] = useState(TaxSelectedValue.UNSELECT)


    useEffect(() => {
        // const newCounselorDTO:CounselorManagerDTO = {
        if (setGroupDTO) {
            setGroupDTO({
                name: groupName,
                address: groupAddress,
                codePhone: cellPhone,
                phoneNumber: cellPhone,
                briefHistory: briefHistory,
                bankName: bankName,
                accountNumber: accountNumber,
                accountName: accountHolder,
                affiliationDistributionAmount: affiliationDistributionAmount,
                consultantDistributionAmount: consultantDistributionAmount,
                isVatInclude: isVatInclude === TaxSelectedValue.SELECT,
                isTaxDeduction: isTaxDeducted === TaxSelectedValue.SELECT,
            });
        }
        //eslint-disable-next-line
    }, [groupName, groupAddress, cellPhone, briefHistory, bankName, accountNumber, accountHolder,
        //eslint-disable-next-line
        affiliationDistributionAmount, consultantDistributionAmount, isVatInclude, isTaxDeducted]);

    useEffect(() => {
    }, [groupNameValidate]);


    return (
        <>
            {/*  상담 소속이름  */}
            <CounselorDuplicateCheckButtonFiled
                duplicateType={CounselorDuplicateType.GROUP_NAME}
                minLength={2} maxLength={50}
                label={t('registration.group.name')}
                placeHolder={t('registration.groupPlaceHolder')}
                isLabelVisible={true}
                value={groupName} setValue={setGroupName}
                validate={groupNameValidate}
                setValidate={setGroupNameValidate}
            />

            {/*  상담 소속주소  */}
            <CounselorInfoAddressField
                value={groupAddress} setValue={setGroupAddress}
                validate={addressValidate} setValidate={setAddressValidate}
            />

            {/*  전화번호  */}
            <CounselorShortInputField
                label={t('registration.group.cellPhone')}
                placeHolder={t('registration.cellPhonePlaceHolder')}
                inputType={'text'}
                minLength={0} maxLength={10}
                isRequired={false}
                isLabelVisible={true}
                value={cellPhone} setValue={setCellPhone}
            />

            {/*  상담 소속약력  */}
            <CounselorLongInputField
                label={t('registration.group.counselorProfile')}
                isRequired={false}
                placeHolder={t('registration.profilePhonePlaceHolder')}
                value={briefHistory}
                setValue={setBriefHistory}
            />

            {/*  소속 계좌정보  */}
            <CounselorCheckboxField
                label={t("registration.group.accountInfo")}
                isRequired={false}
                placeHolder={t('registration.accountPlaceHolder')}
                additionalText={"정산 받으실 은행을 입력해주세요."}
                checkboxOption={CounselorCheckOptions.ACCOUNT}
                option={bankName} setOption={setBankName}
            >
                <div className={style.childrenWrapper}>
                    <CounselorShortInputField
                        label={''}
                        placeHolder={(t('registration.accountNumberPlaceHolder'))}
                        inputType={'text'}
                        minLength={2} maxLength={20}
                        isRequired={true}
                        isLabelVisible={false}
                        value={accountNumber} setValue={setAccountNumber}
                    />
                    <CounselorShortInputField
                        label={''}
                        placeHolder={(t('registration.accountNamePlaceHolder'))}
                        inputType={'text'}
                        minLength={2} maxLength={50}
                        isRequired={true}
                        isLabelVisible={false}
                        value={accountHolder} setValue={setAccountHolder}
                    />
                </div>
            </CounselorCheckboxField>

            {/*  소속 분배금 & 상담사 분배금  */}
            <div className={style.priceMainWrapper}>
                {/*  소속 분배금  */}
                <div className={style.priceInputWrapper}>
                    <Label104x46 text={t("registration.group.groupDistribution")} isRequired={true}/>
                    <CounselorPriceField
                        value={affiliationDistributionAmount} setValue={setAffiliationDistributionAmount}
                        validate={affiliationDistributionValidate}
                        setValidate={setAffiliationDistributionValidate}
                    />
                </div>

                {/*  상담사 분배금  */}
                <div className={style.priceInputWrapper}>
                    <Label104x46 text={t("registration.group.counselorDistribution")} isRequired={true}/>
                    <CounselorPriceField
                        value={consultantDistributionAmount} setValue={setConsultantDistributionAmount}
                        validate={consultantDistributionValidate}
                        setValidate={setConsultantDistributionValidate}
                    />
                </div>
            </div>

            {/*  부가세 / 세금공제  */}
            <div className={style.taxInputWrapper}>
                <div className={style.taxArea}>
                    <Label104x46 text={t("registration.group.addedTax")} isRequired={false}/>
                    <CounselorRadioField
                        selectedValue={isVatInclude}
                        setSelectedValue={(v) => setIsVatInclude(v)}
                        groupName={"groupTaxAdded"}
                    />
                </div>

                <div className={style.taxArea}>
                    <Label104x46 text={t("registration.group.taxDeduction")} isRequired={false}/>
                    <CounselorRadioField
                        selectedValue={isTaxDeducted}
                        setSelectedValue={(v) => setIsTaxDeducted(v)}
                        groupName={"groupTaxDeduction"}
                    />
                </div>
            </div>
        </>
    );
}